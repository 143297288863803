import React from 'react'
import {makeStyles} from '@material-ui/core/styles'
import {PropTypes} from 'prop-types'

import {Avatar, Typography} from '@material-ui/core'
import {BLUE, DEFAULT_AVATAR} from '../../../utils/constants'
import ScoreComponent from "../../common/ScoreComponent";
import LatencyComponent from "../../item/LatencyComponent";
import {getAvatar} from "../../../utils/utils";
import UserDescriptionComponent from "./UserDescriptionComponent";

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexFlow: 'wrap row',
        justifyContent: 'flex-start',
        alignItems: 'center',
        background: 'white',
        color: BLUE,
    },
    detail: {
        display: 'flex',
        flexFlow: 'wrap column',
        justifyContent: 'space-between',
        alignItems: 'flex-start',
    },
    name: {
        fontWeight: 'bold',
        marginLeft: theme.spacing(),
    },
    location: {
        marginLeft: theme.spacing(),
    },
    avatar: {
        margin: theme.spacing(),
        marginRight: theme.spacing(2),
        width: 60,
        height: 60,
    },
    line: {
        marginLeft: theme.spacing(),
    },
    left: {
        display: 'flex',
        flexFlow: 'nowrap column',
    },
    core: {
        display: 'flex',
        flexFlow: 'wrap row',
    },
}));

export default function UserDetailMiniCard({user}) {
    const classes = useStyles();
    return (
        <div className={classes.root}>
            <div className={classes.left}>
                <div className={classes.core}>
                    <Avatar alt={user.nickname} src={getAvatar(user.avatar)} className={classes.avatar}
                            imgProps={{
                                onError: e => e.target.src = DEFAULT_AVATAR
                            }}
                    />
                    <div className={classes.detail}>
                        <Typography variant={'h6'} className={classes.name}>{user.nickname}</Typography>
                        <div className={classes.root}>
                            <ScoreComponent score={user.score}/>
                        </div>
                        <Typography variant={'caption'} className={classes.location}>{user.location}</Typography>
                    </div>
                </div>
                <UserDescriptionComponent nickname={user.nickname} description={user.description}/>
            </div>
            <LatencyComponent latency={user.responseLatency}/>
        </div>
    );
}

UserDetailMiniCard.propTypes = {
    user: PropTypes.object.isRequired,
};