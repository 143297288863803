import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import {makeStyles, Typography} from "@material-ui/core";
import {DATE_TIME_FORMAT, MARINE} from "../../utils/constants";
import LoanStateComponent from "./LoanStateComponent";
import {get} from "../common/i18n/i18n";

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexFlow: 'nowrap column',
        alignItems: 'space-between',
        minHeight: 90,
        minWidth: 195,
    },
    state: {
        display: 'flex',
        flexFlow: 'nowrap row',
        justifyContent: 'space-around',
        alignItems: 'center',
    },
    title: {
        position: 'relative',
        textTransform: 'upperCase',
        fontWeight: 'bold',
        color: MARINE,
    },
    date: {
        position: 'relative',
        top: '-2rem',
        backgroundColor: MARINE,
        marginBottom: '-2rem',
        borderBottomLeftRadius: theme.spacing(),
        borderBottomRightRadius: theme.spacing(),
        display: 'flex',
        flexFlow: 'wrap column',
        justifyContent: 'center',
        alignItems: 'center',
    },
    dateText: {
        fontWeight: 'bold',
        color: 'white',
    }
}));

function formatDate(date) {
    if (!date) {
        return '';
    }
    return moment.utc(date, DATE_TIME_FORMAT).tz(moment.tz.guess()).format('DD/MM');
}

function formatHour(date, hour) {
    if (!date) {
        return '';
    }
    const format = hour ? 'HH:mm [h]' : 'HH:mm';
    return moment.utc(date, DATE_TIME_FORMAT).tz(moment.tz.guess()).format(format);
}

export default function LoanInteractionComponent(props) {
    const {state, date, deliveryDate, deliveryError, receptionDate, receptionError, color} = props;
    const classes = useStyles();
    return (
        <div className={classes.root}>
            <div className={classes.state}>
                <LoanStateComponent
                    date={formatDate(deliveryDate)}
                    hour={formatHour(deliveryDate)}
                    state={get('loan_lifecycle_delivered')}
                    color={color}
                    error={deliveryError}
                />
                <Typography className={classes.title} variant={'body1'}>
                    {state}
                </Typography>
                <LoanStateComponent
                    date={formatDate(receptionDate)}
                    hour={formatHour(receptionDate)}
                    state={get('loan_lifecycle_received')}
                    color={color}
                    error={receptionError}
                />
            </div>
            <div className={classes.date}>
                <Typography className={classes.dateText} variant={'body1'}>
                    {formatDate(date)}
                </Typography>
                <Typography className={classes.dateText} variant={'body1'}>
                    {formatHour(date, true)}
                </Typography>
            </div>
        </div>
    );
}

LoanInteractionComponent.propTypes = {
    state: PropTypes.string.isRequired,
    date: PropTypes.string.isRequired,
    deliveryDate: PropTypes.string,
    deliveryError: PropTypes.string,
    receptionDate: PropTypes.string,
    receptionError: PropTypes.string,
};