export const FIREBASE_AUTHENTICATED = 'FIREBASE_AUTHENTICATED';
export const REQUEST_USER_PUBLIC_PROFILE = 'REQUEST_USER_PUBLIC_PROFILE';
export const GOT_USER_PUBLIC_PROFILE = 'GOT_USER_PUBLIC_PROFILE';

export const SEARCH_ITEM_ACTION = 'SEARCH_ITEM_ACTION';
export const GOT_SEARCH_ITEMS_RESPONSE_ACTION = 'GOT_SEARCH_ITEMS_RESPONSE_ACTION';
export const GOT_SEARCH_ITEMS_ERROR_ACTION = 'GOT_SEARCH_ITEMS_ERROR_ACTION';
export const UPDATE_GEOPOSITION_ACTION = 'UPDATE_GEOPOSITION_ACTION';

export const ADD_NOTIFICATIONS_PENDING_ACTION = 'ADD_NOTIFICATIONS_PENDING_ACTION';
export const RELOAD_NOTIFICATIONS_PENDING_ACTION = 'RELOAD_NOTIFICATIONS_PENDING_ACTION';
export const UPDATED_NOTIFICATIONS_PENDING_ACTION = 'UPDATED_NOTIFICATIONS_PENDING_ACTION';

export const ADD_MESSAGES_PENDING_ACTION = 'ADD_MESSAGES_PENDING_ACTION';
export const RELOAD_MESSAGES_PENDING_ACTION = 'RELOAD_MESSAGES_PENDING_ACTION';
export const UPDATED_MESSAGES_PENDING_ACTION = 'UPDATED_MESSAGES_PENDING_ACTION';
