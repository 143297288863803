const es = {
    'myur': 'la plataforma para alquilarlo todo!',
    // NOTIFICATION CHAT
    '%new_message%': 'Nuevo mensaje de {0}',
    // NOTIFICATION LOAN
    '%loan_request%': 'Solicitud de alquiler ',
    '%cancelled_loan%': 'Alquiler cancelado',
    '%denied_loan%': 'Alquiler denegado',
    '%authorized_loan%': 'Alquiler autorizado',
    '%accepted_loan%': 'Alquiler aceptado',
    '%loan_delivered_by_owner%': 'Entrega realizada',
    '%loan_delivered_to_borrower%': 'Alquiler iniciado',
    '%less_than_30_hours_notice%': 'Tu alquiler finaliza en menos de 24h',
    '%expired_loan_notice%': 'Alquiler caducado',
    '%loan_returned_by_borrower%': 'Artículo devuelto',
    '%loan_returned_to_owner%': 'Artículo devuelto',
    '%error_on_accept%': 'Error de cobro',
    '%extension_request%': 'Solicitud de extensión ',
    '%denied_loan_extension%': 'Extensión denegada',
    '%accept_loan_extension%': 'Extensión aceptada',
    '%unused_loan%': 'Alquiler finalizado',
    '%unconfirmed_delivery%': 'Confirma la recogida',
    '%unconfirmed_return%': 'Confirma la devolución',
    '%cancelled_by_support_notice%': 'Alquiler cancelado',
    '%finalized_by_support_notice%': 'Alquiler finalizado',
    '%overdue_loan_owner_notice%': 'Aviso alquiler expirado',
    '%overdue_loan_borrower_notice%': 'Aviso alquiler expirado',
    '%pro_add_phone_notice%': "Verificar nº de teléfono",
    '%pro_add_phone_text%': "Aún no has validado tu teléfono, recuerda que es necesario para poder alquilar.",
    '%loan_must_start_owner_notice%': 'Aviso alquiler inactivo',
    '%loan_must_start_borrower_notice%': 'Aviso alquiler inactivo',
    '%less_than_2_hours_owner_notice%': 'Alquiler a punto de terminar',
    '%less_than_2_hours_borrower_notice%': 'Alquiler a punto de terminar',
    '%loan_must_start_1h_owner_notice%': 'Aviso alquiler inactivo',
    '%loan_must_start_1h_borrower_notice%': 'Aviso alquiler inactivo',
    '%payment_card_error_notice%': 'Error en tarjeta de pago',
    '%expired_payment_card_notice%': 'Aviso caducidad tarjeta',
    '%model_347_notice%': 'Aviso sobre tus ingresos',
    '%expired_licence_notice%': 'Aviso caducidad permiso',
    '%bank_account_error_notice%': 'Falta número de cuenta',
    '%loan_request_text%': 'Te han solicitado {0}',
    '%cancelled_loan_text%': 'Han cancelado la solicitud de alquiler de {0}',
    '%denied_loan_text%': 'Han denegado tu solicitud de alquiler de {0}',
    '%authorized_loan_text%': 'Has autorizado el alquiler de {0}',
    '%accepted_loan_text%': 'Han aceptado tu solicitud de alquiler de {0}',
    '%loan_delivered_by_owner_text%': 'Se ha confirmado la entrega de {0}. Por favor, confirma la recogida del mismo.',
    '%loan_delivered_to_borrower_text%': 'Se ha confirmado que {0} ya está en manos de {1}',
    '%less_than_30_hours_notice_text%': 'El alquiler de {0} finaliza en menos de 24h.',
    '%expired_loan_notice_text%': 'La solicitud de alquiler para {0} ha caducado sin respuesta.',
    '%loan_returned_by_borrower_text%': '{0} acaba de confirmar la devolución de {1}',
    '%loan_returned_to_owner_text%': '{0} ha confirmado que {1} ya está en sus manos',
    '%error_on_accept_text%': 'Información: Debido a un error bancario, se ha devuelto un importe y lo hemos cargado de nuevo.  ',
    '%extension_request_text%': '{0} ha solicitado la extensión del alquiler de {1}',
    '%denied_loan_extension_text%': 'Se ha denegado tu solicitud de extensión de alquiler de {0}',
    '%accept_loan_extension_text%': 'Han aceptado tu solicitud de extensión de alquiler de {0}',
    '%unused_loan_text%': 'El alquiler {0} ha llegado a su fin',
    '%unconfirmed_delivery_text%': 'Ups! Te has olvidado de confirmar la recogida de {0}. Por favor, confirma que te han entregado el artículo.',
    '%unconfirmed_return_text%': 'Ups! Te has olvidado de finalizar el alquiler de {0}. Por favor, confirma que el artículo ya está en tus manos para poder cerrar el proceso.',
    '%cancelled_by_support_text%': 'El alquiler de {0} ha sido cancelado siguiendo las normas de la plataforma.',
    '%finalized_by_support_text%': 'El alquiler de {0} ha sido finalizado siguiendo las normas de la plataforma.',
    '%overdue_loan_owner_text%': 'Aviso: El tiempo de alquiler ha terminado sin confirmar la devolución de {0}. Finaliza el alquiler para que podamos ingresar el importe en tu cuenta.',
    '%overdue_loan_borrower_text%': 'Aviso: El tiempo de alquiler ha terminado sin confirmar la devolución de {0}. Finaliza el alquiler para que podamos ingresar el importe de la fianza en tu cuenta.',
    '%loan_must_start_owner_text%': 'Aviso: El tiempo de alquiler ha comenzado sin confirmar la entrega. Activa el alquiler de {0}',
    '%loan_must_start_borrower_text%': 'Aviso: El tiempo de alquiler ha comenzado sin confirmar la recogida. Activa el alquiler de {0}',
    '%less_than_2_hours_owner_text%': 'Recordatorio de fin de alquiler: En 2 horas te devolverán {0}',
    '%less_than_2_hours_borrower_text%': 'Recordatorio de fin de alquiler: En 2 horas debes devolver {0} ',
    '%loan_must_start_1h_owner_text%': 'Aviso: El tiempo de alquiler ha comenzado sin confirmar la entrega. Activa el alquiler de {0}',
    '%loan_must_start_1h_borrower_text%': 'Aviso: El tiempo de alquiler ha comenzado sin confirmar la recogida. Activa el alquiler de {0}',
    '%payment_card_error_text%': 'La operación con tu tarjeta {0} ha fallado. Por favor, revisa que siga siendo válida. ',
    '%expired_payment_card_text%': 'Este mes caduca tu tarjeta {0}. Recuerda que debes cambiarla por una válida en tus datos de usuario.',
    '%model_347_text%': 'Este año has ingresado más de 3.005,6€ en alquileres realizados en myur! Te recordamos que debes presentar a Hacienda el modelo 347.',
    '%expired_licence_text%': 'Se acerca la fecha de caducidad de tu permiso de tipo {0}. Recuerda que, para poder alquilar vehículos, el permiso debe estar en vigor',
    '%bank_account_error_text%': 'No hemos podido realizar el ingreso. Recuerda que debes completar tu perfil e indicar un medio de cobro.',
    // NOTIFICATION ITEM
    '%response_sr%': 'Búsqueda exitosa',
    '%expired_sr_notice%': 'Búsqueda caducada',
    '%no_longer_insurable%': 'No se puede asegurar',
    '%notify_disabled_items_notice%': 'Artículos deshabilitados',
    '%response_sr_text%': 'Tienes nuevas respuestas para la búsqueda de {0}',
    '%expired_sr_notice_text%': 'Tu búsqueda de {0} ha caducado.',
    '%no_longer_insurable_text%': 'Debido a cambios en el sistema tu artículo "{0}" no puede ser asegurado',
    '%notify_disabled_items_text%': 'Se han deshabilitado tus artículos de la categoría {0} por cambios en las condiciones. Por favor, revísalos para volver a activarlos.',
    'auth_already_account': "ya tienes cuenta?",
    'auth_create_account': "Crear cuenta",
    'auth_no_account': "no tienes cuenta?",
    'auth_use_account': "Entra con tu cuenta",
    'a_day': "al día",
    'ACCEPT': 'Aceptar',
    'access': 'Accede',
    'active': 'Activa',
    'ADD': 'Añadir',
    'address': 'Dirección',
    'addresses': 'Direcciones',
    'app_error': "Ooops! Se ha producido un error en la aplicación!",
    'app_error_link': "Volver a la página de inicio",
    'age': 'Edad',
    'ask_support': 'Póngase en contacto con soporte@myu.app para actualizar este dato.',
    'ban': 'Banear',
    'banned': 'Baneado',
    'before_registration': 'antes de registrarte debes confirmar tu edad',
    'before_start': 'Antes de continuar',
    'billing_address': 'Dirección de facturación',
    'birth_date': 'Fecha de nacimiento',
    'boat_brand': 'Marca/Astillero',
    'boat_im_pro': 'Soy usuario profesional',
    'boat_im_pro_helper': 'Estimado usuario profesional, debes completar tus datos de empresa antes de subir tus artículos.',
    'boat_material': 'Material de construcción',
    'boat_length': 'Eslora total',
    'boat_number_of_engines': 'Número de motores',
    'boat_construction_year': 'Año de construcción',
    'boat_power': 'Potencia (CV)',
    'boat_port_zipcode': 'C.P. del puerto de amarre',
    'boat_material_FIBRA': 'Fibra',
    'boat_material_NEUMATICA': 'Neumática',
    'boat_material_MADERA': 'Madera',
    'boat_model': 'Modelo/Serie/Tipo',
    'boat_name': 'Nombre',
    'boat_registration': 'Lista de matriculación',
    'boat_boat_type': 'Tipo de embarcación',
    'boat_rental_with_driver': 'Alquiler CON patrón',
    'boat_rental_with_driver_helper': 'El alquiler con patrón se considera un SERVICIO. Por favor, vuelve a seleccionar la categoría.',
    'boat_list_6': 'Sexta lista',
    'boat_list_7': 'Séptima lista',
    'business_area': 'Quiero alquilar como usuario profesional',
    'business_area_add': 'Quiero hacerme una cuenta profesional (te pediremos algunos datos de tu negocio)',
    'business_name': 'Razón social',
    'CANCEL': 'Cancelar',
    'car_brand': 'Marca',
    'car_country_plate': 'País de matriculación',
    'car_im_pro': 'Soy usuario profesional',
    'car_im_pro_helper': 'Estimado usuario profesional, debes completar tus datos de empresa antes de subir tus artículos.',
    'car_model': 'Modelo',
    'car_plate': 'Matrícula',
    'car_rental_with_driver': 'Alquiler CON conductor',
    'car_rental_with_driver_helper': 'El alquiler con conductor se considera un SERVICIO. Por favor, vuelve a seleccionar la categoría.',
    'CATEGORY': 'Categoría',
    'change': 'Cambiar',
    'change_image': 'Cambiar imagen',
    'chat_load_more': 'Cargar más antiguos',
    'check_availability': 'consultar',
    'close': 'cerrar',
    'cookie_policy': 'Política de cookies',
    'cookie_disclaimer': 'En myur! no traficamos con tus datos. No los recolectamos, no los almacenamos y, por supuesto, tampoco los usamos como mercancía. Sin embargo, utilizamos cookies para mejorar tu experiencia. Al pulsar el botón "ACEPTO" o continuar navegando, consientes el uso de dichas cookies.',
    'comments': 'Comentarios',
    'comment_who_when': '{0} el {1}',
    'COMPANY': 'Compañía',
    'config': 'Configuración',
    'contact_follow_us': 'Síguenos!',
    'contact_other': 'otras consultas',
    'contact_platform': 'plataforma',
    'contact_social': 'RRSS',
    'contact_subtitle': 'Escríbenos si tienes alguna duda o sugerencia, estaremos encantados de atenderte!',
    'contact_technical_support': 'soporte técnico',
    'contact_title': 'Contacta con nosotros',
    'country': 'País',
    'current_price': 'Valor actual',
    'hourly_price': 'Precio por hora',
    'daily_price': 'Precio por día',
    'weekly_price': 'Precio por semana',
    'monthly_price': 'Precio por mes',
    'discard_changes': 'Descartar cambios',
    'summary_loan_price': 'Alquiler',
    'summary_loan_price_insurance': 'incluido seguro y gastos de gestión',
    'summary_loan_price_no_insurance': 'gastos incluídos',
    'daily_actual_price': '{0} {1} x {2} días',
    'delete': 'Eliminar',
    'deposit': 'Fianza',
    'deposit_loan': 'FIANZA (a devolver)',
    'deposit_notice_text': 'se devolverá al finalizar el alquiler',
    'description': 'Descripción',
    'description_2_long': 'Descripción demasiado larga',
    'default_address': 'Dirección por defecto',
    'default_bank_account': 'Cuenta por defecto',
    'distinct_passwords': 'Las contraseñas no coinciden',
    'edit': 'Editar',
    'edit_profile': 'Editar perfil',
    'enter_email_password': 'Introduce el email y la contraseña',
    'enter_nickname': 'Introduce tu nickname',
    'enter_zipcode': 'Introduce tu CP',
    'email': 'Email',
    'error_zipcode_bad_format': "El código postal es un número de 5 dígitos entre 01000 y 52999",
    'expired': 'Caducada',
    'faq': 'preguntas frecuentes',
    'finish': 'listo!',
    'freelance': 'soy autónomo',
    'from_euros': 'desde {0} euros',
    'geolocation_consent_dialog_title': '¡Mejora tus búsquedas!',
    'geolocation_consent_dialog_helper': 'Si permites que tu navegador nos diga dónde estás, podemos mostrarte los resultados más cercanos primero. ¿Te apuntas?',
    'geolocation_consent_dialog_cancel': 'Deja que me lo piense',
    'geolocation_consent_dialog_accept': '¡Sí, claro!',
    'go_back': 'atrás',
    'go_back_alt': 'volver',
    'help': 'Ayuda',
    'how_it_works': '¿cómo funciona?',
    'i_accept': 'Acepto',
    'i_got_it': '¡lo tengo!',
    'im_over_8teen': 'soy mayor de 18 años',
    'image_error': 'Error leyendo la imagen {0}',
    'insurance': 'seguro',
    'insurance_acceptance': 'Quiero asegurar el artículo y acepto las condiciones del seguro',
    'insurance_acceptance_error': 'Es necesario aceptar las condiciones del seguro',
    'insurance_conditions': 'Ver condiciones del seguro {0}',
    'insurance_conditions_s': 'Ver condiciones del seguro',
    'insurance_issue_fire': 'Reclamación por incencio',
    'insurance_issue_fire2': 'La foto es obligatoria en las tramitaciones por incendio.',
    'insurance_issue_theft': 'Reclamación por robo',
    'insurance_issue_theft2': 'Recuerda que se solicitará la denuncia presentada ante la policía.',
    'insurance_issue_damage': 'Reclamación por daños',
    'insurance_issue_damage2': '¿Los daños impiden el funcionamiento?',
    'insurance_type_': '',
    'insurance_type_BASIC': 'Seguro Basic',
    'insurance_type_PLUS': 'Seguro Plus',
    'insurance_type_EXTRA': 'Seguro Extra',
    'insurance_type_NIBW': 'A terceros',
    'insurance_type_NIBW_FC': 'A todo riesgo',
    'internal_error': 'Error interno',
    'issue': 'Incidencia',
    'issue_code_detail': 'nº de incidencia: {0}',
    'issue_created': 'Incidencia con código {0} creada correctamente',
    'issue_description_label': 'Describe brevemente la incidencia y dale a enviar',
    'issue_description_placeholder': 'P.E. La chica a la que iba a alquilar mi bicicleta no me contesta al chat y no puedo quedar con ella.',
    'issue_detail_description': 'Descripción de la incidencia según figura en el parte',
    'issue_detail_helper': 'Cada vez que esta incidencia cambie de estado recibirás un mail con toda la información necesaria para su seguimiento.',
    'issue_detail_photo': 'Foto adjuntada',
    'issue_detail_when_date': 'Fecha',
    'issue_detail_when_hour': 'Hora',
    'issue_detail_where': 'Lugar en el que se produjo la incidencia',
    'issue_error_description_mandatory': 'La descripción de la incidencia es obligatoria',
    'issue_error_description_2_long': 'La descripción de la incidencia es demasiado larga',
    'issue_error_image': 'La foto es obligatoria',
    'issue_error_type': 'El tipo de incidencia es obligatorio',
    'issue_error_where': 'El obligatorio indicar dónde se produjo la incidencia',
    'issue_error_when': 'El obligatorio indicar cuándo se produjo la incidencia',
    'issue_loading': 'Cargando datos de la incidencia',
    'issue_open_date': 'Abierta el {0}',
    'issue_select_issue': 'Selecciona una incidencia',
    'issue_send_helper': 'Recibirás un mail con toda la información aportada y un código de seguimiento',
    'issue_status': 'Estado de la incidencia',
    'issue_status_RECIBIDA': 'Recibida',
    'issue_status_TRAMITACION_SOPORTE': 'En tramitación por soporte.',
    'issue_status_TRAMITACION_ASEGURADORA': 'En tramitación por aseguradora.',
    'issue_status_RESUELTA': 'Resuelta',
    'issue_type': 'Tipo: {0}',
    'issue_type_name_CHARGE_PAYMENT': 'Pagos/cobros',
    'issue_type_name_APP_USE': 'Uso de la app',
    'issue_type_name_ITEM': 'Artículos',
    'issue_type_name_USER': 'Usuarios',
    'issue_type_name_LOAN': 'Alquileres',
    'issue_type_name_FIRE': 'Incendio',
    'issue_type_name_THEFT': 'Robo',
    'issue_type_name_DAMAGE': 'Daños',
    'issue_type_name_OTHERS': 'Otros',
    'issue_type_helper': 'Selecciona a continuación la opción que se corresponda con tu problema',
    'issues': 'Incidencias',
    'item_create_item_success': 'Artículo creado correctamente',
    'item_edit_item_success': 'Artículo actualizado correctamente',
    'item_detail': 'Detalle del artículo',
    'item_select_images': 'Elige una foto bonita (hasta 4)',
    'item_desired_deposit': 'Se aconseja una fianza del {0}% del valor del artículo.',
    'item_disable': 'Deshabilitar',
    'item_disable_item': 'Deshabilitar artículo',
    'item_disable_item_description': 'Al deshabilitar el artículo se hará privado, no aparecerá en las búsquedas y no podrán pedírtelo. Puedes habilitarlo de nuevo en cualquier momento.',
    'item_disabled': 'Deshabilitado',
    'item_enable': 'Habilitar',
    'item_enable_item': 'Habilitar artículo',
    'item_enable_item_description': 'Al habilitar el artículo se hará público de forma que aparecerá en las búsquedas y el resto de usuarios podrán pedírtelo.',
    'item_enable_option': 'Quiero habilitar el artículo y que esté disponible para alquilar',
    'item_min_deposit': 'La fianza mínima para este artículo debe de ser de un {0}% de su valor.',
    'item_multiprice_help_1': "Puedes alquilar por horas, días, semanas o meses.",
    'item_multiprice_help_2': "Simplemente rellena el precio en los períodos que aceptes y deja en blanco los demás.",
    'item_not_found': 'El artículo ya no está disponible',
    'item_public_url_title': "alquiler-de-{0}-en-{1}",
    'item_related_items': "Artículos relacionados",
    'i_need': 'necesito',
    'i_want_it': 'lo quiero, me lo dejas?',
    'landing_load_more': 'cargar más',
    'landing_app_link_keep_browsing': 'Regístrate para seguir navegando en la web o...',
    'landing_app_link_download': 'Descárgate ya la app!',
    'landing_app_link_coming_soon': 'muy pronto...',
    'landing_disclaimer_welcome': 'Bienvenido a la app para alquilarlo todo!',
    'landing_disclaimer_dont_buy': 'No compres... tus vecinos te lo prestan!',
    'landing_disclaimer_profit': 'Saca rentabilidad a tus cosas',
    'landing_disclaimer_no_worries': 'Con la tranquilidad de saber quién alquila',
    'landing_disclaimer_be_paid': 'Asegúrate el cobro',
    'landing_sign_up_want_more': '¿Ganas de más?',
    'landing_sign_up_register_for_free': 'Regístrate, es gratis!',
    'landing_sign_up_register': 'registrarme',
    'last': '... y para terminar',
    'lend': 'alquilar',
    'license_create_license': 'Nuevo permiso/licencia',
    'license_create_license_helper': 'Cubre los datos a continuación para crear el nuevo permiso o licencia',
    'license_select_license_type': 'Selecciona el tipo de permiso o licencia',
    'license_title': 'Permiso {0}',
    'license_type': 'Tipo de permiso',
    'license_update_license': 'Actualizar datos del permiso/licencia',
    'license_update_license_helper': 'Cubre los datos a continuación para actualizar el permiso o la licencia',
    'license_valid_from': 'Fecha de expedición',
    'license_valid_to': 'Fecha de caducidad',
    'licenses': 'Permisos',
    'live': 'En directo',
    'live_searches': 'Búsquedas en directo',
    'loading_data': 'Cargando datos',
    'loading_item': 'Recuperando artículo',
    'loan': 'Alquiler',
    'loan_insurance_notice': 'El propietario exige un seguro para alguilar este artículo. Al continuar, aceptas las condiciones del seguro.',
    'loan_notice_text': 'RECUERDA! El importe del alquiler será cargado a tu cuenta en el momento en el que el propietario acepte la petición. Puede tardar hasta 24 horas en aceptarla. Si tienes alguna duda, antes de solicitarlo formalmente, puedes iniciar un chat.',
    'loan_request_success': '¡Alquiler solicitado!',
    'loan_send_photos_chat_begin': 'MENSAJE AUTOMÁTICO FOTOS DE LA ENTREGA',
    'loan_send_photos_chat_end': 'MENSAJE AUTOMÁTICO FOTOS DE LA DEVOLUCIÓN',
    'location': 'Localidad',
    'log-out': 'Cerrar sesión',
    'log-out_text': '¿Estás seguro? Ha sido un placer tenerte en la comunidad.',
    'login': 'Credenciales',
    'mandatory_boat_boat_type': 'El tipo de embarcación es obligatorio',
    'mandatory_boat_type': 'El tipo es obligatorio',
    'mandatory_boat_subtype': 'El sub-tipo es obligatorio',
    'mandatory_boat_material': 'El material de construcción es obligatorio',
    'mandatory_boat_length': 'Debes indicar la eslora',
    'mandatory_boat_engines': 'El número de motores es obligatorio',
    'mandatory_boat_construction_year': 'Debes indicar el año de construcción',
    'mandatory_boat_power': 'La potencia es obligatoria',
    'mandatory_boat_zipcode_port': 'El código postal del puerto de amarre es obligatorio',
    'mandatory_boat_licence_plate': 'La matrícula es obligatoria',
    'mandatory_boat_brand': 'La marca es obligatoria',
    'mandatory_boat_model': 'El modelo es obligatorio',
    'mandatory_description': 'La descripción es un campo obligatorio',
    'mandatory_end_date': 'Por favor, añade una fecha de finalización.',
    'mandatory_item_price': 'Debes establecer al menos un precio',
    'mandatory_item_hourlyPrice': 'El precio por hora debe ser mayor de {0}€',
    'mandatory_item_dailyPrice': 'El precio por día debe ser mayor de {0}€',
    'mandatory_item_weeklyPrice': 'El precio por semana debe ser mayor de {0}€',
    'mandatory_item_monthlyPrice': 'El precio por mes debe ser mayor de {0}€',
    'mandatory_item_category': 'Debes elegir una categoría para tu artículo',
    'mandatory_item_deposit': 'Debes establecer una fianza de al menos {0}€',
    'mandatory_item_insurance': 'Los artículos de esta categoría deben asegurarse',
    'mandatory_item_photos': 'Debes incluir al menos una foto',
    'mandatory_item_purchase_price': 'Es necesario el precio de compra. Pon uno aproximado si no lo recuerdas.',
    'mandatory_item_title': 'El nombre del artículo es obligatorio',
    'mandatory_item_subcategory': 'Debes elegir una subcategoría para tu artículo',
    'mandatory_location': 'La población es un campo obligatorio',
    'mandatory_title': 'El nombre es un campo obligatorio',
    'mandatory_email': 'El email es un campo obligatorio',
    'mandatory_password': 'El password es un campo obligatorio',
    'mandatory_username': 'El nickname es un campo obligatorio',
    'mandatory_vehicle_brand': 'La marca es obligatoria',
    'mandatory_vehicle_licence_plate': 'La matrícula es obligatoria',
    'mandatory_vehicle_model': 'El modelo es obligatorio',
    'mandatory_vehicle_type': 'El tipo es obligatorio',
    'mandatory_vehicle_subtype': 'El subtipo es obligatorio',
    'mandatory_zipcode': 'El código postal es un campo obligatorio',
    'map_hide_map': 'Ocultar mapa',
    'map_show_map': 'Mostrar mapa',
    'messages': 'Mensajes',
    'mobile_actions_add_item': '+artículo',
    'mobile_actions_add_search': '+búsqueda',
    'month': 'Mes',
    'more_myur': 'más myur!',
    'my_account': 'Mi cuenta',
    'name_it': 'Ponle un nombre',
    'new_issue': 'Abrir incidencia',
    'new_item': 'subir artículo',
    'next_step': 'Continuar',
    'no': 'No',
    'no_data_found': 'No se han encontrado datos',
    'no_payment_methods': 'Sin formas de pago',
    'not_enabled': 'Deshabilitado',
    'notifications': 'Notificaciones',
    'number_of_reviews': '{0} opiniones',
    'payment_methods': 'Formas de pago',
    'page_not_found': 'Ooops! La página que buscas no está en alquiler!',
    'password': 'Contraseña',
    'period_earns': "ganancias totales en {0} de {1}",
    'period_earns_code': "cod. {0}",
    'period_earns_helper': "Las fechas mostradas se corresponden con el ingreso en cuenta.",
    'phone_number': 'Teléfono',
    'phone_verification_code': 'Código de verificación',
    'phone_verification_title': 'Verificación del número de teléfono',
    'phone_verification_helper': 'Introduce el código que has recibido por SMS al número de teléfono indicado',
    'pick-up_date': 'Recogida',
    'price': 'Precio',
    'price_hourlyPrice': '/hora',
    'price_dailyPrice': '/día',
    'price_weeklyPrice': '/semana',
    'price_monthlyPrice': '/mes',
    'privacy': 'política de privacidad',
    'public_email': 'Dirección de contacto',
    'publication_date': 'Fecha de publicación',
    'purchase_date': 'Fecha de compra',
    'purchase_price': 'Precio de compra',
    'read+': 'leer +',
    'reason': 'Motivo',
    "msg1": "Ha de rellenar los datos de la tarjeta",
    "msg2": "La tarjeta es obligatoria",
    "msg3": "La tarjeta ha de ser numérica",
    "msg4": "La tarjeta no puede ser negativa",
    "msg5": "El mes de caducidad de la tarjeta es obligatorio",
    "msg6": "El mes de caducidad de la tarjeta ha de ser numérico",
    "msg7": "El mes de caducidad de la tarjeta es incorrecto",
    "msg8": "El año de caducidad de la tarjeta es obligatorio",
    "msg9": "El año de caducidad de la tarjeta ha de ser numérico",
    "msg10": "El año de caducidad de la tarjeta no puede ser negativo",
    "msg11": "El código de seguridad de la tarjeta no tiene la longitud correcta",
    "msg12": "El código de seguridad de la tarjeta ha de ser numérico",
    "msg13": "El código de seguridad de la tarjeta no puede ser negativo",
    "msg14": "El código de seguridad no es necesario para su tarjeta",
    "msg15": "La longitud de la tarjeta no es correcta",
    "msg16": "Debe Introducir un número de tarjeta válido (sin espacios ni guiones).",
    "msg17": "Validación incorrecta por parte del comercio",
    'register': 'Regístrate',
    'rental_with_driver': "Alquiler CON conductor",
    'rental_with_skipper': "Alquiler CON patrón",
    'rental_with_driver_helper': 'El alquiler con conductor se considera un SERVICIO. Por favor, vuelve a seleccionar la categoría.',
    'rental_with_skipper_helper': 'El alquiler con patrón se considera un SERVICIO. Por favor, vuelve a seleccionar la categoría.',
    'repeat': 'repetir!',
    'repeat_password': 'Repite la contraseña',
    'repeated_email': 'Email no válido',
    'repeated_username': 'Nickname no válido',
    'report': 'Denunciar',
    'report_item': 'Denunciar artículo',
    'report_item_helper': 'Explica brevemente por qué no es adecuado este artículo',
    'report_success': 'Denuncia enviada correctamente.',
    'request': 'Solicitar',
    'requests': 'Peticiones',
    'response_send_success': 'Respuesta enviada correctamente.',
    'responses': 'Respuestas',
    'return_date': 'Devolución',
    'SAVE': 'Guardar cambios',
    'saved': 'Cambios guardados correctamente',
    'score': 'Puntuación: {0}',
    'search': 'Buscar',
    'search_from': "desde...",
    "search_to": "hasta...",
    'select_and_crop': 'Selecciona una nueva imagen y recórtala a tu gusto',
    'select_category': 'Elige la categoría',
    'select_email_password': 'Continúa con tu email y contraseña',
    'select_google_account': 'Continúa con tu cuenta de Google',
    'select_image': 'Selecciona una imagen',
    'select_insurance': 'Selecciona tipo de seguro',
    'select_location': 'Selecciona una población',
    'select_location_helper': 'Comienza a escribir para seleccionar la población',
    'select_notifications': 'Quiero recibir mis notificaciones',
    'select_notifications_email': 'en el correo',
    'select_notifications_fcm': 'en la app',
    'select_province': 'Selecciona una provincia',
    'select_province_helper': 'Comienza a escribir para seleccionar la provincia',
    'select_subcategory': 'Elige la sub-categoría',
    'select_subtype': 'Elige el sub-tipo',
    'select_boat_type': 'Elige el tipo de embarcación',
    'select_vehicle_type': 'Elige el tipo de vehículo',
    'send': 'Enviar',
    'send_message': 'mensaje',
    'share': 'Compartir',
    'share_with_friends': 'Invitar a mis amigos',
    'short_password': 'El password debe tener al menos 6 caracteres.',
    'sign_in_email': 'Correo electrónico',
    'sign_in_login': 'Entrada',
    'sign_in_password': 'Contraseña',
    'sign_in_send': 'Enviar',
    'sign_in_zombie': 'No has completado el registro. Redirigiendo...',
    'sign_up_by_1': 'Al continuar declaro que acepto',
    'sign_up_by_2': 'y la',
    'signed_out': 'Sesión cerrada',
    'spanish_bank_accounts_only': 'Sólo cuentas españolas.',
    'terms': 'términos y condiciones',
    'this_one': '¡éste!',
    'title_2_long': 'Nombre demasiado largo',
    'today': 'Hoy',
    'total': 'Total',
    'unban': 'Desbanear',
    'update': 'Actualizar',
    'user_data': 'Datos de usuario',
    'user_detail': 'Detalle de usuario',
    'user_identification_document': 'doc. de identificación',
    'user_identification_document_type': 'Tipo de documento',
    'user_more_items_from': 'más artículos de {0}',
    'user_name': 'Nombre',
    'user_no_chats': 'No hay chats.',
    'user_no_messages': 'No hay mensajes.',
    'user_no_live_search': "La petición no existe",
    'user_phone': 'Teléfono',
    'user_surname': 'Apellidos',
    'VALUE': 'Valor',
    'vat_number': 'Indentificación fiscal',
    'vat_number_type': 'Tipo',
    'verify_email': 'Verifica tu cuenta.',
    'verify_email_done': 'Hecho',
    'verify_email_resend': 'Reenviar Correo',
    'verify_email_sent': 'Se ha enviado un correo de confirmación.',
    'verify_email_verification': 'Accede a tu correo ({0}) y verifica tu cuenta.',
    'walrus_lets_make_money': 'Vamos! empieza a ganar alquilando con myur!',
    'what': '¿qué estás buscando?',
    'when': '¿en qué fechas?',
    'when_1': '¿cuándo?',
    'when_needed': '¿cuándo lo necesitas?',
    'where': '¿dónde?',
    'where_happened': '¿Dónde ha sucedido?',
    'withholding': 'Retención',
    'withholding_helper': 'Introduce la retención a aplicar en tus facturas.',
    'who_are_we': '¿quiénes somos?',
    'write_here': 'Aquí puedes escribir',
    'year': 'Año',
    'yes': 'Sí',
    'yesterday': 'Ayer',
    'zipcode': 'Código postal',
    // COMPANY
    'SERVICE_FEE': 'Comisión de servicio',
    'DEFAULT_FEE': 'Comisión por defecto',
    'COMPANY_DEFAULT_FEE': 'El valor por defecto aplicado a una empresa es del {0}%.',
    // CATEGORIES
    'ELECTRONIC_AND_COMPUTERS': 'electrónica',
    'AUDIO_VISUAL': 'audiovisual',
    'ARTS_AND_EDUCATION': 'educacion y arte',
    'BABIES_AND_KIDS': 'bebés y niños',
    'PARTY_AND_EVENTS': 'fiestas y eventos',
    'HOME_AND_GARDEN': 'casa y jardín',
    'SPORTS_AND_ADVENTURE': 'deportes y aventura',
    'TOOLS_AND_MACHINERY': 'herramientas',
    'VEHICLES': 'vehiculos',
    'FASHION': 'moda',
    'FASHION_BEAUTY_AND_HEALTH': 'moda, belleza, salud',
    'SPACES': 'espacios',
    'SMARTPHONE': 'teléfonos',
    'DRONES': 'drones',
    'CONSOLES': 'consolas',
    'VIDEOGAMES': 'videojuegos',
    'LAPTOPS': 'ordenadores',
    'PRINT_AND_SCAN': 'impresoras y escáneres',
    'TABLETS': 'tablets',
    'DESKTOPS': 'ordenadores de sobremesa',
    'NETWORKING': 'conectividad',
    'ELECTRONIC_ACCESSORIES': 'accesorios electrónica',
    'FILM': 'películas',
    'PHOTOGRAPHY': 'fotografía',
    'AUDIO_AND_DJ_EQUIPMENT': 'equipos de audio y DJ',
    'PROJECTORS': 'proyectores',
    'SCREENS': 'pantallas',
    'MUSICAL_INSTRUMENTS': 'instrumentos musicales',
    'LEARNING_MATERIAL': 'material didáctico',
    'ART_AND_CRAFTS': 'arte y manualidades',
    'CINEMA_MUSIC_AND_LITERATURE': 'cine, música y literatura',
    'BABY_CARE': 'artículos para el cuidado infantil',
    'PRAMS_AND_CARRIERS': 'coches, sillas y mochilas',
    'CAR_AND_BIKE_SEATS': 'asientos de coche y bici',
    'TOYS_AND_GAMES': 'juguetes',
    'PARTY_COSTUMES': 'disfraces',
    'PARTY_DECORATION': 'artículos de decoración para eventos',
    'PARTY_ATTRACTIONS': 'artículos de entretenimiento para fiestas',
    'PARTY_FURNITURE': 'mobiliario para eventos',
    'PARTY_SPACES': 'espacios',
    'POOL': 'piscina',
    'PETS_SUPPLIES': 'mascotas',
    'GARDEN': 'jardin',
    'ELECTRICAL_HOUSEHOLD_APPLIANCE': 'electrodomésticos',
    'ILLUMINATION': 'iluminación',
    'FURNITURE': 'mobiliario',
    'DECORATION': 'decoración',
    'SURF_AND_KAYAK': 'surf y kayak',
    'BIKES': 'bicis',
    'SKI': 'esqui',
    'FISHING': 'pesca',
    'HUNTING': 'caza',
    'RACQUET_SPORTS': 'deportes de raqueta',
    'HIKING_AND_OUTDOORS': 'montaña',
    'OTHER_SPORTS': 'otros deportes',
    'AGRICULTURE': 'agricultura',
    'CONSTRUCTION': 'construcción',
    'DYI': 'diy',
    'CARS_AND_VANS': 'coches y furgos',
    'TRANSPORT_ACCESSORIES': 'accesorios',
    'TRUCKS': 'camiones',
    'MOTORBIKES': 'motos',
    'BOAT': 'navegación',
    'JET_SKI': 'motos de agua',
    'AIRCRAFT': 'aviones',
    'QUADS': 'quads',
    'CARAVANS': 'campers, caravanas y autocaravanas',
    'CLOTHES_AND_SHOES': 'ropa y zapatos',
    'FASHION_ACCESSORIES': 'accesorios de moda',
    'WATCHES': 'relojes',
    'JEWELRY': 'joyas',
    'BEAUTY': 'belleza',
    'HEALTH_CARE': 'salud',
    'STORAGE_ROOMS': 'almacenes',
    'HOMES_AND_ROOMS': 'casas y habitaciones',
    'OFFICES': 'oficinas',
    'GARAGE': 'garajes',
    'GARDENS_AND_OUTDOORS': 'jardín y exteriores',
    'SERVICES': 'Servicios',
    'LESSONS': 'Clases',
    'OTHER_SERVICES': 'Otros servicios',
    'EVENTS': 'Eventos',
    'EXPERIENCES': 'Experiencias',
    'ANTIQUES': 'antigüedades',
    'BOATS': 'embarcaciones',
    'AIRCRAFTS': 'aeronáutica',
    'HEALTH': 'salud',
    'OUTDOOR_PETS': 'aire libre y mascotas',
    'OFFICE': 'oficina',
    'HOME': 'hogar',
    'AUDIO': 'sonido',
    'CELEBRATIONS': 'celebraciones',
    'KITCHEN': 'cocina',
    'DEVICES': 'aparatos',
    'VIDEO': 'vídeo',
    'TRAILER': 'camiones',
    'TRAILERS': 'trailers',
    'FARM_MACHINERY': 'maquinaria agrícola',
    'CONSTRUCTION_MACHINERY': 'maquinaria de construcción',
    'HELICOPTER': 'helicópteros',
    'LIGHT_AIRCRAFT': 'aeronáutica ligera',
    'CLASSIC_CARS': 'coches clásicos',
    'CLASSIC_MOTORBIKES': 'motos clásicas',
    'COCHE': 'Coche',
    'MOTO': 'Moto',
    'MENOS_50_CC': 'Hasta 49cc',
    'DE_50_250_CC': 'Entre 50cc y 249cc',
    'DE_250_750_CC': 'Entre 250cc y 749cc',
    'MAS_750_CC': 'Desde 750cc',
    'FURGONETA': 'Furgoneta',
    'COCHE_CLASICO': 'Coche clásico',
    'MOTO_CLASICA': 'Moto clásica',
    'QUAD': 'Quad',
    'MENOS_750_CC': 'Hasta 749cc',
    'CABEZA_TRACTORA': 'Cabeza tractora',
    'AMBITO_NACIONAL': 'Ámbito nacional',
    'AMBITO_INTERNACIONAL': 'Ámbito internacional',
    'CAMION': 'Camión',
    'MENOS_24_TN': 'Hasta 23Tm',
    'MAS_24_TN': 'Desde 24Tm',
    'REMOLQUE': 'Remolque',
    'AUTOCARAVANA': 'Autocaravana',
    'CARAVANA': 'Caravana',
    'BARCO': 'Barco',
    'MOTOR_FIBRA_PW_MENOS_200': 'Fibra, motor hasta 199CV',
    'MOTOR_FIBRA_PW_MAS_200_MENOS_600': 'Fibra, motor entre 200CV y 599CV',
    'MOTOR_FIBRA_PW_MAS_600_MENOS_800': 'Fibra, motor entre 600CV y 799CV',
    'MOTOR_FIBRA_PW_MAS_800': 'Fibra, motor desde 800CV',
    'MOTOR_MADERA_PW_MENOS_200': 'Madera, motor hasta 199CV',
    'MOTOR_MADERA_PW_MAS_200_MENOS_600': 'Madera, motor entre 200CV y 599CV',
    'MOTOR_MADERA_PW_MAS_600_MENOS_800': 'Madera, motor entre 600CV y 799CV',
    'MOTOR_MADERA_PW_MAS_800': 'Madera, motor desde 800CV',
    'NEUMATICA_PW_MENOS_200': 'Neumática, motor hasta 199CV',
    'NEUMATICA_PW_MAS_200_MENOS_600': 'Neumática, motor entre 200CV y 599CV',
    'NEUMATICA_PW_MAS_600_MENOS_800': 'Neumática, motor entre 600CV y 800CV',
    'NEUMATICA_PW_MAS_800': 'Neumática, desde 800CV',
    'VELA_FIBRA_MENOS_12M': 'Vela fibra, menor de 12m',
    'VELA_FIBRA_MAS_12M_MENOS_15M': 'Vela fibra, de 12m a 14,99m',
    'VELA_FIBRA_MAS_15M_MENOS_24M': 'Vela fibra, de 15m a 23,99m',
    'VELA_MADERA_MENOS_12M': 'Vela madera, hasta 12m',
    'VELA_MADERA_MAS_12M_MENOS_15M': 'Vela madera, de 12m a 14,99m',
    'VELA_MADERA_MAS_15M_MENOS_24M': 'Vela madera, de 15m a 23,99m',
    'MOTO_AGUA': 'Moto de agua',
    'MOTOAGUA_MENOS_5A_PW_MENOS_55': '< 5 años, hasta 55CV',
    'MOTOAGUA_MENOS_5A_PW_MAS_55_MENOS_110': '< 5 años, entre 55CV y 109CV',
    'MOTOAGUA_MENOS_5A_PW_MAS_110': '< 5 años, desde 110CV',
    'MOTOAGUA_MAS_5A_PW_MENOS_55': '> 5 años, hasta 55CV',
    'MOTOAGUA_MAS_5A_PW_MAS_55_MENOS_110': '> 5 años, entre 55CV y 109CV',
    'MOTOAGUA_MAS_5A_PW_MAS__110': '> 5 años, desde 110CV',
    'BOAT_ACCESSORIES': 'Accesorios',
    'MAQUINARIA_OBRA': 'Maquinaria de obra',
    'MENOS_10_TN': 'Menos de 10Tm',
    'ENTRE_10_24_TN': 'Entre 10Tm y 23,99Tm',
    'MAQUINARIA_AGRICOLA': 'Maquinaria agrícola',
    'TRACTOR': 'Tractor',
    'REMOLQUE_AGRICOLA': 'Remolque agrícola',
    'MOTOCULTOR': 'Motocultor',
    'COSECHADORA': 'Cosechadora',
    'COCHE_ALQUILER': 'Coche',
    'COCHE_CLASICO_ALQUILER': 'Coche clásico',
    'FURGONETA_ALQUILER': 'Furgoneta',
    'QUAD_ALQUILER': 'Quad',
    'MICROCAR': 'Microcar',
    'MOTO_ALQUILER': 'Motos',
    '_50CC': 'Hasta 49cc',
    '_250CC': 'Entre 50cc y 249cc',
    '_750CC': 'Entre 250cc y 749cc',
    'MAS_750CC': 'Desde 750cc',
    'CLOTHES': 'ropa',
    'SHOES': 'zapatos',
    'EYES': 'ojos',
    'HAIR': 'cabello',
    'FACE': 'rostro',
    'NAILS': 'uñas',
    'BODY': 'cuerpo',
    'WELFARE': 'bienestar',
    'HYGIENE': 'higiene',
    'MEDICAL_EQUIPMENT': 'equipamiento médico',
    'BEACH': 'playa',
    'CAMPING': 'acampada',
    'OFFICE_SUPPLIES': 'material de oficina',
    'OFFICE_FURNITURE': 'mobiliario de oficina',
    'GARDEN_FURNITURE': 'muebles de jardín',
    'GARDEN_TOOLS': 'herramientas de jardinería',
    'PETS_CARE': 'artículos para el cuidado de mascotas',
    // USER
    'user_data_pending_title': "Completa tu perfil",
    'user_data_pending_helper': 'Para poder realizar un alquiler, debes completar cierta información en el perfil. Esta información se utiliza únicamente para conseguir que nuestros alquileres sean más seguros.',
    'user_data_pending_redirect': 'Pulsa OK para ir a tu perfil y poder completar los datos que faltan.',
    'user_not_found': 'No se ha encontrado al usuario',
    'user_not_id_card': 'Falta el DNI/NIE',
    'user_not_address': ' Debes introducir al menos una dirección',
    'user_no_paym': 'Debes añadir una tarjeta de crédito',
    "user_not_phone": "Falta el número de teléfono",
    'unknown_operation': 'Operación desconocida',
    'uid': 'Identificador de usuario',
    'email_address': 'Dirección de correo',
    'nickname': 'Nombre de usuario',
    'new_addr': 'Nueva dirección',
    'update_addr': 'Actualizar dirección',
    'new_addr_help': 'Cubre los datos a continuación para añadir la nueva dirección',
    'update_addr_help': 'Cubre los datos a continuación para actualizar la dirección',
    'credit_card_number': 'Número de tarjeta',
    'bank_account_number': 'Número cuenta bancaria (IBAN)',
    'cvv2': 'CVV2',
    'administration': 'Administración',
    'personal_data': 'Datos personales',
    'items': 'Artículos',
    'my_items': 'Mis artículos',
    'loans': 'Alquileres',
    'my_loans': 'Mis alquileres',
    'my_earns': 'Mis ganancias',
    'favourites': 'Favoritos',
    'my_favourites': 'Mis favoritos',
    'no_favourites': 'No tienes ningún artículo marcado como favorito',
    'my_profile': 'Mi perfil',
    'chats': 'Mensajes',
    'reviews': 'Opiniones',
    'reviews_about_me': 'Opiniones recibidas',
    'payments': 'Pagos',
    'public_profile': 'Perfil público',
    'user_description': 'Dinos algo sobre ti',
    'user_description_helper': 'Longitud máxima: {0} caracteres',
    'user_add_bank_account': 'Añadir número de cuenta',
    'user_add_bank_account_help': 'La cuenta bancaria se utiliza para poder ingresarte el dinero que ganas cada vez que te alquilan algo.',
    'user_add_credit_card': 'Añadir tarjeta',
    'user_add_credit_card_help': 'La tarjeta de crédito se utiliza para pagar los alquileres.',
    'user_credit_card_owner': 'Titular de la tarjeta',
    'user_credit_card_type': 'Tipo de tarjeta',
    'user_credit_card_is_default': 'Tarjeta por defecto: {0}',
    'user_credit_card_is_validated': 'Tarjeta validada: {0}',
    'user_credit_card_valid_until': 'Válida hasta {0}',
    'user_default_credit_card': 'Tarjeta por defecto',
    'user_bank_information': 'Información bancaria',
    'female': 'Mujer',
    'male': 'Hombre',
    'no_self_description': '{0} no ha escrito nada sobre sí mism@',
    'reviews_about': 'opiniones acerca de {0}',
    'unified_profile_title': 'Completa tu perfil',
    'unified_profile_phone_tip': 'Si has añadido o modificado el teléfono, al pulsar guardar te enviaremos un SMS de verificación para comprobar que eres tú y poder garantizarte que el proceso de alquiler es seguro',
    'user_available_items': 'artículos disponibles de {0}',
    'user_profile_image': 'Imagen de perfil',
    'user_zero_delay': 'Responde inmediatamente',
    'user_unknown_delay': 'Puede tardar en responder.',
    'user_delay': 'Responde en {0} hora{1}.',
    // ITEM
    'ban_item': 'Banear artículo',
    'ban_item_help': 'Indica el motivo del baneo.',
    'ban_item_label': 'Motivo del baneo.',
    'ban_success': 'Artículo {0} baneado correctamente.',
    'unban_item': 'Desbanear artículo',
    'unban_item_help': 'Indica el motivo para quitar el baneo.',
    'unban_item_label': 'Motivo de desbaneo.',
    'unban_success': 'Artículo {0} desbaneado correctamente.',
    'delete_favorite_content': '¿Estás seguro de que quieres eliminar este artículo de tu lista de favoritos?',
    'delete_favorite_success': 'Artículo eliminado de la lista de favoritos',
    'delete_favorite_title': 'Eliminar favorito',
    'delete_item': 'Eliminar artículo',
    'delete_item_description': 'El artículo se eliminará. Esta acción no tiene vuelta atrás. ¿Estás seguro?',
    'delete_item_help': 'Indica el motivo para el borrado.',
    'delete_item_label': 'Motivo para el borrado.',
    'delete_success': 'Artículo {0} eliminado correctamente.',
    'item_saving_success': 'Artículo {0} guardado correctamente.',
    'user_items': 'Artículos del usuario',
    'user_no_items': 'El usuario no dispone de artículos.',
    'user_no_pending_messages': 'No tienes notificaciones pendientes.',
    'edit_item': 'Edición de artículo',
    'edit_item_help': 'Actualiza los campos correspondientes y guarda los cambios.',
    'create_item': 'Nuevo artículo',
    'create_item_help': 'Añade la información a continuación y guarda los cambios',
    // Live! search
    'live_search_submission_banner_line_1': '¿necesitas algo que no encuentras?',
    'live_search_submission_banner_line_2': 'publica una petición en nuestro tablón',
    'live_search_board': 'Tablón',
    'live_search_new': 'Publicar',
    'live_search_name_label': 'Necesito...',
    'live_search_name_placeholder': 'Nombre del artículo',
    'live_search_photo_label': 'Foto orientativa (opcional)',
    'live_search_description_label': 'Mini descripción/explicación',
    'live_search_description_placeholder': 'Máximo 80 caracteres',
    'live_search_location_label': 'Dónde lo necesito',
    'live_search_location_placeholder': 'Localidad',
    'live_search_zipcode_placeholder': 'Código postal',
    'live_search_tip': 'Recibirás una notificación cuando la búsqueda caduque.',
    'live_search_my_responses': 'Mis respuestas',
    'live_search_my_submissions': 'Mis peticiones',
    'live_search_new_submission_title': 'Publicar petición',
    'live_search_search_data': 'Datos de la solicitud',
    'live_search_response_to': "en respuesta a",
    'live_search_response_with': "con mi artículo",
    'live_search_responses': "respuestas",
    'live_search_no_searches': "No hay ninguna petición",
    'live_search_no_responses': "No hay ninguna respuesta",
    'live_search_i_need': "necesito",
    'live_search_banner': "Si no encuentras lo que buscas... ¡pídelo!",
    'live_search_mobile_banner_1': "Si no encuentras lo que necesitas,",
    'live_search_mobile_banner_2': "¡PÍDELO!",
    'live_search_request': "pedir artículo",
    'live_search_link': "{0} está buscando: {1}",
    // LOANS
    'PENDIENTE_RESPUESTA': "Solicitado",
    'CANCELADO': 'Cancelado',
    'DENEGADO': 'Denegado',
    'CADUCADO': 'Caducado',
    'ACEPTADO': 'Aceptado',
    'ACTIVO_OWNER': 'Entregado',
    'ACTIVO_BORROWER': 'Activo',
    'FINALIZADO': 'Finalizado',
    'INCIDENCIA': 'Incidencia',
    'DEVUELTO': 'Devuelto',
    'goto_item_detail': 'ver detalles',
    'loan_change_dates': 'Cambiar fechas',
    'loan_details': 'Detalles de alquiler',
    'loan_detail_countdown_pendiente_respuesta': 'tiempo restante, pendiente de aprobación',
    'loan_detail_countdown_aceptado-activo_owner': 'comienzo del alquiler',
    'loan_detail_countdown_activo_borrower': 'tiempo restante de alquiler',
    'loan_detail_loading': 'Recuperando alquiler...',
    'loan_detail_not_exist': 'No se ha podido recuperar el alquiler solicitado',
    'loan_detail_borrower_accepted_l1': '¿Estás en el punto de encuentro?',
    'loan_detail_borrower_accepted_b1': 'iniciar proceso de recogida',
    'loan_detail_borrower_active_l1': 'Esperamos que hayas disfrutado de este alquiler!',
    'loan_detail_borrower_active_b1': 'iniciar proceso de devolución',
    'loan_detail_borrower_finished_l1': 'Si el alquiler ha finalizado sin incidencias, el importe de la fianza se ingresará en tu cuenta en un plazo de 2-3 días.',
    'loan_detail_borrower_pending_l1': 'El alquiler aún no ha sido aceptado.',
    'loan_detail_borrower_pending_l2': 'Si ya no lo necesitas, puedes cancelar la solicitud.',
    'loan_detail_borrower_pending_b1': 'cancelar solicitud',
    'loan_detail_confirm_finished_l1': 'Antes de terminar, valora este alquiler',
    'loan_detail_confirm_finished_l2': 'Si todo el proceso ha sido correcto, finaliza el alquiler.',
    'loan_detail_confirm_finished_b1': 'confirmo fin de alquiler',
    'loan_detail_end_borrower_what': 'proceso de devolución',
    'loan_detail_end_borrower_done': 'artículo devuelto',
    'loan_detail_end_borrower_now': 'adjunta al menos una foto del artículo',
    'loan_detail_end_borrower_and': 'y confirma que ya se lo has devuelto al propietario',
    'loan_detail_end_owner_what': 'proceso de recogida',
    'loan_detail_end_owner_done': 'ya tengo mi artículo',
    'loan_detail_end_owner_now': 'adjunta al menos una foto del artículo',
    'loan_detail_end_owner_and': 'y confirma que ya está en tus manos',
    'loan_detail_invalid_state': 'Estado no válido: {0}',
    'loan_detail_issue_other_l1': 'Hay una incidencia en el alquiler',
    'loan_detail_issue_other_l2': 'Estamos trabajando para resolverla',
    'loan_detail_issue_own_l1': 'Estamos procesando tu incidencia',
    'loan_detail_issue_own_l1s': 'Estamos procesando tus incidencias',
    'loan_detail_issue_own_l2': 'Número de seguimiento {0}',
    'loan_detail_issue_own_l3': 'Muy pronto nos pondremos en contacto contigo',
    'loan_detail_owner_accepted_l1': '¿Estás en el punto de encuentro?',
    'loan_detail_owner_accepted_b1': 'iniciar proceso de entrega',
    'loan_detail_owner_active_l1': 'Cuando el alquiler llegue a su fin...',
    'loan_detail_owner_active_b1': 'iniciar proceso de recogida',
    'loan_detail_owner_finished_l1': 'Recuerda, el importe del alquiler se ingresará en tu cuenta en un plazo de 2-3 días.',
    'loan_detail_owner_pending_l1': 'El alquiler aún no ha sido autorizado.',
    'loan_detail_owner_pending_l2': 'El solicitante puede cancelar la petición mientras no des tu aprobación.',
    'loan_detail_owner_pending_b1': 'no está disponible',
    'loan_detail_owner_pending_b2': 'sí, autorizo el alquiler',
    'loan_detail_rating_form_anonymous': 'No mostrar mi nombre en la valoración',
    'loan_detail_rating_form_input_placeholder': 'y escribe algo... (opcional)',
    'loan_detail_start_borrower_what': 'proceso de recogida',
    'loan_detail_start_borrower_done': 'artículo recogido',
    'loan_detail_start_borrower_now': 'adjunta al menos una foto del artículo',
    'loan_detail_start_borrower_and': 'y confirma la recogida',
    'loan_detail_start_borrower_other': '(comprueba que el propietario confirme la entrega)',
    'loan_detail_start_owner_what': 'proceso de entrega',
    'loan_detail_start_owner_done': 'artículo entregado',
    'loan_detail_start_owner_now': 'adjunta al menos una foto del artículo',
    'loan_detail_start_owner_and': 'y confirma la entrega',
    'loan_detail_start_owner_other': '(comprueba que el solicitante confirme la recogida)',
    'loan_detail_thanks': 'gracias por utilizar',
    'loan_error_start_in_past': 'Revisa la fecha de inicio del alquiler. Debes pedirlo con al menos una hora de antelación',
    'loan_error_start_after_end': 'La fecha de inicio debe ser posterior a la de finalización del alquiler',
    'loan_error_too_short': 'Revisa la duración del alquiler. La duración mínima de un alquiler es de una hora',
    'loan_lifecycle_delivered': 'entregado',
    'loan_lifecycle_delivery_error': 'Pendiente de marcar como entregado',
    'loan_lifecycle_end': 'fin',
    'loan_lifecycle_init': 'inicio',
    'loan_lifecycle_received': 'recibido',
    'loan_lifecycle_reception_error': 'Pendiente de marcar como recogido',
    'loan_can_earn': 'puedes ganar',
    'loan_history': 'historial de alquileres',
    'loan_response_accepted': "Alquiler aceptado.",
    'loan_response_not_accepted': "No se ha podido completar la aceptación del alquiler.",
    'loan_response_cancelled': "Alquiler cancelado.",
    'loan_response_not_cancelled': "No se ha podido cancelar el alquiler.",
    'loan_response_denied': "Alquiler rechazado.",
    'loan_response_not_denied': "No se ha podido rechazar el alquiler.",
    'loan_response_not_available': "El artículo no está disponible en las fechas que has seleccionado, pero puedes elegir otras fechas o iniciar una conversación con el propietario.",
    'loan_will_earn': 'vas a ingresar',
    'from_to': 'desde {0} hasta {1}',
    'item_currently_available': 'Este artículo está DISPONIBLE en tu calendario',
    'remaining_time': 'tiempo restante',
    'whos_asking': '¿quién lo solicita?',
    'whoses_this_item': 'este artículo es de...',
    // NOTIFICATION LOAN
    'loan_request': 'Solicitud de alquiler ',
    'cancelled_loan': 'Alquiler cancelado',
    'denied_loan': 'Alquiler denegado',
    'authorized_loan': 'Alquiler autorizado',
    'accepted_loan': 'Alquiler aceptado',
    'loan_delivered_by_owner': 'Entrega realizada',
    'loan_delivered_to_borrower': 'Alquiler iniciado',
    'less_than_30_hours_notice': 'Tu alquiler finaliza en menos de 24h',
    'expired_loan_notice': 'Alquiler caducado',
    'loan_returned_by_borrower': 'Artículo devuelto',
    'loan_returned_to_owner': 'Artículo devuelto',
    'error_on_accept': 'Error de cobro',
    'extension_request': 'Solicitud de extensión ',
    'denied_loan_extension': 'Extensión denegada',
    'accept_loan_extension': 'Extensión aceptada',
    'unused_loan': 'Alquiler finalizado',
    'unconfirmed_delivery': 'Confirma la recogida',
    'unconfirmed_return': 'Confirma la devolución',
    'cancelled_by_support_notice': 'Alquiler cancelado',
    'finalized_by_support_notice': 'Alquiler finalizado',
    'overdue_loan_owner_notice': 'Aviso alquiler expirado',
    'overdue_loan_borrower_notice': 'Aviso alquiler expirado',
    'loan_must_start_owner_notice': 'Aviso alquiler inactivo',
    'loan_must_start_borrower_notice': 'Aviso alquiler inactivo',
    'less_than_2_hours_owner_notice': 'Alquiler a punto de terminar',
    'less_than_2_hours_borrower_notice': 'Alquiler a punto de terminar',
    'loan_must_start_1h_owner_notice': 'Aviso alquiler inactivo',
    'loan_must_start_1h_borrower_notice': 'Aviso alquiler inactivo',
    'loan_request_text': 'Te han solicitado [titulo item]',
    'cancelled_loan_text': 'Han cancelado la solicitud de alquiler de [titulo item]',
    'denied_loan_text': 'Han denegado tu solicitud de alquiler de [titulo item]',
    'authorized_loan_text': 'Has autorizado el alquiler de [titulo item]',
    'accepted_loan_text': 'Han aceptado tu solicitud de alquiler de [titulo item]',
    'loan_delivered_by_owner_text': 'Se ha confirmado la entrega de [titulo item]. Por favor, confirma la recogida del mismo.',
    'loan_delivered_to_borrower_text': 'Se ha confirmado que [titulo item] ya está en manos de [borrower nick]',
    'less_than_30_hours_notice_text': 'El alquiler de [titulo item] finaliza en menos de 24h.',
    'expired_loan_notice_text': 'La solicitud de alquiler para [titulo item] ha caducado sin respuesta.',
    'loan_returned_by_borrower_text': '[borrower nick] acaba de confirmar la devolución de [titulo item]',
    'loan_returned_to_owner_text': '[owner nick] ha confirmado que [titulo item] ya está en sus manos',
    'error_on_accept_text': 'Información: Debido a un error bancario, se ha devuelto un importe y lo hemos cargado de nuevo.  ',
    'extension_request_text': '[borrower nick] ha solicitado la extensión del alquiler de [titulo item]',
    'denied_loan_extension_text': 'Se ha denegado tu solicitud de extensión de alquiler de [titulo item]',
    'accept_loan_extension_text': 'Han aceptado tu solicitud de extensión de alquiler de [titulo item]',
    'unused_loan_text': 'El alquiler [titulo item] ha llegado a su fin',
    'unconfirmed_delivery_text': 'Ups! Te has olvidado de confirmar la recogida de (titulo item). Por favor, confirma que te han entregado el artículo.',
    'unconfirmed_return_text': 'Ups! Te has olvidado de finalizar el alquilerde (titulo item). Por favor, confirma que el artículo ya está en tus manos para poder cerrar el proceso.',
    'cancelled_by_support_text': 'El alquiler de (titulo item) ha sido cancelado siguiendo las normas de la plataforma.',
    'finalized_by_support_text': 'El alquiler de (titulo item) ha sido finalizado siguiendo las normas de la plataforma.',
    'overdue_loan_owner_text': 'Aviso: El tiempo de alquiler ha terminado sin confirmar la devolución de (titulo item). Finaliza el alquiler para que podamos ingresar el importe en tu cuenta.',
    'overdue_loan_borrower_text': 'Aviso: El tiempo de alquiler ha terminado sin confirmar la devolución de (titulo item). Finaliza el alquiler para que podamos ingresar el importe de la fianza en tu cuenta.',
    'loan_must_start_owner_text': 'Aviso: El tiempo de alquiler ha comenzado sin confirmar la entrega. Activa el alquiler de (titulo item)',
    'loan_must_start_borrower_text': 'Aviso: El tiempo de alquiler ha comenzado sin confirmar la recogida. Activa el alquiler de (titulo item)',
    'less_than_2_hours_owner_text': 'Recordatorio de fin de alquiler: En 2 horas te devolverán [titulo item]',
    'less_than_2_hours_borrower_text': 'Recordatorio de fin de alquiler: En 2 horas debes devolver [titulo item] ',
    'loan_must_start_1h_borrower_text': 'Aviso: El tiempo de alquiler ha comenzado sin confirmar la recogida. Activa el alquiler de (titulo item)',
    // NOTIFICATION ITEM
    'response_sr': 'Búsqueda exitosa',
    'expired_sr_notice': 'Búsqueda caducada',
    'no_longer_insurable': 'No se puede asegurar',
    'response_sr_text': 'Tienes nuevas respuestas para la búsqueda de (title search)',
    'expired_sr_notice_text': 'Tu búsqueda de (title search) ha caducado.',
    'no_longer_insurable_text': 'Debido a cambios en el sistema tu artículo "item name" no puede ser asegurado',
    // FIREBASE
    "firebase_auth/invalid-verification-code": "El código introducido no es válido.",
    "firebase_auth/provider-already-linked": "No se ha podido vincular el teléfono indicado.",
    "firebase_auth/no-such-provider": "El usuario no tiene cuenta con el proveedor indicado",
    "firebase_auth/credential-already-in-use": "Las credenciales utilizadas no son válidas",
    "firebase_auth/account-exists-with-different-credential": "Las credenciales utilizadas no son válidas",
    "firebase_auth/claims-too-large": "La carga útil de la reclamación que se entregó a setCustomUserClaims() supera el tamaño máximo de 1,000 bytes.",
    "firebase_auth/email-already-exists": "La dirección de correo no es válida.",
    "firebase_auth/id-token-expired": "El token de ID de Firebase ha caducado.",
    "firebase_auth/id-token-revoked": "Se revocó el token de ID de Firebase.",
    "firebase_auth/insufficient-permission": "La aplicación no tiene permisos para usar este método de autenticación",
    "firebase_auth/internal-error": "El servidor de autenticación encontró un error inesperado cuando se intentaba procesar la solicitud.",
    "firebase_auth/invalid-argument": "Se proporcionó un argumento no válido para un método de autenticación. El mensaje de error debe incluir información adicional.",
    "firebase_auth/invalid-claims": "Los atributos personalizados del reclamo que se entregaron a setCustomUserClaims() no son válidos.",
    "firebase_auth/invalid-continue-uri": "La URL de continuación debe ser una string de URL válida.",
    "firebase_auth/invalid-creation-time": "La hora de creación debe ser una string de fecha en formato UTC válida.",
    "firebase_auth/invalid-credential": "La credencial que se usa en la autenticación de los SDK de Admin no se puede emplear para realizar la acción deseada. Algunos métodos de autenticación, como createCustomToken() y verifyIdToken(), requieren que los SDK se inicialicen con una credencial de certificado en lugar de un token de actualización o una credencial predeterminada de la aplicación. Consulta Inicializa el SDK para ver documentación sobre cómo autenticar el SDK de Admin con una credencial de certificado.",
    "firebase_auth/invalid-disabled-field": "El valor que se proporcionó para la propiedad del usuario disabled no es válido. Debe ser un booleano.",
    "firebase_auth/invalid-display-name": "El valor que se proporcionó para la propiedad del usuario displayName no es válido. Debe ser una string que no esté vacía.",
    "firebase_auth/invalid-dynamic-link-domain": "El dominio del vínculo dinámico proporcionado no se configuró o no se autorizó para el proyecto actual.",
    "firebase_auth/invalid-email": "El valor que se proporcionó para la propiedad del usuario email no es válido. Debe ser una dirección de correo electrónico de string.",
    "firebase_auth/invalid-email-verified": "El valor que se proporcionó para la propiedad del usuario emailVerified no es válido. Debe ser un booleano.",
    "firebase_auth/invalid-hash-algorithm": "El algoritmo de hash debe coincidir con las strings de la lista de algoritmos compatibles.",
    "firebase_auth/invalid-hash-block-size": "El tamaño del conjunto de hash debe ser un número válido.",
    "firebase_auth/invalid-hash-derived-key-length": "La longitud de la clave derivada de hash debe ser un número válido.",
    "firebase_auth/invalid-hash-key": "La clave de hash debe ser un búfer de bytes válido.",
    "firebase_auth/invalid-hash-memory-cost": "El costo de la memoria de hash debe ser un número válido.",
    "firebase_auth/invalid-hash-parallelization": "La paralelización de hash debe ser un número válido.",
    "firebase_auth/invalid-hash-rounds": "Las rondas de hash deben ser un número válido.",
    "firebase_auth/invalid-hash-salt-separator": "El campo del separador de sal del algoritmo de hash debe ser un búfer de bytes válido.",
    "firebase_auth/invalid-id-token": "El token de ID que se proporcionó no es un token de ID de Firebase válido.",
    "firebase_auth/invalid-last-sign-in-time": "La hora del último acceso debe ser una fecha en formato UTC válida.",
    "firebase_auth/invalid-page-token": "El token de página siguiente que se entregó en listUsers() no es válido. Debe ser una string válida que no esté vacía.",
    "firebase_auth/invalid-password": "El password no es válido. Debe ser una cadena con al menos seis caracteres.",
    "firebase_auth/invalid-password-hash": "El hash de contraseñas debe ser un búfer de bytes válidos.",
    "firebase_auth/invalid-password-salt": "La contraseña con sal debe ser un búfer de bytes válido.",
    "firebase_auth/invalid-phone-number": "El número de teléfono indicado no es válido",
    "firebase_auth/invalid-photo-url": "URL para la foto no válida.",
    "firebase_auth/invalid-provider-data": "providerData debe ser una serie de objetos UserInfo.",
    "firebase_auth/invalid-provider-id": "providerId debe ser una string del identificador del proveedor compatible válida.",
    "firebase_auth/invalid-session-cookie-duration": "La duración de las cookies de la sesión debe ser un número válido en milisegundos que vaya entre los 5 minutos y las 2 semanas.",
    "firebase_auth/invalid-uid": "El UID proporcionado no es válido.",
    "firebase_auth/invalid-user-import": "El registro de usuarios para importar no es válido.",
    "firebase_auth/maximum-user-count-exceeded": "Se excedió la cantidad máxima de usuarios permitidos para importar.",
    "firebase_auth/missing-android-pkg-name": "Si es obligatorio instalar la app para Android, debe proporcionarse un nombre de paquete de Android.",
    "firebase_auth/missing-continue-uri": "Se debe proporcionar una URL de continuación válida en la solicitud.",
    "firebase_auth/missing-hash-algorithm ": "Para importar usuarios con hash de contraseñas, es necesario proporcionar el algoritmo de hash y sus parámetros.",
    "firebase_auth/missing-ios-bundle-id": "La solicitud debe contener un ID del paquete de iOS.",
    "firebase_auth/missing-uid": "Se requiere un identificador uid para la operación actual.",
    "firebase_auth/operation-not-allowed": "El proveedor de acceso proporcionado está inhabilitado para tu proyecto de Firebase. Habilítalo en la sección Método de acceso de Firebase console.",
    "firebase_auth/phone-number-already-exists": "Número de teléfono no válido.",
    "firebase_auth/project-not-found": "El proyecto indicado no es válido.",
    "firebase_auth/reserved-claims": "Uno o más de los reclamos personalizados de usuarios que se entregaron a setCustomUserClaims() están reservados. Por ejemplo, no deben usarse reclamos específicos de OIDC (p. ej., sub, iat, iss, exp, aud o auth_time) como claves para reclamos personalizados.",
    "firebase_auth/session-cookie-expired": "La cookie proporcionada de la sesión de Firebase ha caducado.",
    "firebase_auth/session-cookie-revoked": "Se revocaron las cookies de la sesión de Firebase.",
    "firebase_auth/uid-already-exists": "El UID no es válido.",
    "firebase_auth/unauthorized-continue-uri": "El dominio de la URL de continuación no está en la lista blanca. Inclúyelo en la lista en Firebase console.",
    "firebase_auth/user-not-found": "No se ha encontrado al usuario",
    // FORMATS
    date_time_ui: 'DD-MM-YYYY HH:mm:ss',
    compact_date_time_ui: 'DD/MM/YYYY HH:mm',
    min_date_time_ui: 'DD-MM HH:mm',
    date_ui: 'DD/MM/YYYY',
    month_day_ui: 'MM/YYYY',
    time_seconds_ui: 'HH:mm:ss',
    time_ui: 'HH:mm',
    number_formatter: new Intl.NumberFormat('es-ES'),
    currency_formatter: new Intl.NumberFormat('es-ES', {
        style: 'currency',
        currency: 'EUR',
    }),
    pdistance_formatter: new Intl.NumberFormat('es-ES', {
        style: "decimal",
        maximumSignificantDigits: 1,
    }),
    /* Commented out because safari does not support it
    distance_formatter: new Intl.NumberFormat('es-ES', {
        style: "unit",
        unit: "kilometer",
        unitDisplay: "short",
        maximumSignificantDigits: 1,
    }),
    */
};

const replace = (string, replacement) =>
    string.replace(/{(\d+)}/g, (match, captureGroup) => replacement[captureGroup]);

export const get = (key, replacement) => {
    const value = es[key];
    if (value === undefined) {
        return key;
    }
    if (replacement !== undefined && replacement !== []) {
        return replace(value, replacement);
    }
    return value;
};