import React from 'react';
import {Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle} from '@material-ui/core';

import {PropTypes} from 'prop-types';
import buttonStyles from "../common/styles/buttonStyles";
import {makeStyles} from "@material-ui/core/styles";
import {get} from "../common/i18n/i18n";

const useStyles = makeStyles(theme => ({
    ...buttonStyles(theme),
}));

export default function GeolocationConsentDialog(props) {
    const {open, handleClose, handleAccept, handleCancel} = props;
    const classes = useStyles();
    return (
        <div>
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>{get('geolocation_consent_dialog_title')}</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {get('geolocation_consent_dialog_helper')}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button className={classes.defaultButton} onClick={handleCancel}>
                        {get('geolocation_consent_dialog_cancel')}
                    </Button>
                    <Button className={classes.blueButton} onClick={handleAccept} autoFocus>
                        {get('geolocation_consent_dialog_accept')}
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

GeolocationConsentDialog.propTypes = {
    open: PropTypes.bool,
    handleClose: PropTypes.func.isRequired,
    handleAccept: PropTypes.func.isRequired,
    handleCancel: PropTypes.func.isRequired
}
