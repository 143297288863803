import React, {useEffect, useState} from "react";
import {buildUrl, doGet} from "../../utils/http";
import {API_ITEM_RELATED_ITEMS, MARINE} from "../../utils/constants";
import {showMessage} from "../common/NotificationSnack";
import ItemVertSkeleton from "./ItemVertSkeleton";
import ItemCard from "./ItemCard";
import {makeStyles, Grid, Typography} from "@material-ui/core";
import {get} from "../common/i18n/i18n";
import {PropTypes} from "prop-types";
import {useSelector} from "react-redux";
import {getOrigin} from "../../reducers";

const useStyles = makeStyles(theme => ({
    owner: {
        color: MARINE,
    },
}));

export default function RelatedItemListComponent({itemId}) {

    const classes = useStyles();

    const [items, setItems] = useState([]);
    const [waiting, setWaiting] = useState(false);
    const origin = useSelector(state => getOrigin(state));

    useEffect(() => {
        setWaiting(true);
        const coordinates = !!origin?.lat && !! origin?.lng ? `&latitude=${origin.lat}&longitude=${origin.lng}` : '';
        doGet(
            buildUrl(API_ITEM_RELATED_ITEMS, {itemId, count: 12}) + coordinates)
            .then(
                response => {
                    setItems(response.items);
                    setWaiting(false);
                })
            .catch(
                error => {
                    setWaiting(false);
                    showMessage(error);
                });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <>
            <Typography className={classes.owner} variant={'h2'} align={"left"}>
                {get('item_related_items')}
            </Typography>
            <Grid container spacing={1}>
                {
                    waiting
                        ? [0, 1, 2, 3, 4, 5].map(idx => <ItemVertSkeleton key={idx} gridProps={{xs: 4, sm: 3, md: 2}}/>)
                        : items.map(item => <ItemCard key={item.id} item={item} gridProps={{xs: 4, sm: 3, md: 2}}/>)
                }
            </Grid>
        </>
    );
}

RelatedItemListComponent.propTypes = {
    itemId: PropTypes.string.isRequired,
};