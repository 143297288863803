import React from 'react'
import {PropTypes} from 'prop-types';
import {Paper, Typography, IconButton, Divider} from "@material-ui/core";
import DeleteIcon from '@material-ui/icons/Delete'
import {withStyles} from "@material-ui/core/styles";

const styles = theme => ({
    root: {
        margin: theme.spacing(),
        padding: theme.spacing(),
        minWidth: 180,
        textAlign: 'left',
    },
    divider: {
        marginBottom: theme.spacing(1),
    },
    first_line: {
        display: 'flex',
        flexFlow: 'nowrap row',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    delete: {
        margin: theme.spacing(0),
    },
});

function BankAccountCard(props) {
    const {bankAccount, handleDelete, classes} = props;
    const defaultAccount = bankAccount.defaultAccount === true ? 'sí' : 'no';
    return (
        <Paper className={classes.root}>
            <div className={classes.first_line}>
                <Typography variant={"subtitle2"} component={'span'}>Cuenta bancaria</Typography>
                <IconButton aria-label="Delete" className={classes.delete} size="small" onClick={() => handleDelete(bankAccount.id)}>
                    <DeleteIcon fontSize="inherit" />
                </IconButton>
            </div>
            <Divider className={classes.divider}/>
            <Typography variant={"caption"} component={'p'}>{bankAccount.iban}</Typography>
            <div>
                <Typography variant={"caption"} component={'p'}>cuenta por defecto: {defaultAccount}</Typography>
            </div>
        </Paper>
    );}

BankAccountCard.propTypes = {
    bankAccount: PropTypes.object.isRequired,
    handleDelete: PropTypes.func.isRequired,
};

export default withStyles(styles)(BankAccountCard);