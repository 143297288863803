import React from 'react';

const withMessaging = notificationType => Component => {

    return class extends React.Component {

        registerPushListener = pushNotification =>
            !!navigator.serviceWorker && navigator.serviceWorker.addEventListener("message", pushNotification);

        unregisterPushListener = pushNotification =>
            !!navigator.serviceWorker && navigator.serviceWorker.removeEventListener("message", pushNotification);

        componentDidMount() {
            this.registerPushListener(this.onNotification);
        }

        componentWillUnmount() {
            this.unregisterPushListener(this.onNotification);
        }

        registerNotificationHandler = notificationHandler => {
            this.notificationHandler = notificationHandler;
        }

        unRegisterNotificationHandler = () => {
            this.notificationHandler = undefined;
        }

        onNotification = notification => {
            if (!this.notificationHandler || !notification) {
                return;
            }

            let data = undefined;
            if (!!notification.data && !!notification.data.firebaseMessaging) {
                data = notification.data.firebaseMessaging.payload.data;
            } else if (!!notification.data && !!notification.data.data) {
                data = notification.data.data;
            }

            if (!!data &&
                ((!!notificationType && data.android_channel_id === notificationType) || !notificationType)) {
                this.notificationHandler(data);
            }
        };

        render() {
            return (
                <Component
                    registerNotificationHandler={this.registerNotificationHandler}
                    unRegisterNotificationHandler={this.unRegisterNotificationHandler}
                    {...this.props}
                />
            );
        }
    }
};

export default withMessaging;