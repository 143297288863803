import React from 'react';
import PropTypes from 'prop-types';
import {Paper, Typography} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import {BLUE, PALE_BLUE} from "../../utils/constants";
import Tip from "../../images/tips.png";
import clsx from "clsx";

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexFlow: 'nowrap row',
        backgroundColor: PALE_BLUE,
        padding: theme.spacing(1),
        borderRadius: theme.spacing(1),
        width: '100%'
    },
    icon: {
        flexGrow: 0,
        width: 48,
        height: 48,
        margin: theme.spacing(1),
    },
    tip: {
        flexGrow: 1,
        textAlign: "left",
        color: BLUE,
        margin: theme.spacing(1),
    },
}));

const TipComponent = ({tip, className, children}) => {
    const classes = useStyles();
    return (
        <Paper className={clsx(classes.root, className)}>
            <img className={classes.icon} alt={'tip'} src={Tip}/>
                {!!tip && <Typography className={classes.tip}>{tip}</Typography>}
                <div className={classes.tip}>
                    {children}
                </div>
        </Paper>
    );
};

TipComponent.propTypes = {
    tip: PropTypes.string,
    className: PropTypes.string,
};

export default TipComponent;