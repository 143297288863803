import React from "react";
import {PropTypes} from 'prop-types';

import {makeStyles} from "@material-ui/core/styles";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Typography,
    withStyles
} from "@material-ui/core";
import clsx from "clsx";

import {MARINE, MYUR_COOKIES_URL, PALE_BLUE} from "../../utils/constants";
import buttonStyles from "../common/styles/buttonStyles";
import {LS_COOKIE_CONSENT} from "../../utils/localStorage";
import {get} from "../common/i18n/i18n";
import layoutStyles from "../common/styles/layoutStyles";

const useStyles = makeStyles(theme => ({
    buttonDiv: {
        height: '100%',
        verticalAlign: 'middle',
        boxSizing: 'border-box',
        width: '100%',
    },
    air: {
        margin: theme.spacing(2, 0),
    },
    button: {
        width: '100%',
    },
    ...layoutStyles(theme),
    ...buttonStyles(theme),
}));

const StyledDialog = withStyles((theme) => ({
    paper: {
        width: '100%',
        backgroundColor: PALE_BLUE,
        color: MARINE,
        padding: theme.spacing(0, 2),
        boxSizing: 'border-box',
    }
}))(Dialog);

export default function CookieConsent({updateCookieConsent, open}) {
    const classes = useStyles();

    const handleClick = () => {
        localStorage.setItem(LS_COOKIE_CONSENT, 'true');
        updateCookieConsent(true);
    }
    const onClose = (event, reason) => {
        if (!reason) {
            handleClick();
        }
    }
    return (
        <StyledDialog open={open} onClose={onClose} maxWidth={'md'}>
            <DialogTitle>{get("cookie_policy")}</DialogTitle>
            <DialogContent>
                <Typography variant={'h1'} >myur!</Typography>
                <Typography variant={'h4'} className={classes.air}>{get('before_start')}</Typography>
                <Typography variant={'h5'} className={classes.air}>
                    {get('cookie_disclaimer')}
                </Typography>
                <Typography variant={'h4'} className={clsx(classes.air, classes.link)} component={'a'} target="_blank" rel="noopener noreferrer" href={MYUR_COOKIES_URL}>
                        {get('cookie_policy')}
                </Typography>
            </DialogContent>
            <DialogActions className={classes.buttonDiv}>
                <Button className={clsx(classes.defaultButton, classes.button)} onClick={handleClick}>
                    {get('i_accept')}
                </Button>
            </DialogActions>
        </StyledDialog>
    )
}

CookieConsent.propTypes = {
    open: PropTypes.bool.isRequired,
    updateCookieConsent: PropTypes.func.isRequired,
}