import {FIREBASE_AUTHENTICATED, GOT_USER_PUBLIC_PROFILE, REQUEST_USER_PUBLIC_PROFILE} from "../actions/actionTypes";

const INITIAL_STATE = {
    authUser: null,
    profile: null,
    anonymous: true, // t
};

export default function session(state = {...INITIAL_STATE}, action) {
    switch(action.type) {
        case FIREBASE_AUTHENTICATED : {
            const authUser = action.authUser;
            const profile = null;
            return {
                ...state,
                authUser,
                profile,
            }
        }
        case REQUEST_USER_PUBLIC_PROFILE: {
            return state;
        }
        case GOT_USER_PUBLIC_PROFILE: {
            return {
                ...state,
                profile: action.profile,
            }
        }
        default : return state;
    }
}

export const getProfile = state => state.profile;