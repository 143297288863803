import React from 'react';
import PropTypes from 'prop-types';
import ContextualItemMenu from "./ContextualItemMenu";

function ContextualItemMenuWrapper(props) {
    const {uid, item, handleEdit, handleDelete, handleReport, anonymousAction} = props;
    if (!!uid) {
        return <ContextualItemMenu
            uid={uid} item={item}
            handleDelete={handleDelete}
            handleReport={handleReport}
            handleEdit={handleEdit}/>
    }
    return <ContextualItemMenu
        uid={"anonymous"} item={item}
        handleDelete={anonymousAction}
        handleReport={anonymousAction}
        handleEdit={anonymousAction}
    />
}

ContextualItemMenuWrapper.propTypes = {
    uid: PropTypes.string,
    item: PropTypes.object,
    handleEdit: PropTypes.func.isRequired,
    handleDelete: PropTypes.func.isRequired,
    handleReport: PropTypes.func.isRequired,
    anonymousAction: PropTypes.func.isRequired
};
export default ContextualItemMenuWrapper;