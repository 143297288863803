import React from 'react';
import PropTypes from 'prop-types';

import {Typography} from "@material-ui/core";
import makeStyles from "@material-ui/core/styles/makeStyles";
import {get} from "../common/i18n/i18n";

const useStyles = makeStyles(theme => ({
    expired: {
        fontWeight: 'bold',
        color: 'RED',
    },
    active: {
        fontWeight: 'bold',
        color: 'MARINE',
    }
}));

export default function LiveSearchState({expired}) {
    const classes = useStyles();

    if(expired){
        return (<Typography variant={"caption"} className={classes.expired}>{get('expired')}</Typography>);
    }
    return (<Typography variant={"caption"} className={classes.active}>{get('active')}</Typography>);
}

LiveSearchState.propTypes = {
    expired: PropTypes.bool.isRequired,
};