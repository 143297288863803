import {Link} from "react-router-dom";
import {PASS_FORGET} from "../../utils/routes";
import React from "react";
import {compose} from "recompose";
import withStyles from "@material-ui/core/styles/withStyles";

const styles = theme => ({
    p: {
        textAlign: 'center',
        padding: theme.spacing(),
    },
    link: {
        fontSize: 'small',
        textDecoration: 'none',
        color: 'dodgerBlue'
    },
});

const PasswordForgetLink = (props) => {
    const {classes} = props;
    return (
        <p className={classes.p}>
            <Link to={PASS_FORGET} className={classes.link}>¿Password olvidado?</Link>
        </p>
    );
};

export default compose(
    withStyles(styles),
)(PasswordForgetLink);