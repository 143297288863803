import React from 'react';
import { connect } from 'react-redux';

import { auth, onAuthStateChanged } from '../../utils/firebase';
import {userAuthenticated} from "../../actions/sessionActions";

const withAuthentication = (Component) => {
    class WithAuthentication extends React.Component {

        componentDidMount() {
            const { userAuthenticated } = this.props;

            onAuthStateChanged(auth, authUser => {
                if (!!authUser) {
                    userAuthenticated(authUser)
                } else {
                    userAuthenticated(null)
                }
            });
        }

        render() {
            return (
                <Component {...this.props}/>
            );
        }
    }

    const mapDispatchToProps = (dispatch) => ({
        userAuthenticated: (authUser) => dispatch(userAuthenticated(authUser)),
    });

    return connect(null, mapDispatchToProps)(WithAuthentication);
};

export default withAuthentication;