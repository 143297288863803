import React from 'react';
import clsx from 'clsx';
import {PropTypes} from 'prop-types'

import {makeStyles} from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import {green, red} from '@material-ui/core/colors';
import Button from '@material-ui/core/Button';
import {isFunction} from "../../utils/utils";

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        alignItems: 'center',
    },
    wrapper: {
        margin: theme.spacing(1),
        position: 'relative',
    },
    buttonSuccess: {
        backgroundColor: green[500],
        '&:hover': {
            backgroundColor: green[700],
        },
    },
    buttonError: {
        backgroundColor: red[500],
        '&:hover': {
            backgroundColor: red[700],
        },
    },
    buttonProgress: {
        color: green[500],
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
    },
}));

export default function ProgressButton(props) {
    const {className, type, size, variant, color} = props;
    const {loading, success, error, disabled, handleClick, children} = props;

    const classes = useStyles();
    const buttonClassname = clsx(className, success && classes.buttonSuccess, error && classes.buttonError);

    const handleButtonClick = event => {
        if (isFunction(handleClick)) {
            handleClick(event);
        }
    };

    return (
        <div className={classes.wrapper}>
            <Button
                className={buttonClassname}
                variant={variant}
                type={type}
                color={color}
                size={size}
                disabled={loading || disabled}
                onClick={handleButtonClick}
            >
                {children}
            </Button>
            {loading && <CircularProgress size={24} className={classes.buttonProgress}/>}
        </div>
    );
}

ProgressButton.propTypes = {
    loading: PropTypes.bool.isRequired,
    handleClick: PropTypes.func,
    children: PropTypes.node,
    type: PropTypes.oneOf(['button', 'submit', 'reset']),
    size: PropTypes.oneOf(['small', 'medium', 'large']),
    variant: PropTypes.oneOf(['text', 'outlined', 'contained']),
    color: PropTypes.oneOf(['default', 'inherit', 'primary', 'secondary']),
    disabled: PropTypes.bool,
    success: PropTypes.bool,
    error: PropTypes.bool,
    className: PropTypes.string,
};

ProgressButton.defaultProps = {
    disabled: false,
    type: 'button',
    size: 'medium',
    variant: 'text',
    color: 'default',
    className: '',
};
