import React, {useState} from 'react';
import {PropTypes} from "prop-types";

import {Checkbox, FormControlLabel, makeStyles, Typography} from "@material-ui/core";
import {get} from "../common/i18n/i18n";
import {MARINE} from "../../utils/constants";
import {no_op} from "../../utils/utils";

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexFlow: 'nowrap column',
        justifyContent: 'space-between',
        margin: theme.spacing(1),
        color: 'white',
    },
    over8teen: {
        display: 'flex',
        flexFlow: 'nowrap row',
        justifyContent: 'center',
        margin: theme.spacing(1),
    },
    checkBox: {
        color: 'white',
    },
    checked: {
        color: MARINE,
        border: '2px solid white',
    },
    label: {
        fontSize: 'large',
        fontWeight: 700,
    },
    before: {
        fontWeight: 700,
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
    }
}));

export default function CheckAgeComponent(props) {
    const classes = useStyles();
    const [over8teen, setOver8teen] = useState(false);

    const onChange = event => {
        setOver8teen(event.target.checked);
        props.onChange(event.target.checked);
    };

    return (
        <div className={classes.root}>
            <Typography className={classes.before} variant={"h5"}>
                {get('before_registration')}
            </Typography>
            <FormControlLabel
                className={classes.over8teen}
                classes={{
                    label: classes.label
                }}
                control={
                    <Checkbox
                        className={classes.checkBox}
                        color={'default'}
                        classes={{
                            checked: classes.checkBox,
                        }}
                        checked={over8teen}
                        onChange={onChange}
                        value="over8teen"
                    />
                }
                label={get('im_over_8teen')}
            />

        </div>
    )
}

CheckAgeComponent.propTypes = {
    onChange: PropTypes.func
};

CheckAgeComponent.defaultProps = {
    onChange: no_op,
};