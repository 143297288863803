import React from 'react';
import moment from 'moment'
import {PropTypes} from 'prop-types';

import {Avatar, makeStyles, withStyles, Paper, Typography} from "@material-ui/core";
import {DEFAULT_AVATAR, MARINE} from "../../../utils/constants";
import {getAvatar} from "../../../utils/utils";
import ScoreComponent from "../../common/ScoreComponent";
import clsx from "clsx";
import {reviewCardStyles} from "./ReviewCardStyles";

const useStyles = makeStyles(theme => reviewCardStyles(theme));

const Description = withStyles(theme => ({
    caption: {
        fontSize: 'x-small',
        color: MARINE,
        marginLeft: theme.spacing(),
    },
}))(Typography);

export default function UserReviewCard({className, review}) {
    const date = moment(review.date, 'YYYYMMDDhhmmss').format('DD-MM-YY');
    const classes = useStyles();

    return (
        <Paper className={clsx(classes.root, className)} variant={"outlined"}>
            <div className={classes.user}>
                <Avatar alt={review.nickname} src={getAvatar(review.avatar)} className={classes.avatar}
                        imgProps={{
                            onError: e => e.target.src = DEFAULT_AVATAR
                        }}
                />

                <div className={classes.detail}>
                    <Typography variant={'body1'} className={classes.name}>{review.nickname}</Typography>
                    <Typography variant={'body2'} className={classes.date}>{date}</Typography>
                </div>
                <div className={classes.rating}>
                    <ScoreComponent score={review.rating} size={'16px'}/>
                </div>
            </div>
            <Description variant={'caption'} align={'left'}>
                {review.description}
            </Description>
        </Paper>
    );
}

UserReviewCard.propTypes = {
    review: PropTypes.object.isRequired,
    className: PropTypes.string,
};