import React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';

import clsx from "clsx";
import {Avatar, makeStyles, Typography} from "@material-ui/core";
import {getAvatar} from "../../utils/utils";
import {BROWN, DEFAULT_AVATAR, MARINE} from "../../utils/constants";

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        display: 'flex',
        flexFlow: 'nowrap row',
        justifyContent: 'flex-start',
        alignItems: 'center',
    },
    rootOwn: {
        justifyContent: 'flex-end',
    },
    avatar: {
        margin: theme.spacing(),
        width: 40,
        height: 40,
        order: 0,
    },
    avatarOwn: {
        order: 1,
    },
    text: {
        display: 'flex',
        flexFlow: 'nowrap column',
        alignItems: 'flex-start',
        color: MARINE,
        order: 1,
    },
    textOwn: {
        alignItems: 'flex-end',
        color: BROWN,
        order: 0,
    },
    nickname: {
        fontWeight: 700,
    }
}));

export default function MessageGroupHeader(props) {
    const classes = useStyles();
    const {nickname, avatar, timestamp, own} = props.header;

    const ts = moment(timestamp).format('hh:mm a');
    return(
        <div className={clsx(classes.root, own && classes.rootOwn)}>
            <Avatar alt={nickname} src={getAvatar(avatar)} className={clsx(classes.avatar, own && classes.avatarOwn)}
                    imgProps={{
                        onError: e => e.target.src = DEFAULT_AVATAR
                    }}
            />
            <div className={clsx(classes.text, own && classes.textOwn)}>
                <Typography className={classes.nickname} variant={'caption'}>{nickname}</Typography>
                <Typography variant={'caption'}>{ts}</Typography>
            </div>
        </div>
    )
}

MessageGroupHeader.propTypes = {
    header: PropTypes.shape({
        nickname: PropTypes.string.isRequired,
        avatar: PropTypes.string.isRequired,
        timestamp: PropTypes.string.isRequired,
        own: PropTypes.bool.isRequired,
    }).isRequired,
};