import React from 'react'
import {compose} from 'recompose'

import { withStyles } from '@material-ui/core/styles'
import PropTypes from "prop-types";
import PaymentCard from "./PaymentCard";
import BankAccountCard from "./BankAccountCard";

const styles = theme => ({
    root: {
        display: 'flex',
        flexFlow: 'wrap row',
    },
});

function PaymentMethodsList(props) {
    const {paymentMethods, bankAccounts, handleDeletePM, handleDeleteBA, classes} = props;
    return (
        <div className={classes.root}>
            {paymentMethods.map(pm =>
                <PaymentCard key={pm.id} paymentMethod={pm} handleDelete={handleDeletePM}/>
            )}
            {bankAccounts.map(ba =>
                <BankAccountCard key={ba.id} bankAccount={ba} handleDelete={handleDeleteBA}/>
            )}
        </div>
    )
}

PaymentMethodsList.propTypes = {
    classes: PropTypes.object.isRequired,
    paymentMethods: PropTypes.array.isRequired,
    bankAccounts: PropTypes.array.isRequired,
    handleDeletePM: PropTypes.func.isRequired,
    handleDeleteBA: PropTypes.func.isRequired,
};


export default compose(
    withStyles(styles),
)(PaymentMethodsList);
