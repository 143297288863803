import React from 'react';
import {PropTypes} from "prop-types";

import {Typography, makeStyles} from "@material-ui/core";

import EmailPasswordDialog from "./EmailPasswordDialog";
import {PALE_SAND, MARINE, SAND, MYUR_TERMS_URL, MYUR_PRIVACY_URL} from "../../utils/constants";
import GoogleButton from "./GoogleButton";
import {get} from "../common/i18n/i18n";
import layoutStyles from "../common/styles/layoutStyles";
import clsx from "clsx";

const useStyles = makeStyles(theme => ({
    ...layoutStyles(theme),
    button: {
        background: theme.palette.common.white,
        color: MARINE,
        borderRadius: 20,
        fontWeight: 700,
        minWidth: 340,
        marginTop: theme.spacing(2),
        '&:hover': {
            backgroundColor: PALE_SAND,
            borderColor: SAND,
            boxShadow: 'none',
        },
        '&:last-child': {
            marginBottom: theme.spacing(2),
        }
    },
    agreement: {
        color: theme.palette.common.white,
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
    },
    top: {
        marginTop: theme.spacing(1),
    },
    white: {
        color: theme.palette.common.white,
    }
}));

export default function SelectCredentialsComponent(props) {
    const classes = useStyles();
    return (
        <div className={classes.top}>
            <div>
                <Typography className={classes.agreement} variant={"h5"} display={"inline"}>
                    {get('sign_up_by_1')}
                </Typography>
                <Typography variant={'h5'} className={clsx(classes.link, classes.white)} component={'a'} display={"inline"} target="_blank" rel="noopener noreferrer" href={MYUR_TERMS_URL}>
                    {get('terms')}
                </Typography>
                <Typography className={classes.agreement} variant={"h5"} display={"inline"}>
                    {get('sign_up_by_2')}
                </Typography>
                <Typography variant={'h5'} className={clsx(classes.link, classes.white)} component={'a'} target="_blank" rel="noopener noreferrer" href={MYUR_PRIVACY_URL}>
                    {get('privacy')}
                </Typography>
            </div>
            <GoogleButton className={classes.button} onCredentials={props.onCredentials}/>
            <EmailPasswordDialog classes={{dialogButton: classes.button}} onCredentials={props.onCredentials}/>
        </div>
    );
}

SelectCredentialsComponent.propTypes = {
    onCredentials: PropTypes.func.isRequired,
};
