import React from "react";
import {PropTypes} from "prop-types";
import clsx from "clsx";

import {makeStyles} from "@material-ui/core/styles";

import {get} from "../common/i18n/i18n";
import {BLUE, MARINE, PALE_BLUE, RED, SERVICE_CATEGORY_ID} from "../../utils/constants";
import {Button, Divider, Typography} from "@material-ui/core";
import CustomDateTimeComponent from "../common/CustomDateTimeComponent";
import buttonStyles from "../common/styles/buttonStyles";
import layoutStyles from "../common/styles/layoutStyles";
import {isLoanCarInsurable} from "./item_utils";

const useStyles = makeStyles(theme => ({
    ...layoutStyles(theme),
    ...buttonStyles(theme),
    root: {
        background: PALE_BLUE,
        padding: theme.spacing(2),
        marginTop: theme.spacing(),
    },
    container: {
        display: 'flex',
        flexFlow: 'wrap row',
        justifyContent: 'space-between',
        alignItems: 'baseline',
        backgroundColor: theme.palette.common.white,
        borderRadius: '2rem',
        maxWidth: '26rem',
        marginLeft: 'auto',
        marginRight: 'auto',
    },
    divider: {
        margin: theme.spacing(0, 2),
        backgroundColor: MARINE,
        background: MARINE,
        color: MARINE,
        width: '2px',
        height: '1.3rem',
        flexGrow: 0,
    },
    table: {
        display: 'flex',
        flexFlow: 'wrap column',
        justifyContent: 'flex-start',
        marginTop: theme.spacing(2),
        maxWidth: '410px',
        marginLeft: 'auto',
        marginRight: 'auto',
    },
    summaryRow: {
        display: 'flex',
        flexFlow: 'nowrap row',
        justifyContent: 'space-between',
        borderBottom: `2px solid ${MARINE}`,
    },
    deposit: {
        display: "flex",
        flexDirection: 'column',
        alignItems: 'flex-start',
    },
    warningContainer: {
        display: 'flex',
        flexFlow: 'nowrap column',
        position: "relative",
        margin: '0px auto',
        padding: theme.spacing(2),
        maxWidth: 410,
    },
    warningNote: {
        fontWeight: 'bold',
        color: RED,
        textAlign: 'left',
    },
}));

export default function NewLoanSummary({item, startDate, endDate, error, response, reset, requestLoan}) {
    const classes = useStyles();
    const dp = response.detailedPrice;
    const dateTimeFormat = get('min_date_time_ui');
    const priceNotice = !!dp.insurancePrice ? 'summary_loan_price_insurance' : 'summary_loan_price_no_insurance';
    const loanNotice = item.parentCategoryId === SERVICE_CATEGORY_ID ? 'SERVICES' : 'summary_loan_price';
    return (
        <div className={classes.root}>
            <Typography variant={'h2'} style={{color: MARINE, marginBottom: '1em'}}>
                {get('when_needed')}
            </Typography>
            <div className={classes.container}>
                <CustomDateTimeComponent
                    ampm={false}
                    label={get('pick-up_date')}
                    style={{flexGrow: 2}}
                    value={startDate}
                    initialFocusedDate={startDate}
                    InputProps={{
                        disableUnderline: true
                    }}
                    disablePast
                    format={dateTimeFormat}
                    disabled
                />
                <Divider className={classes.divider} orientation={'vertical'} variant={"middle"}/>
                <CustomDateTimeComponent
                    ampm={false}
                    label={get('return_date')}
                    style={{flexGrow: 2}}
                    value={endDate}
                    initialFocusedDate={endDate}
                    InputProps={{
                        disableUnderline: true
                    }}
                    minDate={startDate}
                    disablePast
                    format={dateTimeFormat}
                    disabled
                />
            </div>
            <Typography variant={'caption'} className={classes.warningNote}>{get(error)}</Typography>
            <div className={classes.table}>
                <div className={classes.summaryRow}>
                    <div className={classes.deposit}>
                        <Typography variant={'body1'} noWrap={true} style={{marginLeft: '0.5em'}}>
                            {get(loanNotice)}
                        </Typography>
                        <Typography style={{marginLeft: '0.5em'}} variant={'caption'} noWrap={true}>
                            ({get(priceNotice)})
                        </Typography>
                    </div>
                    <div>
                        <Typography variant={'body1'} style={{marginRight: '0.5em'}}>
                            {get('currency_formatter').format(dp.totalRentalPrice)}
                        </Typography>
                    </div>
                </div>
                <div className={classes.summaryRow}>
                    <div className={classes.deposit}>
                        <Typography variant={'body1'} noWrap={true} style={{marginLeft: '0.5em'}}>
                            {get('deposit')}
                        </Typography>
                        <Typography style={{marginLeft: '0.5em'}} variant={'caption'} noWrap={true}>
                            ({get('deposit_notice_text')})
                        </Typography>
                    </div>
                    <div>
                        <Typography variant={'body1'} style={{marginRight: '0.5em'}}>
                            {get('currency_formatter').format(dp.depositPrice)}
                        </Typography>
                    </div>
                </div>
                <div className={classes.summaryRow}>
                    <div className={clsx(classes.spaceBetweenRow, classes.fullWidth)}
                         style={{background: BLUE, margin: '0.25em 0em'}}>
                        <div>
                            <Typography variant={'h4'} style={{color: 'white', textTransform: 'uppercase', marginLeft: '0.5em'}}>
                                {get('total')}
                            </Typography>
                        </div>
                        <div>
                            <Typography variant={'h4'} style={{color: 'white', marginRight: '0.5em'}}>
                                {get('currency_formatter').format(dp.totalPrice)}
                            </Typography>
                        </div>
                    </div>
                </div>
            </div>
            {
                isLoanCarInsurable(item) &&
                <div className={classes.warningContainer}>
                    <Typography variant={'caption'} className={classes.warningNote}>
                        {get('loan_insurance_notice')}
                    </Typography>
                    <Typography component={"a"} variant={"caption"} className={clsx(classes.link, classes.warningNote)}
                                href={item.insuranceConditionsURL}
                                target="_blank" rel="noopener noreferrer">
                        {get('insurance_conditions_s')}
                    </Typography>
                </div>
            }
            <div className={classes.warningContainer}>
                <Typography variant={'caption'} className={classes.warningNote}>
                    {get('loan_notice_text')}
                </Typography>
            </div>
            <Button className={classes.defaultButton} onClick={reset}>
                {get('loan_change_dates')}
            </Button>

            <Button className={classes.blueButton} onClick={requestLoan} disabled={!!error}>
                {get('i_want_it')}
            </Button>
        </div>
    );
}

NewLoanSummary.propTypes = {
    item: PropTypes.object.isRequired,
    startDate: PropTypes.object.isRequired,
    endDate: PropTypes.object.isRequired,
    error: PropTypes.string,
    response: PropTypes.object.isRequired,
    reset: PropTypes.func.isRequired,
    requestLoan: PropTypes.func.isRequired,
};