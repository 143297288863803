import React from 'react'
import PropTypes from "prop-types";
import {compose} from 'recompose';

import {withStyles} from '@material-ui/core/styles'
import {API_LIVE_GET_SEARCHES_RESPONSES, DEFAULT_MAX_WIDTH, PALE_BLUE} from "../../utils/constants";
import {Typography} from "@material-ui/core";
import LiveSearchNewSubmissionBanner from "./LiveSearchNewSubmissionBanner";
import {get} from "../common/i18n/i18n";
import ItemSkeleton from "../user/item/ItemSkeleton";
import {showMessage} from "../common/NotificationSnack";
import {buildUrl, doGet} from "../../utils/http";
import {connect} from "react-redux";
import LiveSearchMyCard from "./LiveSearchMyCard";

const styles = theme => ({
    root: {
        width: '100%',
        maxWidth: DEFAULT_MAX_WIDTH,
        background: PALE_BLUE,
    },
});

const INITIAL_STATE = {
    waiting: false,
    found: 0,
    start: 0,
    liveSearches: [],
};

class LiveSearchMySubmissions extends React.Component {

    constructor(props) {
        super(props);
        this.state = {...INITIAL_STATE};
    }

    componentDidMount() {
        this.retrieveLiveSearches();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.authUser !== this.props.authUser) {
            this.retrieveLiveSearches();
        }
    }

    retrieveLiveSearches = () => {
        if (!!this.props.authUser) {
            this.setState({waiting: true});
            doGet(buildUrl(API_LIVE_GET_SEARCHES_RESPONSES, {uid: this.props.authUser.uid}))
                .then(this.onLiveSearchesRetrieved)
                .catch(this.onError);
        }
    };

    onLiveSearchesRetrieved = liveSearches => {
        const _liveSearches = liveSearches.sort((search1, search2) => search1.publicationDate < search2.publicationDate);
        this.setState({
            liveSearches: _liveSearches,
            waiting: false
        });
    };

    onError = error => {
        this.setState({waiting: false});
        showMessage(error);
    };

    render() {
        const {classes} = this.props;
        const {waiting, liveSearches} = this.state;
        if (waiting) {
            return this.waiting();
        }
        if (liveSearches === []) {
            return this.noItems();
        }
        return (
            <div className={classes.root}>
                <LiveSearchNewSubmissionBanner/>
                {liveSearches.map(search => <LiveSearchMyCard key={search.id} search={search}/>)}
            </div>
        );
    }

    noItems() {
        return (
            <div className={this.props.classes.root}>
                <LiveSearchNewSubmissionBanner/>
                <Typography variant={"h6"}>{get('live_search_no_searches')}</Typography>
            </div>
        );
    }

    waiting() {
        return (
            <div className={this.props.classes.root}>
                <LiveSearchNewSubmissionBanner/>
                {[0, 1, 2].map(index => <ItemSkeleton key={index}/>)}
            </div>
        );
    }
}

LiveSearchMySubmissions.propTypes = {
    classes: PropTypes.object.isRequired,
};

const mapStateToProps = ({session}) => ({
    authUser: session.authUser,
});

export default compose (
    connect(mapStateToProps),
    withStyles(styles),
)(LiveSearchMySubmissions)
