import React from 'react'
import {makeStyles} from '@material-ui/core'
import {PropTypes} from 'prop-types'

import ArrowIcon from "@material-ui/icons/ArrowForward";
import NamedAvatar from "../common/NamedAvatar";
import clsx from "clsx";


const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexFlow: 'nowrap row',
        justifyContent: 'space-between',
        alignItems: 'baseline',
    },
}));

export default function OwnerToBorrower(props) {

    const {className, owner, borrower} = props;
    const classes = useStyles();

    return (
        <div className={clsx(classes.root, className)}>
            <NamedAvatar avatarUrl={owner.avatar} name={owner.nickname}/>
            <ArrowIcon/>
            <NamedAvatar avatarUrl={borrower.avatar} name={borrower.nickname}/>
        </div>

    );
}

OwnerToBorrower.propTypes= {
    className: PropTypes.string,
    owner: PropTypes.object.isRequired,
    borrower: PropTypes.object.isRequired,
};
