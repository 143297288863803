import React from 'react';
import PropTypes from 'prop-types';

import {makeStyles, Typography, Divider} from "@material-ui/core";
import {MARINE} from "../../utils/constants";

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexFlow: 'nowrap row',
        justifyContent: 'space-between',
        alignItems: 'center',
        margin: theme.spacing(1, 0),
        color: MARINE,
    },
    date: {
        margin: theme.spacing(0, 1),
        flexGrow: 1,
    },
    divider: {
        height: '2px',
        flexGrow: 2,
    }

}));

export default function DateSeparator({timestamp}) {
    const classes = useStyles();
    const date = timestamp.calendar(null, {
        sameDay: '[Today]',
        lastDay: '[Yesterday]',
        lastWeek: 'DD/MM/YYYY',
        sameElse: 'DD/MM/YYYY'
    });

    return (
        <div className={classes.root}>
            <Divider className={classes.divider}/>
            <Typography className={classes.date} variant={'h6'}>{date}</Typography>
            <Divider className={classes.divider}/>
        </div>
    );
}

DateSeparator.propTypes = {
    timestamp: PropTypes.object.isRequired,
};