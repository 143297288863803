import { combineReducers } from 'redux'

import session, * as fromSession from './session'
import searchItems, * as fromSearchItems from "./searchItems";
import notifications from "./notifications";

export default combineReducers({
    session,
    searchItems,
    notifications,
})

export const getProfile = state => fromSession.getProfile(state.session);
/**
 * returns the coordinates of the user. These can be device or user related.
 * @param state Redux store state.
 * @returns {{lng: *, lat: *}} Reference: north - east.
 */
export const getOrigin = state => fromSearchItems.getOrigin(state.searchItems);