const LOCAL_STORAGE_BASE_URI = 'app.myur.';

export const LS_IM_TOKEN_KEY = LOCAL_STORAGE_BASE_URI + 'im_token';
export const LS_IM_DEVICE_ID = LOCAL_STORAGE_BASE_URI + 'im_device_id';
export const LS_UID = LOCAL_STORAGE_BASE_URI + 'uid';
export const LS_NICKNAME = LOCAL_STORAGE_BASE_URI + 'nickname';
export const LS_COOKIE_CONSENT = LOCAL_STORAGE_BASE_URI + 'cookie_consent';

export const LS_ORIGIN_COORDS = LOCAL_STORAGE_BASE_URI + 'origin_coords';
export const LS_USER_LANDINGS = LOCAL_STORAGE_BASE_URI + 'user_landings';
export const LS_USER_SEARCHES = LOCAL_STORAGE_BASE_URI + 'user_searches';

export const saveProfile = profile => {
    if (!!profile) {
        localStorage.setItem(LS_UID, profile.uid);
        localStorage.setItem(LS_NICKNAME, profile.nickname);
    } else {
        localStorage.removeItem(LS_UID);
        localStorage.removeItem(LS_NICKNAME);
        localStorage.removeItem(LS_IM_DEVICE_ID);
    }
};

export const getProfile = () => {
    const uid = localStorage.getItem(LS_UID);
    const nickname = localStorage.getItem(LS_NICKNAME);
    if (!!uid && !!nickname) {
        return {uid, nickname};
    }
    return null;
};

export const saveOriginCoords = origin => {
    if (!!origin) {
        localStorage.setItem(LS_ORIGIN_COORDS, JSON.stringify(origin));
    } else {
        localStorage.removeItem(LS_ORIGIN_COORDS);
    }
}

export const incrementVariable = variable => {
    if (variable !== LS_USER_LANDINGS && variable !== LS_USER_SEARCHES) {
        return;
    }
    const currentValue = localStorage.getItem(variable);
    if (!currentValue || isNaN(currentValue)) {
        localStorage.setItem(variable, '1');
    } else {
        localStorage.setItem(variable, '' + (currentValue * 1 + 1));
    }
}

export const getOriginCoords = () => {
    const originCoordsAsString = localStorage.getItem(LS_ORIGIN_COORDS);
    const defaultCoords = {lat: null, lng: null};
    if (!!originCoordsAsString) {
        try {
            return JSON.parse(originCoordsAsString);
        } catch (error) {
            saveOriginCoords(defaultCoords);
        }
    }
    return defaultCoords;
}