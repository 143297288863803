import React, {useEffect, useState} from 'react';
import {PropTypes} from "prop-types";
import {makeStyles} from '@material-ui/core/styles';
import {useDispatch, useSelector} from "react-redux";

import {
    BLUE,
    DEFAULT_MAX_WIDTH,
    GEOLOCATION_PERMISSION,
    GEOLOCATION_PERMISSION_STATUS,
    LANDINGS_BEFORE_ASK_FOR_GEOLOCALIZATION_CONSENT,
    MARINE,
    PALE_BLUE,
    PERMISSION_DENIED,
    PERMISSION_GRANTED,
    PERMISSION_PROMPT,
    SEARCH_COUNT_ITEMS,
    SEARCHES_BEFORE_ASK_FOR_GEOLOCALIZATION_CONSENT,
    SEVERITY_ERROR
} from "../../utils/constants";
import {IconButton, Typography, Paper, TextField, Divider, withStyles, Hidden} from "@material-ui/core";
import {DatePicker} from "@material-ui/pickers";
import CloseIcon from '@material-ui/icons/Close';

import dateArrow from '../../images/datesarrow_blue.png';
import filters from '../../images/filters.png';

import '../common/react_dates.css';
import {retrieveBrowserGeoPosition, searchItems} from "../../actions/searchActions";
import {get} from "../common/i18n/i18n";
import layoutStyles from "../common/styles/layoutStyles";
import {showMessage} from "../common/NotificationSnack";
import {incrementVariable, LS_USER_LANDINGS, LS_USER_SEARCHES} from "../../utils/localStorage";
import GeolocationConsentDialog from "./GeolocationConsentDialog";

const useStyles = makeStyles(theme => ({
    ...layoutStyles(theme),
    root: {
        width: '100%',
        backgroundColor: PALE_BLUE,
        display: 'flex',
        flexFlow: 'nowrap column',
        justifyContent: "space-around",
        alignItems: 'center',
    },
    text: {
        color: MARINE,
        marginTop: theme.spacing(2),
    },
    paper: {
        backgroundColor: MARINE,
        maxWidth: DEFAULT_MAX_WIDTH,
        borderRadius: theme.spacing(),
        display: 'flex',
        flexFlow: 'nowrap row',
        justifyContent: 'center',
        width: '85%',
        margin: theme.spacing(2, 0)
    },
    form: {
        backgroundColor: theme.palette.common.white,
        borderRadius: 32,
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
        width: '85%',
        display: 'flex',
        flexFlow: 'nowrap row',
        justifyContent: 'space-between',
        alignItems: 'center',
        alignContent: 'center',
    },
    divider: {
        width: '2px',
        backgroundColor: MARINE,
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
    },
    buttonRoot: {
        boxShadow: 'none',
        textTransform: 'none',
        fontWeight: 700,
        backgroundColor: BLUE,
        color: theme.palette.common.white,
        '&:hover': {
            backgroundColor: PALE_BLUE,
            boxShadow: 'none',
        },
        '&:active': {
            boxShadow: 'none',
            backgroundColor: '#0062cc',
            borderColor: '#005cbf',
        },
    },
    button: {
        fontSize: 'xs',
        padding: "4px",
        margin: "4px",
    },
    what: {
        margin: theme.spacing(0, 1),
        marginLeft: theme.spacing(3),
        flexGrow: 2,
        [theme.breakpoints.down('xs')]: {
            marginLeft: theme.spacing(2),
        },
    },
    where: {
        margin: theme.spacing(0, 1),
        flexGrow: 2,
    },
    when: {
        display: "flex",
        flexFlow: "nowrap row",
        justifyContent: "space-around",
        alignItems: 'baseline',
    },
    arrow: {
        marginRight: theme.spacing(1),
    },
    date: {
        maxWidth: '8em',
    },
    filters: {
        marginLeft: "4px",
    },
    showMap: {
        display: "block",
        cursor: "pointer",
        maxWidth: DEFAULT_MAX_WIDTH,
        width: "85%",
        color: MARINE,
        marginTop: theme.spacing(-2),
    }
}));

const inputStyles = theme => ({
    root: {
        '& input': {
            ...theme.typography.h7,
            color: BLUE,
        },
    },
});

const dateInputStyles = theme => ({
    root: {
        '& input': {
            ...theme.typography.h7,
            color: BLUE,
            textAlign: "center",
        },
    },
});

const SearchTextField = withStyles(inputStyles)(TextField);

export default function SearchComponent({handleShowMap, showMap}) {
    const classes = useStyles();
    const [geolocationCosentDialogOpen, setGeolocationConsentDialogOpen] = useState(false);
    const [what, setWhat] = useState('');
    const [where, setWhere] = useState('');
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const dispatch = useDispatch();
    const storeSearchItems = useSelector(state => state.searchItems);
    const user = useSelector(state => state.session.profile);
    const dateTimeFormat = get('date_ui');

    useEffect(() => {
            setWhat(storeSearchItems.what);
            setWhere(storeSearchItems.where);
            setStartDate(storeSearchItems.startDate);
            setEndDate(storeSearchItems.endDate);
        }, [storeSearchItems]
    );

    const onWhereUpdated = event => {
        setWhat(event.target.value);
        if (event.key === "Enter") {
            _onSubmit(event);
        }
    }

    useEffect(() => {
        const geolocationPermissionStatus = sessionStorage.getItem(GEOLOCATION_PERMISSION_STATUS);
        if(geolocationPermissionStatus === PERMISSION_DENIED) {
            return;
        } else if (geolocationPermissionStatus === PERMISSION_GRANTED) {
            dispatch(retrieveBrowserGeoPosition());
            return;
        }
        const userSearches = localStorage.getItem(LS_USER_SEARCHES);
        const userLandings = localStorage.getItem(LS_USER_LANDINGS);
        if ((!!userSearches && userSearches % SEARCHES_BEFORE_ASK_FOR_GEOLOCALIZATION_CONSENT === 0)
            || (!!userLandings && userLandings % LANDINGS_BEFORE_ASK_FOR_GEOLOCALIZATION_CONSENT === 0)) {
            if (!!navigator.permissions) {
                navigator.permissions.query({name: GEOLOCATION_PERMISSION})
                    .then(function (result) {
                        if (result.state === PERMISSION_PROMPT) {
                            setGeolocationConsentDialogOpen(true);
                        }
                    });
            } else {
                setGeolocationConsentDialogOpen(true);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onStartDateUpdated = newDate => {
        setStartDate(newDate);
        let newEndDate;

        if (!newDate) {
            setEndDate(null);
            return;
        }
        if (!endDate || newDate.isAfter(endDate)) {
            newEndDate = newDate.clone().add(2, 'day');
            setEndDate(newEndDate);
        } else {
            newDate = endDate;
        }
        dispatch(searchItems(what, where, newDate, newEndDate, 0, SEARCH_COUNT_ITEMS));
    };

    const onEndDateUpdated = newDate => {
        setEndDate(newDate);
        dispatch(searchItems(what, where, startDate, newDate, 0, SEARCH_COUNT_ITEMS));
    }

    const _onSubmit = event => {
        event.preventDefault();
        if (!!startDate && !endDate) {
            showMessage(get('mandatory_end_date'), SEVERITY_ERROR);
            return;
        }
        dispatch(searchItems(what, where, startDate, endDate, 0, SEARCH_COUNT_ITEMS));
    };

    const submit = event => {
        if (event.key === "Enter") {
            incrementVariable(LS_USER_SEARCHES);
            _onSubmit(event);
        }
    }
    const reset = () => {
        setWhat('');
        setWhere(null);
        setStartDate(undefined);
        setEndDate(undefined);
        dispatch(searchItems("", null, null, null, 0, SEARCH_COUNT_ITEMS));
    }

    const geolocationConsentDialogOnClose = () => {
        setGeolocationConsentDialogOpen(false);
    }

    const geolocationConsentDialogOnAccept = () => {
        setGeolocationConsentDialogOpen(false);
        dispatch(retrieveBrowserGeoPosition());
    }

    return (
        <div className={classes.root}>
            <Hidden smDown>
                <Typography className={classes.text} variant={'h1'}>{get('myur')}</Typography>
            </Hidden>
            <Paper className={classes.paper}>
                <form className={classes.form} onKeyPress={submit}>
                    <img className={classes.filters} width={32} alt="filters" src={filters}/>
                    <SearchTextField
                        placeholder={get('what')}
                        className={classes.what}
                        type={"text"}
                        value={what}
                        onChange={onWhereUpdated}
                        InputProps={{
                            disableUnderline: true,
                        }}
                        margin="dense"/>
                    <Hidden smDown>
                        <Divider className={classes.divider} orientation={'vertical'} variant={'middle'}/>
                        <div className={classes.when}>
                            <DatePicker
                                ampm={false}
                                className={classes.date}
                                placeholder={get('search_from')}
                                value={startDate}
                                variant={"inline"}
                                initialFocusedDate={startDate}
                                InputProps={{
                                    disableUnderline: true,
                                }}
                                TextFieldComponent={withStyles(dateInputStyles)(TextField)}
                                disablePast
                                autoOk={true}
                                format={dateTimeFormat}
                                onChange={onStartDateUpdated}
                            />
                            <img className={classes.arrow} width={24} alt={'arrow'} src={dateArrow}/>
                            <DatePicker
                                ampm={false}
                                className={classes.date}
                                placeholder={get('search_to')}
                                disabled={!startDate}
                                value={endDate}
                                initialFocusedDate={endDate}
                                InputProps={{
                                    disableUnderline: true
                                }}
                                TextFieldComponent={withStyles(dateInputStyles)(TextField)}
                                minDate={startDate}
                                disablePast
                                autoOk={true}
                                format={dateTimeFormat}
                                onChange={onEndDateUpdated}
                            />
                        </div>
                    </Hidden>
                    <IconButton className={classes.button} classes={{root: classes.buttonRoot}} onClick={reset}>
                        <CloseIcon fontSize="inherit" />
                    </IconButton>
                </form>
            </Paper>
            {!!user &&
                <Hidden smDown>
                    <Typography variant={'caption'} onClick={handleShowMap} className={classes.showMap}
                                align={"right"}>
                        {get(`map_${showMap ? 'hide' : 'show'}_map`)}
                    </Typography>
                </Hidden>
            }
            <GeolocationConsentDialog
                open={geolocationCosentDialogOpen}
                handleClose={geolocationConsentDialogOnClose}
                handleCancel={geolocationConsentDialogOnClose}
                handleAccept={geolocationConsentDialogOnAccept}
            />
        </div>
    );
}

SearchComponent.propTypes = {
    handleShowMap: PropTypes.func.isRequired,
    showMap: PropTypes.bool,
};