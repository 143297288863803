import React from "react";
import {PropTypes} from "prop-types";
import MyurWaitingComponent from "./MyurWaitingComponent";
import clsx from "clsx";
import {makeStyles} from "@material-ui/core";
import layoutStyles from "./styles/layoutStyles";

const useStyles = makeStyles(theme => ({
    ...layoutStyles(theme)
}));

export default function ComponentWrapper({className, loading, children}) {
    const classes = useStyles();
    return (
        <div className={clsx(classes.component, className)}>
            {children}
            <MyurWaitingComponent open={loading} />
        </div>
    )
}

ComponentWrapper.propTypes = {
    className: PropTypes.string,
    loading: PropTypes.bool.isRequired,
};
