import {initializeApp} from "firebase/app";

import {getAuth, GoogleAuthProvider, PhoneAuthProvider} from 'firebase/auth';
import {getAnalytics, logEvent, isSupported} from "firebase/analytics";

import {
    FB_API_KEY, FB_APP_ID,
    FB_AUTH_DOMAIN,
    FB_DATABASE_URL,
    FB_MEASUREMENT_ID,
    FB_MESSAGING_SENDER_ID,
    FB_PROJECT_ID,
    FB_STORAGE_BUCKET
} from "../constants";

const config = {
    apiKey: FB_API_KEY,
    authDomain: FB_AUTH_DOMAIN,
    databaseURL: FB_DATABASE_URL,
    projectId: FB_PROJECT_ID,
    storageBucket: FB_STORAGE_BUCKET,
    messagingSenderId: FB_MESSAGING_SENDER_ID,
    appId: FB_APP_ID,
    measurementId: FB_MEASUREMENT_ID // for fallback purposes (after firebase version >= 7.20)
};

let analytics;
initializeApp(config);
isSupported()
    .then(yes => {
        if (yes) {
            analytics = getAnalytics();
        } else {
            console.warn("Analytics not supported.");
        }
    })
    .catch(error => console.error("Error initializing analytics. ", error));

const auth = getAuth();
auth.languageCode = 'es';

const googleProvider = new GoogleAuthProvider();
const phoneProvider = new PhoneAuthProvider(auth);

export {
    auth,
    analytics,
    logEvent,
    googleProvider,
    phoneProvider,
    PhoneAuthProvider
};