import React, {useState, useEffect} from 'react'
import {PropTypes} from 'prop-types'
import {withRouter} from 'react-router-dom'
import {compose} from "recompose";
import {connect} from "react-redux";

import {makeStyles} from "@material-ui/core/styles";
import GoBackHeader from "../../common/GoBackHeader";
import {buildUrl, doGet} from "../../../utils/http";
import UserDetailMiniCard from "./UserDetailMiniCard";
import {showMessage} from "../../common/NotificationSnack";
import {API_USER_GET_PUBLIC_DATA, API_USER_GET_PUBLIC_ITEMS, DEFAULT_MAX_WIDTH} from "../../../utils/constants";
import withAuthorization from "../../auth/withAuthorization";
import UserReviewsComponent from "./UserReviewsComponent";
import {Typography} from "@material-ui/core";
import {get} from "../../common/i18n/i18n";
import UserItemsComponent from "./UserItemsComponent";
import ComponentWrapper from "../../common/ComponentWrapper";
import NotFound from "../../navigation/NotFound";
import UserDetailMiniCardSkeleton from "./UserDetailMiniCardSkeleton";

const useStyles = makeStyles (theme => ({
    root: {
        width: '100%',
        maxWidth: DEFAULT_MAX_WIDTH,
        padding: theme.spacing(2),
        boxSizing: "border-box",
    },
    user: {

    },
    reviews: {
        display: 'flex',
        flexFlow: 'nowrap column',
    },
    name: {
        marginTop: theme.spacing(),
        marginBottom: theme.spacing(),
    },
    items: {

    }
}));

function UserDetailComponent(props) {

    const [user, setUser] = useState(null);
    const [items, setItems] = useState([]);
    const [uWaiting, setUWaiting] = useState(false);
    const [iWaiting, setIWaiting] = useState(false);
    const classes = useStyles();
    const uid = props.match.params.uid;
    const authUser = props.authUser;

    useEffect(() => {
        if (!!authUser){
            setUWaiting(true);
            doGet(buildUrl(API_USER_GET_PUBLIC_DATA, {uid}))
                .then(user => {setUser(user); setUWaiting(false);})
                .catch(error => {setUWaiting(false); showMessage(error);});
            setIWaiting(true);
            doGet(buildUrl(API_USER_GET_PUBLIC_ITEMS, {userId: uid, start: 0, count: 1000}))
                .then(response => {setItems(response.items);setIWaiting(false);})
                .catch(error => {setIWaiting(false); showMessage(error);});
        }
    }, [uid, authUser]);

    if (user === null && !uWaiting) {
        return <NotFound/>
    }
    return (
        <ComponentWrapper className={classes.root} loading={uWaiting}>
            <GoBackHeader title={get('user_detail')}/>
            <div className={classes.user}>
                {
                    !!user ? <UserDetailMiniCard user={user} /> : <UserDetailMiniCardSkeleton/>
                }
            </div>
            <div className={classes.reviews}>
                <Typography variant={'h6'} align={'left'} className={classes.name}>
                    {get('reviews_about', [!!user ? user.nickname : ""])}
                </Typography>
                <UserReviewsComponent user={user} waiting={uWaiting}/>
            </div>
            <div className={classes.items}>
                <Typography variant={'h6'} align={'left'} className={classes.name}>
                    {get('user_available_items', [!!user ? user.nickname : ""])}
                </Typography>
                <UserItemsComponent items={items} waiting={iWaiting}/>
            </div>
        </ComponentWrapper>
    );
}

UserDetailComponent.propTypes = {
    authUser: PropTypes.object,
};

const mapStateToProps = ({session}) => ({
    authUser: session.authUser,
});

const authCondition = profile => !!profile;

export default compose (
    connect(mapStateToProps),
    withAuthorization(authCondition),
    withRouter,
)(UserDetailComponent);