import React from 'react'
import PropTypes from 'prop-types'

import {IconButton, Paper, Tooltip, withStyles} from '@material-ui/core'
import {get} from "../i18n/i18n";
import DeleteIcon from '@material-ui/icons/Delete'
import {BLUE, SAND} from "../../../utils/constants";
import shortid from 'shortid'

const styles = theme => ({
    root: {
        display: 'flex',
        flexFlow: 'nowrap row',
    },
    imagePaper: {
        width: 210,
        height: 175,
        display: 'flex',
        flexFlow: 'nowrap row',
        justifyContent: 'space-around',
        alignContent: 'space-around',
        margin: theme.spacing(1),
    },
    image: {
        width: 210,
        height: 175,
        borderRadius: 4,
    },
    action: {
        fontSize: 16,
        height: 24,
        width: 24,
        margin: theme.spacing(0),
        color: BLUE,
        backgroundColor: SAND,
        float: 'left',
        marginRight: -28,
        position: 'relative',
        right: '36px',
        top: '12px',
    },
});

function ImageCard(props) {
    const {classes, photo, deleteOption, handleDelete, changeOption, handleChange} = props;
    const inputId = 'file_image_' + shortid.generate();
    return (
        <div className={classes.root}>
            <label htmlFor={inputId}>
                <Paper key={photo.id} className={classes.imagePaper}>
                    <img className={classes.image} alt={'Foto ' + photo.order} src={photo.photo}/>
                </Paper>
            </label>
            {(deleteOption === true) &&
            <Tooltip title={get('delete')}>
                <IconButton aria-label={get('delete')} className={classes.action} size="small"
                            onClick={() => handleDelete(photo)}>
                    <DeleteIcon fontSize="inherit"/>
                </IconButton>
            </Tooltip>
            }
            {(changeOption === true) &&
            <input
                hidden={true}
                type={'file'}
                name={'image'}
                id={inputId}
                onChange={event => handleChange(event, photo)}
                accept={'image/png, image/jpeg'}
            />
            }
        </div>
    );
}

ImageCard.propTypes = {
    classes: PropTypes.object.isRequired,
    photo: PropTypes.object.isRequired,
    deleteOption: PropTypes.bool,
    handleDelete: PropTypes.func,
    changeOption: PropTypes.bool,
    handleChange: PropTypes.func,
};

export default withStyles(styles)(ImageCard);