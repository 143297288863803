import {deleteToken as _deleteToken, getMessaging, getToken, isSupported} from "firebase/messaging";
import Fingerprint2 from "fingerprintjs2";

import {doPost} from "../http";
import {API_DEVICE_DEVICE, FB_PUBLIC_VAPID_KEY, SEVERITY_SUCCESS, SEVERITY_WARNING} from "../constants";
import {showMessage} from "../../components/common/NotificationSnack";
import {LS_IM_DEVICE_ID, LS_IM_TOKEN_KEY, LS_UID} from "../localStorage";

let messaging;
isSupported()
    .then(yes => {
        if (yes) {
            messaging = getMessaging();
        } else {
            if (process.env.NODE_ENV !== 'test') {
                console.warn("No instant messaging support.");
            }
        }
    })
    .catch(error => console.error("Error initializing messaging. Instant messaging will not be available.", error));

export default messaging;

export const doRequestFCMPermission = () => {
    messaging.requestPermission().then(function () {
        //console.debug('Notification permission granted.');
        showMessage("Ya puedes recibir notificaciones del chat.", SEVERITY_SUCCESS);
    }).catch(function (err) {
        console.debug('Unable to get permission to notify.', err);
    })
};

export const doGetFCMToken = serviceWorkerRegistration => {
    getToken(messaging, { vapidKey: FB_PUBLIC_VAPID_KEY, serviceWorkerRegistration})
        .then(
            currentToken => {
                if (!!currentToken) {
                    //console.debug("Token ok");
                    sendTokenToServer(currentToken);
                } else {
                    //console.debug('No Instance ID token available. Request permission to generate one.');
                    showMessage("No has habilitado permisos para recibir las notificaciones del chat", SEVERITY_WARNING);
                    localStorage.removeItem(LS_IM_TOKEN_KEY);
                }
            })
        .catch(function (err) {
            //console.debug('An error occurred while retrieving token. ', err);
            localStorage.removeItem(LS_IM_TOKEN_KEY);
        });
};

const sendTokenToServer = currentToken => {
    const uid = localStorage.getItem(LS_UID);
    const prevToken = localStorage.getItem(LS_IM_TOKEN_KEY);
    checkDeviceId(!!currentToken).then(deviceId => {
        if (!!deviceId && !!currentToken && !!uid && currentToken !== prevToken) {
            //console.debug('Sending token to server...');
            const body = JSON.stringify({deviceId, userDeviceId: currentToken, uid: uid});
            doPost(API_DEVICE_DEVICE, body)
                .then(response => localStorage.setItem(LS_IM_TOKEN_KEY, response.userDeviceId))
                .catch(error => {
                    showMessage(error);
                    localStorage.removeItem(LS_IM_TOKEN_KEY);
                });
        } else {
            //console.debug('Token already sent to server so won\'t send it again unless it changes');
        }
    });
}

function checkDeviceId(createNew) {
    return new Promise(((resolve, reject) => {
        const deviceId = localStorage.getItem(LS_IM_DEVICE_ID);
        if (!!deviceId) {
            resolve(deviceId);
        }
        if (createNew === false) {
            resolve(null);
        }
        // Fingerprint used to uniquely register browser as notifications receiver device.
        const options = {
            preprocessor: null,
            audio: {
                timeout: 1000,
                excludeIOS11: true
            },
            fonts: {
                swfContainerId: 'fingerprintjs2',
                swfPath: 'flash/compiled/FontList.swf',
                userDefinedFonts: [],
                extendedJsFonts: false
            },
            screen: {
                detectScreenOrientation: true
            },
            plugins: {
                sortPluginsFor: [/palemoon/i],
                excludeIE: false
            },
            extraComponents: [],
            excludes: {
                userAgent: false,
                webdriver: false,
                language: false,
                colorDepth: false,
                deviceMemory: false,
                pixelRatio: true,
                hardwareConcurrency: false,
                screenResolution: false,
                availableScreenResolution: false,
                timezoneOffset: false,
                timezone: false,
                sessionStorage: false,
                localStorage: false,
                indexedDb: false,
                addBehavior: false,
                openDatabase: false,
                cpuClass: false,
                platform: false,
                doNotTrack: true,
                plugins: false,
                canvas: false,
                webgl: false,
                webglVendorAndRenderer: false,
                adBlock: false,
                hasLiedLanguages: false,
                hasLiedResolution: false,
                hasLiedOs: false,
                hasLiedBrowser: false,
                touchSupport: false,
                fonts: false,
                fontsFlash: true,
                audio: true,
                enumerateDevices: true,
            },
            NOT_AVAILABLE: 'not available',
            ERROR: 'error',
            EXCLUDED: 'excluded'
        };
        try {
            if (window.requestIdleCallback) {
                window.requestIdleCallback(() =>
                    Fingerprint2.get(options, components => {
                        const fingerPrint = Fingerprint2.x64hash128(JSON.stringify(components), 53);
                        localStorage.setItem(LS_IM_DEVICE_ID, fingerPrint);
                        resolve(fingerPrint);
                    }))
            } else {
                setTimeout(() =>
                    Fingerprint2.get(options, components => {
                        const fingerPrint = Fingerprint2.x64hash128(JSON.stringify(components), 53);
                        localStorage.setItem(LS_IM_DEVICE_ID, fingerPrint);
                        resolve(fingerPrint);
                    }), 500);
            }
        } catch (error) {
            reject(error);
        }
    }))
}

export function deleteToken() {
    if (!messaging) {
        return;
    }
    localStorage.removeItem(LS_IM_TOKEN_KEY);
    _deleteToken(messaging)
        .then(() => {
            //console.debug('Token deleted.');
        })
        .catch(error => {
            //console.debug('Unable to delete token. ', err);
        });
}