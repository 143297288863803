import React from 'react'

import {PropTypes} from "prop-types";

import StarIcon from '@material-ui/icons/Star'
import NoStarIcon from '@material-ui/icons/StarBorder'
import HalfStarIcon from '@material-ui/icons/StarHalf'

import {makeStyles} from '@material-ui/core/styles'
import {BLUE} from "../../utils/constants";
import {Tooltip} from "@material-ui/core";
import {get} from "./i18n/i18n";


const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexFlow: 'nowrap row',
        justifyContent: 'flex-start',
        color: BLUE,
    },
    icon: props => ({
        fontSize: props.size,
    }),
}));


function ScoreComponent(props) {
    const {score} = props;
    const classes = useStyles(props);

    const drawDonough = () => [0, 1, 2, 3, 4].map(star => <NoStarIcon key={star} className={classes.icon}/>);

    function drawStarLine() {
        const starLine = drawDonough();
        let i;
        for (i = 0; i < (score - score % 1); i++) {
            starLine[i] = <StarIcon key={i} className={classes.icon}/>;
        }
        if (score % 1 !== 0) {
            starLine[i] = <HalfStarIcon key={i} className={classes.icon}/> ;
        }
        return starLine;
    }

    if (score === undefined || score === 0) {
        return (<div className={classes.root}>{drawDonough()}</div>)
    }
    return (
        <Tooltip title={get('score', [score])} aria-label={get('score', [score])}>
            <div className={classes.root}>
                {drawStarLine()}
            </div>
        </Tooltip>
    );

}

ScoreComponent.propTypes= {
    score: PropTypes.number,
    size: PropTypes.string,
};

ScoreComponent.defaultProps = {
    size: '1.5rem',
};

export default ScoreComponent;