import React from 'react';
import {Typography} from "@material-ui/core";
import clsx from "clsx";
import {makeStyles} from "@material-ui/core/styles";
import {
    BROWN,
    CHAT_MESSAGE_TYPE_IMAGE,
    CHAT_MESSAGE_TYPE_TEXT,
    DEFAULT_PHOTO_WIDTH,
    MARINE
} from "../../utils/constants";

const useStyles = makeStyles(theme => ({
    message: {
        color: MARINE,
        margin: theme.spacing(0, 1),
        alignSelf: 'flex-start',
    },
    image: {
        width: DEFAULT_PHOTO_WIDTH,
        height: 'auto',
    },
    messageOwn: {
        color: BROWN,
        alignSelf: 'flex-end',
    },

}));

export default function ChatMessageComponent({message}) {
    const classes = useStyles();

    if (message.payloadType === CHAT_MESSAGE_TYPE_TEXT) {
        return (
            <Typography variant={!message.own && message.status !== 'read' ? 'h4' : 'body1'}
                        className={clsx(classes.message, message.own && classes.messageOwn)}>
                {message.payload}
            </Typography>
        );
    } else if (message.payloadType === CHAT_MESSAGE_TYPE_IMAGE) {
        return (
            <img className={clsx(classes.message, message.own && classes.messageOwn, classes.image)}
                 alt={message.payload} src={message.payload}
            />
        );
    }
    return (<React.Fragment/>);
}
