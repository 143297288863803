import React from 'react';
import {PropTypes} from "prop-types";

import {BLUE, DEFAULT_PHOTO} from "../../utils/constants";
import {Typography} from "@material-ui/core";
import makeStyles from "@material-ui/core/styles/makeStyles";
import {get} from "../common/i18n/i18n";
import moment from "moment";
import LiveSearchState from "./LiveSearchState";

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexFlow: 'wrap row',
        justifyContent: 'flex-start',
        alignItems: 'center',
        color: BLUE,
    },
    detail: {
        display: 'flex',
        flexFlow: 'wrap column',
        justifyContent: 'space-between',
        alignItems: 'flex-start',
    },
    title: {
        fontWeight: 'bold',
        alignSelf: 'center',
    },
    photo: {
        margin: theme.spacing(),
        marginRight: theme.spacing(2),
        borderRadius: 4,
        width: 108,
        height: 90,
    },
    header: {
        display: 'flex',
        flexFlow: 'nowrap row',
        justifyContent: 'space-between',
        width: '100%',
    },
}));

export default function LiveSearchResponseHeader({liveSearch}) {

    const classes = useStyles();
    const date = moment(liveSearch.publicationDate, 'YYYYMMDDhhmmss').format('DD/MM/YYYY');
    const getPhoto = photo => !!photo ? photo : DEFAULT_PHOTO;
    return (
        <div className={classes.root}>
            <img alt={liveSearch.title} src={getPhoto(liveSearch.photo)} className={classes.photo} />
            <div className={classes.detail}>
                <Typography variant={'h6'} className={classes.title}>{liveSearch.title}</Typography>
                <div className={classes.header}>
                    <Typography variant={'caption'}>
                        {get('publication_date')}: {date}
                    </Typography>
                    <LiveSearchState expired={liveSearch.expired}/>
                </div>
                <Typography variant={'body2'} align={'left'}>
                    {liveSearch.description}
                </Typography>
            </div>
        </div>
    );
}

LiveSearchResponseHeader.propTypes = {
    liveSearch: PropTypes.object.isRequired,
};