import React from 'react';
import {PropTypes} from 'prop-types';

import {makeStyles, Typography} from "@material-ui/core";
import {
    PALE_SAND,
    BLUE,
    DEFAULT_PHOTO_WIDTH,
    ORANGE,
    PENDIENTE_RESPUESTA, DATE_TIME_FORMAT
} from "../../utils/constants";
import moment from "moment";
import {get} from "../common/i18n/i18n";

import Arrow from '../../images/datesarrow_blue.png';
import {useSelector} from "react-redux";
import ContextualLoanMenu from "./ContextualLoanMenu";
import clsx from "clsx";
import layoutStyles from "../common/styles/layoutStyles";

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexFlow: 'nowrap row',
        justifyContent: 'flex-start',
        backgroundColor: PALE_SAND,
        marginBottom: theme.spacing(),
    },
    photo: {
        height: 'auto',
        width: '30%',
        maxWidth: DEFAULT_PHOTO_WIDTH * 1.5,
        borderRadius: '4px',
        marginRight: theme.spacing(2),
    },
    info: {
        display: 'flex',
        flexFlow: 'nowrap column',
        justifyContent: 'space-around',
        alignItems: 'center',
        width: '70%',
    },
    header: {
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
    },
    title: {
        color: BLUE,
        flexGrow: 1,
    },
    contextualMenu: {
        flexGrow: 0,
    },
    earn: {
        display: 'flex',
        flexFlow: 'nowrap row',
        justifyContent: 'space-between',
        border: '1px solid ' + BLUE,
        backgroundColor: 'white',
        color: BLUE,
        width: '90%',
        padding: theme.spacing(),
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(3),
        textTransform: 'upperCase',
    },
    available: {
        color: ORANGE,
        marginTop: theme.spacing(),
        marginBottom: theme.spacing(2),
    },
    dateContainer: {
        display: 'flex',
        flexFlow: 'nowrap row',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '90%',
    },
    date: {
        border: '1px solid ' + BLUE,
        backgroundColor: 'white',
        color: BLUE,
        padding: theme.spacing(),
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(3),
    },
    ...layoutStyles(theme),
}));

function Earn({uid, loan}) {
    const classes = useStyles();
    const imOwner = uid === loan.ownerId;
    const state = loan.loanStatus.key;

    let firstLineText;
    let amount;
    let secondLine;

    if (imOwner) {
        if (state === PENDIENTE_RESPUESTA) {
            firstLineText = get('loan_can_earn');
            secondLine = (
                <Typography variant={'h4'} className={classes.available}>
                    {get('item_currently_available')}
                </Typography>
            );
        } else {
            firstLineText = get('loan_will_earn');
            secondLine = (<div/>)
        }
        amount = loan.profitOwner;
    } else {
        firstLineText = get('total');
        amount = loan.totalPrice;
        secondLine = (
            <div className={classes.earn}>
                <Typography variant={'h3'} className={classes.bold}>{get('deposit_loan')}</Typography>
                <Typography variant={'h3'} className={classes.bold}>{loan.deposit + '€'}</Typography>
            </div>

        );
    }
    return (
        <React.Fragment>
            <div className={classes.earn}>
                <Typography variant={'h3'} className={classes.bold}>{firstLineText}</Typography>
                <Typography variant={'h3'} className={classes.bold}>{amount + '€'}</Typography>
            </div>
            {secondLine}
        </React.Fragment>
    );
}

Earn.propTypes = {
    uid: PropTypes.string.isRequired,
    loan: PropTypes.object.isRequired,
};

export default function LoanMiniCard({loan, createIssue}) {
    const classes = useStyles();
    const user = useSelector(state => state.session.profile);

    const startDate = moment.utc(loan.loanInitDate, DATE_TIME_FORMAT).tz(moment.tz.guess()).format('DD/MM/YYYY');
    const endDate = moment.utc(loan.loanEndDate, DATE_TIME_FORMAT).tz(moment.tz.guess()).format('DD/MM/YYYY');

    return (
        <div className={classes.root}>
            <img className={classes.photo} src={loan.itemPhoto} alt={loan.itemTitle}/>
            <div className={classes.info}>
                <div className={clsx(classes.defaultRow, classes.header)}>
                    <Typography variant={"h1"} className={classes.title}>{loan.itemTitle}</Typography>
                    <ContextualLoanMenu className={classes.contextualMenu} handleIssue={createIssue} loan={loan}/>
                </div>
                <Earn uid={user.uid} loan={loan}/>
                <div className={classes.dateContainer}>
                    <Typography variant={"h3"} className={classes.date}>{startDate}</Typography>
                    <img alt={'arrow'} src={Arrow}/>
                    <Typography variant={"h3"} className={classes.date}>{endDate}</Typography>
                </div>
            </div>
        </div>
    );
}

LoanMiniCard.propTypes = {
    loan: PropTypes.object.isRequired,
    createIssue: PropTypes.func.isRequired,
};