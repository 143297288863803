import React from 'react';
import {PropTypes} from "prop-types";
import {makeStyles} from '@material-ui/core/styles';
import clsx from "clsx";

import {get} from '../common/i18n/i18n';
import {Checkbox, FormControlLabel, Paper, Typography} from "@material-ui/core";

import MyurInput from "../live/MyurInput";
import CameraIcon from "@material-ui/icons/CameraAltOutlined";
import {
    BLUE,
    DEFAULT_PHOTO_HEIGHT,
    DEFAULT_PHOTO_WIDTH,
    MYUR_ITEM_PHOTO_ASPECT_RATIO, MYUR_ITEM_PHOTO_MAX_WIDTH,
    SEVERITY_ERROR
} from "../../utils/constants";
import layoutStyles from "../common/styles/layoutStyles";
import loadImage from "blueimp-load-image";
import {showMessage} from "../common/NotificationSnack";
import {cropAndResize} from "../common/image/imageUtils";
import MyurDateTimePicker from "../common/MyurDateTimePicker";

const useStyles = makeStyles(theme => ({
    root: {},
    input: {
        width: '100%',
    },
    imagePaper: {
        width: DEFAULT_PHOTO_WIDTH,
        height: DEFAULT_PHOTO_HEIGHT,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        margin: theme.spacing(1),
    },
    camera: {
        margin: theme.spacing(2),
        fontSize: '6em',
        color: BLUE,
    },
    ...layoutStyles(theme),
}));

export default function InsuranceIssueComponent({className, value: issue, issueType, errors, onChange}) {
    const classes = useStyles();

    const handleAddPhoto = event => {
        if (event.target.files && event.target.files.length > 0) {
            const file = event.target.files[0];
            loadImage(file,
                img => {
                    if (img.type === "error") {
                        showMessage(get('image_error', [file.name]), SEVERITY_ERROR);
                    } else {
                        const ext = file.name.substr(file.name.lastIndexOf('.'));
                        const fileName = 'issue' + ext;
                        const image = {
                            image: img,
                            fileName: fileName,
                            fileType: file.type,
                            width: img.width,
                            height: img.height,
                        };
                        cropAndResize(image, MYUR_ITEM_PHOTO_ASPECT_RATIO, MYUR_ITEM_PHOTO_MAX_WIDTH)
                            .then(bin => {
                                const img = window.URL.createObjectURL(bin);
                                onChange({...issue, image: {...image, img, bin}});
                            });
                    }
                }, {orientation: true});
        }
    };

    let header = <React.Fragment/>;
    if (issueType.issueTypeId === 6) {
        header =
            <React.Fragment>
                <Typography variant={'h4'}>{get('insurance_issue_fire')}</Typography>
                <Typography variant={'body1'}>{get('insurance_issue_fire2')}</Typography>
            </React.Fragment>;
    } else if (issueType.issueTypeId === 7) {
        header =
            <React.Fragment>
                <Typography variant={'h4'}>{get('insurance_issue_theft')}</Typography>
                <Typography variant={'body1'}>{get('insurance_issue_theft2')}</Typography>
            </React.Fragment>;
    } else if (issueType.issueTypeId === 8) {
        header =
            <React.Fragment>
                <Typography variant={'h4'}>{get('insurance_issue_damage')}</Typography>
                <FormControlLabel
                    className={classes.input}
                    control={
                        <Checkbox
                            checked={issue.broken}
                            onChange={event => onChange({...issue, broken: event.target.checked})}
                            value={''}
                            color="primary"
                        />
                    }
                    label={get('insurance_issue_damage2')}
                />
            </React.Fragment>;
    }
    return (
        <div className={clsx(classes.root, className)}>
            {header}
            <div className={classes.spaceBetweenRow}>
                <div className={classes.defaultColumn}>
                    <MyurInput
                        className={classes.input}
                        label={get('where_happened')}
                        placeholder={get('where_happened')}
                        value={issue.where}
                        onChange={event => onChange({...issue, where: event.target.value})}
                        fullWidth
                        error={errors.insuranceIssueWhere !== ''}
                        helperText={errors.insuranceIssueWhere}
                    />
                    <MyurDateTimePicker
                        disableFuture={true}
                        ampm={false}
                        format={get('compact_date_time_ui')}
                        className={classes.input}
                        label={get('when_1')}
                        placeholder={get('when_1')}
                        value={issue.when}
                        shrink={true}
                        onChange={newDate => onChange({...issue, when: newDate})}
                        error={errors.insuranceIssueWhen !== ''}
                        helperText={errors.insuranceIssueWhen}
                    />
                </div>
                <label htmlFor={'file_image'}>
                    <Paper className={classes.imagePaper}>
                        <input
                            hidden={true}
                            type={'file'}
                            name={'image'}
                            id={'file_image'}
                            onChange={handleAddPhoto}
                            accept={'image/png, image/jpeg'}
                        />
                        {
                            !!issue.image
                                ? <img alt={'issue_image'} src={issue.image.img}/>
                                : <CameraIcon className={classes.camera}/>
                        }
                    </Paper>
                    <Typography variant={'caption'} color={'error'}>
                        {errors.insuranceIssueImage}
                    </Typography>
                </label>
            </div>
        </div>
    );
}

InsuranceIssueComponent.propTypes = {
    className: PropTypes.string,
    issueType: PropTypes.object,
    value: PropTypes.shape({
        broken: PropTypes.bool,
        where: PropTypes.string,
        when: PropTypes.any,
        image: PropTypes.object,
    }),
    errors: PropTypes.shape({
        insuranceIssueWhere: PropTypes.string,
        insuranceIssueWhen: PropTypes.string,
        insuranceIssueImage: PropTypes.string,
    }),
    onChange: PropTypes.func.isRequired,
};