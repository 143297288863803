import React from 'react'
import {PropTypes} from 'prop-types';
import {Paper, Typography, Divider, IconButton} from "@material-ui/core";
import DeleteIcon from '@material-ui/icons/Delete'
import EditIcon from '@material-ui/icons/Edit'
import {withStyles} from "@material-ui/core/styles";
import {openAddressDialog} from "./AddressDialog";

const styles = theme => ({
    root: {
        margin: theme.spacing(),
        padding: theme.spacing(),
        minWidth: 180,
        textAlign: 'left',
    },
    bottomMargin: {
        bottomMargin: theme.spacing(2),
    },
    topMargin: {
        topMargin: theme.spacing(4),
    },
    first_line: {
        display: 'flex',
        flexFlow: 'no-wrap row',
        justifyContent: 'flex-end',
        alignItems: 'center',
    },
    description: {
        flexGrow: 1,
        textAlign: 'left',
    },
    divider: {
        marginBottom: theme.spacing(),
    },
    delete: {
        margin: theme.spacing(0),
    },
});

function AddressCard(props) {
    const {address, handleDelete, classes} = props;

    const def = address.defaultAddr === true ? 'sí' : 'no';
    const billing = address.billingAddr === true ? 'sí' : 'no';
    return (
        <Paper className={classes.root}>
            <div className={classes.first_line}>
                <Typography variant={"subtitle2"} className={classes.description}>
                    {address.description}
                </Typography>
                <IconButton aria-label="Edit" className={classes.delete} size="small"
                            onClick={() => openAddressDialog(address.uid, address)}>
                    <EditIcon fontSize="inherit" />
                </IconButton>
                <IconButton aria-label="Delete" className={classes.delete} size="small" onClick={() => handleDelete(address.addressId)}>
                    <DeleteIcon fontSize="inherit" />
                </IconButton>
            </div>
            <Divider className={classes.divider}/>
            <Typography variant={"caption"} component={'p'}>{address.address1}</Typography>
            <Typography variant={"caption"} component={'p'}>{address.address2}</Typography>
            <Typography variant={"caption"} component={'p'}>{address.country} - {address.province}</Typography>
            <Typography variant={"caption"} component={'p'} className={classes.bottomMargin}>
                C.P. {address.zipCode} - {address.town}
            </Typography>
            <Typography variant={"caption"} component={'p'}>por defecto: {def} - facturación: {billing}</Typography>
        </Paper>
    );}

AddressCard.propTypes = {
    address: PropTypes.object.isRequired,
    handleDelete: PropTypes.func.isRequired,
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(AddressCard);