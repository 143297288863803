
export const readFile = file => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.addEventListener('load', () => {
            if (!reader.result) {
                reject(new Error('Image could not be read.'));
            }
            const image = new Image();
            image.src = reader.result;
            image.onload = () =>
                resolve({
                    image: image,
                    fileName: file.name,
                    fileType: file.type,
                    width: image.naturalWidth,
                    height: image.naturalHeight,
                });
        });
        reader.readAsDataURL(file);
    });
};

export const cropAndResize = (image, aspectRatio, maxWidth) => {
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');
    const crop = getCrop(image.width, image.height, aspectRatio);
    canvas.width = crop.width;
    canvas.height = crop.height;
    if (crop.width > maxWidth) {
        canvas.width = maxWidth;
        canvas.height = Math.floor(maxWidth / aspectRatio);
    }

    ctx.drawImage(
        image.image,
        crop.x,
        crop.y,
        crop.width,
        crop.height,
        0,
        0,
        canvas.width,
        canvas.height,
    );

    return new Promise((resolve, reject) => {
        canvas.toBlob(blob => {
            if (!blob) {
                reject(new Error('Canvas is empty'));
            }
            blob.name = image.fileName;
            resolve(blob);
        }, image.fileType);
    });
};

export const getCrop = (actualWidth, actualHeight, aspectRatio) => {
    let width = actualWidth,
        height = actualHeight,
        x = 0,
        y = 0;
    if (actualHeight * aspectRatio < actualWidth) {
        width = Math.floor(height * aspectRatio);
        x = Math.floor((actualWidth - width) / 2);
    } else {
        height = Math.floor(width / aspectRatio);
        y = Math.floor((actualHeight - height) / 2);
    }
    return {width, height, x, y, unit: 'px', aspect: aspectRatio};
};
