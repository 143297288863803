import React, {useState} from 'react';
import {PropTypes} from 'prop-types';
import {useHistory} from 'react-router-dom';

import {
    TextField,
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    makeStyles,
} from "@material-ui/core";
import {get} from "../common/i18n/i18n";
import {showMessage} from "../common/NotificationSnack";
import MailIcon from "../../images/Email_icon.png";
import {
    doCreateUserWithEmailAndPassword,
    doSignInWithEmailAndPassword,
    sendEmailVerification,
    userExists
} from "../../utils/firebase";
import buttonStyles from "../common/styles/buttonStyles";
import {API_USER_EXISTS, SEVERITY_ERROR, SEVERITY_INFO} from "../../utils/constants";
import {buildUrl, doFetch} from "../../utils/http";
import {LANDING} from "../../utils/routes";

const useStyles = makeStyles(theme => ({
    content: {
        display: 'flex',
        flexFlow: 'nowrap column',
        justifyContent: 'space-between',
        margin: theme.spacing(1),
    },
    field: {
        marginTop: theme.spacing(2),
    },
    dialogButton: {

    },
    ...buttonStyles(theme),
}));

export default function EmailPasswordDialog(props) {
    const [open, setOpen] = React.useState(false);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [repeatPassword, setRepeatPassword] = useState('');
    const [emailError, setEmailError] = useState('');
    const [passwordError, setPasswordError] = useState('');
    const [emailExists, setEmailExists] = useState(false);
    const history = useHistory();
    const classes = useStyles();
    const dialogButtonClass =
        (props.classes && props.classes.dialogButton) ? props.classes.dialogButton : classes.dialogButton;
    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };
    const handleOk = () => {
        const {onCredentials} = props;
        /*
        Intentamos hacer login. Si podemos, comprobamos que el usuario existe en backend, si existe mandamos a la landing
        Si no existe, continuamos con el proceso de alta. El se proceso de login falla, es que el usuario no existe en
        firebase y procedemos a crearlo.
        */
        doSignInWithEmailAndPassword(email, password)
            .then(result => {
                setOpen(false);
                const {uid, email, emailVerified} = result.user;
                return doFetch(
                    buildUrl(API_USER_EXISTS, {uid}),
                    user => {
                        if (user.uid === uid) {
                            history.push(LANDING)
                        } else {
                            props.onCredentials(uid, email, emailVerified);
                        }
                    },
                    () => props.onCredentials(uid, email, emailVerified),
                    {method: 'GET'}
                );
            })
            .catch(() => {
                doCreateUserWithEmailAndPassword(email, password)
                    .then(result => {
                        setOpen(false);
                        sendEmailVerification(
                            () => showMessage("Enviado correo de confirmación", SEVERITY_INFO),
                            error => showMessage(error, SEVERITY_ERROR));
                        const {uid, email, emailVerified} = result.user;
                        onCredentials(uid, email, emailVerified);
                    })
                    .catch(error => {
                        setEmailError(error.message)
                    });
            });
    };

    const updateEmail = event => {
        const _mail = event.target.value;
        setEmail(_mail.trim());
        if (!!_mail) {
            setEmailError('');
        }
    };
    const updateRepeatPassword = event => {
        const pass = event.target.value;
        if (!!pass) {
            setPasswordError('');
        }
        setRepeatPassword(pass);
    };

    const checkEmail = () => {
        if (!!email) {
            setEmailError('');
            userExists(email)
                .then(onEmailCheckSuccess)
                .catch(onEmailCheckError)
        } else {
            setEmailError(get('mandatory_email'));
        }
    };
    const onEmailCheckSuccess = sources =>
        sources.length > 0 ? setEmailExists(true) : setEmailExists(false);

    const onEmailCheckError = error => {
        setEmailExists(false);
        showMessage(error);
    };

    const disabled = (mail, pass1, pass2, emailExists) => {
        return (mail === '' || pass1 === '') || (!emailExists && pass1 !== pass2);
    };

    const checkPassword = () => {
        if (password === '') {
            setPasswordError(get('mandatory_password'));
            return;
        }
        if (password.length < 6) {
            setPasswordError(get('short_password'));
            return;
        }
        if (password !== repeatPassword) {
            setPasswordError(get('distinct_passwords'));
        }
    };

    return (
        <div>
            <Button className={dialogButtonClass} onClick={handleClickOpen}>
                <img alt={'select_email_password'} src={MailIcon} width={24} style={{marginRight: 16}}/>
                {get('select_email_password')}
            </Button>
            <Dialog open={open} onClose={handleClose} fullWidth={true} maxWidth={'sm'}>
                <DialogTitle>{get('enter_email_password')}</DialogTitle>
                <DialogContent className={classes.content}>
                    <TextField
                        className={classes.field}
                        autoFocus
                        error={emailError !== ''}
                        label={get('email')}
                        type="email"
                        value={email}
                        onChange={updateEmail}
                        onBlur={checkEmail}
                        helperText={emailError}
                    />
                    <TextField
                        error={passwordError !== ''}
                        className={classes.field}
                        label={get('password')}
                        type="password"
                        value={password}
                        onChange={event => setPassword(event.target.value)}
                    />
                    {
                        !emailExists && (
                            <TextField
                                className={classes.field}
                                error={passwordError !== ''}
                                label={get('repeat_password')}
                                type="password"
                                value={repeatPassword}
                                onChange={updateRepeatPassword}
                                onBlur={checkPassword}
                                helperText={passwordError}
                            />
                        )
                    }
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} className={classes.defaultButton}>
                        {get('CANCEL')}
                    </Button>
                    <Button onClick={handleOk}
                            className={classes.defaultButton}
                            disabled={disabled(email, password, repeatPassword, emailExists)}>
                        {emailExists ? get('access') : get('register')}
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

EmailPasswordDialog.propTypes = {
    classes: PropTypes.object,
    onCredentials: PropTypes.func,
};