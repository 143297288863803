import React, {useState} from 'react';
import {IconButton, Menu, MenuItem} from '@material-ui/core';
import {PropTypes} from "prop-types";

import MoreVertIcon from '@material-ui/icons/MoreVert';
import {makeStyles} from "@material-ui/core/styles";
import {BLUE} from "../../utils/constants";
import {get} from "../common/i18n/i18n";
import NewIssueDialog from "./NewIssueDialog";
import clsx from "clsx";
import LoanIssueDialog from "./LoanIssueDialog";

const useStyles = makeStyles(theme => ({
    root: {

    },
    button: {
        padding: 0,
        color: BLUE,
    },
}));

export default function ContextualLoanMenu({className, loan, handleIssue}) {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [newIssueOpen, setNewIssueOpen] = useState(false);
    const [issueListOpen, setIssueListOpen] = useState(false);
    const classes = useStyles();

    const handleClick = event => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const onIssueClicked = issue => {
        handleIssue(issue);
        setNewIssueOpen(false);
        handleClose();
    };

    const handleNewIssueDialogOpen = () => {
        setNewIssueOpen(true);
        handleClose();
    };

    const handleDialogClose = () => {
        setNewIssueOpen(false);
        handleClose();
    };

    const handleIssueListDialogOpen = () => {
        setIssueListOpen(true);
        handleClose();
    };

    return (
        <div className={clsx(classes.root, className)}>
            <IconButton onClick={handleClick} className={classes.button}>
                <MoreVertIcon/>
            </IconButton>

            <Menu anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
                <MenuItem onClick={handleNewIssueDialogOpen}>{get('new_issue')}</MenuItem>
                {
                    !!loan.issues && loan.issues.length > 0 &&
                    <MenuItem onClick={handleIssueListDialogOpen}>{get('issues')}</MenuItem>
                }
            </Menu>
            <NewIssueDialog loan={loan} handleIssue={onIssueClicked} open={newIssueOpen} handleClose={handleDialogClose}/>
            <LoanIssueDialog loan={loan} open={issueListOpen} handleClose={() => setIssueListOpen(false)} />
        </div>
    );
}

ContextualLoanMenu.propTypes = {
    className: PropTypes.string,
    loan: PropTypes.object,
    handleIssue: PropTypes.func.isRequired,
};