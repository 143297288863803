import React from "react";
import {Backdrop, CircularProgress, makeStyles} from "@material-ui/core";

const useStyles = makeStyles(theme => ({
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
}));

export default function MyurWaitingComponent({open, ...rest}) {
    const classes = useStyles();
    return (
        <Backdrop className={classes.backdrop} open={open} {...rest}>
            <CircularProgress color="inherit" size={68}/>
        </Backdrop>
    );
}