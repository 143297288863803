import React, {useEffect, useRef, useState} from 'react'
import {useSelector} from "react-redux";
import {compose} from 'recompose';
import PropTypes from "prop-types";

import moment from "moment";

import {makeStyles, Typography} from "@material-ui/core";

import {API_CHAT_CHAT_LIST} from "../../utils/constants";
import {get} from "../common/i18n/i18n";
import GoBackHeader from "../common/GoBackHeader";
import {buildUrl} from "../../utils/http";
import {showMessage} from "../common/NotificationSnack";
import ItemSkeleton from "../user/item/ItemSkeleton";
import ProgressButton from "../common/ProgressButton";
import {isSignedId, getToken} from "../../utils/firebase";
import ChatItemCard from "./ChatItemCard";
import withAuthorization from "../auth/withAuthorization";
import withMessaging from "../../utils/firebase/withMessaging";
import layoutStyles from "../common/styles/layoutStyles";

const useStyles = makeStyles(theme => ({
    ...layoutStyles(theme)
}));

function ChatList({children}) {
    const classes = useStyles();
    return (
        <div className={classes.component}>
            <GoBackHeader title={get('chats')}/>
            {children}
        </div>
    );
}

function ChatListComponent(props) {
    const {registerNotificationHandler, unRegisterNotificationHandler} = props;
    const [pendingChats, setPendingChats] = useState(false);
    const [chatList, setChatList] = useState([]);
    const [waiting, setWaiting] = useState(false);
    const user = useSelector(state => state.session.profile);
    const chatListRef = useRef(chatList);
    chatListRef.current = chatList;

    useEffect(() => {
            if (!user) {
                return;
            }
            retrieveChats();
            // eslint-disable-next-line
        }, [user]
    );

    const addNotification = notification => {
        const chatKey = `${notification.borrowerId}!${notification.ownerId}!${notification.itemId}`;
        if (!!chatListRef.current) {
            const newChatList = chatListRef.current.map(chat => {
                if (chat.key !== chatKey) {
                    return chat;
                }
                return {
                    ...chat,
                    lastMsgId: notification.id,
                    lastMsgRead: false,
                    lastMsgText: notification.body,
                    lastMsgTS: notification.timestamp,
                    msgNumber: chat.msgNumber + 1
                };
            });
            setChatList(newChatList);
        } else {
            !!user && retrieveChats();
        }
    };

    useEffect(() => {
        registerNotificationHandler(addNotification);
        return () => unRegisterNotificationHandler();
        // eslint-disable-next-line 
    }, []);

    const doRequest = url => {
        if (isSignedId()) {
            return getToken()
                .then(token => {
                    const headers = new Headers({'Accept': 'application/json'});
                    //const headers = new Headers();
                    headers.append('Authorization', 'Bearer ' + token);
                    fetch(url, {headers})
                        .then(response => {
                            if (response.ok && response.status !== 204) {
                                return response.json().then(json => onChatsRetrieved(json));
                            } else if (response.status === 204) {
                                onChatsRetrieved({});
                            } else {
                                return response.text()
                                    .then(json => onError(json))
                                    .catch(error => onError(error));
                            }
                        });
                })
                .catch(error => onError(error));
        }
    };

    const retrieveChats = () => {
        setWaiting(true);
        doRequest(
            buildUrl(API_CHAT_CHAT_LIST, {since: moment().format(), max: 50}),
            onChatsRetrieved,
            onError,
            {method: 'GET'}
        );
    };

    const onChatsRetrieved = response => {
        setPendingChats(response.moreResults);
        setChatList(response.entityResults);
        setWaiting(false);
    };

    const onError = error => {
        setWaiting(false);
        showMessage(error);
    };

    if (waiting) {
        return (
            <ChatList>
                <div>
                    {[0, 1, 2].map(index => <ItemSkeleton key={index}/>)}
                </div>
            </ChatList>
        );
    }

    if (chatList.length === 0) {
        return (
            <ChatList>
                <Typography variant={"h6"}>{get('user_no_chats')}</Typography>
            </ChatList>
        );
    }

    return (
        <ChatList>
            {chatList.map(chat =>
                <ChatItemCard key={chat.key} uid={user.uid} chat={chat}/>
            )}
            {pendingChats && (<ProgressButton>{get('chat_load_more')}</ProgressButton>)}
        </ChatList>
    );
}

ChatListComponent.propTypes = {
    authUser: PropTypes.object,
};

const authCondition = profile => !!profile;

export default compose (
    withAuthorization(authCondition),
    withMessaging('chatchannel')
)(ChatListComponent);
