import {withStyles} from "@material-ui/core/styles";
import {MARINE} from "../../utils/constants";
import {DateTimePicker} from "@material-ui/pickers";

const DateTimeComponent = withStyles(theme => ({
    root: {
        '& label + .MuiInput-formControl': {
            marginTop: 7,
        },
        '& label': {
            ...theme.typography.body2,
            color: MARINE,
        },
        '& input': {
            ...theme.typography.body2,
            color: MARINE,
            marginLeft: theme.spacing(1),
            maxWidth: '7rem',
        },
        '& input.Mui-disabled': {
            color: 'rgba(0, 0, 0, 0.4)',
        },
        '& label.MuiInputLabel-shrink': {
            color: MARINE,
            ...theme.typography.caption,
            marginLeft: theme.spacing(1),
        },
        '& label.Mui-disabled': {
            ...theme.typography.body2,
            color: 'rgba(0, 0, 0, 0.4)',
        },
    },
}))(DateTimePicker);

export default DateTimeComponent;