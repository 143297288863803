import React from "react";
import {PropTypes} from 'prop-types'
import {Typography} from "@material-ui/core";
import {makeStyles} from "@material-ui/styles";
import {BLUE} from "../../utils/constants";
import {get} from "../common/i18n/i18n";
import clsx from "clsx";

const useStyles = makeStyles(theme => ({
    label: {
        color: BLUE,
    },
    value: {
        marginLeft: 4,
        color: BLUE,
    },
}));

const getValue = value => {
    if (value === true) {
        return get('yes');
    }
    if (value === false) {
        return get('no');
    }
    return value;
};

export default function LabelValue({className, classes, label, value, children}) {
    const _classes = useStyles();
    return (
        <div className={className}>
            <Typography variant={"h5"} className={clsx(_classes.label, !!classes && classes.label)} display={'inline'}>
                {label}:
            </Typography>
            {
                value !== undefined && value !== null &&
                    <Typography variant={"body1"} className={clsx(_classes.value, !!classes && classes.value)} display={'inline'}>
                        {getValue(value)}
                    </Typography>
            }
            {children}
        </div>
    )
}

LabelValue.propTypes = {
    label: PropTypes.string.isRequired,
    value: PropTypes.any,
    className: PropTypes.string,
    classes: PropTypes.object,
    children: PropTypes.any,
};

LabelValue.defaultProps = {
    className: '',
};