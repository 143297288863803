import React from 'react'
import withAuthorization from '../auth/withAuthorization'
import {get} from "../common/i18n/i18n";
import GoBackHeader from "../common/GoBackHeader";

function ShareComponent(props) {
    return (
        <GoBackHeader title={get('share')}/>
    )
}

const authCondition = profile => !!profile;

export default withAuthorization(authCondition)(ShareComponent)