import React, {useState} from 'react';
import {PropTypes} from "prop-types";

import {makeStyles, Typography} from "@material-ui/core";
import {get} from "../common/i18n/i18n";
import MyurInput from "../live/MyurInput";
import {showMessage} from "../common/NotificationSnack";
import {buildUrl, doFetch} from "../../utils/http";
import {API_USER_EXISTS_NICKNAME, PALE_SAND} from "../../utils/constants";
import {no_op} from "../../utils/utils";
import {validate_zipcode, validate_username} from "../user/user_validation";

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexFlow: 'nowrap column',
        alignItems: 'center',
        margin: theme.spacing(1),
        color: theme.palette.common.white,
    },
    label: {
        fontSize: 'large',
        color: theme.palette.common.white,
    },
    labelFocused: {
        '&.Mui-focused' : {
            color: PALE_SAND,
        }
    },
    before: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        fontWeight: 700,
    },
}));

export default function NicknameComponent(props) {
    const classes = useStyles();
    const [username, setUsername] = useState('');
    const [usernameError, setUsernameError] = useState('');
    const [zipcode, setZipcode] = useState('');
    const [zipcodeError, setZipcodeError] = useState('');

    const checkUsername = () => {
        const error = validate_username(username);
        setUsernameError(error);
        if (!error) {
            doFetch(buildUrl(API_USER_EXISTS_NICKNAME, {nickname: username.trim()}),
                onUsernameExistsSuccess,
                onUsernameExistsError,
                {method: 'GET'}
            );
        }
    };
    const onUsernameExistsSuccess = user =>
        (!!user && user.nickname === username) ? setUsernameError(get('repeated_username')) : setUsernameError('');

    const onUsernameExistsError = error => {
        setUsernameError(error.message);
        showMessage(error);
    };

    const onUsernameChange = event => {
        setUsername(event.target.value);
        props.onUsernameChange(event.target.value);
    };

    const onZipcodeChange = event => {
        setZipcode(event.target.value);
        props.onZipcodeChange(event.target.value);
    };

    const checkZipcode = () => {
        setZipcodeError(validate_zipcode(zipcode));
    }
    return (
        <div className={classes.root}>
            <Typography className={classes.before} variant={"h5"}>
                {get('last')}
            </Typography>
            <MyurInput
                classes={{label: classes.label, focused: classes.labelFocused}}
                autoFocus
                label={get('enter_nickname')}
                placeholder={get('nickname')}
                value={username}
                helperText={usernameError}
                onBlur={checkUsername}
                error={!!usernameError}
                onChange={onUsernameChange}
            />
            <MyurInput
                classes={{label: classes.label, focused: classes.labelFocused}}
                label={get('enter_zipcode')}
                placeholder={get('zipcode')}
                value={zipcode}
                onChange={onZipcodeChange}
                onBlur={checkZipcode}
                error={!!zipcodeError}
                helperText={zipcodeError}
            />
        </div>
    )
}

NicknameComponent.propTypes = {
    onUsernameChange: PropTypes.func,
    onZipcodeChange: PropTypes.func,
};

NicknameComponent.defaultProps = {
    onUsernameChange: no_op,
    onZipcodeChange: no_op,
};
