import {SERVICE_CATEGORY_ID, SPACE_CATEGORY_ID} from "../../utils/constants";
import {sanitize} from "../../utils/utils";
import {get} from "../common/i18n/i18n";
import {buildUrl} from "../../utils/http";
import {ROUTE_ITEM} from "../../utils/routes";

export const isServiceOrSpace = item =>
    !(!item.category
        || (item.category.parentCategory !== SERVICE_CATEGORY_ID
            && item.category.parentCategory !== SPACE_CATEGORY_ID));

export const isInsurable = item =>
    !!item.category && item.category.insurable;

/**
 * Insurable categories:
 * <ol>
 *     <li>cars_and_vans, 58</li>
 *     <li>motorbikes, 61</li>
 *     <li>classic_cars, 86</li>
 *     <li>quads 65 (pending)</li>
 *     <li>caravans 66</li>
 * </ol>
 * @param item
 * @returns {boolean}
 */
export const isLoanCarInsurable = item => {
    if (!item || !item.categoryId || !item.parentCategoryId || (item.parentCategoryId*1) !== 9) {
        return false
    }
    const categoryId = item.categoryId*1;
    return categoryId === 58
        || categoryId === 61
        || categoryId === 66
        || categoryId === 86
}

/**
 * Insurable categories:
 * <ol>
 *     <li>cars_and_vans, 58</li>
 *     <li>motorbikes, 61</li>
 *     <li>classic_cars, 86</li>
 *     <li>quads 65 (pending)</li>
 *     <li>caravans 66</li>
 * </ol>
 * @param item
 * @returns {boolean}
 */
export const isCarInsurable = item => {
    if (!item || !item.category || item.category.parentCategory !== 9 || !item.category.categoryId) {
        return false
    }
    const categoryId = item.category.categoryId;
    return categoryId === 58
        || categoryId === 61
        || categoryId === 66
        || categoryId === 86
}

/**
 * Insurable categories:
 * <ol>
 *     <li>boats, 62</li>
 *     <li>jet_ski, 63</li>
 * </ol>
 * @param item
 * @returns {boolean}
 */
export const isBoatInsurable = item => {
    if (!item || !item.category || item.category.parentCategory !== 92 || !item.category.categoryId) {
        return false
    }
    const categoryId = item.category.categoryId;
    return categoryId === 62 || categoryId === 63
}

export const getSeoTitle = item => {
    const title = sanitize(item.title);
    const location = sanitize(item.location);
    return get("item_public_url_title", [title, location]);
}

export const getPublicUrl = item => {
    const sanitizedTitle = getSeoTitle(item);
    return `${buildUrl(ROUTE_ITEM, {id: item.id})}/?title=${sanitizedTitle}`;
}