import {Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle} from "@material-ui/core";
import {PropTypes} from "prop-types";
import React from "react";

export default function VehicleDialog({open, handleClose, title, contentText, buttonAction = handleClose, buttonText = 'ok'}) {
    return (
        <Dialog
            open={open}
            onClose={handleClose}
        >
            <DialogTitle>{title}</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    {contentText}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={buttonAction} color="primary" autoFocus>
                    {buttonText}
                </Button>
            </DialogActions>
        </Dialog>
    );
}

VehicleDialog.propTypes = {
    open: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired,
    title: PropTypes.node,
    contentText: PropTypes.node,
    buttonAction: PropTypes.func,
    buttonText: PropTypes.node,
};
