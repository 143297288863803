export const categories = [
    'NO CATEGORY',
    'ELECTRONIC_AND_COMPUTERS',
    'AUDIO_VISUAL',
    'ARTS_AND_EDUCATION',
    'BABIES_AND_KIDS',
    'PARTY_AND_EVENTS',
    'HOME_AND_GARDEN',
    'SPORTS_AND_ADVENTURE',
    'TOOLS_AND_MACHINERY',
    'VEHICLES',
    'FASHION_BEAUTY_AND_HEALTH',
    'SPACES',
    'SMARTPHONE',
    'DRONES',
    'CONSOLES',
    'VIDEOGAMES',
    'LAPTOPS',
    'PRINT_AND_SCAN',
    'TABLETS',
    'DESKTOPS',
    'NETWORKING',
    'ELECTRONIC_ACCESSORIES',
    'FILM',
    'PHOTOGRAPHY',
    'AUDIO_AND_DJ_EQUIPMENT',
    'PROJECTORS',
    'SCREENS',
    'MUSICAL_INSTRUMENTS',
    'LEARNING_MATERIAL',
    'ART_AND_CRAFTS',
    'CINEMA_MUSIC_AND_LITERATURE',
    'BABY_CARE',
    'PRAMS_AND_CARRIERS',
    'CAR_AND_BIKE_SEATS',
    'TOYS_AND_GAMES',
    'PARTY_COSTUMES',
    'PARTY_DECORATION',
    'PARTY_ATTRACTIONS',
    'PARTY_FURNITURE',
    'PARTY_SPACES',
    'POOL',
    'PETS_SUPPLIES',
    'GARDEN',
    'ELECTRICAL_HOUSEHOLD_APPLIANCE',
    'ILLUMINATION',
    'FURNITURE',
    'DECORATION',
    'SURF_AND_KAYAK',
    'BIKES',
    'SKI',
    'FISHING',
    'HUNTING',
    'RACQUET_SPORTS',
    'HIKING_AND_OUTDOORS',
    'OTHER_SPORTS',
    'AGRICULTURE',
    'CONSTRUCTION',
    'DYI',
    'CARS_AND_VANS',
    'TRANSPORT_ACCESSORIES',
    'TRUCKS',
    'MOTORBIKES',
    'BOAT',
    'JET_SKI',
    'AIRCRAFT',
    'QUADS',
    'CARAVANS',
    'CLOTHES_AND_SHOES',
    'FASHION_ACCESSORIES',
    'WATCHES',
    'JEWELRY',
    'BEAUTY',
    'HEALTH_CARE',
    'STORAGE_ROOMS',
    'HOMES_AND_ROOMS',
    'OFFICES',
    'GARAGE',
    'GARDENS_AND_OUTDOORS',
    'SERVICES',
    'LESSONS',
    'OTHER_SERVICES',
];