import React from 'react'
import PropTypes from "prop-types";

import {API_ITEM_UNFAVORITE, API_USER_GET_FAVOURITES, SAND, SEVERITY_SUCCESS} from "../../../utils/constants";
import {get} from "../../common/i18n/i18n";
import {Grid, Typography} from "@material-ui/core";
import {compose} from "recompose";
import withAuthorization from "../../auth/withAuthorization";
import {connect} from "react-redux";
import {buildUrl, doDelete, doGet} from "../../../utils/http";
import {showMessage} from "../../common/NotificationSnack";
import FavouriteCard from "./FavouriteCard";
import FavouriteSkeleton from "./FavouriteSkeleton";
import ProfileSectionComponent from "../../common/ProfileSectionComponent";
import {withStyles} from "@material-ui/core/styles";

const INITIAL_STATE = {
    favs: [],
    waiting: false,
};

const styles = theme => ({
    favourites: {
        "& >:last-child" : {
            borderBottom: `2px solid ${SAND}`
        }
    }
});

const NoFavs = () =>
    <Typography variant={"h6"}>{get('no_favourites')}</Typography>

const Waiting = () => [0, 1, 2].map(index => <FavouriteSkeleton key={index}/>)

class FavListComponent extends React.Component {

    constructor(props) {
        super(props);
        this.state = {...INITIAL_STATE};
    }

    componentDidMount() {
        this.retrieveFavs(this.props.authUser.uid);
    }

    retrieveFavs = uid => {
        this.setState({waiting: true});
        doGet(buildUrl(API_USER_GET_FAVOURITES, {uid}))
            .then(this.onFavsRetrieved)
            .catch(this.onError);
    };

    onError = error => {
        this.setState({waiting: false});
        showMessage(error);
    };

    onFavsRetrieved = favs => {
        this.setState({
            favs,
            waiting: false,
        });
    };

    removeFavorite = (item, uid) => {
        const url = buildUrl(API_ITEM_UNFAVORITE, {uid, itemId: item});
        this.setState({loading: true});
        doDelete(url)
            .then(response => this.onFavoriteRemoved(response, uid))
            .catch(this.onError);
    };

    onFavoriteRemoved = (response, uid) => {
        showMessage(get('delete_favorite_success'), SEVERITY_SUCCESS);
        this.retrieveFavs(uid);
    }

    render() {
        const {favs, waiting} = this.state;
        const {authUser, classes} = this.props;

        if (waiting) {
            return (
                <ProfileSectionComponent waiting={waiting}>
                    <div className={classes.favourites}>
                        <Waiting/>
                    </div>
                </ProfileSectionComponent>
            );
        }
        if (favs.length === 0) {
            return (
                <ProfileSectionComponent waiting={waiting}>
                    <NoFavs/>
                </ProfileSectionComponent>
            );
        }
        return (
            <ProfileSectionComponent waiting={waiting}>
                <div className={classes.favourites}>
                    {favs.map(fav =>
                        <Grid item key={fav.id}>
                            <FavouriteCard fav={fav} removeFavorite={() => this.removeFavorite(fav.id, authUser.uid)}/>
                        </Grid>
                    )}
                </div>
            </ProfileSectionComponent>
        );
    }
}

FavListComponent.propTypes = {
    classes: PropTypes.object.isRequired,
};


const mapStateToProps = ({session}) => ({
    authUser: session.authUser,
});

const authCondition = profile => !!profile;

export default compose(
    withAuthorization(authCondition),
    withStyles(styles),
    connect(mapStateToProps),
)(FavListComponent)
