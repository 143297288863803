import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import withAuthorization from '../../auth/withAuthorization'
import {buildUrl, doGet} from "../../../utils/http";
import {API_NOTIFICATION_LAST, SAND, SEVERITY_ERROR} from "../../../utils/constants";
import {showMessage} from "../../common/NotificationSnack";
import NotificationCard from "./NotificationCard";
import NotificationSkeleton from "./NotificationSkeleton";
import {reloadNotificationsPending} from "../../../actions/notificationActions";
import ProfileSectionComponent from "../../common/ProfileSectionComponent";
import {Typography} from "@material-ui/core";
import {get} from "../../common/i18n/i18n";
import {makeStyles} from "@material-ui/styles";
import layoutStyles from "../../common/styles/layoutStyles";

const useStyles = makeStyles(theme => ({
    ...layoutStyles(theme),
    notifications: {
        "& >:last-child" : {
            borderBottom: `2px solid ${SAND}`
        }
    }
}));

function UserNotificationsComponent() {

    const [notifications, setNotificacions] = useState([]);
    const [waiting, setWaiting] = useState(false);
    const dispatch = useDispatch();
    const user = useSelector(state => state.session.authUser)
    const classes = useStyles();

    useEffect(() => {
        if (!user) {
            return;
        }
        dispatch(reloadNotificationsPending(user.uid));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user]);

    useEffect(() => {
        setWaiting(true);
        doGet(buildUrl(API_NOTIFICATION_LAST, {uid: user.uid}))
            .then(onNotificationRetrieved)
            .catch(onError);
    }, [user.uid]);

    const onNotificationRetrieved = response => {
        setNotificacions(response);
        setWaiting(false);
    };

    const onError = error => {
        setWaiting(false);
        showMessage(error, SEVERITY_ERROR);
    };

    if (notifications.length > 0) {
        return (
            <ProfileSectionComponent loading={waiting}>
                <div className={classes.notifications}>
                {
                    notifications.map((notification, index) =>
                        <NotificationCard key={index} notification={notification}/>
                    )
                }
                </div>
            </ProfileSectionComponent>
        )
    }
    return waiting ?
        (
            <ProfileSectionComponent loading={waiting}>
                <div className={classes.notifications}>
                    {[0, 1, 2, 3, 4].map(notification => <NotificationSkeleton key={notification}/>)}
                </div>
            </ProfileSectionComponent>
        )
        :
        (
            <ProfileSectionComponent waiting={waiting}>
                <Typography variant={"h6"}>{get('user_no_pending_messages')}</Typography>
            </ProfileSectionComponent>
        )
}

const authCondition = profile => !!profile;

export default withAuthorization(authCondition)(UserNotificationsComponent)