import React, {Component} from 'react'

import {compose} from 'recompose'
import {connect} from 'react-redux'
import {withRouter} from "react-router-dom";
import qs from "qs";
import {withStyles} from '@material-ui/core/styles';
import withWidth from '@material-ui/core/withWidth'
import PropTypes from 'prop-types';

import {Badge, Grid, Hidden, IconButton, Input, Typography} from "@material-ui/core";

import FavoriteIcon from '@material-ui/icons/FavoriteTwoTone'
import ViewsIcon from '@material-ui/icons/Visibility'

import clsx from "clsx";
import Carousel from "nuka-carousel";

import {buildUrl, doDelete, doGet, doPost} from "../../../utils/http";
import {
    MARINE,
    RED,
    API_ITEM_DETAIL_UID,
    LOAN_ADD_LOAN,
    LOAN_CHECK_AVAILABILITY,
    SEVERITY_SUCCESS,
    SEVERITY_ERROR,
    DATE_TIME_FORMAT,
    API_ITEM_UNFAVORITE,
    API_ITEM_FAVORITE,
    API_ITEM_DELETE,
    API_ITEM_REPORT,
    DEFAULT_MAX_WIDTH,
    API_LOAN_CAN_MAKE_OPERATION,
    LOAN_OPERATION_BORROW,
    API_ITEM_PUBLIC_DETAIL,
    ANALYTICS_VIEW_ITEM,
    ANALYTICS_DELETE_ITEM,
    ANALYTICS_REQUEST_LOAN
} from "../../../utils/constants";
import {showMessage} from "../../common/NotificationSnack";

import moment from 'moment-timezone'
import {LANDING, ROUTE_ITEM_EDIT} from "../../../utils/routes";
import {get} from "../../common/i18n/i18n";
import ComponentWrapper from "../../common/ComponentWrapper";
import layoutStyles from "../../common/styles/layoutStyles";
import CategoryBreadcrumb from "../../common/CategoryBreadcrumb";
import buttonStyles from "../../common/styles/buttonStyles";
import WalrusBottomComponent from "../../landing/WalrusBottomComponent";
import RelatedItemListComponent from "../RelatedItemListComponent";
import {getUid} from "../../../utils/utils";
import {getOriginCoords} from "../../../utils/localStorage";
import ItemTitleComponent from "../ItemTitleComponent";
import CanMakeOpDialog from "../CanMakeOpDialog";
import ItemMetaInfo from "../ItemMetaInfo";
import ImComponent from "./IMComponent";
import {analytics, isSignedId, logEvent} from "../../../utils/firebase";
import LetsRegisterDialog, {openLetsRegisterDialog} from "../../common/LetsRegisterDialog";
import ContextualItemMenuWrapper from "./ContextualItemMenuWrapper";
import CheckAvailabilityComponentWrapper from "./CheckAvailabilityComponentWrapper";

const INITIAL_STATE = {
    item: undefined,
    startDate: undefined,
    endDate: undefined,
    response: undefined,
    notAvailable: false,
    canMakeOpOpen: false,
    canMakeOpProblems: [],
    error: '',
    loading: false,
};

const styles = theme => ({
    root: {
        width: '100%',
        maxWidth: DEFAULT_MAX_WIDTH,
        margin: theme.spacing(0, 2),
    },
    item: {
        marginTop: theme.spacing(),
        margin: '0 auto',
    },
    owner: {
        color: MARINE,
        display: 'block',
        marginTop: theme.spacing(2),
    },
    description: {
        marginTop: theme.spacing(),
        padding: theme.spacing(2),
        textAlign: 'left',
        background: 'white',
        whiteSpace: 'pre-line',
    },
    caption: {
        marginLeft: theme.spacing(1),
    },
    favoriteButton: {
        padding: 0,
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(1),
    },
    favorite: {
        color: RED,
    },
    green: {
        //border: "1px solid green",
    },
    ...layoutStyles(theme),
    ...buttonStyles(theme),
});

const DescriptionInput = withStyles(theme => ({
    root: {
        ...theme.typography.body1
    },
}))(Input);

class ItemComponent extends Component {

    constructor(props) {
        super(props);
        const now = moment().startOf('minute');
        const remainder = 30 - (now.minute() % 30);
        this.state = {
            ...INITIAL_STATE,
            // we add one hour to the start time by default, and adjust to the next half of an hour.
            startDate: now.clone().add(60 + remainder, 'minute'),
            // The default end date comes two days after the default start date.
            endDate: now.clone().add(60 + remainder, 'minute').add(2, 'day')
        };
    }

    componentDidMount() {
        const id = this.getId(this.props);
        const uid = getUid();
        this.requestItem(id, uid);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const currentItemId = this.getId(this.props);
        const prevItemId = this.getId(prevProps);
        if(currentItemId !== prevItemId) {
            this.setState({...INITIAL_STATE});
            this.requestItem(currentItemId, getUid());
        }
    }

    requestItem = (id, uid) => {
        this.setState({loading: true});
        const origin = getOriginCoords();
        let coords = '';
        if (!!origin.lat && !!origin.lng) {
            coords = `latitude=${origin.lat}&longitude=${origin.lng}`;
        }
        if (!!uid && isSignedId()) {
            doGet(buildUrl(API_ITEM_DETAIL_UID, {id, uid}) + "&" +coords)
                .then(this.updateItem)
                .catch(this.onError);
        } else {
            doGet(buildUrl(API_ITEM_PUBLIC_DETAIL, {id}) + "?" + coords)
                .then(this.updateItem)
                .catch(this.onError);
        }
    };

    updateItem = item => {
        this.setState({loading: false, item});
        logEvent(analytics, ANALYTICS_VIEW_ITEM, {
            owner_id: item.ownerId,
            owner_nickname: item.ownerNickname,
            item_id: item.id,
            item_title: item.title,
            item_location: item.location,
            item_zipcode: item.zipcode
        });
    };

    onError = error => {
        this.setState({loading: false});
        showMessage(error, SEVERITY_ERROR);
    };

    checkDates = ({startDate, endDate}) => {
        // Start date
        if (moment().add(1, 'hour').isAfter(startDate)) {
            return {valid: false, error: 'loan_error_start_in_past'};
        }
        if (startDate.isAfter(endDate)) {
            return {valid: false, error: 'loan_error_start_after_end'};
        }
        if (startDate.clone().add(1, 'hour').isAfter(endDate)) {
            return {valid: false, error: 'loan_error_too_short'};
        }
        // No
        return {valid: true, error: ''};
    }

    checkAvailability = () => {
        const {startDate, endDate, item} = this.state;
        const {valid, error} = this.checkDates(this.state);
        this.setState({error});
        if (!valid) {
            showMessage(get(error), SEVERITY_ERROR);
            return;
        }
        const {authUser: user} = this.props;
        this.setState({loading: true});
        const itemPriceRequestDTO = {
            itemId: item.id,
            from: moment(startDate).clone().tz('Etc/UTC').format(DATE_TIME_FORMAT),
            to: moment(endDate).clone().tz('Etc/UTC').format(DATE_TIME_FORMAT),
            insurance: item.insuranceMandatory,
            uid: !!user ? user.uid : null,
        };
        doPost(
            LOAN_CHECK_AVAILABILITY, JSON.stringify(itemPriceRequestDTO))
            .then(this.onCheckAvailabilityResponse)
            .catch(this.onError);
    }

    onCheckAvailabilityResponse = response => {
        this.setState({loading: false, response: response, notAvailable: !response});
    }

    requestLoan = () => {
        const {response, item} = this.state;
        const {uid: borrowerId} = this.props.authUser;
        const {valid, error} =
            this.checkDates({
                startDate: moment.utc(response.from, DATE_TIME_FORMAT).local(),
                endDate: moment.utc(response.to, DATE_TIME_FORMAT).local()
            });
        if (!valid) {
            showMessage(get(error), SEVERITY_ERROR);
            this.setState({error});
            return;
        }
        this.setState({loading: true});
        const addLoanDTO = {
            borrowerId,
            endDate: response.to,
            initDate: response.from,
            insurance: true,
            itemId: response.itemId,
            ownerId: item.ownerId,
        };

        doPost(LOAN_ADD_LOAN, JSON.stringify(addLoanDTO))
            .then(this.handleLoanRequestSuccess)
            .catch(error => this.handleLoanRequestError(error, borrowerId));
    };

    handleLoanRequestSuccess = response => {
        logEvent(analytics, ANALYTICS_REQUEST_LOAN, {
            loan_id: response.loanId,
            owner_id: response.ownerId,
            owner_nickname: response.ownerNickname,
            borrower_id: response.borrowerId,
            borrower_nickname: response.borrowerNickname,
            item_id: response.itemId,
            item_title: response.itemTitle,
            start_date: response.loanInitDate,
            end_date: response.loanEndDate
        });
        this.setState({loading: false});
        showMessage(get('loan_request_success'), SEVERITY_SUCCESS);
        // Todo redirect to loan detail page.
        this.props.history.push(LANDING);
    }
    handleLoanRequestError = (error, borrowerId) => {
        this.setState({loading: false});
        doGet(buildUrl(API_LOAN_CAN_MAKE_OPERATION, {operation: LOAN_OPERATION_BORROW, uid: borrowerId}))
            .then(this.handleOpenCanMakeOp)
            .catch(this.onError);
        showMessage(error, SEVERITY_ERROR);
    }

    handleOpenCanMakeOp = problems => {
        this.setState({canMakeOpProblems: problems, canMakeOpOpen: problems !== []});
    }
    handleUpdateDate = (key, value) => {
        this.setState({[key]: value});
    }

    reset = () =>
        this.setState({response: undefined, notAvailable: false});

    handleFavoriteWrapper = (item, uid) => {
        if (!!item && !!uid) {
            this.handleFavorite(item, uid);
        } else {
            openLetsRegisterDialog();
        }
    }

    handleFavorite = (item, uid) => {
        const url = buildUrl(item.favorite ? API_ITEM_UNFAVORITE : API_ITEM_FAVORITE, {uid, itemId: item.id});
        const inc = item.favorite ? -1 : 1;
        this.setState({loading: true});
        if (item.favorite) {
            doDelete(url)
                .then(response => this.onFavoriteUpdated(item.favorite, inc))
                .catch(this.onError);
        } else {
            doPost(url)
                .then(response => this.onFavoriteUpdated(item.favorite, inc))
                .catch(this.onError);
        }
    };

    onFavoriteUpdated = (favorite, inc) => {
        this.setState(prevState => {
            const prevItem = {...prevState.item, favorite: !favorite, numFavorites: prevState.item.numFavorites + inc};
            prevItem.favorite = !favorite;
            return {
                item: prevItem,
                loading: false,
            }
        });
    };

    onDeleteClicked = (uid, item) => {
        this.setState({loading: true});
        doDelete(
            buildUrl(API_ITEM_DELETE, {uid, itemId: item.id}), '')
            .then(() => this.onItemDeleted(item))
            .catch(this.onError);
    }

    onItemDeleted = item => {
        this.setState({loading: false});
        logEvent(analytics, ANALYTICS_DELETE_ITEM, {
            owner_id: item.ownerId,
            owner_nickname: item.ownerNickname,
            item_id: item.id,
            item_title: item.title,
            item_location: item.location,
            item_zipcode: item.zipcode
        });
        showMessage(get('delete_success', [item.title]), SEVERITY_SUCCESS);
        this.props.history.goBack();
    };

    onEditClicked = (uid, item) => {
        this.props.history.push(buildUrl(ROUTE_ITEM_EDIT, {uid, itemId: item.id}))
    };

    onReportClicked = (uid, item, reason) => {
        this.setState({loading: true});
        const reportItemDTO = {
            informantId: uid,
            informantNickname: this.props.profile.nickname,
            itemId: item.id,
            latitude: 0,
            longitude: 0,
            motive: reason,
        };
        doPost(buildUrl(API_ITEM_REPORT, {itemId: item.id}), JSON.stringify(reportItemDTO))
            .then(() => this.onItemReported(item))
            .catch(this.onError);
    };

    onItemReported = item => {
        this.setState({loading: false});
        showMessage(get('report_success'), SEVERITY_SUCCESS);
    };

    handleCanMakeOpClose = () => {
        this.setState({canMakeOpOpen: false});
    }

    getId = props => {
        let id = qs.parse(props.location.search,
            {depth: 1, parameterLimit: 1, ignoreQueryPrefix: true}).i;
        if (!id) {
            id = props.match.params.id;
        }
        return id;
    }

    render() {
        const {item, response, loading, startDate, endDate, error, notAvailable, canMakeOpProblems, canMakeOpOpen} = this.state;
        const {classes} = this.props;
        const uid = getUid();

        if (!item) {
            return (
                <ComponentWrapper className={classes.root} title={get('item_detail')} loading={loading}>
                    {loading ?
                        <Typography variant={'h6'}>{get('loading_item')}</Typography>
                        :
                        <Typography variant={'h6'}>{get('item_not_found')}</Typography>
                    }
                </ComponentWrapper>
            );
        }

        const gallery = !!item && item.itemPhotos.length > 0 &&
            item.itemPhotos.map(photo => <img key={photo.id} alt={item.title} src={photo.photo}/>);

        return (
            <>
                <ItemMetaInfo item={item}/>
                <Grid className={classes.root}>
                    <ComponentWrapper className={classes.item} loading={loading}>
                        <Grid container>
                            <Grid item xs={12} md={6} className={classes.green}>
                                <Hidden mdUp>
                                    <ItemTitleComponent item={item}/>
                                </Hidden>
                                <div className={clsx(classes.spaceBetweenRow, classes.marginVertOne, classes.maxWidth)}>
                                    <CategoryBreadcrumb category={{
                                        categoryCode: item.categoryCode,
                                        parentCategoryCode: item.parentCategoryCode
                                    }}/>
                                    <div className={classes.defaultRow}>
                                        <Badge badgeContent={item.visits}>
                                            <ViewsIcon/>
                                        </Badge>
                                        <IconButton
                                            className={clsx(classes.favoriteButton, item.favorite && classes.favorite)}
                                            onClick={event => this.handleFavoriteWrapper(item, uid)}>
                                            <Badge badgeContent={item.numFavorites}>
                                                <FavoriteIcon/>
                                            </Badge>
                                        </IconButton>
                                        <ContextualItemMenuWrapper uid={uid} item={item}
                                                                   handleDelete={this.onDeleteClicked}
                                                                   handleEdit={this.onEditClicked}
                                                                   handleReport={this.onReportClicked}
                                                                   anonymousAction={openLetsRegisterDialog}
                                        />
                                    </div>
                                </div>
                                <Carousel enableKeyboardControls={true} wrapAround={false}>
                                    {gallery}
                                </Carousel>
                                <Typography variant="h2" align={"left"} style={{color: MARINE}}>
                                    {get("description")}
                                </Typography>
                                <DescriptionInput value={item.description} multiline={true} fullWidth={true}
                                                  readOnly={true}
                                                  disableUnderline={true}/>
                            </Grid>
                            <Grid item xs={12} md={6} className={classes.green}>
                                <Grid container>
                                    <Grid item xs={false} md={1}/>
                                    <Grid item xs={12} md={10}>
                                        <Hidden smDown>
                                            <ItemTitleComponent item={item}/>
                                        </Hidden>
                                        <CheckAvailabilityComponentWrapper
                                            uid={uid} item={item} response={response}
                                            startDate={startDate} endDate={endDate}
                                            notAvailable={notAvailable}
                                            checkAvailability={this.checkAvailability}
                                            handleUpdateDate={this.handleUpdateDate}
                                            error={error}
                                            reset={this.reset}
                                            requestLoan={this.requestLoan}
                                            anonymousAction={openLetsRegisterDialog}
                                        />

                                    </Grid>
                                    <Grid item xs={false} md={1}/>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid container>
                            <ImComponent uid={uid} item={item}/>
                            <Grid item xs={false} md={6} className={classes.green}/>
                        </Grid>
                        <Grid container className={classes.green}>
                            <RelatedItemListComponent itemId={item.id}/>
                        </Grid>
                        <CanMakeOpDialog problems={canMakeOpProblems} open={canMakeOpOpen}
                                         handleClose={this.handleCanMakeOpClose}/>
                    </ComponentWrapper>
                </Grid>
                <WalrusBottomComponent/>
                <LetsRegisterDialog/>
            </>
        )
    }
}

ItemComponent.propTypes = {
    classes: PropTypes.object,
    authUser: PropTypes.object,
    profile: PropTypes.object,
};

const mapStateToProps = ({session}) => ({
    authUser: session.authUser,
    profile: session.profile,
});

export default compose(
    withStyles(styles),
    withWidth(),
    withRouter,
    connect(mapStateToProps),
)(ItemComponent)
