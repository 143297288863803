import React, {Component} from 'react'
import {PropTypes} from 'prop-types'
import {compose} from 'recompose'
import withAuthorization from '../auth/withAuthorization'
import {withStyles} from '@material-ui/core/styles'
import {connect} from 'react-redux'
import {withRouter} from 'react-router-dom'

import {Button, Typography} from "@material-ui/core";

import {
    ACEPTADO,
    LOAN_ACCEPT_LOAN,
    LOAN_CANCEL_LOAN,
    LOAN_DENY_LOAN,
    LOAN_DETAILS,
    BLUE,
    PENDIENTE_RESPUESTA,
    DEFAULT_WIDTH,
    ACTIVO_OWNER,
    ACTIVO_BORROWER,
    PALE_SAND,
    DEVUELTO,
    SEVERITY_ERROR,
    FINALIZADO,
    MARINE,
    DATE_TIME_FORMAT,
    API_ISSUE_CREATE,
    SEVERITY_SUCCESS,
    ANALYTICS_ACCEPT_LOAN, ANALYTICS_DENY_LOAN,
} from "../../utils/constants";
import {buildUrl, doPut, doRequest} from "../../utils/http";
import {showMessage} from "../common/NotificationSnack";
import LoanLifeCycleComponent from "./LoanLifeCycleComponent";
import {get} from "../common/i18n/i18n";
import LoanMiniCard from "./LoanMiniCard";
import UserLoanIMComponent from "./UserLoanIMComponent";
import LoanCountdownComponent from "./LoanCountdownComponent";
import buttonStyles from "../common/styles/buttonStyles";
import {isTodayOrAfter} from "../../utils/utils";
import LoanActionWrapper from "./LoanActionWrapper";
import clsx from "clsx";
import GoBackHeader from "../common/GoBackHeader";
import ComponentWrapper from "../common/ComponentWrapper";
import {analytics, logEvent} from "../../utils/firebase";

// Constants used to select view.
const DETAIL = 'DETAIL';
const ACTION = 'ACTION';

const INITIAL_STATUS = {
    loan: undefined,
    isOwner: false,
    loanState: undefined,
    currentView: DETAIL,
    loading: false,
};

const styles = theme => ({
    root: {
        width: '100%',
        maxWidth: DEFAULT_WIDTH,
    },
    state: {
        color: 'white',
        fontWeight: theme.typography.fontWeightBold,
        borderRadius: '4px',
        width: 146,
        alignSelf: 'center',
    },
    button: {
        backgroundColor: PALE_SAND,
        fontWeight: theme.typography.fontWeightBold,
        color: BLUE,
        margin: theme.spacing(),
    },
    blueText: {
        color: BLUE,
    },
    marginTop: {
        marginTop: theme.spacing(),
    },
    buttonArea: {
        minHeight: '5em',
        backgroundColor: PALE_SAND,
        padding: theme.spacing(2, 1),
    },
    buttonHelper: {
        color: BLUE,
    },
    finished: {
        color: MARINE,
    },
    title: {
        marginTop: theme.spacing(),
        color: BLUE,
    },
    ...buttonStyles(theme),
});

class LoanDetailComponent extends Component {

    constructor(props) {
        super(props);
        this.state = {...INITIAL_STATUS};
    }

    componentDidMount() {
        this.retrieveLoan();
    }

    getLoanId = () => this.props.match.params.loanId;

    retrieveLoan = () => {
        const loanId = this.getLoanId();
        const uid = this.props.authUser.uid;
        this.setState({loading: true});
        doRequest(
            buildUrl(LOAN_DETAILS, {uid, loanId}),
            this.onLoanRetrieved,
            this.onError,
            {method: 'GET'}
        );
    };

    onError = error => {
        this.setState({loading: false});
        showMessage(error, SEVERITY_ERROR);
    }

    onLoanRetrieved = loan => {
        const {uid} = this.props.authUser;
        const isOwner = uid === loan.ownerId;
        this.setState({loan, isOwner, loading: false});
    };

    handleClick = option => {
        const {isOwner, loan} = this.state;
        const state = loan.loanStatus.key;
        if (state === ACEPTADO || state === ACTIVO_OWNER || state === ACTIVO_BORROWER || state === DEVUELTO) {
            this.setState({view: ACTION});
            return;
        } else {
            this.setState({view: DETAIL});
        }

        if (state === PENDIENTE_RESPUESTA) {
            this.setState({loading: true});
            if (isOwner) {
                if (option === 'ok') {
                    doPut(buildUrl(LOAN_ACCEPT_LOAN, {uid: loan.ownerId, loanId: loan.loanId}), '')
                        .then(response => this.onLoanAccepted(response, loan))
                        .catch(this.onError);
                } else {
                    doPut(buildUrl(LOAN_DENY_LOAN, {uid: loan.ownerId, loanId: loan.loanId}), '')
                        .then(response => this.onLoanDenied(response, loan))
                        .catch(this.onError);
                }
            } else {
                doPut(buildUrl(LOAN_CANCEL_LOAN, {uid: loan.borrowerId, loanId: loan.loanId}), '')
                    .then(this.onLoanCancelled)
                    .catch(this.onError);
            }
        }
    };

    onLoanAccepted = (response, loan) => {
        if (!response.accepted) {
            showMessage(get('loan_response_not_accepted'), SEVERITY_ERROR);
        } else {
            logEvent(analytics, ANALYTICS_ACCEPT_LOAN, {
                loan_id: loan.loanId,
                owner_id: loan.ownerId,
                owner_nickname: loan.ownerNickname,
                borrower_id: loan.borrowerId,
                borrower_nickname: loan.borrowerNickname,
                item_id: loan.itemId,
                item_title: loan.itemTitle,
                start_date: loan.loanInitDate,
                end_date: loan.loanEndDate
            });
            showMessage(get('loan_response_accepted'), SEVERITY_SUCCESS);
        }
        this.onLoanResponse(response);
    }

    onLoanCancelled = response => {
        if (!response.canceled) {
            showMessage(get('loan_response_not_cancelled'), SEVERITY_ERROR);
        } else {
            showMessage(get('loan_response_cancelled'), SEVERITY_SUCCESS);
        }
        this.onLoanResponse(response);
    }

    onLoanDenied = (response, loan) => {
        if (!response.denied) {
            showMessage(get('loan_response_not_denied'), SEVERITY_ERROR);
        } else {
            logEvent(analytics, ANALYTICS_DENY_LOAN, {
                loan_id: loan.loanId,
                owner_id: loan.ownerId,
                owner_nickname: loan.ownerNickname,
                borrower_id: loan.borrowerId,
                borrower_nickname: loan.borrowerNickname,
                item_id: loan.itemId,
                item_title: loan.itemTitle,
                start_date: loan.loanInitDate,
                end_date: loan.loanEndDate
            });
            showMessage(get('loan_response_denied'), SEVERITY_SUCCESS);
        }
        this.onLoanResponse(response);
    }

    onLoanResponse = response => {
        this.retrieveLoan();
    }

    createIssue = issue => {
        const {loan} = this.state;
        const loanIssue = {
            loanId: loan.loanId,
            senderId: this.props.authUser.uid,
            issueType: issue.issueType.issueTypeName,
            issueText: issue.issueText,
            issuePlace: null,
            issueWhen: null,
            issueDamageUnusable: null,
            loanIssuePhoto: null,
        };
        if (issue.issueType.issueTypeId >= 6 && issue.issueType.issueTypeId <= 8) {
            loanIssue.issuePlace = issue.insuranceIssue.where;
            loanIssue.issueWhen = issue.insuranceIssue.when.format(DATE_TIME_FORMAT);
            if (!!issue.insuranceIssue.image) {
                loanIssue.loanIssuePhoto = {
                    associatedPartName: issue.insuranceIssue.image.fileName,
                    uploaderId: this.props.authUser.uid,
                    photoOrder: 0,
                }

            }
        }
        if (issue.issueType.issueTypeId === 8) {
            loanIssue.issueDamageUnusable = issue.insuranceIssue.broken;
        }

        const formData = new FormData();
        const loanIssueDTO = new Blob([JSON.stringify(loanIssue)], {type: "application/json"});
        formData.append('loanIssueDTO', loanIssueDTO);

        if (!!issue.insuranceIssue.image) {
            formData.append('file', issue.insuranceIssue.image.bin, issue.insuranceIssue.image.fileName);
        }
        this.setState({loading: true});
        doRequest(API_ISSUE_CREATE, this.onIssueCreated, this.onError,{method: 'POST', body: formData});
    };

    onIssueCreated = response => {
        this.setState({loading: false});
        showMessage(get('issue_created', [response]), SEVERITY_SUCCESS);
    }

    getCountdownData = loan => {
        const state = loan.loanStatus.key;
        switch (state) {
            case PENDIENTE_RESPUESTA:
                return {dueDate: loan.requestDueDate, helperText: get('loan_detail_countdown_pendiente_respuesta')};
            case ACEPTADO:
            case ACTIVO_OWNER:
                return {dueDate: loan.loanInitDate, helperText: get('loan_detail_countdown_aceptado-activo_owner')};
            case ACTIVO_BORROWER:
                return {dueDate: loan.loanEndDate, helperText: get('loan_detail_countdown_activo_borrower')};
            default:
                return {dueDate: loan.loanAskDate, helperText: ''};
        }
    };

    getCountDown = loan => {
        const classes = this.props.classes;
        const state = loan.loanStatus.key;
        if (state === PENDIENTE_RESPUESTA || state === ACEPTADO || state === ACTIVO_OWNER || state === ACTIVO_BORROWER) {
            const data = this.getCountdownData(loan);
            return <LoanCountdownComponent className={classes.marginTop} dueDate={data.dueDate}
                                           helperText={data.helperText}/>
        }
        return <div></div>
    };

    render() {
        const {classes, authUser} = this.props;
        const {loan, isOwner, view, loading} = this.state;

        if (loan === undefined) {
            return (
                <ComponentWrapper loading={loading}>
                    <GoBackHeader title={get('loan_details')}/>
                    <Typography variant={"h4"} className={classes.title}>
                        {
                            loading ? get('loan_detail_loading') : get('loan_detail_not_exist')
                        }
                    </Typography>
                </ComponentWrapper>
            );

        }
        if (view === ACTION) {
            return <LoanActionWrapper loan={loan}/>;
        }
        return (
            <ComponentWrapper loading={loading}>
                <GoBackHeader title={get('loan_details')}/>
                <LoanMiniCard loan={loan} createIssue={this.createIssue}/>
                <LoanLifeCycleComponent className={classes.marginTop} loan={loan}/>
                <UserLoanIMComponent loan={loan} uid={authUser.uid}/>
                {this.getCountDown(loan)}
                {this.renderBottom(isOwner, loan)}
            </ComponentWrapper>
        )
    }

    renderBottom = (isOwner, loan) => {
        const state = loan.loanStatus.key;
        const classes = this.props.classes;
        if (loan.pendingIssue) {
            return this.renderIssuePending(loan);
        }
        switch (state) {
            case PENDIENTE_RESPUESTA:
                if (isOwner) {
                    return this.renderOwnerPendingButtons();
                }
                return this.renderBorrowerPendingButtons();
            case ACEPTADO:
                if (isTodayOrAfter(loan.loanInitDate) && isOwner) {
                    return this.renderOwnerAcceptedButtons();
                }
                return <div className={classes.buttonArea}/>;
            case ACTIVO_OWNER:
                if (!isOwner) {
                    return this.renderBorrowerAcceptedButtons();
                }
                return <div className={classes.buttonArea}/>;
            case ACTIVO_BORROWER:
                if (!isOwner) {
                    return this.renderBorrowerActiveButtons();
                }
                return <div className={classes.buttonArea}/>;
            case DEVUELTO:
                if (isOwner) {
                    return this.renderOwnerActiveButtons();
                }
                return this.renderBorrowerFinished();
            case FINALIZADO:
                if (isOwner) {
                    return this.renderOwnerFinished();
                }
                return this.renderBorrowerFinished();
            default:
                return <div className={classes.buttonArea}/>;
        }
    };

    renderIssuePending = loan => {
        const {classes} = this.props;
        let text = '';
        if (loan.issues.length === 0) {
            text =
                <React.Fragment>
                    <Typography variant={'h5'} className={clsx(classes.buttonHelper, classes.finished)}>
                        {get('loan_detail_issue_other_l1')}
                    </Typography>
                    <Typography variant={'h5'} className={clsx(classes.buttonHelper, classes.finished)}>
                        {get('loan_detail_issue_other_l2')}
                    </Typography>
                </React.Fragment>
        } else if (loan.issues.length === 1) {
            text =
                <React.Fragment>
                    <Typography variant={'h5'} className={clsx(classes.buttonHelper, classes.finished)}>
                        {get('loan_detail_issue_own_l1')}
                    </Typography>
                    <Typography variant={'h5'} className={clsx(classes.buttonHelper, classes.finished)}>
                        {get('loan_detail_issue_own_l2', [loan.issues[0].issueCode])}
                    </Typography>
                    <Typography variant={'h5'} className={clsx(classes.buttonHelper, classes.finished)}>
                        {get('loan_detail_issue_own_l3')}
                    </Typography>
                </React.Fragment>
        } else {
            text =
                <React.Fragment>
                    <Typography variant={'h5'} className={clsx(classes.buttonHelper, classes.finished)}>
                        {get('loan_detail_issue_own_l1s')}
                    </Typography>
                    <Typography variant={'h5'} className={clsx(classes.buttonHelper, classes.finished)}>
                        {get('loan_detail_issue_own_l3')}
                    </Typography>
                </React.Fragment>
        }

        return (
            <div className={classes.buttonArea}>
                {text}
                <Typography variant={'h2'} className={clsx(classes.buttonHelper, classes.finished)}>
                    {get('loan_detail_thanks')}
                </Typography>
                <Typography variant={'h1'} className={clsx(classes.buttonHelper, classes.finished)}>
                    myur!
                </Typography>
            </div>
        );
    };

    renderOwnerPendingButtons = () => {
        const {classes} = this.props;
        return (
            <div className={classes.buttonArea}>
                <Typography variant={'body2'} className={classes.buttonHelper}>
                    {get('loan_detail_owner_pending_l1')}
                </Typography>
                <Typography variant={'body2'} className={classes.buttonHelper}>
                    {get('loan_detail_owner_pending_l2')}
                </Typography>
                <Button variant="contained" className={classes.blueButton} onClick={() => this.handleClick('ko')}>
                    {get('loan_detail_owner_pending_b1')}
                </Button>
                <Button variant="contained" className={classes.blueButton} onClick={() => this.handleClick('ok')}>
                    {get('loan_detail_owner_pending_b2')}
                </Button>
            </div>
        )
    };

    renderBorrowerPendingButtons = () => {
        const {classes} = this.props;
        return (
            <div className={classes.buttonArea}>
                <Typography variant={'body2'} className={classes.buttonHelper}>
                    {get('loan_detail_borrower_pending_l1')}
                </Typography>
                <Typography variant={'body2'} className={classes.buttonHelper}>
                    {get('loan_detail_borrower_pending_l2')}
                </Typography>
                <Button variant="contained" className={classes.blueButton} onClick={() => this.handleClick('ko')}>
                    {get('loan_detail_borrower_pending_b1')}
                </Button>
            </div>
        )
    };

    renderOwnerAcceptedButtons = () => {
        const {classes} = this.props;
        return (
            <div className={classes.buttonArea}>
                <Typography variant={'h6'} className={classes.buttonHelper}>
                    {get('loan_detail_owner_accepted_l1')}
                </Typography>
                <Button variant="contained" className={classes.blueButton} onClick={() => this.handleClick('ok')}>
                    {get('loan_detail_owner_accepted_b1')}
                </Button>
            </div>
        )
    };

    renderBorrowerAcceptedButtons = () => {
        const {classes} = this.props;
        return (
            <div className={classes.buttonArea}>
                <Typography variant={'h6'} className={classes.buttonHelper}>
                    {get('loan_detail_borrower_accepted_l1')}
                </Typography>
                <Button variant="contained" className={classes.blueButton} onClick={() => this.handleClick('ok')}>
                    {get('loan_detail_borrower_accepted_b1')}
                </Button>
            </div>
        )
    };

    renderOwnerActiveButtons = () => {
        const {classes} = this.props;
        return (
            <div className={classes.buttonArea}>
                <Typography variant={'h6'} className={classes.buttonHelper}>
                    {get('loan_detail_owner_active_l1')}
                </Typography>
                <Button variant="contained" className={classes.blueButton} onClick={() => this.handleClick('ok')}>
                    {get('loan_detail_owner_active_b1')}
                </Button>
            </div>
        )
    };

    renderBorrowerActiveButtons = () => {
        const {classes} = this.props;
        return (
            <div className={classes.buttonArea}>
                <Typography variant={'h6'} className={classes.buttonHelper}>
                    {get('loan_detail_borrower_active_l1')}
                </Typography>
                <Button variant="contained" className={classes.blueButton} onClick={() => this.handleClick()}>
                    {get('loan_detail_borrower_active_b1')}
                </Button>
            </div>
        )
    };

    renderBorrowerFinished = () => {
        const {classes} = this.props;
        return (
            <div className={classes.buttonArea}>
                <Typography variant={'body2'} className={clsx(classes.buttonHelper, classes.finished)}>
                    {get('loan_detail_borrower_finished_l1')}
                </Typography>
                <Typography variant={'h2'} className={clsx(classes.buttonHelper, classes.finished)}>
                    {get('loan_detail_thanks')}
                </Typography>
                <Typography variant={'h1'}
                            className={clsx(classes.buttonHelper, classes.finished)}>
                    myur!
                </Typography>
            </div>
        )
    };

    renderOwnerFinished = () => {
        const {classes} = this.props;
        return (
            <div className={classes.buttonArea}>
                <Typography variant={'body2'} className={clsx(classes.buttonHelper, classes.finished)}>
                    {get('loan_detail_owner_finished_l1')}
                </Typography>
                <Typography variant={'h2'} className={clsx(classes.buttonHelper, classes.finished)}>
                    {get('loan_detail_thanks')}
                </Typography>
                <Typography variant={'h1'}
                            className={clsx(classes.buttonHelper, classes.finished)}>
                    myur!
                </Typography>
            </div>
        )
    };
}


LoanDetailComponent.propTypes = {
    classes: PropTypes.object.isRequired,
};

const mapStateToProps = ({session}) => ({
    authUser: session.authUser,
});

const authCondition = profile => !!profile;

export default compose(
    withStyles(styles),
    withAuthorization(authCondition),
    withRouter,
    connect(mapStateToProps),
)(LoanDetailComponent);
