import React, {useState} from 'react'
import {PropTypes} from "prop-types";

import {Typography, makeStyles, withStyles, Accordion, AccordionSummary, AccordionDetails} from '@material-ui/core'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import {PALE_SAND, BLUE} from "../../utils/constants";
import LoanListItem from './LoanListItem'
import withAuthorization from "../auth/withAuthorization";
import {get} from "../common/i18n/i18n";

const loanMode = [
    {
        loanList: 'propioLoans',
        title: 'en otras manos',
    },
    {
        loanList: 'impropioLoans',
        title: 'en mis manos',
    }
];

const MyurAccordion = withStyles({
    root: {
        border: 0,
        boxShadow: 'none',
        '&:not(:last-child)': {
            borderBottom: 0,
        },
        '&:before': {
            display: 'none',
        },
        '&$expanded': {
            margin: 'auto',
        },
    },
    expanded: {

    },
})(Accordion);

const MyurAccordionSummary = withStyles(theme => ({
    root: {
        backgroundColor: PALE_SAND,
        marginBottom: theme.spacing(),
        minHeight: 56,
        '&$expanded': {
            minHeight: 56,
        },
        color: BLUE,
        fontWeight: theme.typography.fontWeightBold,
    },
    content: {
        '&$expanded': {
            margin: '12px 0',
        },
    },
    expanded: {
        marginBottom: theme.spacing()
    },
}))(AccordionSummary);

const MyurAccordionDetails = withStyles(theme => ({
    root: {
        padding: 0,
    },
}))(AccordionDetails);

const useStyles = makeStyles(theme => ({
    pastLoanList: {
        display: 'flex',
        flexFlow: 'nowrap column',
    },
    pastLoanTitle: {
        color: BLUE,
        fontWeight: theme.typography.fontWeightBold,
    },
    pastLoanIcon: {
        color: BLUE,
        fontWeight: theme.typography.fontWeightBold,
        fontSize: 32,
    },
}));

function LoanListComponent({mode, currentLoans, pastLoans, hidden, handleRequestPastLoans}) {

    const classes = useStyles();

    const [expanded, setExpanded] = useState(false);

    const handleChange = (event, isExpanded) => {
        setExpanded(isExpanded);
        if (isExpanded) {
            handleRequestPastLoans();
        }
    };
    return (
        <div hidden={hidden}>
            <Typography component="div" variant={'h6'} style={{ padding: 8, color: BLUE }}>
                {loanMode[mode].title}
            </Typography>
            {currentLoans.map(loan => <LoanListItem key={loan.loanId} loan={loan}/> )}
            <MyurAccordion square expanded={expanded} onChange={handleChange}>
                <MyurAccordionSummary
                    expandIcon={<ExpandMoreIcon className={classes.pastLoanIcon}/>}
                    aria-controls="pastLoansPanelContent"
                    id="pastLoansPanelHeader"
                >
                    <Typography className={classes.pastLoanTitle}>{get('loan_history')}</Typography>
                </MyurAccordionSummary>
                <MyurAccordionDetails className={classes.pastLoanList}>
                    {pastLoans.map(loan => <LoanListItem key={loan.loanId} loan={loan}/> )}
                </MyurAccordionDetails>
            </MyurAccordion>
        </div>
    )
}

LoanListComponent.propTypes= {
    mode: PropTypes.number.isRequired,
    currentLoans: PropTypes.array.isRequired,
    pastLoans: PropTypes.array.isRequired,
    handleRequestPastLoans: PropTypes.func,
    hidden: PropTypes.bool,
};

const authCondition = profile => !!profile;

export default withAuthorization(authCondition)(LoanListComponent);