import {BLUE, MARINE, PALE_SAND, SAND} from "../../../utils/constants";

const buttonStyles = theme => ({
    defaultButton: {
        color: MARINE,
        backgroundColor: SAND,
        borderRadius: '2rem',
        margin: theme.spacing(1),
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        fontWeight: theme.typography.fontWeightBold,
        fontSize: '0.6875rem',
        '&:hover': {
            backgroundColor: PALE_SAND,
            borderColor: SAND,
            boxShadow: 'none',
        },
    },
    blueButton: {
        color: theme.palette.common.white,
        backgroundColor: BLUE,
        borderRadius: '2rem',
        margin: theme.spacing(1),
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        fontWeight: theme.typography.fontWeightBold,
        fontSize: '0.6875rem',
        '&:hover': {
            backgroundColor: MARINE,
            borderColor: BLUE,
            boxShadow: 'none',
        },
    }
});

export default buttonStyles;
