import React from 'react';
import PropTypes from 'prop-types';
import {generate} from 'shortid';

import {FormControl, FormHelperText, InputBase, InputLabel, withStyles} from "@material-ui/core";
import makeStyles from "@material-ui/core/styles/makeStyles";

import {BLUE, MARINE, RED} from "../../utils/constants";
import clsx from "clsx";

const MyurCustomInput = withStyles(theme => ({
    root: {
        'label + &': {
            marginTop: theme.spacing(3),
        },
    },
    input: {
        borderRadius: 20,
        position: 'relative',
        backgroundColor: theme.palette.common.white,
        border: `1px solid ${BLUE}`,
        color: MARINE,
        padding: '10px 12px',
    },
}))(InputBase);

const useStyles = makeStyles(theme => ({
    margin: {
        marginTop: theme.spacing(2),
    },
    error: {
        color: RED,
    },
}));

export default function MyurInput(props) {
    const {label, placeholder, defaultValue, value, error, onChange, className, classes, helperText,
        FormHelperTextProps, ...otherProps} = props;
    const _classes = useStyles();
    const nodeId = 'myur-input-' + generate();
    const labelClass = clsx(!!classes && classes.label);
    const focusClass = clsx(!!classes && classes.focused);
    return (
        <FormControl className={clsx(className, _classes.margin)} error={error}>
            {!!label &&
                <InputLabel className={labelClass} classes={{root: focusClass}} shrink htmlFor={nodeId}>
                    {label}
                </InputLabel>
            }
            <MyurCustomInput
                placeholder={placeholder}
                defaultValue={defaultValue}
                value={value}
                id={nodeId}
                onChange={onChange}
                {...otherProps}
            />
            <FormHelperText
                className={clsx(!!classes && classes.helperText)}
                {...FormHelperTextProps} >
                {helperText}
            </FormHelperText>
        </FormControl>
    );
}

MyurInput.propTypes = {
    label: PropTypes.string,
    placeholder: PropTypes.string,
    defaultValue: PropTypes.string,
    value: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    classes: PropTypes.object,
    className: PropTypes.string,
    FormHelperTextProps: PropTypes.object,
    error: PropTypes.bool,
};