import React, {useEffect, useState} from "react";
import {PropTypes} from "prop-types";

import GoogleMapReact from "google-map-react";

import {makeStyles} from "@material-ui/core/styles";
import {Typography} from "@material-ui/core";

import {get} from "../../common/i18n/i18n";
import ItemCard from "../../item/ItemCard";
import myur from "../../../images/UserPhoto1.png";
import {MAPS_API_PUBLIC_KEY, MAPS_DEFAULT_CENTER, MAPS_DEFAULT_ZOOM, MAPS_MARKER_DISPERSION, PALE_BLUE, RED}
    from "../../../utils/constants";

const useMapsItemCardStyles = makeStyles(theme => ({
    itemCard: {
        position: 'relative',
        bottom: 150,
        left: '-45px',
        width: 220,
        backgroundColor: 'white',
        boxShadow: '0 2px 7px 1px rgba(0, 0, 0, 0.3)',
        padding: 10,
        fontSize: 14,
        zIndex: 100,
    },
    close: {
        display: "block",
        cursor: "pointer",
        width: "100%",
        color: RED,
    }
}));
const MapsItemCard = ({item, handleClose}) => {
    const classes = useMapsItemCardStyles();
    return (
        <div className={classes.itemCard}>
            <Typography variant={'caption'} onClick={handleClose} className={classes.close} align={"right"}>
                [{get('close')}]
            </Typography>
            <ItemCard item={item}/>
        </div>
    );
};

MapsItemCard.propTypes = {
    item: PropTypes.object.isRequired,
    handleClose: PropTypes.func.isRequired,
};

const useMyurMarkerStyles = makeStyles({
    marker: {
        display: 'block',
        position: 'absolute',
        top: '40%',
        left: '50%',
        borderRadius: '50%',
        border: '4px solid #8edded',
        width: 20,
        height: 20,
        backgroundImage: `url(${myur})`,
        backgroundRepeat: 'round',
        backgroundColor: PALE_BLUE,
        transform: 'translate(-50%, -43px)',
        cursor: 'pointer',
        "&::after": {
            position: 'absolute',
            content: '""',
            width: 0,
            height: 0,
            bottom: -26,
            left: 0,
            border: '10px solid transparent',
            borderTop: '17px solid #8edded',
        },
    },
});

const MyurMarker = ({marker, handleClose}) => {
    const classes = useMyurMarkerStyles();
    return (
        <>
            <div className={classes.marker}/>
            {marker.open && <MapsItemCard item={marker.item} handleClose={handleClose}/> }
        </>
    );
};
MyurMarker.propTypes = {
    lat: PropTypes.number.isRequired,
    lng: PropTypes.number.isRequired,
    marker: PropTypes.shape(
        {
            lat: PropTypes.number.isRequired,
            lng: PropTypes.number.isRequired,
            item: PropTypes.object.isRequired,
        }
    ),
    handleClose: PropTypes.func.isRequired,
};

export default function ItemMapComponent({center, items}) {
    const [markers, setMarkers] = useState([]);
    useEffect(() => {
        const _markers = items.map(item => {
            const sign = Math.random() >= 0.5 ? -1 : 1;
            const lat = item.latitude + sign * Math.random() / MAPS_MARKER_DISPERSION;
            const lng = item.longitude + -sign * Math.random() / MAPS_MARKER_DISPERSION;
            return {item, lat, lng, open: false};
        })
        setMarkers(_markers);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onChildClick = key =>
        setMarkers(_markers => _markers.map(
            marker => marker.item.id !== key ? marker : {...marker, open: !marker.open})
        );

    return (
        <div style={{height: '70vh', width: '100%'}}>
            <GoogleMapReact
                bootstrapURLKeys={{key: MAPS_API_PUBLIC_KEY}}
                defaultCenter={center}
                defaultZoom={MAPS_DEFAULT_ZOOM}
                onChildClick={onChildClick}
            >
                {markers.map(marker =>
                    <MyurMarker
                        key={marker.item.id}
                        lat={marker.lat}
                        lng={marker.lng}
                        marker={marker}
                        handleClose={() => onChildClick(marker.item.id)}
                    />
                )}
            </GoogleMapReact>
        </div>
    );
}

ItemMapComponent.propTypes = {
    items: PropTypes.array,
    center: PropTypes.shape(
        {
            lat: PropTypes.number,
            lng: PropTypes.number,
        }
    )
};

ItemMapComponent.defaultProps = {
    center: {...MAPS_DEFAULT_CENTER}
};