import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {useHistory} from 'react-router-dom';

import {makeStyles, useTheme} from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import {DialogActions, DialogContent, useMediaQuery} from "@material-ui/core";
import ItemCard from "../user/item/ItemCard";
import UserItemProperties from "../item/UserItemProperties";
import {buildUrl, doPost, doRequest} from "../../utils/http";
import {
    ANALYTICS_RESPONSE_TO_LIVE_SEARCH,
    API_ITEM_MY_ITEMS,
    API_LIVE_RESPONSE,
    BLUE,
    DEFAULT_MAX_WIDTH,
    SEVERITY_ERROR,
    SEVERITY_SUCCESS
} from "../../utils/constants";
import {showMessage} from "../common/NotificationSnack";
import ItemSkeleton from "../user/item/ItemSkeleton";
import {get} from "../common/i18n/i18n";
import buttonStyles from "../common/styles/buttonStyles";
import ProgressButton from "../common/ProgressButton";
import {ROUTE_ITEM_NEW} from "../../utils/routes";
import {analytics, logEvent} from "../../utils/firebase";

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        maxWidth: DEFAULT_MAX_WIDTH,
    },
    title: {
        color: BLUE,
        fontWeight: 'bold',
    },
    ...buttonStyles(theme)
}));

export default function ItemListDialog({uid, search}) {
    const history = useHistory();
    const [open, setOpen] = useState(false);
    const [items, setItems] = useState([]);
    const [waiting, setWaiting] = useState(false);
    const [sending, setSending] = useState(false);
    const classes = useStyles();
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = value => {
        setOpen(false);
    };

    const retrieveItems = uid => {
        setWaiting(true);
        doRequest(buildUrl(API_ITEM_MY_ITEMS, {uid}), onItemsRetrieved, onError, {method: 'GET'});
    };

    const onItemsRetrieved = items => {
        setWaiting(false);
        setItems(items)
    };

    const handleNewItem = () => {
        const specificResponseDTO = {
            answererId: uid,
            requestorId: search.uid,
            requestorNickname: search.nickname,
            specificRequestId: search.id,
            specificRequestTitle: search.title
        };
        history.push(ROUTE_ITEM_NEW, {specificResponseDTO});
    };

    const sendResponse = item => {
        setSending(true);
        const specificResponseDTO = {
            answererId: uid,
            requestorId: search.uid,
            itemId: item.id,
            specificRequestId: search.id,
        };
        doPost(API_LIVE_RESPONSE, JSON.stringify(specificResponseDTO))
            .then(response => onResponseSent(response, search, item))
            .catch(onError);
    };

    const onResponseSent = (response, search, item) => {
        setSending(false);
        logEvent(analytics, ANALYTICS_RESPONSE_TO_LIVE_SEARCH, {
            search_id: search.id,
            search_title: search.title,
            publisher_id: search.uid,
            publisher_nickname: search.nickname,
            owner_id: uid,
            owner_nickname: item.userNickname || item.ownerNickname,
            item_id: item.id,
            item_title: item.title
        });
        showMessage(get('response_send_success'), SEVERITY_SUCCESS);
        handleClose();
    };

    const onError = error => {
        setWaiting(false);
        setSending(false);
        showMessage(error, SEVERITY_ERROR);
    };

    return (
        <div className={classes.root}>
            <Button className={classes.blueButton} onClick={handleClickOpen} disabled={!uid}>
                {get('i_got_it')}
            </Button>
            <Dialog onClose={handleClose} open={open}
                    fullScreen={fullScreen}
                    maxWidth={'md'}
                    onEnter={() => retrieveItems(uid)}
            >
                <DialogTitle className={classes.title}>Elige uno de tus artículos</DialogTitle>
                <DialogContent>
                    {items.length === 0 && waiting &&
                        [0, 1, 2].map(index => <ItemSkeleton key={index}/>)
                    }
                    {items && items.map(item => (
                        <ItemCard key={item.id} item={item} itemDelete={false} itemEdit={false}
                                  ItemPropertiesComponent={UserItemProperties}>
                            <div style={{width: '100%', display: 'flex', justifyContent: 'flex-end'}}>
                                <ProgressButton className={classes.blueButton} loading={sending}
                                                handleClick={event => sendResponse(item)}>
                                    {get('this_one')}
                                </ProgressButton>
                            </div>
                        </ItemCard>
                    ))}
                </DialogContent>
                <DialogActions>
                    <Button className={classes.defaultButton} onClick={handleClose}>{get('close')}</Button>
                    <Button className={classes.blueButton} onClick={handleNewItem}>{get('new_item')}</Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

ItemListDialog.propTypes = {
    uid: PropTypes.string.isRequired,
    search: PropTypes.object.isRequired,
};
