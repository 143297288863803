import React from "react";
import {PropTypes} from "prop-types";

import {Typography} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";

import {MARINE, RED} from "../../utils/constants";
import {get} from "../common/i18n/i18n";
import {getSeoTitle} from "./item_utils";

const useStyles = makeStyles(theme => ({
    section: {
        marginTop: theme.spacing(),
        background: 'white',
    },
}));

export default function ItemTitleComponent({item}) {
    const distance = (item.distance === null || item.distance === undefined) ? '' : `(a ${item.distance < 0.1 ? '< ' : ''}
        ${get('pdistance_formatter').format(item.distance < 0.1 ? 0.1 : item.distance)} km)`;
    const formatter = get('currency_formatter');
    const classes = useStyles();

    return (
        <div className={classes.section}>
            <h1 style={{display: "none"}}>{getSeoTitle(item)}</h1>
            <Typography variant={'h1'} style={{color: MARINE}}>
                {item.title}
            </Typography>
            <Typography variant={'body1'} style={{color: MARINE}}>
                {item.location}, {item.province} {distance}
            </Typography>
            <Typography variant={'h6'} style={{color: RED}}>
                {
                    ['hourlyPrice', 'dailyPrice', 'weeklyPrice', 'monthlyPrice']
                        .map(price =>
                            !!item[price] ? formatter.format(item[price]) + get(`price_${price}`) : null
                        ).filter(price => !!price).join(' | ')
                }
            </Typography>
        </div>
    );
}

ItemTitleComponent.propTypes = {
    item: PropTypes.shape({
        title: PropTypes.string,
        distance: PropTypes.number,
        location: PropTypes.string,
        province: PropTypes.string,
        hourlyPrice: PropTypes.number,
        dailyPrice: PropTypes.number,
        weeklyPrice: PropTypes.number,
        monthlyPrice: PropTypes.number,
    }).isRequired,
}