import {get} from "../common/i18n/i18n";
import {ITEM_MIN_PRICE, MAX_DESCRIPTION_CHAR} from "../../utils/constants";
import {isInsurable} from "./item_utils";

const VALIDATION_ERRORS = {
    title: '',
    description: '',
    category: '',
    subCategory: '',
    purchasePrice: '',
    insurance: '',
    deposit: '',
    hourlyPrice: '',
    dailyPrice: '',
    weeklyPrice: '',
    monthlyPrice: '',
    photos: '',
    insuranceConditionsAccepted: '',
    vType: '',
    vSubtype: '',
    vBrand: '',
    vModel: '',
    vLicencePlate: '',
    bType: '',
    bSubtype: '',
    bMaterial: '',
    bLength: '',
    bEngines: '',
    bConstructionYear: '',
    bPower: '',
    bZipcodePort: '',
    bBoatName: '',
    bLicencePlate: '',
    bBrand: '',
    bModel: ''
};

const validatePrice = (item, errors) => {
    if (!item.hourlyPrice && !item.dailyPrice && !item.weeklyPrice && !item.monthlyPrice) {
        errors.hourlyPrice = get('mandatory_item_price');
        errors.dailyPrice = get('mandatory_item_price');
        errors.weeklyPrice = get('mandatory_item_price');
        errors.monthlyPrice = get('mandatory_item_price');
        return errors;
    }
    ['hourlyPrice', 'dailyPrice', 'weeklyPrice', 'monthlyPrice']
        .forEach(price =>
            (!!item[price] && item[price] < ITEM_MIN_PRICE) ?
                errors[price] = get(`mandatory_item_${price}`, [ITEM_MIN_PRICE])
                : errors[price] = ''
        )
    return errors;
}

const isProfessional = (item, user) =>
    (item.professionalOwner || (!!user && !!user.companyId));

export const depositRequired = (item, user) => {
    // A los usuarios profesionales no se les pide fianza.
    if (isProfessional(item, user)) {
        return false;
    }
    // Si la categoría es espacios o servicios, no se pide una fianza mínima.
    return !!item.category && item.category.requiredDeposit && !!item.category.depositPercent;
}

const validateDeposit = (item, user, errors) => {
    if (!depositRequired(item, user)) {
        errors.deposit = '';
        return errors;
    }
    const minDeposit = item.purchasePrice * item.category.depositPercent / 100;
    errors.deposit = !!item.purchasePrice && item.deposit < minDeposit
        ? get('mandatory_item_deposit', [minDeposit]) : '';
    return errors;
}

const insuranceRequired = (item, user) =>
    isProfessional(item, user) ? false : isInsurable(item);

export const validate_item = (item, user) => {
    if (!item) {
        return {...VALIDATION_ERRORS};
    }
    const errors = {...VALIDATION_ERRORS};
    const _insuranceRequired = insuranceRequired(item, user);
    const boat = item.boatDetailDTO;

    errors.title = item.title === '' ? get('mandatory_item_title') : '';
    errors.description = (!!item.description && item.description.length > MAX_DESCRIPTION_CHAR) ? get('description_2_long') : '';
    errors.category = !item.category.parentCategory ? get('mandatory_item_category') : '';
    errors.subCategory = !item.category.categoryId ? get('mandatory_item_subcategory') : '';
    errors.purchasePrice = (isInsurable(item) && !item.purchasePrice) ? get('mandatory_item_purchase_price') : '';
    errors.insurance = !_insuranceRequired || (_insuranceRequired && item.insuranceType > 0) ? '' : get('mandatory_item_insurance');
    //errors.insuranceConditionsAccepted = (item.insuranceType > 0 && !item.insuranceRequired)
    //    ? get('insurance_acceptance_error') : '';
    validateDeposit(item, user, errors);
    validatePrice(item, errors);
    errors.photos = item.photos.length !== 0 ? '' : get('mandatory_item_photos');
    errors.vType = !!item.vehicleDetailDTO && !item.vehicleDetailDTO.type ? get('mandatory_vehicle_type') : '';
    errors.vSubtype = !!item.vehicleDetailDTO && !!item.vehicleDetailDTO.type && item.vehicleDetailDTO.type.hasChildren && !item.vehicleDetailDTO.subtype
        ? get('mandatory_vehicle_subtype') : '';
    errors.vBrand = !!item.vehicleDetailDTO && item.vehicleDetailDTO.brand === '' ? get('mandatory_vehicle_brand') : '';
    errors.vModel = !!item.vehicleDetailDTO && item.vehicleDetailDTO.model === '' ? get('mandatory_vehicle_model') : '';
    errors.vLicencePlate = !!item.vehicleDetailDTO && item.vehicleDetailDTO.licencePlate === '' ? get('mandatory_vehicle_licence_plate') : '';
    errors.bType = !!boat && !boat.type ? get('mandatory_boat_type') : '';
    errors.bSubtype = !!boat && !boat.subtype ? get('mandatory_boat_subtype') : '';
    //errors.bMaterial = !!boat && !boat.material ? get('mandatory_boat_material') : '';
    //errors.bLength = !!boat && !boat.length ? get('mandatory_boat_length') : '';
    //errors.bEngines = !!boat && !boat.engines ? get('mandatory_boat_engines') : '';
    errors.bConstructionYear = !!boat && !boat.constructionYear ? get('mandatory_boat_construction_year') : '';
    //errors.bPower = !!boat && !boat.power ? get('mandatory_boat_power') : '';
    errors.bZipcodePort = !!boat && !boat.zipcodePort ? get('mandatory_boat_zipcode_port') : '';
    //errors.bBoatName = !!boat && !boat.boatName ? get('mandatory_boat_name') : '';
    errors.bLicencePlate = !!boat && !boat.licencePlate ? get('mandatory_boat_licence_plate') : '';
    errors.bBrand = !!boat && !boat.brand ? get('mandatory_boat_brand') : '';
    errors.bModel = !!boat && !boat.model ? get('mandatory_boat_model') : '';
    return errors;
};
