import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {makeStyles, useTheme} from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import {Button, CircularProgress, Typography, useMediaQuery} from "@material-ui/core";
import issueIcon from '../../images/incidencias.png';
import {get} from '../common/i18n/i18n';
import moment from "moment";
import {
    API_ISSUE_DETAIL, DATE_TIME_FORMAT, DEFAULT_PHOTO_HEIGHT, DEFAULT_PHOTO_WIDTH,
    LOAN_ISSUE_STATUS, MARINE, PALE_SAND, SEVERITY_ERROR
} from "../../utils/constants";
import {buildUrl, doRequest} from "../../utils/http";
import {showMessage} from "../common/NotificationSnack";
import LabelValue from "../common/LabelValue";
import buttonStyles from "../common/styles/buttonStyles";
import IssueStatusStepper from "./IssueStatusStepper";
import clsx from "clsx";

const useStyles = makeStyles(theme => ({
    icon: {
        width: '2.5rem',
        height: '2.5rem',
    },
    spinner: {
        display: 'flex',
        justifyContent: 'center',
        margin: theme.spacing(2),
    },
    issueDetail: {
        display: 'flex',
        flexFlow: 'wrap column',
        alignContent: 'flex_start',
        marginLeft: theme.spacing(1),
    },
    dialogTitle: {
        backgroundColor: PALE_SAND,
        color: MARINE,
    },
    issueDetailContent: {},
    issueDetailAction: {
        textAlign: 'center',
        backgroundColor: PALE_SAND,
        color: MARINE,
    },
    label: {
        color: MARINE,
    },
    helper: {
        color: MARINE,
    },
    insuranceIssueTitle: {
        backgroundColor: PALE_SAND,
        color: MARINE,
        padding: '1rem 2rem',
    },
    image: {
        width: DEFAULT_PHOTO_WIDTH,
        height: DEFAULT_PHOTO_HEIGHT,
        marginTop: '0.5rem',
    },
    air: {
        padding: '0.5rem 2rem',
    },
    ...buttonStyles(theme),
}));

const DT_FORMAT = get('date_ui');

function IssueListComponent({loan, handleIssueSelected, classes}) {
    return (
        <React.Fragment>
            <DialogTitle className={classes.dialogTitle}>{get('issue_select_issue')}</DialogTitle>
            <List>
                {loan.issues.map(issue => (
                    <ListItem key={issue.issueCode} button onClick={() => handleIssueSelected(issue)}>
                        <ListItemAvatar>
                            <img className={classes.icon} src={issueIcon} alt={get('issues')}/>
                        </ListItemAvatar>
                        <ListItemText
                            primary={`${get('issue')} ${issue.issueCode} (${get('issue_status_' + LOAN_ISSUE_STATUS[issue.issueStatus])})`}
                            secondary={
                                <React.Fragment>
                                    <Typography component={'span'} variant={'body2'}>
                                        {get('issue_type', [get(`issue_type_name_${issue.issueType}`)])}
                                    </Typography>
                                    <br/>
                                    <Typography component={'span'} variant={'body2'}>
                                        {get('issue_open_date', [moment(issue.issueDate, DATE_TIME_FORMAT).format(DT_FORMAT)])}
                                    </Typography>
                                </React.Fragment>
                            }
                        />
                    </ListItem>
                ))}
            </List>
        </React.Fragment>
    )
}

IssueListComponent.propTypes = {
    loan: PropTypes.object.isRequired,
    handleIssueSelected: PropTypes.func.isRequired,
    classes: PropTypes.object.isRequired,
};

function InsuranceData({classes, issue}) {
    if (issue.issueType === 'FIRE') {
        return (
            <React.Fragment>
                <Typography className={classes.insuranceIssueTitle} variant={'h2'}>{get('insurance_issue_fire')}</Typography>
                <div className={classes.air}>
                    <LabelValue classes={{label: classes.label, value: classes.label}}
                                label={get('issue_detail_where')}
                                value={issue.issuePlace}/>
                    <LabelValue classes={{label: classes.label, value: classes.label}}
                                label={get('issue_detail_when_date')}
                                value={moment(issue.issueWhen, DATE_TIME_FORMAT).format(get('date_ui'))}/>
                    <LabelValue classes={{label: classes.label, value: classes.label}}
                                label={get('issue_detail_when_hour')}
                                value={moment(issue.issueWhen, DATE_TIME_FORMAT).format(get('time_ui')) + 'h'}/>
                    <Typography variant={"h5"} className={classes.label}>{get('issue_detail_photo')}:</Typography>
                    <img className={classes.image} alt={'issue_' + issue.issueCode} src={issue.issuePhotoUrl}/>
                </div>
            </React.Fragment>
        );
    }

    if (issue.issueType === 'THEFT') {
        return (
            <React.Fragment>
                <Typography className={classes.insuranceIssueTitle} variant={'h2'}>{get('insurance_issue_theft')}</Typography>
                <div className={classes.air}>
                    <LabelValue classes={{label: classes.label, value: classes.label}}
                                label={get('issue_detail_where')}
                                value={issue.issuePlace}/>
                    <LabelValue classes={{label: classes.label, value: classes.label}}
                                label={get('issue_detail_when_date')}
                                value={moment(issue.issueWhen, DATE_TIME_FORMAT).format(get('date_ui'))}/>
                    <LabelValue classes={{label: classes.label, value: classes.label}}
                                label={get('issue_detail_when_hour')}
                                value={moment(issue.issueWhen, DATE_TIME_FORMAT).format(get('time_ui')) + 'h'}/>
                    {!!issue.issuePhotoUrl &&
                        <React.Fragment>
                            <Typography variant={"h5"} className={classes.label}>{get('issue_detail_photo')}:</Typography>
                            <img className={classes.image} alt={'issue_' + issue.issueCode} src={issue.issuePhotoUrl}/>
                        </React.Fragment>
                    }
                </div>
            </React.Fragment>
        );
    }

    if (issue.issueType === 'DAMAGE') {
        return (
            <React.Fragment>
                <Typography className={classes.insuranceIssueTitle} variant={'h2'}>{get('insurance_issue_damage')}</Typography>
                <div className={classes.air}>
                    <LabelValue classes={{label: classes.label, value: classes.label}}
                                label={get('issue_detail_where')}
                                value={issue.issuePlace}/>
                    <LabelValue classes={{label: classes.label, value: classes.label}}
                                label={get('insurance_issue_damage2')}
                                value={issue.issueDamageUnusable}/>
                    <LabelValue classes={{label: classes.label, value: classes.label}}
                                label={get('issue_detail_when_date')}
                                value={moment(issue.issueWhen, DATE_TIME_FORMAT).format(get('date_ui'))}/>
                    <LabelValue classes={{label: classes.label, value: classes.label}}
                                label={get('issue_detail_when_hour')}
                                value={moment(issue.issueWhen, DATE_TIME_FORMAT).format(get('time_ui')) + 'h'}/>
                    <Typography variant={"h5"} className={classes.label}>{get('issue_detail_photo')}:</Typography>
                    <img className={classes.image} alt={'issue_' + issue.issueCode} src={issue.issuePhotoUrl}/>
                </div>
            </React.Fragment>
        )
    }
    return <React.Fragment/>;
}

InsuranceData.propTypes = {
    issue: PropTypes.object.isRequired,
    classes: PropTypes.object.isRequired,
};

function Comments({classes, issue}) {
    if (issue.comments.length === 0) {
        return <React.Fragment/>
    }
    const _comments = issue.comments.sort((c1, c2) => c1.date > c2.date ? 1 : -1);
    return (
        <React.Fragment>
            <Typography className={classes.insuranceIssueTitle} variant={'h2'}>{get('comments')}</Typography>
            <div className={classes.air}>
                {_comments.map(comment => {
                    const date = moment(comment.date, DATE_TIME_FORMAT);
                    return <LabelValue key={comment.id}
                                       classes={{label: classes.label, value: classes.label}}
                                       label={get('comment_who_when',
                                           [comment.userLogin, date.format(get('date_time_ui'))])}
                                       value={comment.comment}/>;
                })}
            </div>
        </React.Fragment>

    );
}

Comments.propTypes = {
    issue: PropTypes.object.isRequired,
    classes: PropTypes.object.isRequired,
};

function IssueDetailComponent({issue, handleClose, classes}) {
    return (
        <React.Fragment>
            <DialogTitle className={classes.dialogTitle} disableTypography={true}>
                <Typography variant={'h4'} align={'center'}>
                    {get('issue_code_detail', [issue.issueCode])}
                </Typography>
                <Typography variant={'h2'} align={'center'}>{issue.itemTitle}</Typography>
            </DialogTitle>
            <div className={classes.issueDetailContent}>
                <IssueStatusStepper issue={issue}/>
                <InsuranceData classes={classes} issue={issue}/>
                <div className={classes.air}>
                    <Typography variant={"h5"} className={classes.helper}>
                        {get('issue_detail_description')}
                    </Typography>
                    <Typography variant={"body1"} className={classes.helper}>
                        {issue.issueText}
                    </Typography>
                </div>
                <Comments classes={classes} issue={issue}/>
            </div>
            <div className={clsx(classes.issueDetailAction, classes.air)}>
                <Typography className={classes.helper} align={'center'} variant={'h6'}>
                    {get('issue_detail_helper')}
                </Typography>
                <Button className={classes.blueButton} onClick={handleClose}>{get('go_back_alt')}</Button>
            </div>
        </React.Fragment>
    );
}

IssueDetailComponent.propTypes = {
    issue: PropTypes.object.isRequired,
    handleClose: PropTypes.func.isRequired,
    classes: PropTypes.object.isRequired,
};

function Waiting({classes}) {
    return (
        <React.Fragment>
            <DialogTitle className={classes.dialogTitle}>{get('issue_loading')}</DialogTitle>
            <div className={classes.spinner}>
                <CircularProgress color="inherit" size={'4rem'}/>
            </div>
        </React.Fragment>
    );
}

export default function LoanIssueDialog({loan, open, handleClose}) {
    const classes = useStyles();
    const [currentIssue, setCurrentIssue] = useState(null);
    const [waiting, setWaiting] = useState(false);
    const [issueDetailList, setIssueDetailList] = useState({});
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

    const retrieveIssueDetail = issue => {
        if (!!issueDetailList[issue.issueCode]) {
            setCurrentIssue(issueDetailList[issue.issueCode]);
        } else {
            setWaiting(true);
            doRequest(
                buildUrl(API_ISSUE_DETAIL, {senderId: issue.senderId, loanIssueId: issue.loanIssueId}),
                onIssueDetailRetrieved,
                onError,
                {method: 'GET'}
            );
        }
    };

    const onIssueDetailRetrieved = issue => {
        setIssueDetailList(prevState => ({...prevState, [issue.issueCode]: issue}));
        setCurrentIssue(issue);
        setWaiting(false);
    };

    const onError = error => {
        showMessage(error, SEVERITY_ERROR);
        setCurrentIssue(null);
        setWaiting(false);
    };

    return (
        <Dialog onClose={handleClose} open={open} fullWidth={true} maxWidth={'sm'} fullScreen={fullScreen}>
            {waiting ? <Waiting classes={classes}/> :
                !currentIssue
                    ? <IssueListComponent classes={classes} loan={loan} handleIssueSelected={retrieveIssueDetail}/>
                    : <IssueDetailComponent classes={classes} issue={currentIssue}
                                            handleClose={() => setCurrentIssue(null)}/>
            }
        </Dialog>
    );
}

LoanIssueDialog.propTypes = {
    loan: PropTypes.object.isRequired,
    open: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired,
};
