import React from 'react'
import {compose} from 'recompose'

import { withStyles } from '@material-ui/core/styles'
import PropTypes from "prop-types";
import AddressCard from "./AddressCard";

const styles = {
    root: {
        display: 'flex',
        flexFlow: 'row wrap',
    },
};

function AddressComponent(props) {
    const {addresses, handleDelete, classes} = props;
    return (
        <div className={classes.root}>
            {addresses.map(address =>
                <AddressCard key={address.addressId} address={address} handleDelete={handleDelete}/>
            )}
        </div>
    );
}

AddressComponent.propTypes = {
    classes: PropTypes.object.isRequired,
    addresses: PropTypes.array.isRequired,
    handleDelete: PropTypes.func.isRequired,
};


export default compose(
    withStyles(styles),
)(AddressComponent);
