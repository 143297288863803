import React from 'react';
import moment from "moment";
import clsx from 'clsx';
import {useHistory} from 'react-router-dom';
import {PropTypes} from 'prop-types';

import {makeStyles, Avatar, Typography} from "@material-ui/core";

import {DEFAULT_AVATAR, MARINE, PALE_SAND, SALMON} from "../../utils/constants";
import NewMessagesChatIcon from '../../images/icon_mensaje_alerta.png';
import ChatIcon from '../../images/icon_mensajes.png';
import {getAvatar} from "../../utils/utils";
import {buildUrl} from "../../utils/http";
import {ROUTE_CHAT} from "../../utils/routes";
import layoutStyles from "../common/styles/layoutStyles";

const useStyles = makeStyles(theme => ({
    ...layoutStyles(theme),
    root: {
        marginTop: theme.spacing(),
        display: 'flex',
        flexFlow: 'nowrap row',
        backgroundColor: PALE_SAND,
        '&:hover': {
            cursor: 'pointer',
        },
    },
    avatar: {
        margin: theme.spacing(),
        width: 60,
        height: 60,
        [theme.breakpoints.down("sm")]: {
            width: 40,
            height: 40,
        }
    },
    image: {
        width: 60,
        height: 60,
        [theme.breakpoints.down("sm")]: {
            width: 40,
            height: 40,
        }
    },
    data: {
        display: 'flex',
        flexFlow: 'nowrap row',
        justifyContent: 'space-between',
        width: '100%',
        color: MARINE,
    },
    section: {
        display: 'flex',
        flexFlow: 'wrap column',
        justifyContent: 'space-between',
        textAlign: 'left',
        margin: theme.spacing(),
        '&:last-of-type': {
            textAlign: 'right',
        }
    },
    title: {
        color: MARINE,
        fontWeight: 'bold',
    },
    chatIcon: {
        width: 16,
        height: 16,
        marginRight: theme.spacing(),
    },
    chatUnread: {
        color: SALMON,
    },
    message: {
        display: 'flex',
        flexFlow: 'nowrap row',
        justifyContent: 'flex-start',
        alignItems: 'center',
    }
}));


const LastMessage = ({message}) => {
    const classes = useStyles();
    const regExp = /(^MYUR_CDN_BASE_URL|[png|jpg]$)/;
    const isImage = !!message && regExp.test(message);
    if (isImage) {
        return <img alt="last message" className={classes.image} src={message}/>
    }
    return <Typography variant={'caption'}>{message}</Typography>;
}
LastMessage.propTypes = {
    message: PropTypes.string
}

export default function ChatItemCard({uid, chat}) {
    const classes = useStyles();
    const history = useHistory();

    const goToChat = () => {
        history.push(buildUrl(ROUTE_CHAT, {chatId: chat.key}));
    };

    const date = moment(chat.lastMsgTS, 'YYYYMMDDhhmmss').calendar( null,{
        sameDay: 'hh:mm a',
        lastDay: '[Yesterday]',
        lastWeek: 'DD/MM/YYYY hh:mm a',
        sameElse: 'DD/MM/YYYY hh:mm a',
    });
    const {avatar, nickname} = (uid === chat.ownerId) ?
        {avatar: chat.borrowerAvatar, nickname: chat.borrowerNickname}
        : {avatar: chat.ownerAvatar, nickname: chat.ownerNickname};
    const chatIcon = (chat.lastMsgRead === true) ? ChatIcon : NewMessagesChatIcon;
    const dateClass = clsx(!chat.lastMsgRead && classes.chatUnread);
    return (
            <div className={clsx(classes.root, classes.maxWidth)} onClick={goToChat}>
                <div>
                    <Avatar alt={nickname} src={getAvatar(avatar)} className={classes.avatar}
                            imgProps={{
                                onError: e => e.target.src = DEFAULT_AVATAR
                            }}
                    />
                </div>
                <div className={classes.data}>
                    <div className={classes.section}>
                        <Typography variant={'body1'} className={classes.title}>{nickname}</Typography>
                        <div className={classes.message}>
                            <img className={classes.chatIcon} alt={nickname} src={chatIcon}/>
                            <LastMessage message={chat.lastMsgText}/>
                        </div>
                    </div>
                    <div className={classes.section}>
                        <Typography variant={'body1'} className={classes.title}>{chat.itemTitle}</Typography>
                        <Typography variant={'caption'} className={dateClass}>{date}</Typography>
                    </div>
                </div>
            </div>
    );
}