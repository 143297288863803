import React from 'react'

import PropTypes from 'prop-types'
import moment from 'moment'

import makeStyles from "@material-ui/core/styles/makeStyles";
import {Typography} from "@material-ui/core";

import NamedAvatar from "../common/NamedAvatar";
import {
    BLUE, DATE_TIME_FORMAT,
    DEFAULT_PHOTO_HEIGHT,
    DEFAULT_PHOTO_WIDTH,
    MARINE,
} from "../../utils/constants";
import ItemListDialog from "./ItemListDialog";
import {get} from "../common/i18n/i18n";

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        marginTop: theme.spacing(),
        display: 'flex',
        flexFlow: 'nowrap row',
    },
    other: {
        display: 'flex',
        flexFlow: 'nowrap column',
        width: '95%',
        marginRight: theme.spacing(),
    },
    avatar: {
        minWidth: '6em',
    },
    noAvatar: {
        display: 'flex',
        flexFlow: 'nowrap row',
        height: '100%',
        background: 'white',
        color: MARINE,
    },
    what: {
        display: 'flex',
        flexFlow: 'wrap column',
        flex: '2 1 50%',
        textAlign: 'left',
        marginLeft: theme.spacing(),
    },
    where: {
        display: 'flex',
        flexFlow: 'wrap column',
        flex: '1 1 25%',
        textAlign: 'left',
    },
    when: {
        display: 'flex',
        flexFlow: 'wrap column',
        flex: '1 1 25%',
        alignItems: 'center',
        textAlign: 'center',
    },
    section: {
        color: BLUE,
        fontWeight: 'bold',
    },
    title: {
        color: MARINE,
        fontWeight: 'bold',
    },
    photo: {
        width: DEFAULT_PHOTO_WIDTH,
        height: DEFAULT_PHOTO_HEIGHT,
        borderRadius: '4px',
        margin: theme.spacing(),
    }
}));

export default function LiveSearchCard(props) {
    const {search, uid} = props;
    const classes = useStyles();
    const date = moment(search.publicationDate, DATE_TIME_FORMAT).format(get('time_seconds_ui'));
    return (
        <div className={classes.root}>
            <div className={classes.avatar}>
                <NamedAvatar name={search.nickname} avatarUrl={search.avatar}/>
            </div>
            <div className={classes.other}>
                <div className={classes.noAvatar}>
                    <div className={classes.what}>
                        <Typography variant={'caption'} className={classes.section}>{get('i_need')}</Typography>
                        <Typography variant={'body1'} className={classes.title}>{search.title}</Typography>
                        <Typography variant={'caption'}>{search.description}</Typography>
                    </div>
                    <div className={classes.where}>
                        <Typography variant={'caption'} className={classes.section}>{get('where')}</Typography>
                        <Typography variant={'body1'} className={classes.title}>{search.zipCode}</Typography>
                        <Typography variant={'body1'} className={classes.title}>{search.town}</Typography>
                    </div>
                    <div className={classes.when}>
                        <Typography variant={'caption'} className={classes.section}>{date}</Typography>
                        <ItemListDialog uid={uid} search={search}/>
                    </div>
                </div>
                {!!search.photo &&
                    <div className={classes.noAvatar}>
                        <img className={classes.photo} alt={search.title} src={search.photo}/>
                    </div>
                }
            </div>
        </div>
    );
}

LiveSearchCard.propTypes = {
    search: PropTypes.object.isRequired,
    uid: PropTypes.string.isRequired,
};