import {grey} from "@material-ui/core/colors";

export const reviewCardStyles = theme => ({
    root: {
        border: `1px solid ${grey[300]}`,
        display: 'flex',
        flex: '0 0 230px',
        flexFlow: 'nowrap column',
        marginRight: theme.spacing(),
        boxSizing: "border-box",
    },
    user: {
        display: 'flex',
        flexFlow: 'nowrap row',
        alignItems: 'center',
    },
    detail: {
        display: 'flex',
        flexFlow: 'nowrap column',
        alignItems: 'flex-start',
    },
    avatar: {
        margin: theme.spacing(),
        marginRight: theme.spacing(),
        width: 32,
        height: 32,
    },
    name: {
        fontWeight: 'bold',
        fontSize: 'x-small',
    },
    date: {
        fontSize: 'x-small',
    },
    rating: {
        marginRight: theme.spacing(),
    },
});