import {
    GOT_SEARCH_ITEMS_ERROR_ACTION,
    GOT_SEARCH_ITEMS_RESPONSE_ACTION,
    SEARCH_ITEM_ACTION, UPDATE_GEOPOSITION_ACTION
} from "./actionTypes";
import {doGet} from "../utils/http";
import {
    API_ITEM_LANDING,
    DATE_TIME_FORMAT,
    GEOLOCATION_ERROR_CODE_USER_DENIED, GEOLOCATION_PERMISSION_STATUS,
    PERMISSION_DENIED, PERMISSION_GRANTED
} from "../utils/constants";
import {getOrigin} from "../reducers";
import {getBrowserGeoPosition, promiseTimeout} from "../utils/utils";
import {auth, isSignedId, onAuthStateChanged} from "../utils/firebase";
import {getOriginCoords, saveOriginCoords} from "../utils/localStorage";

const searchItemsActionCreator = (what, where, startDate, endDate, start, count) => ({
    type: SEARCH_ITEM_ACTION,
    what, where, startDate, endDate, start, count,
});

const gotSearchItemsResponseActionCreator = response => ({
    type: GOT_SEARCH_ITEMS_RESPONSE_ACTION,
    response,
});

const gotSearchItemsErrorActionCreator = error => ({
    type: GOT_SEARCH_ITEMS_ERROR_ACTION,
    error,
});

const updateGeoPositionActionCreator = (lat, lng) => ({
    type: UPDATE_GEOPOSITION_ACTION,
    lat,
    lng
});

export const updateGeoPosition = (lat, lng) => dispatch => {
    const origin = getOriginCoords();
    if (origin.lat !== lat || origin.lng !== lng) {
        saveOriginCoords({lat, lng});
    }
    dispatch(updateGeoPositionActionCreator(lat, lng));
};

const gotSearchItemsResponse = dispatch => response => dispatch(gotSearchItemsResponseActionCreator(response));

const gotSearchItemsError = dispatch => error => dispatch(gotSearchItemsErrorActionCreator(error));

export const searchItems = (what, where, startDate, endDate, start, count) => (dispatch, getState) => {
    dispatch(searchItemsActionCreator(what, where, startDate, endDate, start, count));
    _searchItems(dispatch, getState, what, where, startDate, endDate, start, count);
};

const _searchItems = async (dispatch, getState, what, where, startDate, endDate, start, count) => {
    let origin = getOrigin(getState());
    const from = !!startDate ? `&initDate=${startDate.format(DATE_TIME_FORMAT)}` : '';
    const to = !!startDate ? `&endDate=${endDate.format(DATE_TIME_FORMAT)}` : '';
    const _where = (!where && !!origin.lat && !!origin.lng) ? {lat: origin.lat, lng: origin.lng} : where;
    const [lat, lng] = !!_where ? [`&latitude=${_where.lat}`, `&longitude=${_where.lng}`] : ['', ''];
    if (!isSignedId()) {
        // Si el usuario no está autenticado, esperamos un máximo de 5 segundos a que se autentique.
        // Se espera para poder obtener de backend la información de distancia.
        let unsubscribe;
        try {
            await promiseTimeout(5000,
                new Promise((resolve, reject) => {
                    unsubscribe = onAuthStateChanged(auth, resolve, reject)
                }));
        } catch (error) {
            console.warn(error);
        }
        !!unsubscribe && unsubscribe();
    }
    doGet(`${API_ITEM_LANDING}?start=${start}&count=${count}${from}${to}${lat}${lng}&query=${what}`)
        .then(gotSearchItemsResponse(dispatch))
        .catch(gotSearchItemsError(dispatch));
};

export const retrieveBrowserGeoPosition = () => dispatch =>
    _retrieveBrowserGeoPosition(dispatch);

const _retrieveBrowserGeoPosition = dispatch => {
    getBrowserGeoPosition()
        .then(origin => {
            sessionStorage.setItem(GEOLOCATION_PERMISSION_STATUS, PERMISSION_GRANTED);
            const {latitude: lat, longitude: lng} = origin.coords;
            saveOriginCoords({lat, lng});
            dispatch(updateGeoPositionActionCreator(lat, lng));
        })
        .catch(error => {
            if (error.code === GEOLOCATION_ERROR_CODE_USER_DENIED) {
                sessionStorage.setItem(GEOLOCATION_PERMISSION_STATUS, PERMISSION_DENIED);
            } else {
                sessionStorage.setItem(GEOLOCATION_PERMISSION_STATUS, PERMISSION_GRANTED);
            }
            console.error(error)
        });
}