import React, {useEffect, useState} from 'react'

import {makeStyles, } from '@material-ui/core/styles'
import {API_USER_GET_PUBLIC_DATA, SEVERITY_ERROR} from "../../../utils/constants";
import {buildUrl, doGet} from "../../../utils/http";
import {showMessage} from "../../common/NotificationSnack";
import layoutStyles from "../../common/styles/layoutStyles";
import {useSelector} from "react-redux";
import ComponentWrapper from "../../common/ComponentWrapper";
import UserReviewCardSkeleton from "../public/UserReviewCardSkeleton";
import UserReviewCard from "../public/UserReviewCard";
import {Grid, Paper} from "@material-ui/core";
import GoBackHeader from "../../common/GoBackHeader";
import {get} from "../../common/i18n/i18n";
import UserDetailMiniCard from "../public/UserDetailMiniCard";
import UserDetailMiniCardSkeleton from "../public/UserDetailMiniCardSkeleton";

const useStyles = makeStyles(theme => ({
    ...layoutStyles(theme),
    review: {
        width: "100%",
        height: "100%",
    },
    user: {
        marginBottom: theme.spacing(2),
        padding: theme.spacing(1),
        boxSizing: "border-box"
    }
}));

export default function ReviewListComponent() {

    const authUser = useSelector(state => state.session.authUser);
    const [loading, setLoading] = useState(false);
    const [user, setUser] = useState(null);

    const classes = useStyles();
    useEffect(() => {
        if (!!authUser){
            setLoading(true);
            doGet(buildUrl(API_USER_GET_PUBLIC_DATA, {uid: authUser.uid}))
                .then(onUser)
                .catch(onError);
        }
    }, [authUser]);

    const onUser = user => {
        setUser(user);
        setLoading(false);
    }

    const onError = error => {
        showMessage(error, SEVERITY_ERROR);
        setLoading(false);
    }

    return (
        <ComponentWrapper className={classes.component} loading={loading}>
            <GoBackHeader title={get('reviews_about_me')}/>
            <Paper className={classes.user}>
                {
                    !!user ? <UserDetailMiniCard user={user} /> : <UserDetailMiniCardSkeleton/>
                }
            </Paper>
            <Grid container spacing={1} className={classes.searchResult}>
                {
                    loading
                        ? [0, 1, 2, 3].map(idx =>
                            <Grid item xs={12} sm={4} md={3} alignItems={"stretch"}>
                                <UserReviewCardSkeleton key={idx} className={classes.review}/>
                            </Grid>
                        )
                        : !!user && user.ratings.map(review =>
                            <Grid item xs={12} sm={4} md={3} alignItems={"stretch"}>
                                <UserReviewCard key={review.id} review={review} className={classes.review}/>
                            </Grid>
                    )
                }
            </Grid>
        </ComponentWrapper>
    );
}

ReviewListComponent.propTypes = {

};
