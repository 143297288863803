import React from 'react'
import {makeStyles} from '@material-ui/core/styles'
import {PropTypes} from 'prop-types'
import {useHistory} from 'react-router-dom'

import {IconButton, Typography} from '@material-ui/core'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import {BLUE, DEFAULT_MAX_WIDTH} from '../../utils/constants'
import {get} from "./i18n/i18n";


const useStyles = makeStyles(theme => ({
    header: {
        display: 'flex',
        flexFlow: 'no-wrap row',
        justifyContent: 'space-between',
        alignItems: 'baseline',
        background: 'white',
        width: '100%',
        maxWidth: DEFAULT_MAX_WIDTH,
    },
    title: {
        marginTop: theme.spacing(1),
        color: BLUE,
        fontWeight: 'bold',
    },
    backButton: {
        margin: theme.spacing(1),
    },
}));

export default function GoBackHeader({title}) {

    const classes = useStyles();
    const history = useHistory();

    const handleGoBack = () => {
        history.goBack();
    };

    return (
        <div className={classes.header}>
            <div>
                <IconButton className={classes.backButton} aria-label={get('go_back')} onClick={handleGoBack}>
                    <ArrowBackIcon />
                </IconButton>
            </div>
            <div>
                <Typography variant={'subtitle1'} className={classes.title}>{title}</Typography>
            </div>
            <div>
            </div>
        </div>
    );
}

GoBackHeader.propTypes= {
    title: PropTypes.string.isRequired,
};