import React from 'react'
import {withStyles} from '@material-ui/core/styles'
import {compose} from 'recompose'
import {PropTypes} from 'prop-types'

import {Avatar, Typography} from '@material-ui/core'
import {BLUE, DEFAULT_AVATAR} from '../../utils/constants'
import {getAvatar} from "../../utils/utils";

const styles = theme => ({
    root: {
        display: 'flex',
        flexFlow: 'wrap column',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    name: {
        fontWeight: 'bold',
        color: BLUE,
    },
    avatar: {
        margin: 10,
        width: 60,
        height: 60,
    },

});

function NamedAvatar(props) {

    const {classes, avatarUrl, name} = props;

    return (
        <div className={classes.root}>
                <Avatar alt={name} src={getAvatar(avatarUrl)} className={classes.avatar}
                        imgProps={{
                            onError: e => e.target.src = DEFAULT_AVATAR
                        }}
                />
                <Typography variant={'subtitle1'} className={classes.name}>{name}</Typography>
        </div>
    );
}

NamedAvatar.propTypes= {
    classes: PropTypes.object.isRequired,
    avatarUrl: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
};

export default compose(
    withStyles(styles),
)(NamedAvatar);