import React from 'react';
import PropTypes from 'prop-types';

import {makeStyles} from "@material-ui/core";
import UserReviewCard from "./UserReviewCard";
import {DEFAULT_MAX_WIDTH} from "../../../utils/constants";
import UserReviewCardSkeleton from "./UserReviewCardSkeleton";

const useStyles = makeStyles(theme => ({
    ratings: {
        width: "100%",
        maxWidth: DEFAULT_MAX_WIDTH,
        display: 'flex',
        flexFlow: 'nowrap row',
        justifyContent: 'flex-start',
        overflow: 'auto hidden',
    },
    review: {
        margin: theme.spacing(0, 1, 1, 0),
    }
}));

export default function UserReviewsComponent({user, waiting}) {
    const classes = useStyles();
    return (
        <div className={classes.ratings}>
            {
                waiting
                    ? [0, 1, 2, 3].map(idx => <UserReviewCardSkeleton key={idx} className={classes.review}/>)
                    : user.ratings.map(review => <UserReviewCard key={review.id} review={review} className={classes.review}/>)
            }
        </div>
    );
}

UserReviewsComponent.props = {
    user: PropTypes.object.isRequired,
    waiting: PropTypes.bool.isRequired,
};