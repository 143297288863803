import React from 'react'
import PropTypes from "prop-types";

import {withStyles} from '@material-ui/core/styles'
import {DEFAULT_WIDTH} from "../../utils/constants";
import {get} from "../common/i18n/i18n";
import GoBackHeader from "../common/GoBackHeader";

const styles = theme => ({
    root: {
        width: DEFAULT_WIDTH
    },
});

class HelpComponent extends React.Component {

    render() {
        const {classes} = this.props;
        return (
            <div className={classes.root}>
                <GoBackHeader title={get('help')}/>
            </div>
        );
    }
}

HelpComponent.propTypes = {
    classes: PropTypes.object.isRequired,
};


export default withStyles(styles)(HelpComponent);
