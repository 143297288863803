import React, {useEffect, useState} from 'react';
import {PropTypes} from "prop-types";
import {makeStyles} from '@material-ui/core/styles';
import clsx from "clsx";

import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';

import {get} from '../common/i18n/i18n';
import {doRequest} from "../../utils/http";
import {API_MASTER_ISSUE_TYPES, SEVERITY_ERROR} from "../../utils/constants";
import {showMessage} from "../common/NotificationSnack";
import {CircularProgress, FormHelperText} from "@material-ui/core";

const useStyles = makeStyles(theme => ({
    formControl: {
        display: 'block',
    },
    label: {
        width: '48%',
    },
    spinner: {
        display: 'flex',
        justifyContent: 'center',
        margin: theme.spacing(2),
    },
}));

export default function IssueTypeRadioGroup({className, value, error: errorText, onChange}) {
    const classes = useStyles();
    const [issueTypes, setIssueTypes] = useState([]);
    const [waiting, setWaiting] = useState(false);
    const error = !!errorText;

    useEffect(() => {
        setWaiting(true);
        doRequest(API_MASTER_ISSUE_TYPES,
            response => {setWaiting(false); setIssueTypes(response);},
            error => {setWaiting(false); showMessage(error, SEVERITY_ERROR);},
            {method: 'GET'})
    }, []);

    if (waiting) {
        return (
            <div className={clsx(classes.root, className)}>
                <div className={classes.spinner}>
                    <CircularProgress color="inherit" size={'4rem'}/>
                </div>
            </div>
        );
    }
    return (
        <div className={clsx(classes.root, className)}>
            <FormControl component="fieldset" error={error}>
                <FormLabel component="legend">{get('issue_type_helper')}</FormLabel>
                <RadioGroup className={classes.formControl} name="issue_type" value={!!value ? value.issueTypeId : ''}
                            onChange={event => onChange(issueTypes[event.target.value * 1 - 1])}>
                    {issueTypes.map(iType =>
                        <FormControlLabel className={classes.label} key={iType.issueTypeId}
                                          value={iType.issueTypeId} control={<Radio/>}
                                          label={get(`issue_type_name_${iType.issueTypeName}`)}/>
                    )}
                </RadioGroup>
                <FormHelperText>{errorText}</FormHelperText>
            </FormControl>
        </div>
    );
}

IssueTypeRadioGroup.propTypes = {
    className: PropTypes.string,
    error: PropTypes.string,
    value: PropTypes.shape({
        id: PropTypes.number,
        name: PropTypes.string,
    }),
    onChange: PropTypes.func.isRequired,
};