import React, {Component} from 'react'
import {withRouter} from 'react-router-dom'
import {compose} from 'recompose'
import {doPasswordUpdate} from '../../utils/firebase'
import Paper from "@material-ui/core/Paper"
import withStyles from '@material-ui/core/styles/withStyles'
import Typography from "@material-ui/core/Typography"
import TextField from "@material-ui/core/TextField"
import Button from "@material-ui/core/Button"
import Grid from '@material-ui/core/Grid'

const byPropKey = (propertyName, value) => () => ({
    [propertyName]: value,
});

const INITIAL_STATE = {
    password1: '',
    password2: '',
    error: null,
};

const styles = theme => ({
    form: {
        display: 'flex',
        justifyContent: 'center',
    },
    headline: {
        textAlign: 'center',
    },
    textField: {
        marginLeft: theme.spacing(),
        marginRight: theme.spacing(),
    },
    error: {
        fontSize: 'small',
        color: 'tomato'
    },
    paper: {
        margin: 10,
        padding: theme.spacing(3),
        textAlign: 'left',
        maxWidth: '600px',
        flex: '0 1 auto'
    }
});

class PassChangeComponent extends Component {
    constructor(props) {
        super(props);

        this.state = {...INITIAL_STATE};
    }

    onSubmit = (event) => {
        const { password1 } = this.state;

        doPasswordUpdate(password1)
            .then(() => {
                this.setState({ ...INITIAL_STATE });
            })
            .catch(error => {
                this.setState(byPropKey('error', error));
            });

        event.preventDefault();
    };

    render() {
        const {password1, password2, error} = this.state;
        const {classes} = this.props;

        const isInvalid = password1 !== password2 || password1 === '';

        return (
            <form onSubmit={this.onSubmit} className={classes.form}>
                <Paper className={classes.paper} elevation={1}>
                    <Typography variant="headline" component="h3" className={classes.headline}>
                        Entrada
                    </Typography>
                    <Grid container direction={"column"}>
                        <TextField
                            id='password1'
                            label="Contraseña"
                            className={classes.textField}
                            type="password"
                            value={password1}
                            onChange={event => this.setState(byPropKey('password1', event.target.value))}
                            margin="normal"
                        />
                        <TextField
                            id='password2'
                            label="Confirmar Contraseña"
                            className={classes.textField}
                            type="password"
                            value={password2}
                            onChange={event => this.setState(byPropKey('password2', event.target.value))}
                            margin="normal"
                        />
                        <Button
                            variant="contained"
                            color="primary"
                            disabled={isInvalid}
                            type={'submit'}
                        >
                            Enviar
                        </Button>
                        {error && <p className={classes.error}>{error.message}</p>}
                    </Grid>
                </Paper>
            </form>
        );
    }
}

export default compose(
    withStyles(styles),
    withRouter
)(PassChangeComponent);
