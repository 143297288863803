import React from 'react';
import PropTypes from 'prop-types';
import CheckAvailabilityComponent from "./CheckAvailabilityComponent";
import NewLoanSummaryComponent from "../NewLoanSummaryComponent";

function CheckAvailabilityComponentWrapper(props) {
    const {uid, item, response, startDate, endDate, notAvailable, error, handleUpdateDate, checkAvailability,
        reset, requestLoan, anonymousAction} = props;
    if (!!uid) {
        return (
            <>
                {!response ?
                    <CheckAvailabilityComponent startDate={startDate} endDate={endDate}
                                                notAvailable={notAvailable}
                                                checkAvailability={checkAvailability}
                                                handleUpdateDate={handleUpdateDate}
                                                error={error}
                    />
                    :
                    <NewLoanSummaryComponent item={item} startDate={startDate} endDate={endDate}
                                             error={error} response={response}
                                             reset={reset} requestLoan={requestLoan}/>
                }
            </>
        );
    }
    return (
        <CheckAvailabilityComponent startDate={startDate} endDate={endDate}
                                    notAvailable={false}
                                    checkAvailability={anonymousAction}
                                    handleUpdateDate={handleUpdateDate}
                                    error={''}
        />
    )
}

CheckAvailabilityComponentWrapper.propTypes = {
    uid: PropTypes.string,
    item: PropTypes.object.isRequired,
    response: PropTypes.object,
    startDate: PropTypes.object.isRequired,
    endDate: PropTypes.object.isRequired,
    notAvailable: PropTypes.bool,
    error: PropTypes.string,
    handleUpdateDate: PropTypes.func,
    checkAvailability: PropTypes.func,
    reset: PropTypes.func,
    anonymousAction: PropTypes.func,
};

export default CheckAvailabilityComponentWrapper;