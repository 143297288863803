import React from 'react';
import makeStyles from "@material-ui/core/styles/makeStyles";
import {Typography} from "@material-ui/core";
import {PALE_SAND, MARINE, DEFAULT_MAX_WIDTH} from "../../utils/constants";
import NewSubmissionDialog from "./NewSubmissionDialog";
import {get} from "../common/i18n/i18n";

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        maxWidth: DEFAULT_MAX_WIDTH,
        display: 'flex',
        flexFlow: 'wrap row',
        justifyContent: 'space-around',
        alignContent: 'center',
        alignItems: 'center',
        paddingTop: theme.spacing(),
        paddingBottom: theme.spacing(),
        background: MARINE,
        color: PALE_SAND,
    },
    text: {
        fontWeight: 'bold',
    },
}));

export default function LiveSearchNewSubmissionBanner(props) {
    const classes = useStyles();
    return (
        <div className={classes.root}>
            <div>
                <Typography className={classes.text}>{get('live_search_submission_banner_line_1')}</Typography>
                <Typography className={classes.text}>{get('live_search_submission_banner_line_2')}</Typography>
            </div>
            <div>
                <NewSubmissionDialog/>
            </div>
        </div>
    );
}