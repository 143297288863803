import React from 'react';
import PropTypes from 'prop-types';
import {Link} from 'react-router-dom';

import {makeStyles} from '@material-ui/core/styles'
import {Card, CardActionArea, CardContent, CardMedia, Grid, IconButton, Tooltip, Typography} from '@material-ui/core';
import {
    DEFAULT_PHOTO,
    MYUR_ITEM_PHOTO_ASPECT_RATIO,
    RED,
    BROWN, BLUE, MARINE
} from "../../utils/constants";
import {get} from "../common/i18n/i18n";
import EditIcon from "@material-ui/icons/Edit";
import ConfirmationDialog from "../common/ConfirmationDialog";
import DeleteIcon from "@material-ui/icons/Delete";
import EnableIcon from '@material-ui/icons/Visibility'
import {getPublicUrl} from "./item_utils";

const useStyles = makeStyles(theme => ({
    imageContainer: {
        position: "relative",
        width: "100%",
        paddingBottom: `${100/MYUR_ITEM_PHOTO_ASPECT_RATIO}%`,
        height: 0,
    },
    img: {
        width: "100%",
        height: "100%",
        position: "absolute",
        left: 0,
    },
    cardContent: {
        textAlign: 'left',
        padding: 0,
    },
    title: {
        color: RED,
    },
    origin: {
        color: BROWN,
    },
    distance: {
        fontWeight: "bold",
        color: theme.palette.common.white,
        position: "absolute",
        background: BLUE,
        borderRadius: theme.spacing(1),
        padding: theme.spacing(0, 1),
        top: theme.spacing(1),
        right: theme.spacing(1),
    },
    actions: {
        position: "absolute",
        background: 'rgba(0, 0, 0, 0.3)',
        borderRadius: theme.spacing(1),
        padding: theme.spacing(0, 1),
        top: theme.spacing(1),
        right: theme.spacing(1),
        display: "flex",
        flexFlow: "nowrap row",
        justifyContent: "space-between",
        zIndex: 2
    },
    nestedLink: {
        position: "absolute",
        left: 0, top: 0, bottom: 0, right: 0,
        zIndex: 1
    },
    action: {
        color: theme.palette.common.white
    },
    price: {
        fontWeight: "bold",
        color: theme.palette.common.white,
        position: "absolute",
        background: MARINE + 'AA', // to add alpha channel
        bottom: 0,
        left: 0,
        width: '100%',
    },
    disabled: {
        fontWeight: "bold",
        color: theme.palette.common.white,
        position: "absolute",
        top: "50%",
        //transform: "translateY(-60%)",
        background: RED + 'AA', // to add alpha channel
        left: 0,
        width: '100%',
    },
    banned: {
        fontWeight: "bold",
        color: theme.palette.common.white,
        position: "absolute",
        top: "30%",
        //transform: "translateY(-40%)",
        background: RED + 'AA', // to add alpha channel
        left: 0,
        width: '100%',
    },
}));

function getPhoto(item) {
    if (!!item.photo) {
        return item.photo;
    }
    if (Array.isArray(item.photos) && item.photos.length > 0) {
        return item.photos[0].photo;
    }
    if (Array.isArray(item.itemPhotos) && item.itemPhotos.length > 0) {
        return item.itemPhotos[0].photo;
    }
    return DEFAULT_PHOTO;
}

export default function ItemCard(props) {
    const {item, gridProps, itemEdit, itemEnable, itemDelete, onEditClicked, onEnableClicked, onDeleteClicked} = props;
    const classes = useStyles();
    const url = getPublicUrl(item);
    const enablePrefix = item.enabled ? 'disable' : 'enable';

    const formatter = get('currency_formatter');
    return (
        <Grid item {...gridProps} style={{position: "relative"}}>
            <Card raised={false} elevation={0} square={true}>
                <Link to={url} className={classes.nestedLink}/>
                <CardActionArea>
                    <div className={classes.imageContainer}>
                        <CardMedia
                            className={classes.img}
                            image={getPhoto(item)}
                            title={item.title}
                        />
                        {!!item.distance &&
                            <Typography variant={"caption"} className={classes.distance}>
                                a {item.distance < 0.1 ? '< ' : ''}
                                {get('pdistance_formatter').format(item.distance < 0.1 ? 0.1 : item.distance)} km
                            </Typography>
                        }
                        <div className={classes.actions}>
                        {
                            itemEdit &&
                            <Tooltip title={get('edit')}>
                                <IconButton aria-label={get('edit')} className={classes.action} size="small"
                                            onClick={onEditClicked}>
                                    <EditIcon fontSize="inherit"/>
                                </IconButton>
                            </Tooltip>
                        }
                        {
                            itemEnable &&
                            <ConfirmationDialog
                                title={get(`item_${enablePrefix}_item`)}
                                content={get(`item_${enablePrefix}_item_description`)}
                                onConfirmation={onEnableClicked}
                                size={'small'}
                                button={
                                    props =>
                                        <Tooltip title={get(`item_${enablePrefix}`)}>
                                            <IconButton aria-label={get(`item_${enablePrefix}`)} {...props} size={'small'} className={classes.action}>
                                                <EnableIcon fontSize="inherit"/>
                                            </IconButton>
                                        </Tooltip>
                                }/>
                        }
                        {
                            itemDelete &&
                            <ConfirmationDialog
                                title={get('delete_item')}
                                content={get('delete_item_description')}
                                onConfirmation={onDeleteClicked}
                                size={'small'}
                                button={
                                    props =>
                                        <Tooltip title={get('delete')}>
                                            <IconButton aria-label={get('delete')} {...props} size={'small'} className={classes.action}>
                                                <DeleteIcon fontSize="inherit"/>
                                            </IconButton>
                                        </Tooltip>
                                }/>
                        }
                        </div>
                        {
                            (item.enabled === false) &&
                            <Typography variant={"caption"} className={classes.disabled}>
                                {get("item_disabled")}
                            </Typography>
                        }
                        {
                            (item.banned === true) &&
                            <Typography variant={"caption"} className={classes.banned}>
                                {get("banned")}
                            </Typography>
                        }
                        <Typography variant={"caption"} className={classes.price}>
                            {
                                ['hourlyPrice', 'dailyPrice', 'weeklyPrice', 'monthlyPrice']
                                    .map(price =>
                                        !!item[price] ? formatter.format(item[price]) + get(`price_${price}`) : null
                                    ).filter(price => !!price).join(' | ')
                            }
                        </Typography>
                    </div>
                    <CardContent className={classes.cardContent}>
                        <Typography gutterBottom variant="h6" className={classes.title}>
                            {item.title}
                        </Typography>
                        <Typography gutterBottom variant="caption" component={'p'} className={classes.origin}>
                            {item.zipcode} - {item.location}
                        </Typography>
                    </CardContent>
                </CardActionArea>
            </Card>
        </Grid>
    );
}

ItemCard.propTypes = {
    item: PropTypes.object.isRequired,
    gridProps: PropTypes.object,
};