import React, {useEffect, useState} from 'react';
import {makeStyles} from "@material-ui/core/styles";
import {PropTypes} from 'prop-types';
import {useHistory} from 'react-router-dom';

import {Checkbox, FormControlLabel, Paper, TextField} from "@material-ui/core";

import AsyncSelect from "../common/AsyncSelect";
import clsx from "clsx";
import {get} from "../common/i18n/i18n";
import {
    API_MASTER_VEHICLE_BOAT_SUBTYPES_BY_TYPE,
    API_MASTER_VEHICLE_BOAT_TYPES_BY_CATEGORY, SAND
} from "../../utils/constants";
import {buildUrl} from "../../utils/http";
import {VEHICLE_TEMPLATE} from "./itemTemplate";
import {ROUTE_PROFILE} from "../../utils/routes";
import {useSelector} from "react-redux";
import layoutStyles from "../common/styles/layoutStyles";
import VehicleDialog from "./VehicleDialog";

const useStyles = makeStyles(theme => ({
    ...layoutStyles(theme),
    root: {
        marginTop: theme.spacing(1),
    },
    select: {
        '&:last-of-type': {
            marginRight: theme.spacing(1),
        },
        [theme.breakpoints.down('md')]: {
            marginTop: theme.spacing(1),
            marginRight: theme.spacing(1),
        },
    },
    formElement: {
        '&:first-of-type': {
            marginLeft: theme.spacing(1),
        },
        '&:last-of-type': {
            marginRight: theme.spacing(1),
        },
        [theme.breakpoints.down('md')]: {
            width: '100%',
            margin: theme.spacing(0, 1),
        },
    },
    row: {
        display: 'flex',
        flexFlow: 'row wrap',
        justifyContent: 'space-between',
        alignItems: 'baseline',
        width: '100%',
    },
    paper: {
        margin: theme.spacing(1),
        padding: theme.spacing(1),
        backgroundColor: SAND,
    }
}));

export default function VehicleForm(props) {
    const {category, vehicle, onUpdate, validationErrors} = props;
    const classes = useStyles();
    const [type, setType] = useState(null);
    const [subType, setSubType] = useState(null);
    const [imPro, setImPro] = useState(false);
    const [withDriver, setWithDriver] = useState(false);
    const history = useHistory();
    const user = useSelector(state => state.session.profile);

    useEffect(() => {
        setType(null);
        setSubType(null);
    }, [category.categoryId]);

    useEffect ( () => {
        if (!!user && !!user.companyId) {
            setImPro(true);
        }
    }, [user]);

    // This is quite complex because boats and vehicles always fill subtype even if subtype is not available, so we
    // may need to update this value from the parent component when the check of the subtype has been done. On the other
    // hand, as long as current component state updates are sent to the parent component and then they come back, we
    // have to perform another check not to overwrite current type/subtype value, which contains additional information.
    // Yes, it is a mess and it may be necessary a refactor of this code.
    useEffect(() => {
        if (!vehicle) {
            setType(null);
            setSubType(null);
            return;
        }

        if ((!type && !!vehicle.type)
            || (!!vehicle.type && !!type && vehicle.type.typeName !== type.typeName)) {
            setType(vehicle.type);
        } else if (!vehicle.type) {
            setType(null);
        }
        if ((!subType && vehicle.subtype)
            || (!!vehicle.subtype && vehicle.subtype.typeName !== subType.typeName)) {
            setSubType(vehicle.subtype);
        } else if (!vehicle.subtype) {
            setSubType(null);
        }
        // useEffect does not do deep object comparison
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [vehicle, vehicle.type, vehicle.subtype]);

    const updateType = type => {
        setType(type);
        onUpdate("type", type);
    }

    const updateSubType = subType => {
        setSubType(subType);
        onUpdate("subtype", subType);
    }

    return (
        <div className={classes.root}>
            <div className={classes.row}>
                <AsyncSelect
                    className={clsx(classes.half, classes.select, classes.zTwo)}
                    isClearable={true}
                    selected={type}
                    optionLabel={option => get(option.typeName)}
                    optionValue={option => option}

                    retrieveUrl={buildUrl(API_MASTER_VEHICLE_BOAT_TYPES_BY_CATEGORY, {subCategoryId: category.categoryId})}
                    title={get('select_vehicle_type')}
                    onSelect={updateType}
                    disabled={!category.categoryId}
                    error={validationErrors.vType !== ''}
                    helperText={validationErrors.vType}
                />
                {((!!subType && !!subType.typeName && !!type) || (!!type && type.hasChildren)) &&
                    <AsyncSelect
                        className={clsx(classes.half, classes.select)}
                        isClearable={true}
                        selected={subType}
                        optionLabel={option => get(option.typeName)}
                        optionValue={option => option}

                        retrieveUrl={buildUrl(API_MASTER_VEHICLE_BOAT_SUBTYPES_BY_TYPE, {parentTypeName: type.typeName})}
                        title={get('select_subtype')}
                        value={subType}
                        onSelect={updateSubType}
                        disabled={!type || (!subType && !!type && !type.hasChildren)}
                        error={validationErrors.vSubtype !== ''}
                        helperText={validationErrors.vSubtype}
                    />
                }
            </div>
            <div className={classes.row}>
                <FormControlLabel
                    control={
                        <Checkbox
                            className={classes.formElement}
                            disabled={!!user && !!user.companyId}
                            checked={imPro}
                            onChange={e => setImPro(e.target.checked)}
                        />
                    }
                    label={get('car_im_pro')}
                />
            </div>
            <div className={classes.row}>
                <FormControlLabel
                    control={
                        <Checkbox
                            className={classes.formElement}
                            checked={withDriver}
                            onChange={e => setWithDriver(e.target.checked)}
                        />
                    }
                    label={get(`rental_with_${category.rentalWith}`)}
                />
            </div>
            <Paper className={classes.paper}>
                <div className={classes.row}>
                    <TextField id="brand" label={get('car_brand')} className={classes.formElement} required
                               onChange={event => onUpdate(event.target.id, event.target.value)}
                               value={!!vehicle ? vehicle.brand : VEHICLE_TEMPLATE.brand}
                               error={validationErrors.vBrand !== ''}
                               helperText={validationErrors.vBrand}
                    />
                    <TextField id="model" label={get('car_model')} className={classes.formElement} required
                               onChange={event => onUpdate(event.target.id, event.target.value)}
                               value={!!vehicle ? vehicle.model : VEHICLE_TEMPLATE.model}
                               error={validationErrors.vModel !== ''}
                               helperText={validationErrors.vModel}
                    />
                    <TextField id="licencePlate" label={get('car_plate')} className={classes.formElement} required
                               onChange={event => onUpdate(event.target.id, event.target.value)}
                               value={!!vehicle ? vehicle.licencePlate : VEHICLE_TEMPLATE.licencePlate}
                               error={validationErrors.vLicencePlate !== ''}
                               helperText={validationErrors.vLicencePlate}
                    />
                    <TextField label={get('car_country_plate')} className={classes.formElement} required
                               value={!!vehicle ? vehicle.countryLicencePlate : VEHICLE_TEMPLATE.countryLicencePlate} disabled={true}/>
                </div>
            </Paper>
            <VehicleDialog key={'car_im_pro_dialog'}
                           open={!!user && !user.companyId && imPro}
                           handleClose={() => setImPro(false)}
                           title={get('car_im_pro')}
                           contentText={get('car_im_pro_helper')}
                           buttonText={get('ADD')}
                           buttonAction={() => history.push(ROUTE_PROFILE, {defaultExpanded: 'business_area'})}
            />
            <VehicleDialog key={`rental_with_${category.rentalWith}`} open={withDriver} handleClose={() => setWithDriver(false)}
                           title={get(`rental_with_${category.rentalWith}`)}
                           contentText={get(`rental_with_${category.rentalWith}_helper`)}
            />
        </div>
    )
}

VehicleForm.propTypes = {
    category: PropTypes.shape(
        {
            categoryId: PropTypes.number,
            rentalWith: PropTypes.string,
        }
    ),
    onUpdate: PropTypes.func.isRequired,
    vehicle: PropTypes.shape(
        {
            type: PropTypes.oneOfType([
                PropTypes.string,
                PropTypes.object
            ]),
            subtype: PropTypes.oneOfType([
                PropTypes.string,
                PropTypes.object
            ]),
            brand: PropTypes.string,
            model: PropTypes.string,
            licencePlate: PropTypes.string,
            countryLicencePlate: PropTypes.string,
        }
    ),
    validationErrors: PropTypes.object,
};

VehicleForm.defaultProps = {
    vehicle: {...VEHICLE_TEMPLATE},
    category: {
        categoryId: undefined,
        rentalWith: undefined,
    }
}