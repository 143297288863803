import React from 'react'

import {PropTypes} from 'prop-types'

import {IconButton, Paper, Tooltip, Typography} from "@material-ui/core";
import {
    PALE_SAND,
    BLUE,
    DEFAULT_PHOTO,
} from "../../../utils/constants";
import DeleteIcon from '@material-ui/icons/Delete'
import EditIcon from '@material-ui/icons/Edit'
import EnableIcon from '@material-ui/icons/Visibility'
import DisableIcon from '@material-ui/icons/VisibilityOff'
import {get} from "../../common/i18n/i18n";
import {makeStyles} from "@material-ui/styles";
import ConfirmationDialog from "../../common/ConfirmationDialog";
import {Link} from "react-router-dom";
import clsx from "clsx";
import layoutStyles from "../../common/styles/layoutStyles";
import {getPublicUrl} from "../../item/item_utils";

const useStyles = makeStyles(theme => ({
    ...layoutStyles(theme),
    photo: {
        height: '160px',
        borderRadius: '4px',
        marginRight: theme.spacing(2),
    },
    paper: {
        display: 'flex',
        flexFlow: 'no-wrap row',
        justifyContent: 'flex-start',
        backgroundColor: PALE_SAND,
        marginBottom: theme.spacing(1),
    },
    info: {
        textAlign: 'left',
        width: '95%',
    },
    first_line: {
        display: 'flex',
        flexFlow: 'no-wrap row',
        justifyContent: 'space-around',
        alignItems: 'center',
    },

    title: {
        marginTop: theme.spacing(1),
        color: BLUE,
        fontWeight: 'bold',
        flexGrow: 1,
        textAlign: 'left',
    },
    action: {
        margin: theme.spacing(0),
        color: BLUE,
    },
}));

function getPhoto(item) {
    if (!!item.photo) {
        return item.photo;
    }
    if (Array.isArray(item.photos) && item.photos.length > 0) {
        return item.photos[0].photo;
    }
    return DEFAULT_PHOTO;
}

export default function ItemCard(props) {
    const {item, ItemPropertiesComponent, onEditClicked, onEnableClicked, onDeleteClicked,
        itemDelete, itemEnable, itemEdit, children} = props;
    const classes = useStyles();

    const photo = getPhoto(item);
    const enablePrefix = item.enabled ? 'disable' : 'enable';
    const Enable = item.enabled ? DisableIcon : EnableIcon;
    return (
        <React.Fragment>
            <Paper className={classes.paper}>
                <img className={classes.photo} src={photo} alt={item.title}/>
                <div className={classes.info}>
                    <div className={classes.first_line}>
                        <Typography variant={"h6"} className={clsx(classes.link, classes.title)} component={Link} to={getPublicUrl(item)}>
                            {item.title}
                        </Typography>
                        {
                            itemEdit &&
                            <Tooltip title={get('edit')}>
                                <IconButton aria-label={get('edit')} className={classes.action} size="small"
                                            onClick={onEditClicked}>
                                    <EditIcon fontSize="inherit"/>
                                </IconButton>
                            </Tooltip>
                        }
                        {
                            itemEnable &&
                            <ConfirmationDialog
                                title={get(`item_${enablePrefix}_item`)}
                                content={get(`item_${enablePrefix}_item_description`)}
                                onConfirmation={onEnableClicked}
                                size={'small'}
                                button={
                                    props =>
                                        <Tooltip title={get(`item_${enablePrefix}`)}>
                                            <IconButton aria-label={get(`item_${enablePrefix}`)} {...props} size={'small'} className={classes.action}>
                                                <Enable fontSize="inherit"/>
                                            </IconButton>
                                        </Tooltip>
                                }/>
                        }
                        {
                            itemDelete &&
                            <ConfirmationDialog
                                title={get('delete_item')}
                                content={get('delete_item_description')}
                                onConfirmation={onDeleteClicked}
                                size={'small'}
                                button={
                                    props =>
                                        <Tooltip title={get('delete')}>
                                            <IconButton aria-label={get('delete')} {...props} size={'small'} className={classes.action}>
                                                <DeleteIcon fontSize="inherit"/>
                                            </IconButton>
                                        </Tooltip>
                                }/>
                        }
                    </div>
                    <ItemPropertiesComponent item={item}/>
                    {children}
                </div>
            </Paper>
        </React.Fragment>
    )
}

ItemCard.propTypes = {
    item: PropTypes.object.isRequired,
    itemDelete: PropTypes.bool.isRequired,
    itemEnable: PropTypes.bool.isRequired,
    itemEdit: PropTypes.bool.isRequired,
    ItemPropertiesComponent: PropTypes.func.isRequired,
    onEditClicked: PropTypes.func,
    onEnableClicked: PropTypes.func,
    onDeleteClicked: PropTypes.func,
};

ItemCard.defaultProps = {
    itemDelete: true,
    itemEnable: true,
    itemEdit: true,
};