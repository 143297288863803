import React from 'react';
import PropTypes from 'prop-types';
import {Helmet} from "react-helmet";
import {MYUR_BASE_ROUTE} from "../../utils/constants";
import {getPublicUrl} from "./item_utils";

ItemMetaInfo.propTypes = {
    item: PropTypes.object.isRequired
};

function ItemMetaInfo({item}) {
    const baseUrl = `${window.location.protocol}//${window.location.host}/${MYUR_BASE_ROUTE}`;
    return (
        <Helmet>
            <title>{item.title}</title>
            <meta name="description" content={item.description} />
            <meta property="og:type" content="myur:item" />
            <meta property="og:title" content={item.title} />
            <meta property="og:description" content={item.description} />
            <meta property="og:url" content={baseUrl + getPublicUrl(item)} />
            <meta property="og:image" content={item.itemPhotos[0]?.photo} />
        </Helmet>
    );
}

export default ItemMetaInfo;