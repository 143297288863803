import {makeStyles} from "@material-ui/styles";
import {BLUE, SAND} from "../../../utils/constants";

const useFavouriteStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexFlow: 'no-wrap row',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: theme.spacing(1),
    },
    border: {
        borderTop: `2px solid ${SAND}`,
    },
    icon: {
        height: 32,
        borderRadius: '4px',
        margin: theme.spacing(),
        cursor: "pointer"
    },
    info: {
        display: 'flex',
        flexFlow: 'no-wrap row',
        justifyContent: 'flex-start',
        alignItems: 'center',
        textAlign: 'left',
    },
    link: {
        textDecoration: 'none',
        marginLeft: theme.spacing(2)
    },
    title: {
        marginTop: theme.spacing(),
        color: BLUE,
        fontWeight: 'bold',
    },
}));

export default useFavouriteStyles;