import React from 'react'
import {PropTypes} from 'prop-types'

import {Typography, Button, makeStyles, Paper} from '@material-ui/core'

import {
    BLUE, PALE_SAND, DEFAULT_MAX_WIDTH, MAX_LOAN_PHOTOS,
} from "../../utils/constants";
import OwnerToBorrower from "./OwnerToBorrower";
import buttonStyles from "../common/styles/buttonStyles";
import clsx from "clsx";
import ComponentWrapper from "../common/ComponentWrapper";
import ImageCard from "../common/image/ImageCard";
import CameraIcon from "@material-ui/icons/CameraAlt";

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        maxWidth: DEFAULT_MAX_WIDTH,
        backgroundColor: PALE_SAND,
    },
    ownerToBorrower: {
        justifyContent: 'center',
    },
    title: {
        marginTop: theme.spacing(),
        color: BLUE,
        fontWeight: 'bold',
    },
    imageContainer: {
        display: 'flex',
        flexFlow: 'row wrap',
        marginTop: theme.spacing(1),
        justifyContent: 'center',
        padding: theme.spacing(1),
    },
    imagePaper: {
        width: 210,
        height: 175,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        margin: theme.spacing(1),
    },
    camera: {
        margin: theme.spacing(2),
        fontSize: '6em',
        color: BLUE,
    },
    button: {
        margin: theme.spacing(2),
        fontWeight: 'bold',
        color: BLUE,
    },
    ...buttonStyles(theme),
}));

export default function LoanStartEndComponent(props) {
    const classes = useStyles();
    const {className, loan, data, loading, images,
        handleAddPhoto, handleDeletePhoto} = props;

    return (
        <ComponentWrapper title={data.what} loading={loading} className={clsx(classes.root, className)}>
            <OwnerToBorrower
                className={classes.ownerToBorrower}
                owner={{avatar: loan.ownerAvatar, nickname: loan.ownerNickname}}
                borrower={{avatar: loan.borrowerAvatar, nickname: loan.borrowerNickname}}
            />
            <div>
                <Typography variant={'body1'} className={classes.title}>
                    {data.now}
                </Typography>
            </div>

            <div className={classes.imageContainer}>
                {
                    images.map(
                        photo =>
                            <ImageCard
                                key={photo.photoOrder}
                                photo={photo}
                                deleteOption={true}
                                handleDelete={() => handleDeletePhoto(photo)}
                            />
                    )
                }
                {
                    images.length < MAX_LOAN_PHOTOS && (
                        <label htmlFor={'file_image'}>
                            <Paper className={classes.imagePaper}>
                                <input
                                    hidden={true}
                                    type={'file'}
                                    name={'image'}
                                    id={'file_image'}
                                    onChange={handleAddPhoto}
                                    accept={'image/png, image/jpeg'}
                                />
                                <CameraIcon className={classes.camera}/>
                            </Paper>
                        </label>
                    )
                }
            </div>
            <div>
                <Typography variant={"body1"} className={classes.title}>
                    {data.and}
                </Typography>
                <Typography variant={"caption"} className={classes.title}>
                    {data.other}
                </Typography>
            </div>
            <div>
                <Button variant={'contained'} className={classes.blueButton} onClick={data.action}>
                    {data.done}
                </Button>
            </div>
        </ComponentWrapper>
    )
}

LoanStartEndComponent.propTypes = {
    className: PropTypes.string,
    loan: PropTypes.object.isRequired,
    data: PropTypes.object,
    loading: PropTypes.bool,
    images: PropTypes.array,
    handleAddPhoto: PropTypes.func,
    handleDeletePhoto: PropTypes.func,
    onButtonClicked: PropTypes.func,
};