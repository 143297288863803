import {
    ADD_NOTIFICATIONS_PENDING_ACTION, ADD_MESSAGES_PENDING_ACTION, RELOAD_MESSAGES_PENDING_ACTION,
    RELOAD_NOTIFICATIONS_PENDING_ACTION, UPDATED_MESSAGES_PENDING_ACTION, UPDATED_NOTIFICATIONS_PENDING_ACTION
} from "./actionTypes";

import {buildUrl, doRequest} from "../utils/http";
import {API_CHAT_PENDING, API_NOTIFICATION_PENDING, SEVERITY_ERROR} from "../utils/constants";
import {showMessage} from "../components/common/NotificationSnack";

const addNotificationsPendingActionCreator = count => ({
    type: ADD_NOTIFICATIONS_PENDING_ACTION,
    count,
});

const addMessagePendingActionCreator = count => ({
    type: ADD_MESSAGES_PENDING_ACTION,
    count,
});

const reloadNotificationsPendingActionCreator = uid => ({
    type: RELOAD_NOTIFICATIONS_PENDING_ACTION,
});

const reloadMessagesPendingActionCreator = uid => ({
    type: RELOAD_MESSAGES_PENDING_ACTION,
});

const updatedNotificationsPendingActionCreator = notificationsPending => ({
    type: UPDATED_NOTIFICATIONS_PENDING_ACTION,
    notificationsPending,
});

const updatedMessagesPendingActionCreator = messagesPending => ({
    type: UPDATED_MESSAGES_PENDING_ACTION,
    messagesPending,
});

export const addNotificationsPending = count => dispatch => {
    dispatch(addNotificationsPendingActionCreator(count));
}

export const addMessagesPending = count => dispatch => {
    dispatch(addMessagePendingActionCreator(count));
}

export const reloadNotificationsPending = uid => dispatch => {
    dispatch(reloadNotificationsPendingActionCreator(uid));
    return retrieveNotificationsPending(uid, dispatch);
}

const retrieveNotificationsPending = (uid, dispatch) => {
    doRequest(
        buildUrl(API_NOTIFICATION_PENDING, {uid}),
        response =>
            dispatch(updatedNotificationsPendingActionCreator(response.pendingNotifications)),
        error => showMessage(error, SEVERITY_ERROR),
        {method: 'GET'});
}

export const reloadMessagesPending = uid => dispatch => {
    dispatch(reloadMessagesPendingActionCreator(uid));
    return retrieveMessagesPending(uid, dispatch);
}

const retrieveMessagesPending = (uid, dispatch) => {
    doRequest(
        buildUrl(API_CHAT_PENDING, {uid}),
        response =>
            dispatch(updatedMessagesPendingActionCreator(response.message_count)),
        error => showMessage(error, SEVERITY_ERROR),
        {method: 'GET', auth: 'bearer'});
}