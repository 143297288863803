import React, {useState} from 'react';
import PropTypes from 'prop-types'

import {Button, Grid, TextField, Typography} from '@material-ui/core'

import {get} from "../../common/i18n/i18n";
import {openImageDialog} from "../../common/image/ImageDialog";
import clsx from "clsx";
import {DEFAULT_AVATAR, MAX_USER_DESCRIPTION_CHAR} from "../../../utils/constants";
import {validate_zipcode} from "../user_validation";
import {makeStyles} from "@material-ui/core/styles";


const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        display: 'flex',
        flexFlow: 'wrap row',
        justifyContent: 'flex-end',
        [theme.breakpoints.down('sm')]: {
            justifyContent: 'center',
        }
    },
    form: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'center',
    },
    headline: {
        textAlign: 'center',
    },
    textField: {
        width: '90%',
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
    },
    error: {
        fontSize: 'small',
        color: 'tomato',
    },
    column: {
        flexBasis: '30%',
        [theme.breakpoints.down('sm')]: {
            flexBasis: '100%',
        }
    },
    helper: {
        [theme.breakpoints.up('md')]: {
            borderLeft: `2px solid ${theme.palette.divider}`,
            padding: `${theme.spacing(1)}px ${theme.spacing(1)}px`,
        }
    },
    button: {
        margin: theme.spacing(1),
    },
}));

export default function BasicUserProfileForm({user, avatarUrl, onUpdate}) {
    const classes = useStyles();
    const [zipcodeError, setZipcodeError] = useState('');

    if (!user) {
        return (
            <Typography>{get('loading_data')}</Typography>
        );
    }

    const checkZipcode = () => {
        const error = validate_zipcode(user.zipCode);
        setZipcodeError(error);
    }

    return (
        <div className={classes.root}>
            <div className={classes.column}>
                <Grid container direction={"column"} alignItems={'center'}>
                    <TextField
                        id='nickname'
                        label={get('nickname')}
                        className={classes.textField}
                        value={user.nickname}
                        margin="normal"
                        disabled
                    />
                    <TextField
                        id='zipcode'
                        label={get('zipcode')}
                        className={classes.textField}
                        value={user.zipCode}
                        onChange={event => onUpdate('zipCode', event.target.value)}
                        error={!!zipcodeError}
                        helperText={zipcodeError}
                        margin="normal"
                        onBlur={checkZipcode}
                    />
                    <TextField
                        id='location'
                        label={get('location')}
                        className={classes.textField}
                        value={user.location || ''}
                        onChange={event => onUpdate('location', event.target.value)}
                        margin="normal"
                    />
                    <TextField
                        id='description'
                        label={get('user_description')}
                        className={classes.textField}
                        value={user.description || ''}
                        onChange={event => onUpdate('description', event.target.value)}
                        margin="normal"
                        inputProps={{
                            maxLength: MAX_USER_DESCRIPTION_CHAR
                        }}
                        helperText={get('user_description_helper', [MAX_USER_DESCRIPTION_CHAR])}
                        error={!!user.description && user.description.length > MAX_USER_DESCRIPTION_CHAR}
                    />
                </Grid>
            </div>
            <div className={clsx(classes.column, classes.helper)}>
                <Typography variant={'h6'}>{get('user_profile_image')}</Typography>
                <img alt={user.nickname} src={avatarUrl || DEFAULT_AVATAR} width={200}/>
                <div>
                    <Button className={classes.button} onClick={() => openImageDialog()}>
                        {get('change_image')}
                    </Button>
                </div>
            </div>
        </div>
    );
}

BasicUserProfileForm.propTypes = {
    user: PropTypes.object.isRequired,
    avatarUrl: PropTypes.string,
    onUpdate: PropTypes.func.isRequired,
};
