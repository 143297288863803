import React, {Component} from 'react';
import {Link, withRouter} from 'react-router-dom';
import {connect} from "react-redux";
import {compose} from "recompose";
import uuid from "uuid/v4";
import mime from "mime-types";
import clsx from 'clsx';

import {FormControlLabel, TextField, Checkbox, Button, Paper, Typography, CircularProgress} from '@material-ui/core'
import withStyles from "@material-ui/core/styles/withStyles";

import {doFetch} from "../../utils/http";
import {ROUTE_PROFILE, ROUTE_SIGN_UP} from '../../utils/routes';
import {ADD_USER, ADD_USER_AVATAR, MARINE} from "../../utils/constants";
import {doCreateUserWithEmailAndPassword} from "../../utils/firebase";

import ImageDialog, {openImageDialog} from "../common/image/ImageDialog";
import {retrieveUserPublicProfile} from "../../actions/sessionActions";
import {green} from "@material-ui/core/colors";
import {get} from "../common/i18n/i18n";
import layoutStyles from "../common/styles/layoutStyles";

const styles = theme => ({
    root: {
        display: 'flex',
        flexFlow: 'wrap row',
    },
    form: {
        display: 'flex',
        flexFlow: 'wrap column',
        justifyContent: 'flex-start',
        alignItems: 'center',
        marginRight: theme.spacing(),
    },
    headline: {
        textAlign: 'center',
        marginBottom: theme.spacing(),
    },
    textField: {
        marginLeft: theme.spacing(),
        marginRight: theme.spacing(),
    },
    error: {
        fontSize: 'small',
        color: 'tomato'
    },
    imageContainer: {
        display: 'flex',
        flexFlow: 'wrap column',
        justifyContent: 'flex-start',
        alignItems: 'center',
        borderLeft: `2px solid ${theme.palette.divider}`,
        padding: `${theme.spacing()}px ${theme.spacing(2)}px`,
        marginLeft: theme.spacing(),
    },
    imageButton: {
        marginTop: theme.spacing(),
    },
    image: {
        marginBottom: theme.spacing(),
    },
    over8teen: {
        display: 'flex',
        justifyContent: 'center',
    },
    paper: {
        margin: 10,
        padding: theme.spacing(3),
        textAlign: 'left',
        minWidth: '300px',
        maxWidth: '600px',
    },
    imagePaper: {
        width: 200,
        height: 200,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginRight: theme.spacing(),
        marginBottom: theme.spacing(),
    },
    wrapper: {
        margin: theme.spacing(),
        position: 'relative',
    },
    buttonSuccess: {
        backgroundColor: green[500],
        '&:hover': {
            backgroundColor: green[700],
        },
    },
    buttonProgress: {
        color: green[500],
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
    },
});

const INITIAL_STATE = {
    email: '',
    passwordOne: '',
    passwordTwo: '',
    over8teen: false,
    username: '',
    zipcode: '',
    avatar: undefined,
    avatarUrl: undefined,
    saving: false,
    error: null,
};

class SignUpCompoment extends Component {
    constructor(props) {
        super(props);
        this.state = {...INITIAL_STATE};
    }

    onUpdate = event => this.setState({[event.target.id]: event.target.value});

    onSubmit = event => {
        const {email, passwordOne, over8teen, username, zipcode, avatar} = this.state;
        this.setState({saving: true, success: false});
        doCreateUserWithEmailAndPassword(email, passwordOne)
            .then(authUser => {
                this.setState(() => ({...INITIAL_STATE}));
                const body = {
                    adult: over8teen,
                    email,
                    nickname: username,
                    uid: authUser.user.uid,
                    zipCode: zipcode
                };
                if (!avatar) {
                    doFetch(ADD_USER,
                        this.onUserCreated,
                        this.onError,
                        {method: 'POST', body: JSON.stringify(body)}
                    );
                } else {
                    const formData = new FormData();
                    const fileName = uuid() + '.' + mime.extension(avatar.type);
                    const userAuthDTO = new Blob([JSON.stringify(body)], {type: "application/json"});
                    formData.append('file', avatar, fileName);
                    formData.append('userAuthDTO', userAuthDTO);
                    doFetch(ADD_USER_AVATAR,
                        this.onUserCreated,
                        this.onError,
                        {method: 'POST', body: formData});
                }
            })
            .catch(error => {
                this.onError(error);
            });

        event.preventDefault();
    };

    onUserCreated = user => {
        this.setState({saving: false, success: true});
        this.props.history.push(ROUTE_PROFILE);
        this.props.retrieveUserPublicProfile(user.uid);
    };

    onError = error => {
        this.setState({
            saving: false,
            success: false,
            error,
        })
    };

    render() {
        const {classes} = this.props;
        const {username, over8teen, email, zipcode, passwordOne, passwordTwo, avatarUrl,
            error, saving, success} = this.state;

        const isInvalid =
            passwordOne !== passwordTwo ||
            passwordOne === '' ||
            email === '' ||
            username === '' ||
            over8teen === false;

        const buttonClassname = clsx({
            [classes.buttonSuccess]: success,
        });

        return (
            <React.Fragment>
            <form onSubmit={this.onSubmit} className={classes.form}>
                <Paper className={classes.paper}>
                    <Typography variant="h5" component="h3" className={classes.headline}>
                        Crear cuenta
                    </Typography>
                    <div className={classes.root}>
                        <div className={classes.form}>
                            <TextField
                                id='username'
                                label="nombre de usuario"
                                className={classes.textField}
                                value={username}
                                onChange={this.onUpdate}
                                margin="normal"
                            />
                            <TextField
                                id='email'
                                label="email"
                                className={classes.textField}
                                value={email}
                                onChange={this.onUpdate}
                                margin="normal"
                            />
                            <TextField
                                id='passwordOne'
                                label="password"
                                className={classes.textField}
                                type="password"
                                value={passwordOne}
                                onChange={this.onUpdate}
                                margin="normal"
                            />

                            <TextField
                                id='passwordTwo'
                                label="repite el password"
                                className={classes.textField}
                                type="password"
                                value={passwordTwo}
                                onChange={this.onUpdate}
                                margin="normal"
                            />

                            <TextField
                                id='zipcode'
                                label="código postal"
                                className={classes.textField}
                                value={zipcode}
                                onChange={this.onUpdate}
                                margin="normal"
                            />
                        </div>
                        <div className={classes.imageContainer}>
                            <Typography variant={'subtitle1'}>Imagen de perfil</Typography>
                            {!!avatarUrl ?
                                <img alt={username} src={avatarUrl} width={200} className={classes.image}/>
                                :
                                <Paper className={classes.imagePaper}/>
                            }
                            <Button variant={"outlined"} className={classes.imageButton}
                                    onClick={() => openImageDialog()}>
                                Cambiar imagen
                            </Button>
                            <ImageDialog title={'Imagen de perfil'} onImageSelected={this.updateAvatar}/>
                        </div>
                    </div>
                    <FormControlLabel
                        className={classes.over8teen}
                        control={
                            <Checkbox
                                checked={over8teen}
                                onChange={event => this.setState({over8teen: event.target.checked})}
                                value="over8teen"
                            />
                        }
                        label="soy mayor de edad"
                    />

                    {error && <p>{error.message}</p>}
                </Paper>
                <div className={classes.wrapper}>
                    <Button
                        variant="contained"
                        color="primary"
                        className={buttonClassname}
                        disabled={isInvalid || saving}
                        type={'submit'}
                    >
                        {get("send")}
                    </Button>
                    {saving && <CircularProgress size={24} className={classes.buttonProgress} />}
                </div>
            </form>
            </React.Fragment>
        );
    }

    updateAvatar = (avatar, url) => {
        window.URL.revokeObjectURL(this.state.avatarUrl);

        const avatarUrl = !avatar ? url : window.URL.createObjectURL(avatar);

        this.setState({
            avatar,
            avatarUrl,
        })
    };
}

export const SignUpLink = withStyles(theme => ({
    ...layoutStyles(theme),
    root: {
        margin: theme.spacing(2, 0),
    },
    marine: {
        color: MARINE,
    },
}))(({classes}) =>
    <div className={classes.root}>
        <Typography variant={"h5"} display={"inline"} className={classes.white}>
            {get('auth_no_account')}
        </Typography>
        {' '}
        <Typography variant={"h5"} component={Link} to={ROUTE_SIGN_UP} className={clsx(classes.link, classes.marine)}>
            {get('auth_create_account')}
        </Typography>
    </div>
);

const mapDispatchToProps = (dispatch) => ({
    retrieveUserPublicProfile: uid => dispatch(retrieveUserPublicProfile(uid)),
});

export default compose(
    withStyles(styles),
    connect(null, mapDispatchToProps),
    withRouter
)(SignUpCompoment);