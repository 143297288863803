import {
    GOT_SEARCH_ITEMS_ERROR_ACTION, GOT_SEARCH_ITEMS_RESPONSE_ACTION, SEARCH_ITEM_ACTION, UPDATE_GEOPOSITION_ACTION
} from "../actions/actionTypes";
import {SEARCH_COUNT_ITEMS} from "../utils/constants";

const INITIAL_STATE = {
    what: '',
    where: null,
    startDate: null,
    endDate: null,
    start: 0,
    count: SEARCH_COUNT_ITEMS,
    searching: false,
    response: null,
    numFound: 0,
    items: [],
    error: null,
    lat: null,
    lng: null,
};

export default function searchItems(state = {...INITIAL_STATE}, action) {
    switch(action.type) {
        case SEARCH_ITEM_ACTION : {
            return {
                ...state,
                what: action.what,
                where: action.where,
                startDate: action.startDate,
                endDate: action.endDate,
                start: action.start,
                count: action.count,
                numFound: 0,
                items: state.items,
                searching: true,
                error: null,
            }
        }
        case GOT_SEARCH_ITEMS_RESPONSE_ACTION: {
            const numFound = action.response.numFound;
            const items = action.response.items.length > state.count
                ? action.response.items.splice(0, state.count)
                : action.response.items;
            return {
                ...state,
                items: state.start === 0 ? items : [...state.items, ...items],
                numFound,
                response: action.response,
                searching: false,
                error: null,
            }
        }
        case GOT_SEARCH_ITEMS_ERROR_ACTION: {
            return {
                ...state,
                searching: false,
                error: action.error,
            }
        }
        case UPDATE_GEOPOSITION_ACTION: {
            return {
                ...state,
                lat: action.lat,
                lng: action.lng,
            }
        }
        default : return state;
    }
}

export const getOrigin = state => ({lat: state.lat, lng: state.lng});