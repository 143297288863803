import React from 'react'
import {PropTypes} from 'prop-types';
import {Paper, Typography, IconButton, Divider} from "@material-ui/core";
import DeleteIcon from '@material-ui/icons/Delete'
import {withStyles} from "@material-ui/core/styles";
import {get} from "../../common/i18n/i18n";

const styles = theme => ({
    root: {
        margin: theme.spacing(),
        padding: theme.spacing(),
        minWidth: 180,
        textAlign: 'left',
    },
    divider: {
        marginBottom: theme.spacing(1),
    },
    first_line: {
        display: 'flex',
        flexFlow: 'nowrap row',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    delete: {
        margin: theme.spacing(0),
    },
});

function PaymentCard(props) {
    const {paymentMethod, handleDelete, classes} = props;

    const def = paymentMethod.defaultMethod === true ? 'sí' : 'no';
    const verified = paymentMethod.verified === true ? 'sí' : 'no';
    return (
        <Paper className={classes.root}>
            <div className={classes.first_line}>
                <Typography variant={"subtitle2"} component={'span'}>{paymentMethod.cardType}</Typography>
                <IconButton aria-label="Delete" className={classes.delete} size="small" onClick={() => handleDelete(paymentMethod.id)}>
                    <DeleteIcon fontSize="inherit" />
                </IconButton>
            </div>
            <Divider className={classes.divider}/>
            <Typography variant={"caption"} component={'p'}>{paymentMethod.cardOwner}</Typography>
            <Typography variant={"caption"} component={'p'}>{paymentMethod.cardNumber}</Typography>
            <div>
                <Typography variant={"caption"} component={'p'}>{get('user_credit_card_valid_until', [paymentMethod.cardExpirationDate])}</Typography>
            </div>
            <div>
                <Typography variant={"caption"} component={'p'}>{get('user_credit_card_is_default', [def])}</Typography>
                <Typography variant={"caption"} component={'p'}>{get('user_credit_card_is_validated', [verified])}</Typography>
            </div>
        </Paper>
    );}

PaymentCard.propTypes = {
    paymentMethod: PropTypes.object.isRequired,
    handleDelete: PropTypes.func.isRequired,
};

export default withStyles(styles)(PaymentCard);