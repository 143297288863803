export const ROUTE_SIGN_UP = '/signup';
export const ROUTE_SIGN_IN = '/signin';
export const PASS_FORGET = '/pass-forget';
export const PASS_CHANGE = '/pass-change';
export const LANDING = '/';
export const ROUTE_ITEM_NEW = '/item/new';
export const ROUTE_ITEM_EDIT = '/item/:uid/:itemId';
export const ROUTE_ITEM = '/item/:id';
export const ROUTE_MY_ITEMS = '/item';
export const ROUTE_LOAN = '/loan/:loanId';
export const ROUTE_FAVS = '/favs';
export const ROUTE_EARNS = '/earns';
export const ROUTE_HELP = '/help';
export const ROUTE_LOANS = '/loan';
export const ROUTE_CHATS = '/chat';
export const ROUTE_CHAT = '/chat/:chatId';
export const ROUTE_NOTIFICATIONS = '/notification';
export const ROUTE_PAYMENT_METHODS = '/payment_methods';
export const ROUTE_PROFILE = '/profile';
export const ROUTE_REVIEWS = '/review';
export const ROUTE_USER_DETAIL =  '/user/:uid';
export const ROUTE_LIVE_SEARCH_DETAIL =  '/liveSearch/:specificRequestId';
export const ROUTE_LIVE_SEARCH = '/liveSearch';
export const ROUTE_SHARE = '/share';
export const ROUTE_CONTACT = '/contact';
export const ROUTE_SHARE_ITEM = '/appItem';
export const ROUTE_SHARE_USER = '/appUser';
export const ROUTE_SHARE_LOAN = '/appLoan';
export const ROUTE_SHARE_LIVE_SEARCH = '/appLiveSearch';