import React from 'react'

import {makeStyles} from '@material-ui/core/styles'
import PropTypes from "prop-types";
import LicenseCard from "./LicenseCard";

const useStyles = makeStyles({
    root: {
        display: 'flex',
        flexFlow: 'row wrap',
    },
});

export default function LicenseComponent({licenses, company, handleDelete}) {
    const classes = useStyles();
    return (
        <div className={classes.root}>
            {licenses.map(license =>
                <LicenseCard key={license.licenceId} license={license} company={company} handleDelete={handleDelete}/>
            )}
        </div>
    );
}

LicenseComponent.propTypes = {
    licenses: PropTypes.array.isRequired,
    company: PropTypes.object,
    handleDelete: PropTypes.func.isRequired,
};
