import React, {useEffect, useState} from 'react';
import {makeStyles} from "@material-ui/core/styles";

import {
    Checkbox, FormControl, FormControlLabel, FormHelperText, InputLabel, MenuItem, Paper, Select, TextField
} from "@material-ui/core";

import AsyncSelect from "../common/AsyncSelect";
import {get} from "../common/i18n/i18n";
import {buildUrl} from "../../utils/http";
import {
    API_MASTER_VEHICLE_BOAT_SUBTYPES_BY_TYPE,
    API_MASTER_VEHICLE_BOAT_TYPES_BY_CATEGORY, BOAT_REGISTRATION_LISTS, SAND
} from "../../utils/constants";
import clsx from "clsx";
import layoutStyles from "../common/styles/layoutStyles";
import {BOAT_TEMPLATE} from "./itemTemplate";
import {useSelector} from "react-redux";
import {ROUTE_PROFILE} from "../../utils/routes";
import VehicleDialog from "./VehicleDialog";
import {PropTypes} from "prop-types";
import {useHistory} from "react-router-dom";
import {DatePicker} from "@material-ui/pickers";
import moment from "moment";


const useStyles = makeStyles(theme => ({
    ...layoutStyles(theme),
    root: {
        marginTop: theme.spacing(1),
    },
    select: {
        '&:last-of-type': {
            marginRight: theme.spacing(1),
        },
        [theme.breakpoints.down('md')]: {
            marginTop: theme.spacing(1),
            marginRight: theme.spacing(1),
        },
    },
    formElement: {
        '&:first-of-type': {
            marginLeft: theme.spacing(1),
        },
        '&:last-of-type': {
            marginRight: theme.spacing(1),
        },
        [theme.breakpoints.down('md')]: {
            width: '100%',
            margin: theme.spacing(0, 1),
        },
    },
    check: {
        marginLeft: theme.spacing(1),
    },
    row: {
        display: 'flex',
        flexFlow: 'row wrap',
        justifyContent: 'space-between',
        alignItems: 'baseline',
        width: '100%',
    },
    paper: {
        margin: theme.spacing(1),
        padding: theme.spacing(1),
        backgroundColor: SAND,
    }
}));

const SUB_TYPES = {
        'MOTOR_FIBRA_PW_MENOS_200' : {
            material: 'FIBRA',
            engines: 1,
            length: 0,
            power: 199,
            construction_year: 2020,
            boat_registration: 6,
            name: ''
        },
        'MOTOR_FIBRA_PW_MAS_200_MENOS_600' : {
            material: 'FIBRA',
            engines: 1,
            length: 0,
            power: 599,
            construction_year: 2020,
            boat_registration: 6,
            name: ''
        },
        'MOTOR_FIBRA_PW_MAS_600_MENOS_800' : {
            material: 'FIBRA',
            engines: 1,
            length: 0,
            power: 799,
            construction_year: 2020,
            boat_registration: 6,
            name: ''
        },
        'MOTOR_FIBRA_PW_MAS_800' : {
            material: 'FIBRA',
            engines: 1,
            length: 0,
            power: 800,
            construction_year: 2020,
            boat_registration: 6,
            name: ''
        },
        'MOTOR_MADERA_PW_MENOS_200' : {
            material: 'MADERA',
            engines: 1,
            length: 0,
            power: 199,
            construction_year: 2020,
            boat_registration: 6,
            name: ''
        },
        'MOTOR_MADERA_PW_MAS_200_MENOS_600' : {
            material: 'MADERA',
            engines: 1,
            length: 0,
            power: 599,
            construction_year: 2020,
            boat_registration: 6,
            name: ''
        },
        'MOTOR_MADERA_PW_MAS_600_MENOS_800' : {
            material: 'MADERA',
            engines: 1,
            length: 0,
            power: 799,
            construction_year: 2020,
            boat_registration: 6,
            name: ''
        },
        'MOTOR_MADERA_PW_MAS_800' : {
            material: 'MADERA',
            engines: 1,
            length: 0,
            power: 800,
            construction_year: 2020,
            boat_registration: 6,
            name: ''
        },
        'NEUMATICA_PW_MENOS_200' : {
            material: null,
            engines: 1,
            length: 0,
            power: 199,
            construction_year: 2020,
            boat_registration: 6,
            name: ''
        },
        'NEUMATICA_PW_MAS_200_MENOS_600' : {
            material: null,
            engines: 1,
            length: 0,
            power: 599,
            construction_year: 2020,
            boat_registration: 6,
            name: '',
        },
        'NEUMATICA_PW_MAS_600_MENOS_800' : {
            material: null,
            engines: 1,
            length: 0,
            power: 799,
            construction_year: 2020,
            boat_registration: 6,
            name: '',
        },
        'NEUMATICA_PW_MAS_800' : {
            material: null,
            engines: 1,
            length: 0,
            power: 800,
            construction_year: 2020,
            boat_registration: 6,
            name: ''
        },
        'VELA_FIBRA_MENOS_12M' : {
            material: 'FIBRA',
            engines: 0,
            length: 11,
            power: null,
            construction_year: 2020,
            boat_registration: 6,
            name: ''
        },
        'VELA_FIBRA_MAS_12M_MENOS_15M' : {
            material: 'FIBRA',
            engines: 0,
            length: 14,
            power: null,
            construction_year: 2020,
            boat_registration: 6,
            name: ''
        },
        'VELA_FIBRA_MAS_15M_MENOS_24M' : {
            material: 'FIBRA',
            engines: 0,
            length: 23,
            power: null,
            construction_year: 2020,
            boat_registration: 6,
            name: ''
        },
        'VELA_MADERA_MENOS_12M' : {
            material: 'MADERA',
            engines: 0,
            length: 11,
            power: null,
            construction_year: 2020,
            boat_registration: 6,
            name: ''
        },
        'VELA_MADERA_MAS_12M_MENOS_15M' : {
            material: 'MADERA',
            engines: 0,
            length: 14,
            power: null,
            construction_year: 2020,
            boat_registration: 6,
            name: ''
        },
        'VELA_MADERA_MAS_15M_MENOS_24M' : {
            material: 'MADERA',
            engines: 0,
            length: 23,
            power: null,
            construction_year: 2020,
            boat_registration: 6,
            name: ''
        },
        'MOTOAGUA_MENOS_5A_PW_MENOS_55' : {
            material: null,
            engines: null,
            length: null,
            power: 54,
            construction_year: 2020,
            boat_registration: 6,
            name: null
        },
        'MOTOAGUA_MENOS_5A_PW_MAS_55_MENOS_110' : {
            material: null,
            engines: null,
            length: null,
            power: 109,
            construction_year: 2020,
            boat_registration: 6,
            name: null
        },
        'MOTOAGUA_MENOS_5A_PW_MAS_110' : {
            material: null,
            engines: null,
            length: null,
            power: 110,
            construction_year: 2020,
            boat_registration: 6,
            name: null
        },
        'MOTOAGUA_MAS_5A_PW_MENOS_55' : {
            material: null,
            engines: null,
            length: null,
            power: 49,
            construction_year: 2014,
            boat_registration: 6,
            name: null
        },
        'MOTOAGUA_MAS_5A_PW_MAS_55_MENOS_110' : {
            material: null,
            engines: null,
            length: null,
            power: 109,
            construction_year: 2014,
            boat_registration: 6,
            name: null
        },
        'MOTOAGUA_MAS_5A_PW_MAS__110' : {
            material: null,
            engines: null,
            length: null,
            power: 110,
            construction_year: 2014,
            boat_registration: 6,
            name: null
        }
}

export default function BoatForm(props) {
    const classes = useStyles();
    const {category, boat, onUpdate, validationErrors} = props;
    const [type, setType] = useState(null);
    const [subType, setSubType] = useState(null);
    const [imPro, setImPro] = useState(false);
    const [withDriver, setWithDriver] = useState(false);
    const history = useHistory();
    const user = useSelector(state => state.session.profile);

    useEffect(() => {
        setType(null);
        setSubType(null);
    }, [category.categoryId]);

    useEffect ( () => {
        if (!!user && !!user.companyId) {
            setImPro(true);
        }
    }, [user]);

    // This is quite complex because boats and vehicles always fill subtype even if subtype is not available, so we
    // may need to update this value from the parent component when the check of the subtype has been done. On the other
    // hand, as long as current component state updates are sent to the parent component and then they come back, we
    // have to perform another check not to overwrite current type/subtype value, which contains additional information.
    // Yes, it is a mess and it may be necessary a refactor of this code.
    useEffect(() => {
        if (!boat) {
            setType(null);
            setSubType(null);
            return;
        }

        if ((!subType && boat.subtype)
            || (!!boat.type && boat.type.typeName !== type.typeName)) {
            setType(boat.type);
        } else if (!boat.type) {
            setType(null);
        }
        if ((!subType && boat.subtype)
            || (!!boat.subtype && boat.subtype.typeName !== subType.typeName)) {
            setSubType(boat.subtype);
        } else if (!boat.subtype) {
            setSubType(null);
        }
        // useEffect does not do deep object comparison
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [boat, boat.type, boat.subtype]);


    const updateType = type => {
        setType(type);
        onUpdate("type", type);
    }

    const updateNumber = event => {
        const {id, value} = event.target;
        if (isNaN(value)) {
            return;
        }
        onUpdate(id, Number(value));
    }

    const updateSubType = _subType => {
        setSubType(_subType);
        onUpdate("subtype", _subType);
        onUpdate('material', SUB_TYPES[_subType.typeName].material);
        onUpdate('engines', SUB_TYPES[_subType.typeName].engines);
        onUpdate('length', SUB_TYPES[_subType.typeName].length);
        onUpdate('power', SUB_TYPES[_subType.typeName].power);
        onUpdate('constructionYear', SUB_TYPES[_subType.typeName].construction_year);
    }

    return(
        <div className={classes.root}>
            <div className={classes.row}>
                <AsyncSelect
                    className={clsx(classes.half, classes.select, classes.zTwo)}
                    isClearable={true}
                    selected={type}
                    optionLabel={option => get(option.typeName)}
                    optionValue={option => option}

                    retrieveUrl={buildUrl(API_MASTER_VEHICLE_BOAT_TYPES_BY_CATEGORY, {subCategoryId: category.categoryId})}
                    title={get('select_boat_type')}
                    onSelect={updateType}
                    disabled={!category.categoryId}
                    error={validationErrors.bType !== ''}
                    helperText={validationErrors.bType}
                />
                {((!!subType && !!subType.typeName && !!type) || (!!type && type.hasChildren)) &&
                    <AsyncSelect
                        className={clsx(classes.half, classes.select)}
                        isClearable={true}
                        selected={subType}
                        optionLabel={option => get(option.typeName)}
                        optionValue={option => option}

                        retrieveUrl={buildUrl(API_MASTER_VEHICLE_BOAT_SUBTYPES_BY_TYPE, {parentTypeName: type.typeName})}
                        title={get('select_subtype')}
                        onSelect={updateSubType}
                        disabled={!type || (!subType && !!type && !type.hasChildren)}
                        error={validationErrors.bSubtype !== ''}
                        helperText={validationErrors.bSubtype}
                    />
                }
            </div>
            <div className={classes.row}>
                <FormControlLabel
                    control={
                        <Checkbox
                            className={classes.check}
                            disabled={!!user && !!user.companyId}
                            checked={imPro}
                            onChange={e => setImPro(e.target.checked)}
                        />
                    }
                    label={get('boat_im_pro')}
                />
            </div>
            <div className={classes.row}>
                <FormControlLabel
                    control={
                        <Checkbox
                            className={classes.check}
                            checked={withDriver}
                            onChange={e => setWithDriver(e.target.checked)}
                        />
                    }
                    label={get(`rental_with_${category.rentalWith}`)}
                />
            </div>

            <Paper className={classes.paper}>
                <div className={classes.row}>
                    <DatePicker
                        id="constructionYear"
                        className={clsx(classes.textField, classes.limitedWidth)}
                        label={get('boat_construction_year')}
                        value={!!boat ? moment(boat.constructionYear, 'YYYY') : moment(BOAT_TEMPLATE.constructionYear, 'YYYY')}
                        initialFocusedDate={boat.constructionYear}
                        disableFuture
                        format={'YYYY'}
                        autoOk={true}
                        onChange={millis => onUpdate("constructionYear", new Date(millis).getFullYear())}
                        style={{marginLeft: '8px'}}
                        openTo={'year'}
                        views={['year']}
                    />

                    <TextField id="brand" label={get('boat_brand')} className={classes.formElement} required
                               onChange={event => onUpdate(event.target.id, event.target.value)}
                               value={!!boat ? boat.brand : BOAT_TEMPLATE.brand}
                               error={validationErrors.bBrand !== ''}
                               helperText={validationErrors.bBrand}
                    />

                    <TextField id="model" label={get('boat_model')} className={classes.formElement} required
                               onChange={event => onUpdate(event.target.id, event.target.value)}
                               value={!!boat ? boat.model : BOAT_TEMPLATE.model}
                               error={validationErrors.bModel !== ''}
                               helperText={validationErrors.bModel}
                    />
                </div>
                <div className={classes.row}>
                    <TextField id="zipcodePort" label={get('boat_port_zipcode')} className={classes.formElement} required
                               onChange={event => onUpdate(event.target.id, event.target.value)}
                               value={!!boat ? boat.zipcodePort : BOAT_TEMPLATE.zipcodePort}
                               error={validationErrors.bZipcodePort !== ''}
                               helperText={validationErrors.bZipcodePort}
                    />
                    <FormControl className={clsx(classes.quarter, classes.select)} error={!!validationErrors.bLicencePlate}>
                        <InputLabel id={'labelLicencePlate'}>{get('boat_registration')}</InputLabel>
                        <Select
                            name={'licencePlate'}
                            labelId={'labelLicencePlate'}
                            value={!!boat ? boat.licencePlate : BOAT_TEMPLATE.licencePlate}
                        >
                            {
                                BOAT_REGISTRATION_LISTS.map(list =>
                                    <MenuItem key={list} value={list}>{get(`boat_list_${list}`)}</MenuItem>
                                )
                            }
                        </Select>
                        {!!validationErrors.bLicencePlate && <FormHelperText>{validationErrors.bLicencePlate}</FormHelperText>}
                    </FormControl>
                    {(!!subType && !!SUB_TYPES[subType.typeName] && SUB_TYPES[subType.typeName].name !== null) &&
                        <TextField id="boatName" label={get('boat_name')} className={classes.formElement} required
                                   onChange={event => onUpdate(event.target.id, event.target.value)}
                                   value={!!boat ? boat.boatName : BOAT_TEMPLATE.boatName}
                                   error={validationErrors.bBoatName !== ''}
                                   helperText={validationErrors.bBoatName}
                        />
                    }
                </div>
                <div className={classes.row}>
                    {(!!subType && !!SUB_TYPES[subType.typeName] && SUB_TYPES[subType.typeName].length !== null) &&
                        <TextField id="length" label={get('boat_length')} className={classes.formElement} required
                                   onChange={updateNumber}
                                   value={!!boat ? boat.length : BOAT_TEMPLATE.length}
                                   error={validationErrors.bLength !== ''}
                                   helperText={validationErrors.bLength}
                                   type={'number'}
                                   InputProps={{
                                       inputProps: {
                                           style: {textAlign: "right"},
                                           min: 0,
                                       },
                                   }}
                        />
                    }
                    {(!!subType && !!SUB_TYPES[subType.typeName] && SUB_TYPES[subType.typeName].engines !== null) &&
                        <TextField id="engines" label={get('boat_number_of_engines')} className={classes.formElement} required
                                   onChange={updateNumber}
                                   value={!!boat ? boat.engines : BOAT_TEMPLATE.engines}
                                   error={validationErrors.bEngines !== ''}
                                   helperText={validationErrors.bEngines}
                                   type={'number'}
                                   InputProps={{
                                       inputProps: {
                                           style: {textAlign: "right"},
                                           min: 0,
                                       },
                                   }}
                        />
                    }
                    {(!!subType && !!SUB_TYPES[subType.typeName] && SUB_TYPES[subType.typeName].power !== null) &&
                        <TextField id="power" label={get('boat_power')} className={classes.formElement} required
                                   onChange={updateNumber}
                                   value={!!boat ? boat.power : BOAT_TEMPLATE.power}
                                   error={validationErrors.bPower !== ''}
                                   helperText={validationErrors.bPower}
                                   type={'number'}
                                   InputProps={{
                                       inputProps: {
                                           style: {textAlign: "right"},
                                           min: 0,
                                       },
                                   }}
                        />
                    }
                </div>
            </Paper>
            <VehicleDialog key={'boat_im_pro_dialog'}
                           open={!!user && !user.companyId && imPro}
                           handleClose={() => setImPro(false)}
                           title={get('boat_im_pro')}
                           contentText={get('boat_im_pro_helper')}
                           buttonText={get('ADD')}
                           buttonAction={() => history.push(ROUTE_PROFILE, {defaultExpanded: 'business_area'})}
            />
            <VehicleDialog key={`rental_with_${category.rentalWith}`} open={withDriver} handleClose={() => setWithDriver(false)}
                           title={get(`rental_with_${category.rentalWith}`)}
                           contentText={get(`rental_with_${category.rentalWith}_helper`)}
            />
        </div>
    )
}

BoatForm.propTypes = {
    category: PropTypes.shape(
        {
            categoryId: PropTypes.number,
            rentalWith: PropTypes.string,
        }
    ),
    onUpdate: PropTypes.func.isRequired,
    boat: PropTypes.shape(
        {
            type: PropTypes.oneOfType([
                PropTypes.string,
                PropTypes.object
            ]),
            subtype: PropTypes.oneOfType([
                PropTypes.string,
                PropTypes.object
            ]),
            boatType: PropTypes.string,
            material: PropTypes.string,
            length: PropTypes.number,
            engines: PropTypes.number,
            constructionYear: PropTypes.number,
            power: PropTypes.number,
            zipcodePort: PropTypes.string,
            boatName: PropTypes.string,
            brand: PropTypes.string,
            model: PropTypes.string,
            licencePlate: PropTypes.number,
        }
    ),
    validationErrors: PropTypes.object,
};

BoatForm.defaultProps = {
    boat: {...BOAT_TEMPLATE},
    category: {
        categoryId: undefined,
        rentalWith: undefined,
    }
}