import React from 'react'

import {DEFAULT_PHOTO_HEIGHT, DEFAULT_PHOTO_WIDTH} from "../../../utils/constants";
import {get} from "../../common/i18n/i18n";
import FavIcon from '../../../images/icon_favoritos_on.png'
import Skeleton from "@material-ui/lab/Skeleton";
import clsx from "clsx";
import useFavouriteStyles from "./FavouriteStyles";

export default function FavouriteSkeleton(props) {
    const classes = useFavouriteStyles();
    return (
        <div className={clsx(classes.root, classes.border)}>
            <div className={classes.info}>
                <Skeleton variant="rect" width={DEFAULT_PHOTO_WIDTH} height={DEFAULT_PHOTO_HEIGHT/2}/>
                <Skeleton height={20} width={'100%'} className={classes.link}/>
            </div>
            <img className={classes.icon} src={FavIcon} alt={get('my_favourites')}/>
        </div>
    )
}