import {get} from "../common/i18n/i18n";

export const validate_zipcode = zipcode => {
    if (!zipcode || !zipcode.trim()) {
        return get('mandatory_zipcode')
    }
    const zipcodeAsNumber = parseInt(zipcode);
    if (!zipcodeAsNumber || zipcode.length !== 5 || zipcodeAsNumber < 1000 || zipcode > 52999) {
        return get('error_zipcode_bad_format')
    }
    return "";
}

export const validate_username = username =>
    (!username || !username.trim()) ? get('mandatory_username') : '';

export const validate_user_basic_profile = user => {
    if (!user) {
        return ({
           has_errors: false,
           errors: {}
        });
    }
    const zipcodeError = validate_zipcode(user.zipCode);
    return ({
        has_errors: !!zipcodeError,
        errors: {
            zipcodeError,
        }
    })
}