import React, {Component} from 'react';
import {connect} from 'react-redux'
import {compose} from 'recompose'
import {withStyles} from '@material-ui/core/styles'
import {PropTypes} from "prop-types";
import {withRouter} from 'react-router-dom'
import moment from 'moment'
import {ElementsConsumer} from "@stripe/react-stripe-js";

import {Button, Divider, Tooltip, Typography} from '@material-ui/core';
import {Accordion, AccordionActions, AccordionDetails, AccordionSummary} from "@material-ui/core";

import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import InfoIcon from '@material-ui/icons/Info';

import withAuthorization from '../../auth/withAuthorization'
import {buildUrl, doDelete, doGet, doPut} from '../../../utils/http'
import {
    API_USER_GET_USER_DATA,
    API_USER_DELETE_ADDRESS,
    API_USER_DELETE_PAYMENT_METHOD,
    API_USER_DELETE_BANK_ACCOUNT,
    API_USER_UPDATE_AVATAR,
    API_USER_UPDATE,
    SEVERITY_SUCCESS,
    SEVERITY_ERROR,
    MYUR_AVATAR_FILE_NAME,
    API_USER_DELETE_LICENSE,
    API_USER_SAVE_PROFILE,
    API_LOAN_CAN_MAKE_OPERATION,
    LOAN_OPERATION_BORROW,
    USER_NO_ID_CARD,
    USER_NO_PHONE, USER_NO_ADDRESS, USER_NO_PAYMENT, SEVERITY_INFO
} from '../../../utils/constants'
import {showMessage} from '../../common/NotificationSnack'
import ImageDialog, {openImageDialog} from '../../common/image/ImageDialog'
import AddressComponent from '../address/AddressComponent'
import AddressDialog, {openAddressDialog} from "../address/AddressDialog";
import BankAccountDialog, {openBankAccountDialog} from "../payment/BankAccountDialog";
import {get} from "../../common/i18n/i18n";
import BasicUserProfileForm from "./BasicUserProfileForm";
import PaymentMethodsList from "../payment/PaymentMethodsList";

import {deleteToken, doSignOut} from "../../../utils/firebase";
import {LANDING} from "../../../utils/routes";
import LicenseComponent from "../licenses/LicenseComponent";
import LicenseDialog, {openLicenseDialog} from "../licenses/LicenseDialog";
import ComponentWrapper from "../../common/ComponentWrapper";
import PaymentMethodDialog, {openCreatePaymentMethodDialog} from "../payment/PaymentMethodDialog";
import {LS_COOKIE_CONSENT, saveOriginCoords} from "../../../utils/localStorage";
import {retrieveUserPublicProfile} from "../../../actions/sessionActions";
import {validate_user_basic_profile} from "../user_validation";
import UnifiedUserProfile from "../private/UnifiedUserProfile";
import PhoneVerifierDialog from "./PhoneVerifierDialog";
import buttonStyles from "../../common/styles/buttonStyles";

const styles = theme => ({
    ...buttonStyles(theme),
    heading: {
        fontSize: theme.typography.pxToRem(15),
        textTransform: 'uppercase',
    },
    button: {
        margin: theme.spacing(),
    },
    secondaryHeading: {
        fontSize: theme.typography.pxToRem(15),
        color: theme.palette.text.secondary,
    },
    icon: {
        verticalAlign: 'bottom',
        height: 20,
        width: 20,
    },
    details: {
        alignItems: 'center',
    },
    column: {
        flexBasis: '33.33%',
    },
    helper: {
        borderLeft: `2px solid ${theme.palette.divider}`,
        padding: `${theme.spacing()}px ${theme.spacing(2)}px`,
    },
    link: {
        color: theme.palette.primary.main,
        textDecoration: 'none',
        '&:hover': {
            textDecoration: 'underline',
        },
    },
    AccordionSummary: {
        margin: theme.spacing(1.5, 0),
        display: "flex",
        justifyContent: "space-between",
        flexGrow: 1,
        transition: "margin 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",

    }
});


const INITIAL_STATE = {
    user: undefined,
    profile: undefined,
    avatar: undefined,
    avatarUrl: undefined,
    company: undefined,
    addresses: [],
    paymentMethods: [],
    bankAccounts: [],
    licenses: [],
    pendingPublicData: false,
    pendingProfile: false,
    pendingData: []
};

const COMPANY_TEMPLATE = {
    companyId: undefined,
    address: '',
    cif: '',
    professional: false,
    withholding: '',
    info: '',
    name: '',
    phone: '',
    town: '',
    webSite: '',
    zipcode: '',
};

class UserProfileComponent extends Component {

    constructor(props) {
        super(props);
        this.state = {...INITIAL_STATE, company: {...COMPANY_TEMPLATE}};
    }

    componentDidMount() {
        const {authUser} = this.props;
        if (authUser) {
            this.retrieveUser(authUser);
        }
    }

    retrieveUser = authUser => {
        this.setState({loading: true});
        doGet(buildUrl(API_USER_GET_USER_DATA, {uid: authUser.uid}))
            .then(this.onRetrieveUserSuccess(authUser))
            .catch(this.onError);
    }

    getProfile = (user, companyProfile) => {
        const profile = {
            uid: user.uid,
            name: user.name,
            surname: user.surname,
            phone: user.phone,
            professional: !!user.companyId,
            byEmail: user.byEmail,
            byFCM: user.byFCM,
            userProfile: {
                birthDate: user.birthdate,
                cardIdType: user.cardIdType,
                nif: user.cardId,
                gender: user.gender,
            },
            companyProfile: {...companyProfile}
        }
        if (!!user.companyId) {
            profile.companyProfile.companyId = user.companyId;
        } else {
            profile.companyProfile = {
                cardIdType: 0,
                cif: '',
                companyId: '',
                description: '',
                name: '',
                webSite: '',
                withholding: 0
            }
        }
        return profile
    }

    onRetrieveUserSuccess = authUser => user => {
        this.setState({
            user: user.userDTO,
            profile: this.getProfile(user.userDTO, user.companyProfile),
            addresses: user.addresses,
            paymentMethods: user.paymentMethods,
            bankAccounts: user.bankAccounts,
            licenses: user.licences,
            pendingPublicData: false,
            pendingProfile: false,
            loading: false,
        });
        this.updateAvatar(undefined, user.userDTO.avatar);
        this.canBorrow(authUser);
    };

    canBorrow = authUser => {
        doGet(buildUrl(API_LOAN_CAN_MAKE_OPERATION, {operation: LOAN_OPERATION_BORROW, uid: authUser.uid}))
            .then(this.onCanBorrow)
            .catch(this.onError);
    }

    onCanBorrow = pendingData => {
        if (pendingData.length !== 0) {
            showMessage("Completa tu perfil para poder alquilar.", SEVERITY_INFO);
        }
        this.setState({pendingData})
    }

    componentWillUnmount() {
        window.URL.revokeObjectURL(this.state.avatarUrl);
    }

    getPersonalDataPendingTooltip = pendingData => {
        const pending = [];
        if (pendingData.includes(USER_NO_ID_CARD)) {
            pending.push(get(USER_NO_ID_CARD));
        }
        if (pendingData.includes(USER_NO_PHONE)) {
            pending.push(get(USER_NO_PHONE));
        }
        return pending.join(". ");
    }

    updateAvatar = (avatar, url) => {
        window.URL.revokeObjectURL(this.state.avatarUrl);

        const avatarUrl = !avatar ? url : window.URL.createObjectURL(avatar);

        this.setState({
            avatar,
            avatarUrl,
            pendingPublicData: !!avatar
        })
    };

    handleSavePublicData = event => {
        event.preventDefault();
        this.setState({loading: true});
        const {user, avatar} = this.state;
        const uid = this.props.authUser.uid;
        const userJson = JSON.stringify(user);
        if (!!avatar) {
            const formData = new FormData();
            const fileName = MYUR_AVATAR_FILE_NAME;
            const userDTO = new Blob([userJson], {type: "application/json"});
            formData.append('file', avatar, fileName);
            formData.append('userDTO', userDTO);
            doPut(buildUrl(API_USER_UPDATE_AVATAR, {uid}), formData)
                .then(this.onSaved)
                .catch(this.onError);
        } else {
            doPut(buildUrl(API_USER_UPDATE, {uid}), userJson)
                .then(this.onSaved)
                .catch(this.onError);
        }
    };

    handleSaveProfile = () => {
        this.setState({loading: true});
        const {profile} = this.state;
        const userProfileDataDTO = {...profile};
        if (profile.professional) {
            userProfileDataDTO.userProfile = null;
            userProfileDataDTO.companyProfile.withholding =
                isNaN(+profile.companyProfile.withholding) ? 0 : +profile.companyProfile.withholding;
        } else {
            userProfileDataDTO.companyProfile = null;
            if (!!profile.userProfile.birthDate) {
                userProfileDataDTO.userProfile.birthDate =
                    moment(profile.userProfile.birthDate).format('YYYYMMDD');
            }
        }
        doPut(API_USER_SAVE_PROFILE, JSON.stringify(userProfileDataDTO))
            .then(this.onSaved)
            .catch(this.onError);
    }

    handleLogOut = () => {
        deleteToken();
        localStorage.removeItem(LS_COOKIE_CONSENT);
        saveOriginCoords(null);
        doSignOut()
            .then(() => {
                showMessage(get('signed_out'), SEVERITY_SUCCESS);
                this.props.history.push(LANDING);
            })
            .catch(this.onError);
    };

    discardChanges = area => {
        this.setState({
            [area]: false,
        });
        this.retrieveUser(this.props.authUser);
    }

    onSaved = () => {
        const {authUser} = this.props;
        showMessage(get('saved'), SEVERITY_SUCCESS);
        this.props.retrieveUserPublicProfile(authUser.uid);
        this.retrieveUser(authUser);
    };

    openImageDialog = event => {
        event.preventDefault();
        openImageDialog();
    };

    updateLocalData = (levelOne, area) => (levelTwo, value) => {
        this.setState(prevState => {
            const newState = {
                [levelOne]: !!levelTwo ? {...prevState[levelOne], [levelTwo]: value} : value,
            }
            if (!!area) {
                newState[area] = true;
            }
            return newState;
        });
    };

    updateProfile = (levelOne, levelTwo, value) => {
        this.setState(prevState => {
            const profile = prevState.profile;
            profile[levelOne] = !!levelTwo ? {...profile[levelOne], [levelTwo]: value} : value;
            return ({
                profile,
                pendingProfile: true
            });
        });
    }

    handleDeleteAddress = uid => addressId => {
        this.setState({loading: true});
        doDelete(buildUrl(API_USER_DELETE_ADDRESS, {uid, addressId}))
            .then(this.onSaved)
            .catch(this.onError);
    };

    handleDeleteLicense = uid => licenseId => {
        this.setState({loading: true});
        doDelete(buildUrl(API_USER_DELETE_LICENSE, {uid, licenseId}))
            .then(this.onSaved)
            .catch(this.onError);
    };

    handleDeletePaymentMethod = uid => cardId => {
        this.setState({loading: true});
        doDelete(buildUrl(API_USER_DELETE_PAYMENT_METHOD, {uid, cardId}))
            .then(this.onSaved)
            .catch(this.onError);
    };

    handleDeleteBankAccount = uid => bankAccountId => {
        this.setState({loading: true});
        doDelete(buildUrl(API_USER_DELETE_BANK_ACCOUNT, {uid, bankAccountId}))
            .then(this.onSaved)
            .catch(this.onError);
    };

    onError = error => {
        this.setState({loading: false});
        showMessage(error, SEVERITY_ERROR);
    }

    render() {
        const {classes, location} = this.props;
        const defaultExpanded = !!location.state ? location.state.defaultExpanded : 'public_profile';
        const {user, profile, avatarUrl, paymentMethods, bankAccounts, addresses, licenses, company,
            pendingPublicData, pendingProfile, loading, pendingData} = this.state;
        const basicProfileErrors = validate_user_basic_profile(user);
        if (!user) {
            return (
                <ComponentWrapper className={classes.root} loading={!user}>
                    <Typography variant={"subtitle1"}>{get('loading_data')}</Typography>
                </ComponentWrapper>
            )}
        return (
            <ComponentWrapper loading={loading}>
                <Accordion defaultExpanded={defaultExpanded === 'public_profile'}>
                    <AccordionSummary expandIcon={<ExpandMoreIcon/>} classes={{content: classes.AccordionSummary}}>
                        <Typography className={classes.heading}>{get('public_profile')}</Typography>
                    </AccordionSummary>
                    <AccordionDetails className={classes.details}>
                        <BasicUserProfileForm
                            onUpdate={this.updateLocalData('user', 'pendingPublicData')}
                            avatarUrl={avatarUrl}
                            user={user}
                        />
                    </AccordionDetails>
                    <Divider/>
                    <AccordionActions>
                        <Button className={classes.defaultButton}
                                disabled={!pendingPublicData}
                                onClick={() => this.discardChanges('pendingPublicData')}>
                            {get('discard_changes')}
                        </Button>
                        <Button className={classes.blueButton}
                                disabled={!pendingPublicData || basicProfileErrors.has_errors}
                                onClick={event => this.handleSavePublicData(event)}>
                            {get('SAVE')}
                        </Button>
                    </AccordionActions>
                </Accordion>

                <Accordion defaultExpanded={defaultExpanded === 'personal_data'}>
                    <AccordionSummary expandIcon={<ExpandMoreIcon/>} classes={{content: classes.AccordionSummary}}>
                        <Typography className={classes.heading}>{get('unified_profile_title')}</Typography>
                        { (pendingData.includes(USER_NO_ID_CARD) || pendingData.includes(USER_NO_PHONE)) &&
                            <Tooltip title={this.getPersonalDataPendingTooltip(pendingData)}>
                                <InfoIcon/>
                            </Tooltip>
                        }
                    </AccordionSummary>
                    <AccordionDetails>
                        <UnifiedUserProfile profile={profile} onUpdate={this.updateProfile}/>
                    </AccordionDetails>
                    <Divider/>
                    <AccordionActions>
                        <Button className={classes.defaultButton}
                                disabled={!pendingProfile}
                                onClick={() => this.discardChanges('pendingProfile')}>
                            {get('discard_changes')}
                        </Button>
                        <PhoneVerifierDialog user={user} profile={profile} handleUserSave={this.handleSaveProfile}>
                            {
                                handleClick => (
                                    <Button className={classes.blueButton}
                                            disabled={!pendingProfile}
                                            onClick={handleClick}>
                                        {get('SAVE')}
                                    </Button>
                                )
                            }
                        </PhoneVerifierDialog>
                    </AccordionActions>
                </Accordion>

                <Accordion defaultExpanded={defaultExpanded === 'addresses'}>
                    <AccordionSummary expandIcon={<ExpandMoreIcon/>} classes={{content: classes.AccordionSummary}}>
                        <Typography className={classes.heading}>{get('addresses')}</Typography>
                        { pendingData.includes(USER_NO_ADDRESS) &&
                            <Tooltip title={get(USER_NO_ADDRESS)}>
                                <InfoIcon/>
                            </Tooltip>
                        }
                    </AccordionSummary>
                    <AccordionDetails>
                        <AddressComponent addresses={addresses} handleDelete={this.handleDeleteAddress(user.uid)}/>
                        <AddressDialog onSuccess={this.onSaved} onError={this.onError}/>
                    </AccordionDetails>
                    <Divider/>
                    <AccordionActions>
                        <Button className={classes.blueButton}
                                onClick={() => openAddressDialog(user.uid)}>
                            {get('ADD')}
                        </Button>
                    </AccordionActions>
                </Accordion>

                <Accordion defaultExpanded={defaultExpanded === 'licenses'}>
                    <AccordionSummary expandIcon={<ExpandMoreIcon/>} classes={{content: classes.AccordionSummary}}>
                        <Typography className={classes.heading}>{get('licenses')}</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <LicenseComponent licenses={licenses} company={company} handleDelete={this.handleDeleteLicense(user.uid)}/>
                        <LicenseDialog onSuccess={this.onSaved} onError={this.onError}/>
                    </AccordionDetails>
                    <Divider/>
                    <AccordionActions>
                        <Button className={classes.blueButton}
                                onClick={() => openLicenseDialog(user.uid)}>
                            {get('ADD')}
                        </Button>
                    </AccordionActions>
                </Accordion>

                <Accordion defaultExpanded={defaultExpanded === 'user_bank_information'}>
                    <AccordionSummary expandIcon={<ExpandMoreIcon/>} classes={{content: classes.AccordionSummary}}>
                        <Typography className={classes.heading}>{get('user_bank_information')}</Typography>
                        { pendingData.includes(USER_NO_PAYMENT) &&
                        <Tooltip title={get(USER_NO_PAYMENT)}>
                            <InfoIcon/>
                        </Tooltip>
                        }
                    </AccordionSummary>
                    <AccordionDetails>
                        <PaymentMethodsList
                            paymentMethods={paymentMethods}
                            bankAccounts={bankAccounts}
                            handleDeletePM={this.handleDeletePaymentMethod(user.uid)}
                            handleDeleteBA={this.handleDeleteBankAccount(user.uid)}
                        />
                        <ElementsConsumer>
                            {({stripe, elements}) => (
                                <PaymentMethodDialog onSuccess={this.onSaved} onError={this.onError} stripe={stripe} elements={elements} />
                            )}
                        </ElementsConsumer>
                        <BankAccountDialog onSuccess={this.onSaved} onError={this.onError}/>
                    </AccordionDetails>
                    <Divider/>
                    <AccordionActions>
                        <Button className={classes.blueButton}
                                onClick={() => openBankAccountDialog(user.uid)}>
                            {get('user_add_bank_account')}
                        </Button>
                        <Button className={classes.blueButton}
                                onClick={() => openCreatePaymentMethodDialog(user.uid)}>
                            {get('user_add_credit_card')}
                        </Button>
                    </AccordionActions>
                </Accordion>

                <Accordion defaultExpanded={defaultExpanded === 'log-out'}>
                    <AccordionSummary expandIcon={<ExpandMoreIcon/>} classes={{content: classes.AccordionSummary}}>
                        <Typography className={classes.heading}>{get('log-out')}</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        {get('log-out_text')}
                    </AccordionDetails>
                    <Divider/>
                    <AccordionActions>
                        <Button className={classes.blueButton} onClick={this.handleLogOut}>
                            {get('log-out')}
                        </Button>
                    </AccordionActions>
                </Accordion>

                <ImageDialog title={get('user_profile_image')} accept={'image/jpeg'} onImageSelected={this.updateAvatar}/>
            </ComponentWrapper>
        );
    }
}

const mapStateToProps = ({session}) => ({
    authUser: session.authUser,
});

const mapDispatchToProps = dispatch => ({
    retrieveUserPublicProfile: uid => dispatch(retrieveUserPublicProfile(uid)),
});

const authCondition = profile => !!profile;

UserProfileComponent.propTypes = {
    classes: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    authUser: PropTypes.object,
}

export default compose(
    withAuthorization(authCondition),
    withRouter,
    connect(mapStateToProps, mapDispatchToProps),
    withStyles(styles),
)(UserProfileComponent)