import {
    ADD_NOTIFICATIONS_PENDING_ACTION, ADD_MESSAGES_PENDING_ACTION, RELOAD_MESSAGES_PENDING_ACTION,
    RELOAD_NOTIFICATIONS_PENDING_ACTION, UPDATED_MESSAGES_PENDING_ACTION, UPDATED_NOTIFICATIONS_PENDING_ACTION
} from "../actions/actionTypes";

const INITIAL_STATE = {
    notificationsPending: 0,
    messagesPending: 0,
};

export default function notifications(state = {...INITIAL_STATE}, action) {
    switch(action.type) {
        case ADD_NOTIFICATIONS_PENDING_ACTION : {
            return {
                notificationsPending: state.notificationsPending + action.count,
                messagesPending: state.messagesPending,
            }
        }
        case ADD_MESSAGES_PENDING_ACTION : {
            return {
                notificationsPending: state.notificationsPending,
                messagesPending: state.messagesPending + action.count,
            }
        }
        case RELOAD_NOTIFICATIONS_PENDING_ACTION: {
            return state;
        }
        case RELOAD_MESSAGES_PENDING_ACTION: {
            return state;
        }
        case UPDATED_NOTIFICATIONS_PENDING_ACTION: {
            return {
                notificationsPending: action.notificationsPending,
                messagesPending: state.messagesPending
            }
        }
        case UPDATED_MESSAGES_PENDING_ACTION: {
            return {
                notificationsPending: state.notificationsPending,
                messagesPending: action.messagesPending,
            }
        }
        default : return state;
    }
}