import React, {useEffect, useState} from 'react';

import moment from "moment";
import {getUid, range} from "../../../utils/utils";
import {FormControl, Hidden, InputLabel, MenuItem, Select, Typography} from "@material-ui/core";
import {makeStyles} from "@material-ui/styles";
import {buildUrl, doGet} from "../../../utils/http";
import {API_LOAN_GET_PROFIT_DETAIL, DATE_FORMAT, SAND, SEVERITY_ERROR} from "../../../utils/constants";
import {showMessage} from "../../common/NotificationSnack";
import {get} from "../../common/i18n/i18n";
import {Link} from "react-router-dom";
import {ROUTE_LOAN} from "../../../utils/routes";

const useEntryStyles = makeStyles(theme => ({
    entry: {
        position: "relative",
        width: "100%",
        display: "flex",
        flexFlow: "wrap row",
        alignItems: "center",
        margin: theme.spacing(1, 0),
        padding: theme.spacing(1, 0),
        borderBottom: `2px solid ${SAND}`,
        boxSizing: "border-box",
    },
    columnNarrow: {
        width: "15%",
        [theme.breakpoints.down('sm')]: {
            width: "100%"
        },
    },
    columnWide: {
        width: "35%",
        [theme.breakpoints.down('sm')]: {
            width: "100%"
        },
    },
    link: {
        position: "absolute",
        top: 0,
        bottom: 0,
        right: 0,
        left: 0
    }
}));

const EarnEntry = ({entry}) => {
    const classes = useEntryStyles();
    return (
        <div className={classes.entry}>
            <Link className={classes.link} to={buildUrl(ROUTE_LOAN, {loanId: entry.loanId})}/>
            <Typography className={classes.columnNarrow} align={"left"} component={"span"}>
                {moment(entry.paymentOrderDate, DATE_FORMAT).format(get('month_day_ui'))}
            </Typography>
            <Typography className={classes.columnWide} align={"left"} component={"span"}>
                {get('period_earns_code', [entry.orderCode])}
            </Typography>
            <Typography className={classes.columnWide} align={"left"} component={"span"}>
                {entry.loanTitle}
            </Typography>
            <Hidden smDown>
                <Typography className={classes.columnNarrow} align={"right"} component={"span"}>
                    {get('currency_formatter').format(entry.amount)}
                </Typography>
            </Hidden>
            <Hidden smUp>
                <Typography className={classes.columnNarrow} align={"left"} component={"span"}>
                    {get('currency_formatter').format(entry.amount)}
                </Typography>
            </Hidden>
        </div>
    )
}

const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%",
        textAlign: "left",
        padding: theme.spacing(2),
        boxSizing: "border-box"
    },
    formControl: {
        marginRight: theme.spacing(1),
        minWidth: 140,
    },
    period: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(1)
    }
}));

const EarnsDetailComponent = props => {
    const classes = useStyles();
    const years = range(2020, moment().year(), 1);
    const months = moment.months();
    const uid = getUid();

    const [year, setYear] = useState(moment().year());
    const [month, setMonth] = useState(moment().month());
    const [earnEntries, setEarnEntries] = useState([]);
    const [periodTotal, setPeriodTotal] = useState(0);
    useEffect(() => {
        if (!uid || !month || !year) {
            return;
        }
        doGet(buildUrl(API_LOAN_GET_PROFIT_DETAIL, {uid, year, month: month + 1}))
            .then(response => {
                setPeriodTotal(response.totalAmountForPeriod || 0);
                setEarnEntries(response.movements)
            })
            .catch(error => showMessage(error, SEVERITY_ERROR));
    }, [month, year, uid]);

    return (
        <div className={classes.root}>
            <FormControl className={classes.formControl}>
                <InputLabel id="year-select-label">{get('year')}</InputLabel>
                <Select labelId="year-select-label" id="year-select" value={year}
                    onChange={event => setYear(event.target.value)}>
                    {years.map(year => <MenuItem key={year} value={year}>{year}</MenuItem>)}
                </Select>
            </FormControl>
            <FormControl className={classes.formControl}>
                <InputLabel id="month-select-label">{get('month')}</InputLabel>
                <Select labelId="month-select-label"  id="month-select" value={month}
                    onChange={event => setMonth(event.target.value)}>
                    {months.map((month, index) => <MenuItem key={month} value={index}>{month}</MenuItem>)}
                </Select>
            </FormControl>
            <div className={classes.period}>
                <Typography>
                    {get('period_earns', [months[month], year])}: {get('currency_formatter').format(periodTotal)}
                </Typography>
                { earnEntries.length > 0 &&
                    <Typography variant={"caption"}>
                        {get('period_earns_helper')}
                    </Typography>
                }
            </div>
            <div>
                {earnEntries.map(earnEntry => <EarnEntry key={earnEntry.loanId} entry={earnEntry}/>)}
            </div>
        </div>
    );
};

export default EarnsDetailComponent;