import React, {Component} from 'react';
import {compose} from "recompose";
import {PropTypes} from "prop-types";

import Async from 'react-select/async';
import {createFilter} from "react-select";
import shortid from "shortid";

import {FormControl, FormHelperText} from "@material-ui/core";
import {withStyles} from '@material-ui/core/styles'

import {buildUrl, doRequest} from "../../utils/http";
import {get} from "./i18n/i18n";
import {isFunction} from "../../utils/utils";

const styles = theme => ({
    formControl: {
        margin: theme.spacing(1),
    },
    progress: {
        marginRight: theme.spacing(1),
    }
});

const filterConfig = {
    ignoreCase: true,
    ignoreAccents: true,
    trim: true,
};

class SearchSelect extends Component {

    controller = undefined;

    // TODO try to get lodash/debounce work
    getOptions = search => {

        if (!search || search.length < 3) {
            return new Promise(resolve => resolve(undefined));
        }
        if (this.controller && !this.controller.signal.aborted) {
            this.controller.abort();
        }
        this.controller = new AbortController();
        return doRequest(buildUrl(this.props.retrieveUrl, {search,}),
            json => this.onLoaded(json),
            error => this.onError(error),
            {method: 'GET', signal: this.controller.signal}
        );
    };

    onLoaded = json => {
        this.setState({isLoading: false});
        return json;
    };

    onError = error => {
        this.setState({isLoading: false});
    };

    render() {
        const {
            disabled, title, helperText, onSelect, selected, retrieveUrl,
            optionLabel, optionValue, classes, ...other
        } = this.props;
        const value = !selected ? '' : isFunction(optionLabel) ? !!optionLabel(selected) ? selected : '' : !!selected ? selected : '';
        return (
            <FormControl {...other}>
                <Async
                    cacheOptions
                    defaultOptions
                    value={value}
                    getOptionLabel={optionLabel}
                    getOptionValue={optionValue}
                    onChange={onSelect}
                    isDisabled={disabled}
                    inputId={shortid.generate()}
                    name={'search-select-menu-' + shortid.generate()}
                    loadingMessage={() => get('loading_data')}
                    noOptionsMessage={() => get('no_data_found')}
                    placeholder={title}
                    loadOptions={this.getOptions}
                    filterOption={createFilter(filterConfig)}
                />
                {!!helperText && <FormHelperText>{helperText}</FormHelperText>}
            </FormControl>
        );
    }
}

SearchSelect.propTypes = {
    title: PropTypes.string.isRequired,
    helperText: PropTypes.string,
    onSelect: PropTypes.func.isRequired,
    retrieveUrl: PropTypes.string.isRequired,
    optionLabel: PropTypes.func.isRequired,
    optionValue: PropTypes.func.isRequired,
    selected: PropTypes.any,
    disabled: PropTypes.bool,
    classes: PropTypes.object.isRequired,
};

export default compose(
    withStyles(styles),
)(SearchSelect);
