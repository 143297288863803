import React from 'react';
import {connect} from 'react-redux';
import {compose} from 'recompose';
import {withRouter} from 'react-router-dom';

import {auth, onAuthStateChanged} from '../../utils/firebase';
import * as routes from '../../utils/routes';
import {DEFAULT_MAX_WIDTH} from "../../utils/constants";
import {Grid, Paper, withStyles} from "@material-ui/core";
import {AppLink, SignUp} from "../landing/LandingPanels";
import WalrusBottomComponent from "../landing/WalrusBottomComponent";
import MyurWaitingComponent from "../common/MyurWaitingComponent";

export const LetsRegister = withStyles(theme => ({
    paper: {
        marginTop: theme.spacing(2),
        width: '90%',
        maxWidth: DEFAULT_MAX_WIDTH,
        padding: theme.spacing(2),
    },
    appLink: {
        marginTop: theme.spacing(1),
    }
}))(({classes}) => {
    return (
        <>
            <Paper elevation={3} className={classes.paper}>
                <Grid container direction={"row"} justifyContent={"space-evenly"}>
                    <Grid item xs={12} md={6}>
                        <SignUp/>
                    </Grid>
                    <Grid item xs={12} md={6} className={classes.appLink}>
                        <AppLink/>
                    </Grid>
                </Grid>
            </Paper>
            <WalrusBottomComponent/>
        </>
    );
});

const withAuthorization = authCondition => Component => {

    class WithAuthorization extends React.Component {

        componentDidMount() {
            const {history} = this.props;
            onAuthStateChanged(auth, authUser => {
                if (!authUser) {
                    history.push(routes.ROUTE_SIGN_IN);
                }
            });
        }

        render() {
            return (
                <>
                    <MyurWaitingComponent open={!this.props.authUser}/>
                    {authCondition(this.props.profile) ? <Component {...this.props}/> : <LetsRegister/>}
                </>
            );
        }
    }

    const mapStateToProps = ({session}) => ({
        authUser: session.authUser,
        profile: session.profile,
    });

    return compose(
        withRouter,
        connect(mapStateToProps),
    )(WithAuthorization);
};

export default withAuthorization;