import React from "react";
import PropTypes from "prop-types";
import {makeStyles} from "@material-ui/core/styles";
import clsx from "clsx";

import facebook from "../../images/facebook_icon.png";
import instagram from "../../images/instagram_icon.png";
import youtube from "../../images/youtube_icon.png";
import linkedin from "../../images/linkind_icon.png";

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexFlow: 'wrap row',
        justifyContent: 'flex-start',
    },
    logo: {
        margin: theme.spacing(1),
        "&:first-child": {
            marginLeft: 0,
        },
    },
}));

export default function RRSSComponent({className}) {
    const classes = useStyles();
    return(
        <div className={clsx(classes.root, className)}>
            <a className={classes.logo} href="https://www.facebook.com/myurapp" target="_blank" rel="noopener noreferrer">
                <img alt={'facebook'} src={facebook}/>
            </a>
            <a className={classes.logo} href="https://www.instagram.com/myur.app/" target="_blank" rel="noopener noreferrer">
                <img alt={'instagram'} src={instagram}/>
            </a>
            <a className={classes.logo} href="https://www.youtube.com/channel/UCLL0NFRpdsoHhjbSYTWtVIw" target="_blank" rel="noopener noreferrer">
                <img alt={'youtube'} src={youtube}/>
            </a>
            <a className={classes.logo} href="https://www.linkedin.com/company/myur" target="_blank" rel="noopener noreferrer">
                <img alt={'linkedin'} src={linkedin}/>
            </a>
        </div>
    )
}

RRSSComponent.propTypes = {
    className: PropTypes.string,
};