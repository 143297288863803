import React from 'react'
import PropTypes from 'prop-types'
import {withStyles} from '@material-ui/core/styles'
import shortid from 'shortid'

import {FormControl, FormHelperText} from "@material-ui/core";
import Select from 'react-select';

import {doRequest} from "../../utils/http";
import {showMessage} from "./NotificationSnack";
import {get} from "./i18n/i18n";
import {isFunction} from "../../utils/utils";

const INITIAL_STATE = {
    options: [],
    waiting: false,
};

const styles = theme => ({
    formControl: {
        margin: theme.spacing(1),
    },
    progress: {
        marginRight: theme.spacing(1),
    }
});

class AsyncSelect extends React.Component {

    retrieveUrl = '';

    constructor(props) {
        super(props);
        this.state = {...INITIAL_STATE};
    }

    handleOpen = () => {
        const {retrieveUrl} = this.props;
        const {options} = this.state;
        if (!options || options.length === 0 || this.retrieveUrl !== retrieveUrl) {
            this.setState({options: []});
            this.retrieveUrl = retrieveUrl;
            this.loadOptions();
        }
    };

    handleClose = () => {
        this.setState({waiting: false});
    };

    loadOptions = () => {
        const {retrieveUrl} = this.props;
        this.setState({waiting: true});
        doRequest(
            retrieveUrl,
            options => this.setState({waiting: false, options}),
            error => {
                this.setState({waiting: false});
                showMessage({error})
            },
            {method: 'GET'}
        );
    };

    render() {
        const {
            classes, className,
            title, error, helperText, isClearable,
            optionValue, optionLabel, selected, onSelect, disabled, ...otherProps
        } = this.props;
        const {options, waiting} = this.state;
        const id = 'react-select-' + shortid.generate();
        const value = !selected ? '' : isFunction(optionLabel) ? !!optionLabel(selected) ? selected : '' : !!selected ? selected : '';
        return (
            <FormControl className={className || classes.formControl} error={error}>
                <Select
                    id={id}
                    inputId={id + '-input'}
                    name={'react-select-' + shortid.generate()}
                    value={value}
                    getOptionLabel={optionLabel}
                    getOptionValue={optionValue}
                    onChange={onSelect}
                    onMenuOpen={this.handleOpen}
                    onMenuClose={this.handleClose}
                    isDisabled={disabled}
                    isLoading={waiting}
                    isClearable={isClearable}
                    loadingMessage={() => get('loading_data')}
                    noOptionsMessage={() => get('no_data_found')}
                    placeholder={title}
                    options={options}
                    {...otherProps}
                />
                {!!helperText && <FormHelperText>{helperText}</FormHelperText>}
            </FormControl>
        )
    }
}

AsyncSelect.propTypes = {
    classes: PropTypes.object.isRequired,
    error: PropTypes.bool,
    helperText: PropTypes.string,
    isClearable: PropTypes.bool,
    optionValue: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.func,
    ]).isRequired,
    optionLabel: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.func,
    ]).isRequired,
    retrieveUrl: PropTypes.string.isRequired,
    title: PropTypes.string,
    onSelect: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
    value: PropTypes.any,
    className: PropTypes.string,
};

AsyncSelect.defaultProps = {
    isClearable: false,
};

export default withStyles(styles)(AsyncSelect);