import React from 'react'
import {connect} from 'react-redux'
import {compose} from 'recompose'
import {PropTypes} from 'prop-types';

import {makeStyles, withStyles} from '@material-ui/core/styles';
import {Button, Grid, Hidden} from '@material-ui/core';

import {
    DEFAULT_WIDTH,
    MARINE,
    SEARCH_COUNT_ITEMS,
} from "../../utils/constants";

import ItemCard from "../item/ItemCard";
import SearchComponent from "./SearchComponent";
import WalrusBottomComponent from "./WalrusBottomComponent";
import {showMessage} from "../common/NotificationSnack";
import {searchItems, updateGeoPosition} from "../../actions/searchActions";
import ItemVertSkeleton from "../item/ItemVertSkeleton";
import {get} from "../common/i18n/i18n";
import {getUid} from "../../utils/utils";
import {AppLink, SignUp} from "./LandingPanels";
import ItemMapComponent from "./maps/ItemMapComponent";
import {getOriginCoords, incrementVariable, LS_USER_LANDINGS} from "../../utils/localStorage";
import {getOrigin} from "../../reducers";
import LiveSearchSnack from "./LiveSearchSnack";
import qs from "qs";
import {withRouter} from "react-router-dom";

const styles = theme => ({
    root: {
        width: '100%',
        display: 'flex',
        flexFlow: 'wrap column',
        alignItems: 'center',
    },
});

const INITIAL_STATE = {
   showMap: false,
};

class MainComponent extends React.Component {

    constructor(props) {
        super(props);
        this.state = {...INITIAL_STATE};
    }

    async componentDidMount() {
        const {items, numFound, searching} = this.props;
        this.updateOrigin();
        if (
            (!searching && items.length === 0) // entramos en la app, no hay artículos y no los estamos buscando
            || (items.length < SEARCH_COUNT_ITEMS && items.length !== numFound)
        ) {
            this._searchItems();
        }
    }

    updateOrigin = () => {
        const {origin, updateGeoPosition} = this.props;
        incrementVariable(LS_USER_LANDINGS);
        if (!origin.lat || !origin.lng) { // check redux store if coords already exist.
            // update with the ones cached in local storage
            const localStoredOrigin = getOriginCoords();
            updateGeoPosition(localStoredOrigin.lat, localStoredOrigin.lng);
        }
    }
    async componentDidUpdate(prevProps, prevState, snapshot) {
        const {items, numFound, searching, profile} = this.props;
        if (!searching && !prevProps.profile && !!profile && (
            (items.length === 0) || (items.length < SEARCH_COUNT_ITEMS && items.length !== numFound))) {
            this._searchItems();
        }
    }

    _searchItems = () => {
        const query = qs.parse(this.props.location.search,
            {depth: 1, parameterLimit: 1, ignoreQueryPrefix: true}).query;
        const {what, where, startDate, endDate, start, searchItems} = this.props;
        const _what = !!what || !query ? what : query;
        searchItems(_what, where, startDate, endDate, start, SEARCH_COUNT_ITEMS);
    };

    handleShowMap = () =>
        this.setState((prevState, props) => ({showMap: !prevState.showMap}));

    render() {
        const {classes, error, cookieConsent, ...itemListProps} = this.props;
        const {showMap} = this.state;
        const uid = getUid();
        if (!!error) {
            showMessage(error);
        }
        return (
            <>
                <div className={classes.root}>
                    <SearchComponent handleShowMap={this.handleShowMap} showMap={showMap}/>
                    {showMap ? <ItemMapComponent items={this.props.items}/> : <ItemList {...itemListProps}/>}
                    {
                        !uid &&
                        <>
                            <SignUp/>
                            <Hidden mdUp>
                                <AppLink/>
                            </Hidden>
                        </>
                    }
                </div>
                <WalrusBottomComponent/>
                {cookieConsent && <LiveSearchSnack/>}
            </>
        );
    }
}

const useStyles = makeStyles(theme => ({
    searchResult: {
        width: '100%',
        maxWidth: DEFAULT_WIDTH,
        marginTop: theme.spacing(),
    },
    button: {
        background: MARINE,
        color: theme.palette.common.white,
        fontWeight: 'bold',
        borderRadius: 20,
        width: 150,
        height: 48,
        marginBottom: theme.spacing(),
    },
}));

const ItemList = props => {
    const {what, where, startDate, endDate, items, numFound, searchItems, searching} = props;
    const classes = useStyles();
    return (
        <>
            <Grid container spacing={1} className={classes.searchResult}>
                {items.map(item => <ItemCard key={item.id} item={item} gridProps={{xs: 6, sm: 4, md: 3}}/>)}
                {searching && [0, 1, 2, 3].map(idx => <ItemVertSkeleton key={idx} gridProps={{xs: 6, sm: 4, md: 3}}/>)}
            </Grid>
            {
                (items.length < numFound) && (
                    <Button className={classes.button}
                            onClick={event => searchItems(what, where, startDate, endDate, items.length, SEARCH_COUNT_ITEMS)}>
                        {get('landing_load_more')}
                    </Button>
                )
            }
        </>
    );
}

MainComponent.propTypes = {
    searchItems: PropTypes.func,
    updateGeoPosition: PropTypes.func,
    what: PropTypes.string,
    where: PropTypes.object,
    startDate: PropTypes.object,
    endDate: PropTypes.object,
    start: PropTypes.number,
    count: PropTypes.number,
    items: PropTypes.array,
    searching: PropTypes.bool,
    numFound: PropTypes.number,
    cookieConsent: PropTypes.bool.isRequired
};

const mapStateToProps = (state) => ({
    what: state.searchItems.what,
    where: state.searchItems.where,
    startDate: state.searchItems.startDate,
    endDate: state.searchItems.endDate,
    start: state.searchItems.start,
    count: state.searchItems.count,
    items: state.searchItems.items,
    searching: state.searchItems.searching,
    numFound: state.searchItems.numFound,
    profile: state.session.profile,
    origin: getOrigin(state),
});

const mapDispatchToProps = dispatch => ({
    searchItems: (profile, what, where, startDate, endDate, start, count) =>
        dispatch(searchItems(profile, what, where, startDate, endDate, start, count)),
    updateGeoPosition: (lat, lng) => dispatch(updateGeoPosition(lat, lng)),
});

export default compose(
    withStyles(styles),
    withRouter,
    connect(mapStateToProps, mapDispatchToProps),
)(MainComponent);
