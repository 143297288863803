import React from 'react';
import {PropTypes} from "prop-types";

import clsx from "clsx";
import {Typography} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";

import {get} from './i18n/i18n';
import {MARINE} from "../../utils/constants";

const useStyles = makeStyles(theme => ({
    root: {

    },
    text: {
        color: MARINE,
    },
}));

export default function CategoryBreadcrumb({category, className}) {
    const classes = useStyles();
    return (
        <div className={clsx(classes.root, className)}>
            <Typography variant={'caption'} className={classes.text}>
                {get(category.parentCategoryCode)} > {get(category.categoryCode)}
            </Typography>
        </div>
    )
}

CategoryBreadcrumb.propTypes = {
    category: PropTypes.object.isRequired,
    className: PropTypes.string,
};