import {doGetFCMToken} from './utils/firebase';

const registerServiceWorker = () => {
    if ("serviceWorker" in navigator) {
        navigator.serviceWorker
            .register("/myur-sw.js")
            .then(registration => {
                doGetFCMToken(registration);
                console.debug("Registration successful, scope is:", registration.scope);
            })
            .catch(err => {
                console.error("Service worker registration failed, error:", err);
            });
    }
};

export { registerServiceWorker };