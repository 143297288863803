import React from 'react'

import {makeStyles} from "@material-ui/core/styles";
import Skeleton from "@material-ui/lab/Skeleton";

import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardMedia from "@material-ui/core/CardMedia";
import CardContent from "@material-ui/core/CardContent";
import {MYUR_ITEM_PHOTO_ASPECT_RATIO} from "../../utils/constants";
import {Grid} from "@material-ui/core";
import PropTypes from "prop-types";

const useStyles = makeStyles(theme => ({
    imageContainer: {
        position: "relative",
        width: "100%",
        paddingBottom: `${100/MYUR_ITEM_PHOTO_ASPECT_RATIO}%`,
        height: 0,
    },
    img: {
        width: "100%",
        height: "100%",
        position: "absolute",
        left: 0,
    },
    cardContent: {
        width: "100%",
        maxWidth: "100%",
        boxSizing: "border-box",
        padding: 0
    },
    text: {
        marginTop: theme.spacing(1),
    },
    float: {
        position: "absolute",
        width: "100%",
        bottom: 0,
    }
}));

export default function ItemVertSkeleton({gridProps}) {
    const classes = useStyles();
    return (
        <Grid item {...gridProps}>
            <Card raised={false} elevation={0} square={true} className={classes.card}>
                <CardActionArea>
                    <div className={classes.imageContainer}>
                        <CardMedia>
                            <Skeleton variant={'rect'} className={classes.img}/>
                        </CardMedia>
                        <Skeleton variant={"rect"} height={20} className={classes.float}/>
                    </div>
                    <CardContent className={classes.cardContent}>
                        <Skeleton className={classes.text} height={8} width={'60%'}/>
                        <Skeleton className={classes.text} height={8} width={'80%'}/>
                    </CardContent>
                </CardActionArea>
            </Card>
        </Grid>
    )
}

ItemVertSkeleton.propTypes = {
    gridProps: PropTypes.object,
};