import React, {useEffect, useRef, useState} from 'react';
import PropTypes from 'prop-types';

import {
    Button, CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    TextField, useMediaQuery
} from "@material-ui/core";
import {useTheme} from "@material-ui/core/styles";
import {makeStyles} from "@material-ui/styles";

import {get} from "../../common/i18n/i18n";
import {
    auth,
    linkWithCredential,
    PhoneAuthProvider,
    signInWithPhoneNumber,
    RecaptchaVerifier,
    AuthErrorCodes, unlink
} from "../../../utils/firebase/";
import {showMessage} from "../../common/NotificationSnack";
import {SEVERITY_ERROR} from "../../../utils/constants";

const useStyles = makeStyles(theme => ({
    content: {
        display: "flex",
        flexFlow: "nowrap row",
        justifyContent: "flex-start",
        alignItems: "center"
    },
    progress: {
        marginRight: theme.spacing(1)
    }
}));

const PhoneVerifierDialog = ({children, user, profile, handleUserSave}) => {
    const [open, setOpen] = useState(false);
    const [code, setCode] = useState('');
    const [confirmationResult, setConfirmationResult] = useState(null);
    const [verificationError, setVerificationError] = useState(null);
    const classes = useStyles();

    const recaptchaRef = useRef(null);
    const verifier = useRef(null);
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

    useEffect(() => {
        verifier.current = new RecaptchaVerifier(recaptchaRef.current, {
            'size': 'invisible',
            'callback': response => {
                // reCAPTCHA solved, allow signInWithPhoneNumber. Intentionally left blank
            }
        }, auth);
    }, []);

    const handleClose = () => {
        setConfirmationResult(null);
        setVerificationError(null);
        setOpen(false);
    };

    const handleClickOpen = () => {
        if (!user.verifiedPhone || user.phone !== profile.phone) {
            setOpen(true);
            signInWithPhoneNumber(auth, `+34 ${profile.phone}`, verifier.current)
                .then(confirmationResult =>
                    setConfirmationResult(confirmationResult)
                )
                .catch(error => {
                    const errorMessage = get(`firebase_${error.code}`);
                    showMessage(errorMessage, SEVERITY_ERROR);
                    setVerificationError(errorMessage);
                    setConfirmationResult(null);
                });
        } else {
            handleUserSave();
        }
    }

    const checkVerificationCode = () => {
        if (!!confirmationResult) {
            const credential = PhoneAuthProvider.credential(confirmationResult.verificationId, code);
            linkWithCredential(auth.currentUser, credential)
                .then(onLinkWithCredentialSuccess)
                .catch(error => {
                    if (error.code === AuthErrorCodes.PROVIDER_ALREADY_LINKED) {
                        unlink(auth.currentUser, PhoneAuthProvider.PROVIDER_ID)
                            .then(user =>
                                linkWithCredential(user, credential)
                                    .then(onLinkWithCredentialSuccess)
                                    .catch(onLinkWithCredentialError)
                            )
                    } else {
                        onLinkWithCredentialError(error);
                    }
                });
        }
    }

    const onLinkWithCredentialSuccess = userCredential => {
        console.debug("Account linking success", userCredential.user);
        setVerificationError(null);
        setOpen(false);
        handleUserSave();
    }

    const onLinkWithCredentialError = error => {
        const errorMessage = get(`firebase_${error.code}`);
        showMessage(errorMessage, SEVERITY_ERROR);
        setVerificationError(errorMessage);
    }

    return (
        <div>
            {typeof children === "function" ? children(handleClickOpen) : children}
            <Dialog open={open} onClose={handleClose} disableBackdropClick={true}
                    fullWidth={true} maxWidth={'sm'} fullScreen={fullScreen} >
                <DialogTitle>{get('phone_verification_title')}</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {get('phone_verification_helper')}
                    </DialogContentText>
                    <div className={classes.content}>
                        { !confirmationResult && !verificationError && <CircularProgress size={25} className={classes.progress}/> }
                        <TextField
                            autoFocus
                            margin="dense"
                            label={get('phone_verification_code')}
                            display={"inline"}
                            fullWidth
                            disabled={!confirmationResult}
                            value={code}
                            onChange={event => setCode(event.target.value)}
                            error={!!verificationError}
                            helperText={verificationError}
                        />
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} variant={"outlined"} color={"secondary"}>
                        {get('CANCEL')}
                    </Button>
                    <Button onClick={checkVerificationCode} variant={"contained"} color={"primary"} disabled={!code}>
                        {get('SAVE')}
                    </Button>
                </DialogActions>
            </Dialog>
            <div id="recaptcha-container" ref={recaptchaRef}></div>
        </div>
    );
};

PhoneVerifierDialog.propTypes = {
    user: PropTypes.object,
    profile: PropTypes.object,
    handleUserSave : PropTypes.func.isRequired
};

export default PhoneVerifierDialog;