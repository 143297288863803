import React from 'react'
import PropTypes from "prop-types"

import Button from '@material-ui/core/Button'
import CircularProgress from "@material-ui/core/CircularProgress";
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import {withStyles} from '@material-ui/core/styles'
import CameraIcon from '@material-ui/icons/CameraAlt'

import ReactCrop from 'react-image-crop';
import 'blueimp-canvas-to-blob';
import 'react-image-crop/dist/ReactCrop.css';

import {
    MYUR_AVATAR_MAX_WIDTH,
    BLUE,
    MYUR_AVATAR_ASPECT_RATIO,
    SEVERITY_ERROR,
} from '../../../utils/constants'
import {get} from '../i18n/i18n'
import {getCrop} from "./imageUtils";
import buttonStyles from "../styles/buttonStyles";
import loadImage from "blueimp-load-image";
import {showMessage} from "../NotificationSnack";

let openDialogFn;

const INITIAL_STATE = {
    open: false,
    fileName: undefined,
    fileType: undefined,
    src: undefined,
    croppedImageUrl: undefined,
    loading: false,
    crop: {
        aspect: 1,
        unit: '%',
        width: 100,
        height: 100,
    },
};

const styles = theme => ({
    button: {
        display: 'block',
        marginLeft: 'auto',
        marginRight: 'auto',
        width: '25%',
    },
    camera: {
        margin: theme.spacing(1),
        fontSize: 36,
        color: BLUE,
    },
    loading: {
        width: '100%',
        textAlign: 'center'
    },
    input: {
        display: 'none',
    },
    ...buttonStyles(theme),
});

class ImageDialog extends React.Component {

    constructor(props) {
        super(props);
        this.state = {...INITIAL_STATE};
    }

    componentDidMount() {
        openDialogFn = this.handleClickOpen;
    }

    onImageSelected = event => (fun, image) => {
        event.preventDefault();
        fun(image);
        this.setState({...INITIAL_STATE});
    };

    onSelectFile = e => {
        if (e.target.files && e.target.files.length > 0) {
            const file = e.target.files[0];
            loadImage(file, canvas => {
                this.setState({loading: true});
                if (canvas.type === "error") {
                    this.setState({loading: false});
                    showMessage(get('image_error', [file.name]), SEVERITY_ERROR);
                } else {
                    canvas.toBlob(blob => {
                        this.setState({
                            loading: false,
                            src: URL.createObjectURL(blob),
                            fileName: file.name,
                            fileType: file.type,
                        });
                    });
                }
            }, {orientation: true, canvas: true});
        }

    };

    onImageLoaded = image => {
        this.imageRef = image;
        const crop = getCrop(image.width, image.height, MYUR_AVATAR_ASPECT_RATIO);
        this.setState({
            crop
        });
        this.makeClientCrop(crop);
        return false;
    };

    onCropComplete = crop => this.makeClientCrop(crop);

    onCropChange = crop => this.setState({crop});

    async makeClientCrop(crop) {
        if (this.imageRef && crop.width && crop.height) {
            const {fileName, fileType} = this.state;
            const croppedImageUrl = await this.getCroppedImg(
                this.imageRef,
                crop,
                fileName,
                fileType,
            );
            this.setState({croppedImageUrl});
        }
    }

    getCroppedImg(image, crop, fileName, fileType) {
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');
        const scaleX = image.naturalWidth / image.width;
        const scaleY = image.naturalHeight / image.height;
        let dWidth = crop.width;
        canvas.width = crop.width;
        canvas.height = crop.height;
        if (crop.width > MYUR_AVATAR_MAX_WIDTH) {
            canvas.width = MYUR_AVATAR_MAX_WIDTH;
            canvas.height = MYUR_AVATAR_MAX_WIDTH;
            dWidth = MYUR_AVATAR_MAX_WIDTH;
        }
        ctx.drawImage(
            image,
            crop.x * scaleX,
            crop.y * scaleY,
            crop.width * scaleX,
            crop.height * scaleY,
            0,
            0,
            dWidth,
            dWidth,
        );

        return new Promise((resolve, reject) => {
            canvas.toBlob(blob => {
                if (!blob) {
                    reject(new Error('Canvas is empty'));
                }
                blob.name = fileName;
                resolve(blob);
            }, fileType);
        });
    }

    handleClickOpen = () => {
        this.setState({open: true});
    };

    handleClose = () => {
        if (!!this.imageRef) {
            window.URL.revokeObjectURL(this.imageRef);
        }
        this.setState({...INITIAL_STATE});
    };

    render() {
        const {open, src, crop, croppedImageUrl, loading} = this.state;
        const {title, accept, onImageSelected, classes} = this.props;
        const disabled = !croppedImageUrl;
        return (
            <Dialog
                open={open}
                onClose={this.handleClose}
            >
                <DialogTitle>{title}</DialogTitle>
                <DialogContent>

                    {
                        loading &&
                        <div className={classes.loading}>
                            <CircularProgress size={68}/>
                        </div>
                    }
                    {src && (
                        <ReactCrop
                            src={src}
                            crop={crop}
                            onImageLoaded={this.onImageLoaded}
                            onComplete={this.onCropComplete}
                            onChange={this.onCropChange}
                            onImageError={event => showMessage(get('image_error', ['']), SEVERITY_ERROR)}
                        />
                    )}

                    <div>
                        <DialogContentText>
                            {get('select_and_crop')}
                        </DialogContentText>
                        <label htmlFor="file_image" className={classes.button}>
                            <Button variant="contained" component={'span'} size="large" disabled={loading} className={classes.camera}>
                                <CameraIcon/>
                            </Button>
                        </label>

                        <input
                            className={classes.input}
                            type={'file'}
                            name={'image'}
                            id={'file_image'}
                            onChange={this.onSelectFile}
                            accept={accept}
                        />
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button onClick={this.handleClose} className={classes.defaultButton}>
                        {get('CANCEL')}
                    </Button>
                    <Button disabled={disabled} className={classes.blueButton}
                            onClick={event => this.onImageSelected(event)(onImageSelected, croppedImageUrl)}>
                        {get('update')}
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
}

ImageDialog.propTypes = {
    title: PropTypes.string.isRequired,
    accept: PropTypes.string,
    onImageSelected: PropTypes.func.isRequired,
};

ImageDialog.defaultProps = {
    accept: 'image/png, image/jpeg',
};

export function openImageDialog() {
    openDialogFn();
}

export default withStyles(styles)(ImageDialog);