import React from 'react'

import {PropTypes} from 'prop-types'
import {Typography} from "@material-ui/core";
import {withRouter, Link} from 'react-router-dom'

import {DEFAULT_PHOTO_WIDTH} from "../../../utils/constants";
import FavIcon from '../../../images/icon_favoritos_on.png'
import clsx from "clsx";
import ConfirmationDialog from "../../common/ConfirmationDialog";
import {get} from "../../common/i18n/i18n";
import useFavouriteStyles from "./FavouriteStyles";
import {getPublicUrl} from "../../item/item_utils";

function FavouriteCard({fav, removeFavorite}) {
    const classes = useFavouriteStyles();

    return (
        <div className={clsx(classes.root, classes.border)}>
            <div className={classes.info}>
                <img alt={fav.title} width={DEFAULT_PHOTO_WIDTH/2} src={fav.photoUrl}/>
                <Link to={getPublicUrl(fav)} className={classes.link}>
                    <Typography variant={"body1"} className={classes.title}>{fav.title}</Typography>
                </Link>
            </div>
            <ConfirmationDialog
                title={get('delete_favorite_title')}
                content={get('delete_favorite_content')}
                onConfirmation={removeFavorite}
                className={classes.icon}
                button={
                    props =>
                        <img src={FavIcon} alt={fav.title} {...props}/>
                }/>

        </div>
    )
}

FavouriteCard.TypeProps = {
    fav: PropTypes.object.isRequired,
    removeFavorite: PropTypes.func.isRequired
};

export default withRouter(FavouriteCard);