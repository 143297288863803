import React, {Component} from 'react'
import {Link, withRouter} from 'react-router-dom'
import {compose} from 'recompose'
import {doSignInWithEmailAndPassword} from '../../utils/firebase'
import Paper from "@material-ui/core/Paper"
import withStyles from '@material-ui/core/styles/withStyles'
import Typography from "@material-ui/core/Typography"
import TextField from "@material-ui/core/TextField"
import Button from "@material-ui/core/Button"
import Grid from '@material-ui/core/Grid'
import PasswordForgetLink from "./PassForgetLink";
import {SignUpLink} from "./SignUpComponent";
import GoogleButton from "./GoogleButton";
import {LANDING, ROUTE_SIGN_IN, ROUTE_SIGN_UP} from "../../utils/routes";
import layoutStyles from "../common/styles/layoutStyles";
import {get} from "../common/i18n/i18n";
import clsx from "clsx";
import {connect} from "react-redux";
import {buildUrl, doFetch} from "../../utils/http";
import {API_USER_EXISTS, SEVERITY_WARNING} from "../../utils/constants";
import {getUid} from "../../utils/utils";
import {showMessage} from "../common/NotificationSnack";

const byPropKey = (propertyName, value) => () => ({
    [propertyName]: value,
});

const INITIAL_STATE = {
    email: '',
    password: '',
    error: null,
};

const styles = theme => ({
    form: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'center',
    },
    headline: {
        textAlign: 'center',
    },
    textField: {
        marginLeft: theme.spacing(),
        marginRight: theme.spacing(),
    },
    error: {
        fontSize: 'small',
        color: 'tomato'
    },
    paper: {
        margin: 10,
        padding: theme.spacing(3),
        textAlign: 'left',
        minWidth: '75%',
        maxWidth: '960px',
    },
    button: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1)
    }
});

class SignInComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {...INITIAL_STATE};
    }

    componentDidMount() {
        const uid = getUid();
        if(!!uid) {
            this.onCredentials();
        }
    }

    onSubmit = event => {
        event.preventDefault();
        const {email, password} = this.state;
        doSignInWithEmailAndPassword(email, password)
            .then(result => {
                const {uid, email, emailVerified} = result.user;
                return doFetch(
                    buildUrl(API_USER_EXISTS, {uid}),
                    user => {
                        if (user.uid === uid) { //user.uid is null if user does not exist.
                            this.onCredentials();
                        } else {
                            this.onZombieUser(uid, email, emailVerified);
                        }
                    },
                    () => this.onZombieUser(uid, email, emailVerified),
                    {method: 'GET'}
                );
            })
            .catch(error => {
                this.setState(byPropKey('error', error));
            });
    };

    onCredentials = () => {
        this.setState(() => ({...INITIAL_STATE}));
        this.props.history.push(LANDING);
    };

    onZombieUser = (uid, email, emailVerified) => {
        showMessage(get('sign_in_zombie', SEVERITY_WARNING));
        this.props.history.push(ROUTE_SIGN_UP, {uid, email, emailVerified});
    };

    render() {
        const {email, password, error} = this.state;
        const {classes} = this.props;

        const isInvalid = password === '' || email === '';

        return (
            <form onSubmit={this.onSubmit} className={classes.form}>
                <Paper className={classes.paper}>
                    <Typography variant="h5" component="h3" className={classes.headline}>
                        {get('sign_in_login')}
                    </Typography>
                    <Grid container direction={"column"}>
                        <TextField
                            id='email'
                            label={get('sign_in_email')}
                            className={classes.textField}
                            value={email}
                            onChange={event => this.setState(byPropKey('email', event.target.value))}
                            margin="normal"
                        />
                        <TextField
                            id='password'
                            label={get('sign_in_password')}
                            className={classes.textField}
                            type="password"
                            value={password}
                            onChange={event => this.setState(byPropKey('password', event.target.value))}
                            margin="normal"
                        />
                        <Button
                            variant="contained"
                            color="primary"
                            disabled={isInvalid}
                            type={'submit'}
                        >
                            {get('sign_in_send')}
                        </Button>
                        <GoogleButton className={classes.button} onCredentials={this.onZombieUser}/>
                        <PasswordForgetLink/>
                        {error && <p className={classes.error}>{error.message}</p>}
                    </Grid>
                </Paper>
                <SignUpLink/>
            </form>
        );
    }
}

export const SignInLink = withStyles(theme => ({
    ...layoutStyles(theme),
    root: {
        margin: theme.spacing(2, 0),
    },
    white: {
        color: theme.palette.common.white,
    },
}))(({classes}) =>
    <div className={classes.root}>
        <Typography variant={"h5"} display={"inline"} className={classes.white}>
            {get('auth_already_account')}
        </Typography>
        {' '}
        <Typography variant={"h5"} component={Link} to={ROUTE_SIGN_IN} className={clsx(classes.link, classes.white)}>
            {get('auth_use_account')}
        </Typography>
    </div>
);

const mapStateToProps = ({searchItems, session}) => ({
    authUser: session.profile,
    profile: session.profile,
});

export default compose(
    withStyles(styles),
    withRouter,
    connect(mapStateToProps)
)(SignInComponent);
