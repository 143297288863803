import React from 'react';
import PropTypes from 'prop-types';
import {Redirect, useLocation} from "react-router-dom";

import {LANDING} from "../../utils/routes";
import {buildUrl} from "../../utils/http";
import qs from "qs";

const RedirectComponent = ({destinationUrl, destinationParam, queryParam}) => {
    const location = useLocation();
    if (!!destinationUrl) {
        const query = qs.parse(location.search, {depth: 1, ignoreQueryPrefix: true})
        if (!!query && !!query[queryParam]) {
            return <Redirect to={buildUrl(destinationUrl, {[destinationParam]: query[queryParam]})}/>
        }
        if (!destinationParam && !queryParam) {
            return <Redirect to={destinationUrl}/>
        }
    }
    return (
        <Redirect to={LANDING}/>
    );
};

RedirectComponent.propTypes = {
    destinationUrl: PropTypes.string,
    destinationParam: PropTypes.string,
    queryParam: PropTypes.string
};

export default RedirectComponent;