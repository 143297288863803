import React from 'react';
import {PropTypes} from 'prop-types';
import {useHistory} from 'react-router-dom';

import {makeStyles, withStyles, Menu, MenuItem, Typography, useMediaQuery, useTheme,} from "@material-ui/core";
import DropdownIcon from "@material-ui/icons/ExpandMore";
import MenuIcon from "@material-ui/icons/Menu";

import '../../index.css';
import {get} from "../common/i18n/i18n";

import {MARINE, PALE_BLUE} from "../../utils/constants";
import layoutStyles from "../common/styles/layoutStyles";

const useStyles = makeStyles(theme => ({
    header: {
        marginLeft: theme.spacing(2),
        color: MARINE,
    },
    img: {
        marginRight: theme.spacing(1),
        width: 24,
        height: 24,
    },
    row: {
        display: 'flex',
        flexFlow: 'wrap row',
        alignItems: 'center',
    },
    ...layoutStyles(theme),
}));

const StyledMenu = withStyles({
    paper: {
        border: `1px solid ${PALE_BLUE}`,
    },
})(props => (
    <Menu
        elevation={0}
        getContentAnchorEl={null}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
        }}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
        }}
        PaperProps={{square: true}}
        {...props}
    />
));

const StyledMenuItem = withStyles(theme => ({
    root: {
        '&:hover': {
            backgroundColor: PALE_BLUE,
        },
    },
}))(MenuItem);

export default function MyurDropdown({label, variant, options}) {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const classes = useStyles();
    const theme = useTheme();
    const mobile = useMediaQuery(theme.breakpoints.down('sm'));
    const history = useHistory();

    const handleClick = event => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = section => {
        history.push(section.url);
        setAnchorEl(null);
    };

    return (
        <>
            <div className={classes.defaultRowNoWrap} onClick={handleClick}>
                {
                    mobile ?
                        <MenuIcon style={{fontSize: "xx-large"}}/>
                        :
                        <>
                            <Typography className={classes.header} variant={variant}>
                                {get(label)}
                            </Typography>
                            <DropdownIcon/>
                        </>
                }
            </div>
            <StyledMenu
                anchorEl={anchorEl}
                keepMounted
                anchorReference={'anchorEl'}
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                {
                    options.map(section =>
                        <StyledMenuItem onClick={() => handleClose(section)} key={section.text}>
                            <Typography variant={'body1'} className={'row'}>
                                {get(section.text)}
                            </Typography>
                        </StyledMenuItem>
                    )
                }
            </StyledMenu>
        </>
    );
}

MyurDropdown.propTypes = {
    label: PropTypes.string.isRequired,
    variant: PropTypes.string,
    options: PropTypes.arrayOf(
        PropTypes.shape({
            text: PropTypes.string,
            icon: PropTypes.string,
            url: PropTypes.string,
        })
    ),
};
