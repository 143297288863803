import React, {Component} from 'react';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import {withStyles, MuiThemeProvider, createTheme, responsiveFontSizes} from "@material-ui/core/styles";
import {
    BrowserRouter as Router,
    Route, Switch, Redirect
} from 'react-router-dom'
import {compose} from 'recompose'
import MomentUtils from "@date-io/moment";
import moment from 'moment'
import "moment/locale/es";

import {MuiPickersUtilsProvider} from '@material-ui/pickers'

import './index.css';

import * as routes from "./utils/routes";
import MainComponent from "./components/landing/MainComponent";
import SignInComponent from "./components/auth/SignInComponent";
import PassForgetComponent from "./components/auth/PassForgetComponent";
import PassChangeComponent from "./components/auth/PassChangeComponent";
import withAuthentication from './components/auth/withAuthentication'
import LiveSearchComponent from "./components/live/LiveSearchComponent";
import MyLoanListComponent from "./components/loan/MyLoanListComponent";
import LoanDetailComponent from "./components/loan/LoanDetailComponent";
import UserProfileComponent from "./components/user/detail/UserProfileComponent";
import FavListComponent from "./components/user/detail/FavListComponent";
import ChatListComponent from "./components/chat/ChatListComponent";
import ChatComponent from "./components/chat/ChatComponent";
import ShareComponent from "./components/app/ShareComponent";
import HelpComponent from "./components/app/HelpComponent";
import NotificationSnack from "./components/common/NotificationSnack";
import UserDetailComponent from "./components/user/public/UserDetailComponent";

import EarnsComponent from "./components/user/detail/EarnsComponent";
import ItemListComponent from "./components/user/item/ItemListComponent";
import ReviewListComponent from "./components/user/detail/ReviewListComponent";
import LiveSearchResponseComponent from "./components/live/LiveSearchResponseComponent";
import SignUpStepper from "./components/auth/SignUpStepper";
import FootComponent from "./components/navigation/FootComponent";
import UserNotificationsComponent from "./components/user/notifications/UserNotificationsComponent";
import {LS_COOKIE_CONSENT} from "./utils/localStorage";
import ItemComposerComponent from "./components/item/ItemComposerComponent";
import NotFound from "./components/navigation/NotFound";
import CookieConsent from "./components/landing/CookieConsent";
import {HTML_FONT_SIZE, MYUR_BASE_ROUTE} from "./utils/constants";
import ContactComponent from "./components/landing/ContactComponent";
import {esES} from "@material-ui/core/locale";
import ErrorBoundary from "./components/common/ErrorBoundary";
import TopComponent from "./components/navigation/TopComponent";
import RedirectComponent from "./components/navigation/RedirectComponent";
import {auth, onAuthStateChanged} from "./utils/firebase";
import {registerServiceWorker} from "./serviceWorker";
import ItemComponent from "./components/item/detail/ItemComponent";

moment.locale('es');

const INITIAL_STATE = {
    cookieConsent: true
};

const styles = theme => ({
    App: {
        textAlign: 'center',
    },
    content: {
        width: '100%',
        display: 'flex',
        flexFlow: 'row wrap',
        justifyContent: 'center',
        alignContent: 'space-between',
//        alignItems: 'flex-start',
        marginLeft: 'auto',
        marginRight: 'auto',
        minHeight: '80vh',
    },
});
/*
    Raleway font weights:
        * Regular: 400
        * Medium: 500
        * Semibold: 600
        * Bold: 700
        * Extra bold: 800
        * Black: 900
 */
let theme = createTheme({
    typography: {
        useNextVariants: true,
        fontFamily: [
            'Raleway', '-apple-system', 'system-ui', 'BlinkMacSystemFont', "Segoe UI", "Roboto", "Oxygen",
            "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", 'sans-serif',
        ].join(','),
        htmlFontSize: HTML_FONT_SIZE,
        fontSize: HTML_FONT_SIZE - 2,
        fontWeightLight: 300,
        fontWeightRegular: 400,
        fontWeightMedium: 500,
        fontWeightBold: 700,
        h1: {
            fontFamily: [
                'Raleway', '-apple-system', 'system-ui', 'BlinkMacSystemFont', "Segoe UI", "Roboto", "Oxygen",
                "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", 'sans-serif',
            ].join(','),
            fontWeight: 900,
            fontSize: "1.625rem",
            lineHeight: 1.7,
            letterSpacing: "-0.01562em",
        },
        h2: {
            fontFamily: [
                'Raleway', '-apple-system', 'system-ui', 'BlinkMacSystemFont', "Segoe UI", "Roboto", "Oxygen",
                "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", 'sans-serif',
            ].join(','),
            fontWeight: 700,
            fontSize: "1.375rem",
            lineHeight: 1.7,
            letterSpacing: "-0.00833em",
        },
        h3: {
            fontFamily: [
                'Raleway', '-apple-system', 'system-ui', 'BlinkMacSystemFont', "Segoe UI", "Roboto", "Oxygen",
                "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", 'sans-serif',
            ].join(','),
            fontWeight: 900,
            fontSize: "1.25rem",
            lineHeight: 1.7,
            letterSpacing: "0em",
        },

        h4: {
            fontFamily: [
                'Raleway', '-apple-system', 'system-ui', 'BlinkMacSystemFont', "Segoe UI", "Roboto", "Oxygen",
                "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", 'sans-serif',
            ].join(','),
            fontWeight: 700,
            fontSize: "1rem",
            lineHeight: 1.7,
            letterSpacing: "0.00735em",
        },
        h5: {
            fontFamily: [
                'Raleway', '-apple-system', 'system-ui', 'BlinkMacSystemFont', "Segoe UI", "Roboto", "Oxygen",
                "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", 'sans-serif',
            ].join(','),
            fontWeight: 700,
            fontSize: "0.875rem",
            lineHeight: 1.7,
            letterSpacing: "0em",
        },

        h6: {
            fontFamily: [
                'Raleway', '-apple-system', 'system-ui', 'BlinkMacSystemFont', "Segoe UI", "Roboto", "Oxygen",
                "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", 'sans-serif',
            ].join(','),
            fontWeight: 700,
            fontSize: "0.8125rem",
            lineHeight: 1.7,
            letterSpacing: "0.0075em",
        },
        subtitle1: {
            fontFamily: [
                'Raleway', '-apple-system', 'system-ui', 'BlinkMacSystemFont', "Segoe UI", "Roboto", "Oxygen",
                "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", 'sans-serif',
            ].join(','),
            fontWeight: 500,
            fontSize: "1.25rem",
            lineHeight: 1.7,
            letterSpacing: "0.00938em",
        },
        subtitle2: {
            fontFamily: [
                'Raleway', '-apple-system', 'system-ui', 'BlinkMacSystemFont', "Segoe UI", "Roboto", "Oxygen",
                "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", 'sans-serif',
            ].join(','),
            fontWeight: 500,
            fontSize: "1.125rem",
            lineHeight: 1.7,
            letterSpacing: "0.00714em",
        },
        body1: {
            fontFamily: [
                'Raleway', '-apple-system', 'system-ui', 'BlinkMacSystemFont', "Segoe UI", "Roboto", "Oxygen",
                "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", 'sans-serif',
            ].join(','),
            fontWeight: 500,
            fontSize: "1rem",
            lineHeight: 1.5,
            letterSpacing: "0.00938em",
        },
        body2: {
            fontFamily: [
                'Raleway', '-apple-system', 'system-ui', 'BlinkMacSystemFont', "Segoe UI", "Roboto", "Oxygen",
                "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", 'sans-serif',
            ].join(','),
            fontWeight: 500,
            fontSize: "0.8125rem",
            lineHeight: 1.43,
            letterSpacing: "0.01071em",
        },
        button: {
            fontFamily: [
                'Raleway', '-apple-system', 'system-ui', 'BlinkMacSystemFont', "Segoe UI", "Roboto", "Oxygen",
                "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", 'sans-serif',
            ].join(','),
            fontWeight: 700,
            fontSize: "0.8125rem",
            lineHeight: 1.75,
            letterSpacing: "0.02857em",
            textTransform: "uppercase",
        },
        caption: {
            fontFamily: [
                'Raleway', '-apple-system', 'system-ui', 'BlinkMacSystemFont', "Segoe UI", "Roboto", "Oxygen",
                "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", 'sans-serif',
            ].join(','),
            fontWeight: 500,
            fontSize: "0.75rem",
            lineHeight: 1.66,
            letterSpacing: "0.03333em",
        },
        overline: {
            fontFamily: [
                'Raleway', '-apple-system', 'system-ui', 'BlinkMacSystemFont', "Segoe UI", "Roboto", "Oxygen",
                "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", 'sans-serif',
            ].join(','),
            fontWeight: 500,
            fontSize: "0.75rem",
            lineHeight: 2.66,
            letterSpacing: "0.08333em",
            textTransform: "uppercase",
        }
    }
}, esES);

theme.typography.h1 = {
    ...theme.typography.h1,
    [theme.breakpoints.down('md')]: {
        fontSize: '1rem',
    },
};

theme.typography.h2 = {
    ...theme.typography.h2,
    [theme.breakpoints.down('md')]: {
        fontSize: '0.8125rem',
    },
};

theme = responsiveFontSizes(theme);

class App extends Component {

    constructor(props) {
        super(props);
        this.state = {...INITIAL_STATE};
        this.onAuthStateChangeUnsubscribe = null;
    };

    shouldComponentUpdate(nextProps, nextState, nextContext) {
        // Needed as App was rendered twice on app loaded.
        return (this.state.cookieConsent !== nextState.cookieConsent);
    }

    componentDidMount() {
        this.setState({
            cookieConsent: localStorage.getItem(LS_COOKIE_CONSENT) === 'true',
        });
        this.onAuthStateChangeUnsubscribe = onAuthStateChanged(auth, authUser => {
            if (!!authUser) {
                registerServiceWorker();
            }
        })
    }

    componentWillUnmount() {
        if (!!this.onAuthStateChangeUnsubscribe) {
            this.onAuthStateChangeUnsubscribe();
        }
    }

    render() {
        const {classes} = this.props;
        const {cookieConsent} = this.state;
        return (
            <MuiThemeProvider theme={theme}>
                <Router basename={'/' + MYUR_BASE_ROUTE + '/'}>
                    <div className={classes.App}>
                        <TopComponent/>
                        <ErrorBoundary>
                            <MuiPickersUtilsProvider utils={MomentUtils} moment={moment}>
                                <div className={classes.content}>
                                    <Switch>
                                        <Route exact path={routes.LANDING}>
                                            <MainComponent cookieConsent={cookieConsent}/>
                                        </Route>
                                        <Route exact path={routes.ROUTE_ITEM_NEW}>
                                            <ItemComposerComponent/>
                                        </Route>
                                        <Route exact path={routes.ROUTE_ITEM_EDIT}>
                                            <ItemComposerComponent/>
                                        </Route>
                                        <Route exact path={routes.ROUTE_ITEM}>
                                            <ItemComponent/>
                                        </Route>
                                        <Route exact path={routes.ROUTE_SHARE_ITEM}>
                                            <ItemComponent/>
                                        </Route>
                                        <Route exact path={routes.ROUTE_LIVE_SEARCH}>
                                            <LiveSearchComponent/>
                                        </Route>
                                        <Route exact path={routes.ROUTE_SHARE_LIVE_SEARCH}>
                                            <RedirectComponent destinationUrl={routes.ROUTE_LIVE_SEARCH}/>
                                        </Route>
                                        <Route exact path={routes.ROUTE_LIVE_SEARCH_DETAIL}>
                                            <LiveSearchResponseComponent/>
                                        </Route>
                                        <Route exact path={routes.ROUTE_LOANS}>
                                            <MyLoanListComponent/>
                                        </Route>
                                        <Route exact path={routes.ROUTE_LOAN}>
                                            <LoanDetailComponent/>
                                        </Route>
                                        <Route exact path={routes.ROUTE_SHARE_LOAN}>
                                            <RedirectComponent destinationUrl={routes.ROUTE_LOAN}
                                                               destinationParam={'loanId'} queryParam={'l'}/>
                                        </Route>
                                        <Route exact path={routes.ROUTE_PROFILE}>
                                            <UserProfileComponent/>
                                        </Route>
                                        <Route exact path={routes.ROUTE_NOTIFICATIONS}>
                                            <UserNotificationsComponent/>
                                        </Route>
                                        <Route exact path={routes.ROUTE_CHATS}>
                                            <ChatListComponent/>
                                        </Route>
                                        <Route exact path={routes.ROUTE_CHAT}>
                                            <ChatComponent/>
                                        </Route>
                                        <Route exact path={routes.ROUTE_MY_ITEMS}>
                                            <ItemListComponent/>
                                        </Route>
                                        <Route exact path={routes.ROUTE_FAVS}>
                                            <FavListComponent/>
                                        </Route>
                                        <Route exact path={routes.ROUTE_REVIEWS}>
                                            <ReviewListComponent/>
                                        </Route>
                                        <Route exact path={routes.ROUTE_SHARE}>
                                            <ShareComponent/>
                                        </Route>
                                        <Route exact path={routes.ROUTE_EARNS}>
                                            <EarnsComponent/>
                                        </Route>
                                        <Route exact path={routes.ROUTE_HELP}>
                                            <HelpComponent/>
                                        </Route>
                                        <Route exact path={routes.ROUTE_USER_DETAIL}>
                                            <UserDetailComponent/>
                                        </Route>
                                        <Route exact path={routes.ROUTE_SHARE_USER}>
                                            <RedirectComponent destinationUrl={routes.ROUTE_USER_DETAIL}
                                                               destinationParam={'uid'} queryParam={'u'}/>
                                        </Route>
                                        <Route exact path={routes.ROUTE_CONTACT}>
                                            <ContactComponent/>
                                        </Route>
                                        <Route exact path={routes.ROUTE_SIGN_UP}>
                                            <SignUpStepper/>
                                        </Route>
                                        <Route exact path={routes.ROUTE_SIGN_IN}>
                                            <SignInComponent/>
                                        </Route>
                                        <Route exact path={routes.PASS_FORGET}>
                                            <PassForgetComponent/>
                                        </Route>
                                        <Route exact path={routes.PASS_CHANGE}>
                                            <PassChangeComponent/>
                                        </Route>
                                        <Route>
                                            <NotFound/>
                                        </Route>
                                        <Redirect from="/" to="/myur"/>
                                    </Switch>
                                </div>
                                <Switch>
                                    <Route exact path={[routes.ROUTE_CHATS, routes.ROUTE_CHAT]}/>
                                    <Route>
                                        <FootComponent/>
                                    </Route>
                                </Switch>
                            </MuiPickersUtilsProvider>
                            {!cookieConsent &&
                            <CookieConsent
                                updateCookieConsent={value => this.setState({cookieConsent: value})}
                                open={!cookieConsent}/>
                            }
                            <NotificationSnack/>
                        </ErrorBoundary>
                    </div>
                </Router>
            </MuiThemeProvider>
        );
    }
}

export default compose(
    withStyles(styles),
    withAuthentication,
)(App);
