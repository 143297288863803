import React from 'react';
import {analytics, logEvent} from "../../utils/firebase";
import {Link, withRouter} from 'react-router-dom';

import {Typography} from "@material-ui/core";

import logo from '../../images/walrus_small.png';
import {get} from "./i18n/i18n";
import {LANDING} from "../../utils/routes";
import {makeStyles} from "@material-ui/core/styles";
import layoutStyles from "./styles/layoutStyles";

const useStyles = makeStyles(theme => ({
    ...layoutStyles(theme),
}));

const ErrorPage = props => {
    const classes = useStyles();
    return (
        <div className="fof">
            <img alt="myur! logo" src={logo}/>
            <h1>{get('app_error')}</h1>
            <Typography variant={"h4"} component={Link} to={LANDING} className={classes.link}>
                {get('app_error_link')}
            </Typography>
        </div>
    );
}

class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = {hasError: false};
        const { history } = this.props;

        history.listen((location, action) => {
            if (this.state.hasError) {
                this.setState({
                    hasError: false,
                });
            }
        });
    }

    static getDerivedStateFromError(error) {
        return {hasError: true};
    }

    componentDidCatch(error, errorInfo) {
        const message = `Cause: ${error}. \nStackTrace: ${errorInfo.componentStack}`;
        console.error(message);

        if (process.env.NODE_ENV !== 'production') {
            logEvent(analytics,"exception", {
                description: message,
                fatal: true,
            });
        }
    }

    render() {
        if (this.state.hasError) {
            return <ErrorPage/>;
        }
        return this.props.children;
    }
}

export default withRouter(ErrorBoundary);
