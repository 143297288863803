import React, {Component} from 'react'
import {compose} from 'recompose'
import {connect} from 'react-redux'
import withAuthorization from '../auth/withAuthorization'
import {withRouter} from "react-router-dom";
import {PropTypes} from 'prop-types';
import shortid from 'shortid';
import loadImage from 'blueimp-load-image';

import {Typography, Paper, Button} from '@material-ui/core';

import withStyles from '@material-ui/core/styles/withStyles'

import {
    PALE_SAND,
    API_ITEM_CREATE_ITEM,
    DEFAULT_WIDTH,
    API_ITEM_MY_ITEM_DETAIL,
    API_ITEM_UPDATE_MULTIPART,
    DATE_FORMAT,
    SEVERITY_SUCCESS,
    SEVERITY_ERROR,
    MAX_ITEM_PHOTOS,
    MYUR_ITEM_PHOTO_ASPECT_RATIO,
    MYUR_ITEM_PHOTO_MAX_WIDTH,
    API_LIVE_RESPONSE,
    DEFAULT_INSURANCE,
    EXTRA_DATA_VEHICLE,
    EXTRA_DATA_BOAT,
    API_MASTER_VEHICLE_BOAT_SUBTYPES_BY_TYPE,
    API_MASTER_VEHICLE_BOAT_TYPES_BY_CATEGORY,
    ANALYTICS_UPDATE_ITEM,
    ANALYTICS_NEW_ITEM, ANALYTICS_RESPONSE_TO_LIVE_SEARCH
} from "../../utils/constants";

import moment from 'moment'
import "moment/locale/es";

import {showMessage} from "../common/NotificationSnack";
import {cropAndResize} from "../common/image/imageUtils";
import {BOAT_TEMPLATE, ITEM_TEMPLATE, VEHICLE_TEMPLATE} from "./itemTemplate";
import {buildUrl, doGet, doPost, doPut} from "../../utils/http";
import ItemForm from "./ItemForm";
import {get} from "../common/i18n/i18n";
import ProgressButton from "../common/ProgressButton";
import layoutStyles from "../common/styles/layoutStyles";
import clsx from "clsx";
import ComponentWrapper from "../common/ComponentWrapper";
import {ROUTE_MY_ITEMS} from "../../utils/routes";
import {depositRequired, validate_item} from "./item_validation";
import {getPublicUrl, isServiceOrSpace} from "./item_utils";
import {analytics, logEvent} from "../../utils/firebase";

const styles = theme => ({
    root: {
        background: PALE_SAND,
        width: DEFAULT_WIDTH,
        marginTop: theme.spacing(2),
        padding: theme.spacing(1),
        [theme.breakpoints.down('md')]: {
            width: '100%',
        },
    },
    title: {
        textAlign: 'left',
        marginLeft: theme.spacing(1),
        marginTop: theme.spacing(1),
    },
    button: {
        margin: theme.spacing(1),
    },
    action: {
        justifyContent: 'center',
        alignItems: 'center',
    },
    ...layoutStyles(theme),
});

const INITIAL_STATE = {
    item: {...ITEM_TEMPLATE},
    saving: false,
    loading: false,
    error: false,
    validationErrors: {...validate_item()},
};

class ItemComposerComponent extends Component {

    constructor(props) {
        super(props);
        this.state = {...INITIAL_STATE};
        this.imagesUpdated = {};
        this.imagesDeleted = []; // order is not important.
        this.specificResponseDTO = undefined;
    }

    componentDidMount() {
        const {itemId, uid} = this.props.match.params;
        if (!!this.props.location.state) {
            this.specificResponseDTO = this.props.location.state.specificResponseDTO;
        }
        if (!!itemId) {
            const url = buildUrl(API_ITEM_MY_ITEM_DETAIL, {uid, itemId});
            this.setState({loading: true, error: false});
            doGet(url)
                .then(this.updateItem)
                .catch(this.onError);
        }
    }

    setItemDetails = item => {
        return item;
    }

    updateItem = item => {
        if (!!item.category && item.category.extraDataRequired === EXTRA_DATA_VEHICLE && !item.vehicleDetailDTO) {
            item.vehicleDetailDTO = {...VEHICLE_TEMPLATE};
            item.boatDetailDTO = undefined;
        }
        if (!!item.category && item.category.extraDataRequired !== EXTRA_DATA_VEHICLE && !!item.vehicleDetailDTO) {
            item.vehicleDetailDTO = undefined;
        }
        if (!!item.category && item.category.extraDataRequired === EXTRA_DATA_BOAT && !item.boatDetailDTO) {
            item.boatDetailDTO = {...BOAT_TEMPLATE};
            item.vehicleDetailDTO = undefined;
        }
        if (!!item.category && item.category.extraDataRequired !== EXTRA_DATA_BOAT && !!item.boatDetailDTO) {
            item.boatDetailDTO = undefined;
        }
        if (!!item.vehicleDetailDTO && !!item.vehicleDetailDTO.type) {
            this.retrieveSubtypes(item, "vehicleDetailDTO");
        } else if (!!item.boatDetailDTO && !!item.boatDetailDTO.type) {
            this.retrieveSubtypes(item, "boatDetailDTO");
        } else {
            this.setState({loading: false, item});
        }

    };

    retrieveSubtypes = (item, detail) => {
        const urlSubtype = buildUrl(API_MASTER_VEHICLE_BOAT_SUBTYPES_BY_TYPE, {parentTypeName: item[detail].type});
        doGet(urlSubtype)
            .then(response => {
                if (!!response && response.length) {
                    const subtype = response.find(vehicleBoatType => vehicleBoatType.typeName === item[detail].subtype);
                    item[detail].subtype = subtype;
                    this.setState({item, loading: false});
                } else {
                    item[detail].subtype = null;
                    this.setState({item, loading: false})
                }
            })
            .catch(error => this.onError(error));
        const urlType = buildUrl(API_MASTER_VEHICLE_BOAT_TYPES_BY_CATEGORY, {subCategoryId: item.category.categoryId})
        doGet(urlType)
            .then(response => {
                if (!!response && response.length) {
                    const type = response.find(vehicleBoatType => vehicleBoatType.typeName === item[detail].type);
                    item[detail].type = type;
                    this.setState({item, loading: false});
                } else {
                    item[detail].type = null;
                    item[detail].subtype = null;
                    this.setState({item, loading: false})
                }
            })
            .catch(error => this.onError(error));
    }

    onError = error => {
        this.setState({loading: false, saving: false, error: true});
        showMessage(error);
    };

    componentWillUnmount() {
        this.clearImages();
        this.setState({...INITIAL_STATE});
    }

    onCancel = () => {
        this.clearImages();
        this.setState({...INITIAL_STATE});
        this.props.history.goBack();
    };

    clearImages = () => {
        for (let i = 0; i < MAX_ITEM_PHOTOS; i++) {
            if (!!this.imagesUpdated[i]) {
                window.URL.revokeObjectURL(this.imagesUpdated[i].src);
            }
        }
    };

    onInsuranceConditionsAccepted = event => {
        event.persist();
        this.setState(prevState => {
                const insuranceRequired = !prevState.item.insuranceRequired;
                return {
                    item: {
                        ...prevState.item,
                        insurance: insuranceRequired ? DEFAULT_INSURANCE : null,
                        insuranceType: insuranceRequired ? DEFAULT_INSURANCE.type : 0,
                        insuranceCode: insuranceRequired ? DEFAULT_INSURANCE.code : '',
                        insuranceRequired,
                    },
                }
            }
        );
    };

    onItemEnable = event => {
        event.persist();
        this.setState(prevState => {
                const enabled = !prevState.item.enabled;
                return {
                    item: {
                        ...prevState.item,
                        enabled,
                    },
                }
            }
        );
    };

    handleUpdate = event => {
        const {id, value} = event.target;
        this.setState(prevState => ({
            item: {
                ...prevState.item,
                [id]: value,
            }
        }));
    };

    handleUpdateNumber = field => event => {
        const number = Number(event.target.value);
        if (number !== 0 && !number) {
            return;
        }
        this.setState(prevState => {
            const isPurchasePrice = field === 'purchasePrice';
            return ({
                item: {
                    ...prevState.item,
                    insurance: isPurchasePrice ? null : prevState.insurance,
                    insuranceType: isPurchasePrice ? 0 : prevState.item.insuranceType,
                    insuranceCode: isPurchasePrice ? '' : prevState.item.insuranceCode,
                    deposit: isPurchasePrice ? this.getDeposit(prevState.item, prevState.item.category, number) : prevState.item.deposit,
                    [field]: number,
                }
            });
        });
    };

    onSelectCategory = category => {
        this.setState(prevState => {
            const _category = !!category ?
                {
                    ...ITEM_TEMPLATE.category,
                    parentCategory: category.categoryId,
                    parentCategoryCode: category.categoryCode,
                } : {...ITEM_TEMPLATE.category}

            return ({
                item: {
                    ...prevState.item,
                    category: _category,
                }
            });
        })
    };

    getDeposit = (item, category, purchasePrice) => {
        if (!depositRequired({...item, category, purchasePrice})) {
            return item.deposit;
        }
        if (!category.depositPercent || !purchasePrice) {
            return item.deposit;
        }
        const newDeposit = purchasePrice * category.depositPercent / 100;
        return newDeposit < item.deposit ? item.deposit : newDeposit;
    }

    onSelectSubCategory = subCategory =>
        this.setState(prevState => {
                if (!subCategory) {
                    const category = {
                        ...ITEM_TEMPLATE.category,
                        parentCategory: prevState.item.category.parentCategory,
                        parentCategoryCody: prevState.item.category.parentCategoryCode,
                    }
                    return ({
                        item: {
                            ...prevState.item,
                            category: category,
                            vehicleDetailDTO: undefined,
                            boatDetailDTO: undefined
                        }
                    });
                }
                const newDeposit = this.getDeposit(prevState.item, subCategory, prevState.item.purchasePrice);
                const item = {
                    ...prevState.item,
                    insurance: null,
                    insuranceType: 0,
                    insuranceCode: '',
                    deposit: newDeposit,
                    categoryId: subCategory.categoryCode,
                    category: {
                        ...prevState.item.category,
                        categoryId: subCategory.categoryId,
                        categoryCode: subCategory.categoryCode,
                        insurable: subCategory.insurable,
                        extraDataRequired: subCategory.extraDataRequired,
                        depositPercent: subCategory.depositPercent,
                        requiredDeposit: subCategory.requiredDeposit,
                        rentalWith: subCategory.rentalWith,

                    }
                };
                // If an only if parent category is vehicles and current category is not accessories, then make sure the item
                // has a proper vehicleDetailDTO, .
                if (subCategory.extraDataRequired === EXTRA_DATA_VEHICLE) {
                    if (!item.vehicleDetailDTO) {
                        item.vehicleDetailDTO = {...VEHICLE_TEMPLATE};
                    }
                    if (!!item.vehicleDetailDTO) {
                        item.vehicleDetailDTO.type = null;
                        item.vehicleDetailDTO.subtype = null;
                    }
                    item.boatDetailDTO = undefined;
                }
                if (subCategory.extraDataRequired !== EXTRA_DATA_VEHICLE && !!item.vehicleDetailDTO) {
                    item.vehicleDetailDTO = undefined;
                }
                if (subCategory.extraDataRequired === EXTRA_DATA_BOAT) {
                    if (!item.boatDetailDTO) {
                        item.boatDetailDTO = {...BOAT_TEMPLATE};
                    }
                    if (!!item.boatDetailDTO) {
                        item.boatDetailDTO.type = null;
                        item.boatDetailDTO.subtype = null;
                    }
                    item.vehicleDetailDTO = undefined;
                }
                if (subCategory.extraDataRequired !== EXTRA_DATA_BOAT && !!item.boatDetailDTO) {
                    item.boatDetailDTO = undefined;
                }
                return ({item});
            }
        );

    onSelectInsurance = insurance => {
        if (insurance === null) {
            this.setState(prevState => {
                    return {
                        item: {
                            ...prevState.item,
                            insurance: undefined,
                            insuranceType: 0,
                            insuranceCode: '',
                            insuranceRequired: false,
                        },
                    }
                }
            );
        } else {
            this.setState(prevState => {
                    let deposit = !!prevState.item.deposit ? Number(prevState.item.deposit) : 0;
                    if (deposit < insurance.excess) {
                        deposit = insurance.excess;
                    }
                    return {
                        item: {
                            ...prevState.item,
                            insurance,
                            deposit,
                            insuranceType: insurance.insuranceType,
                            insuranceCode: insurance.insuranceCode,
                            insuranceRequired: true,
                        },
                    }
                }
            );
        }
    };

    handleUpdatePurchaseDate = millis =>
        this.setState(prevState => {
            return ({
                item: {
                    ...prevState.item,
                    purchaseDate: new Date(millis)
                }
            });
        });

    handleVehicleUpdate = (field, value) => {
        this.setState(prevState => {
            const vehicleDetailDTO = {...prevState.item.vehicleDetailDTO, [field]: value}
            return ({
                item: {
                    ...prevState.item,
                    vehicleDetailDTO,
                }
            });
        });
    };

    handleBoatUpdate = (field, value) => {
        this.setState(prevState => {
            const boatDetailDTO = {...prevState.item.boatDetailDTO, [field]: value}
            return ({
                item: {
                    ...prevState.item,
                    boatDetailDTO,
                }
            });
        });
    };

    handleAddPhoto = (event, photo) => {
        if (event.target.files && event.target.files.length > 0) {
            const file = event.target.files[0];
            loadImage(file,
                img => {
                    if (img.type === "error") {
                        showMessage(get('image_error', [file.name]), SEVERITY_ERROR);
                    } else {
                        const image = {
                            image: img,
                            fileName: file.name,
                            fileType: file.type,
                            width: img.width,
                            height: img.height,
                        };
                        cropAndResize(image, MYUR_ITEM_PHOTO_ASPECT_RATIO, MYUR_ITEM_PHOTO_MAX_WIDTH)
                            .then(bin => this.onPhotoAdded(image, bin, photo));
                    }
                }, {orientation: true});
        }
    };

    /**
     * 1. Se envía el dto de todas las imágenes que son susceptibles de una operación en backend.
     * 2. Si el dto no tiene id, se trata de una imagen nueva y debe incluirse el fichero en el part correspondiente.
     * 3. Si el dto tiene id y tiene el fichero en el part correspondiente, debe eliminarse  (o no) el fichero anterior
     * *  y actualizar la imagen con el nuevo fichero.
     * 4. Si el dto tiene id y no tiene el fichero en el part correspondiente, entonces es que hay que eliminar la imagen.
     * @param photo {"image":{},"fileName":"banks.jpg","fileType":"image/jpeg","width":600,"height":600}
     * @param bin
     * @param prevPhoto if != undefined, the new image updates an existing one
     */
    onPhotoAdded = (photo, bin, prevPhoto) => {
        const url = window.URL.createObjectURL(bin);
        const ext = photo.fileName.substr(photo.fileName.lastIndexOf('.'));
        const fileName = shortid() + ext;
        let newPhoto;
        this.setState(prevState => {
            const prevPhotos = !!prevPhoto
                ? prevState.item.photos.filter(p => prevPhoto.photoOrder !== p.photoOrder)
                : prevState.item.photos;
            newPhoto = !!prevPhoto
                ? {...prevPhoto, associatedPartName: fileName, fileName, photo: url, bin}
                : {
                    ...photo,
                    itemId: prevState.item.id,
                    associatedPartName: fileName,
                    fileName,
                    photo: url,
                    bin,
                    photoOrder: prevPhotos.length
                };
            const photos = [...prevPhotos, newPhoto].sort((p1, p2) => p1.photoOrder - p2.photoOrder);
            return {
                item: {
                    ...prevState.item,
                    photos,
                },
            }
        });
        this.imagesUpdated[newPhoto.photoOrder] = newPhoto;
    };

    handleDeletePhoto = photoToDelete => {
        this.setState(prevState => {
            const photos = prevState.item.photos
                .filter(photo => photo.photoOrder !== photoToDelete.photoOrder)
                .map((photo, index) => ({...photo, photoOrder: index}));
            return {
                item: {
                    ...prevState.item,
                    photos,
                },
            }
        });
        if (!!photoToDelete.id) {
            this.imagesDeleted.push({...photoToDelete, associatedPartName: photoToDelete.photo});
        }
    };

    validate = item => {
        const errors = validate_item(item, this.props.profile);
        this.setState({validationErrors: errors});
        return errors;
    };

    hasErrors = errors => {
        return (errors.title !== '' || errors.description !== '' || errors.category !== '' || errors.subCategory !== ''
            || errors.purchasePrice !== '' // || errors.insuranceConditionsAccepted !== ''
            || errors.insurance !== '' || errors.deposit !== '' || errors.photos !== ''
            || errors.hourlyPrice !== '' || errors.dailyPrice !== '' || errors.weeklyPrice !== '' || errors.monthlyPrice !== ''
            || errors.vType !== '' || errors.vBrand !== '' || errors.vModel !== '' || errors.vLicencePlate !== ''
            || errors.bType !== '' || errors.bSubtype !== '' || errors.bConstructionYear !== ''
            || errors.bZipcodePort !== '' || errors.bLicencePlate !== '' || errors.bBrand !== '' || errors.bModel !== ''
            //|| errors.bMaterial !== '' || errors.bLength !== '' || errors.bEngines !== ''
            //|| errors.bPower !== '' || errors.bBoatName !== ''
        );
    };

    handleSave = () => {
        const _item = this.state.item;
        if (this.hasErrors(this.validate(_item))) {
            return;
        }
        this.setState({saving: true, error: false});
        //Copy made to proper photo management
        const item = {..._item};
        const {profile} = this.props;
        item.purchaseDate = moment(item.purchaseDate).format(DATE_FORMAT);
        if (item.insuranceType === 0) {
            item.insuranceType = null;
            item.insuranceRequired = false;
        }
        if (!!item.vehicleDetailDTO) {
            item.vehicleDetailDTO.type = item.vehicleDetailDTO.type.typeName;
            item.vehicleDetailDTO.subtype = !!item.vehicleDetailDTO.subtype ? item.vehicleDetailDTO.subtype.typeName : null;
        }
        if (!!item.boatDetailDTO) {
            item.boatDetailDTO.type = item.boatDetailDTO.type.typeName;
            item.boatDetailDTO.subtype = !!item.boatDetailDTO.subtype ? item.boatDetailDTO.subtype.typeName : null;
        }
        item.categoryId = item.category.categoryId;
        item.userUid = profile.uid;
        item.userNickname = profile.nickname;
        item.hourlyPrice = !!item.hourlyPrice ? item.hourlyPrice : null;
        item.dailyPrice = !!item.dailyPrice ? item.dailyPrice : null;
        item.weeklyPrice = !!item.weeklyPrice ? item.weeklyPrice : null;
        item.monthlyPrice = !!item.monthlyPrice ? item.monthlyPrice : null;
        if (isServiceOrSpace(item)) {
            item.purchaseDate = null;
            item.purchasePrice = null;
        }
        const formData = new FormData();

        item.photos = [];
        let partCount = 0;
        for (let i = 0; i < MAX_ITEM_PHOTOS; i++) {
            if (!!this.imagesDeleted[i]) {
                item.photos.push(this.imagesDeleted[i]);
            }
            if (!!this.imagesUpdated[i]) {
                item.photos.push(this.imagesUpdated[i]);
                if (this.imagesUpdated[i].bin !== undefined) {
                    formData.append('files', this.imagesUpdated[i].bin, this.imagesUpdated[i].fileName);
                    partCount++;
                }
            }
        }
        if (partCount === 0) {
            formData.append('files', '');
        }

        const itemDTO = new Blob([JSON.stringify(item)], {type: "application/json"});

        if (!!item && !!item.id) {
            formData.append('dto', itemDTO);
            doPut(API_ITEM_UPDATE_MULTIPART, formData)
                .then(() => this.onItemSaved(item, ANALYTICS_UPDATE_ITEM))
                .catch(this.onError)
        } else {
            formData.append('item', itemDTO);
            doPost(API_ITEM_CREATE_ITEM, formData)
                .then(item => this.onItemSaved(item, ANALYTICS_NEW_ITEM))
                .catch(this.onError)
        }
    };

    onItemSaved = (item, mode) => {
        const _item = !!item ? item : this.state.item;
        const owner_id = !!item.userUid ? item.userUid : item.ownerId;
        logEvent(analytics, mode, {
            owner_nickname: item.userNickname,
            owner_id,
            item_id: item.id,
            item_title: item.title,
            item_location: item.location,
            item_zipcode: item.zipcode
        });
        showMessage(get('item_saving_success', [_item.title]), SEVERITY_SUCCESS);
        this.setState({saving: false});
        if (!!this.specificResponseDTO) {
            this.specificResponseDTO.itemId = _item.id;
            doPost(API_LIVE_RESPONSE, JSON.stringify(this.specificResponseDTO))
                .then(response => this.onResponseSent(this.specificResponseDTO, item))
                .catch(this.onError);
        } else if (!item.enabled) {
            this.props.history.push(buildUrl(ROUTE_MY_ITEMS, {id: _item.id}));
        } else {
            this.props.history.push(getPublicUrl(_item));
        }
    };

    onResponseSent = (specificRequest, item) => {
        this.setState({saving: false});
        logEvent(analytics, ANALYTICS_RESPONSE_TO_LIVE_SEARCH, {
            search_id: specificRequest.specificRequestId,
            search_title: specificRequest.specificRequestTitle,
            publisher_id: specificRequest.requestorId,
            publisher_nickname: specificRequest.requestorNickname,
            owner_id: item.ownerId || item.userUid,
            owner_nickname: item.nickname || item.userNickname,
            item_id: item.id,
            item_title: item.title
        });
        showMessage(get('response_send_success'), SEVERITY_SUCCESS);
        this.props.history.goBack();
    };

    render() {
        const {classes, profile} = this.props;
        const {item, saving, loading, error, validationErrors} = this.state;
        const prefix = (!!item && !!item.id) ? 'edit' : 'create';
        const title = get(prefix + '_item');
        return (
            <ComponentWrapper loading={loading} title={title}>
                <Paper className={classes.root}>
                    <Typography variant={'h6'} className={classes.title}>{title}</Typography>
                    <Typography variant={'body1'} color={'textSecondary'} className={classes.title}>
                        {get(prefix + '_item_help')}
                    </Typography>
                    <ItemForm item={item}
                              user={profile}
                              validationErrors={validationErrors}
                              onSelectCategory={this.onSelectCategory}
                              onSelectSubCategory={this.onSelectSubCategory}
                              onSelectInsurance={this.onSelectInsurance}
                              handleUpdatePurchaseDate={this.handleUpdatePurchaseDate}
                              handleVehicleUpdate={this.handleVehicleUpdate}
                              handleBoatUpdate={this.handleBoatUpdate}
                              handleAddPhoto={this.handleAddPhoto}
                              handleDeletePhoto={this.handleDeletePhoto}
                              onUpdate={this.handleUpdate}
                              onItemEnable={this.onItemEnable}
                              onUpdateNumber={this.handleUpdateNumber}/>
                </Paper>
                <div className={clsx(classes.defaultRow, classes.action)}>
                    <div>
                        <Button variant="outlined" color="secondary" className={classes.button} onClick={this.onCancel}>
                            {get('CANCEL')}
                        </Button>
                    </div>
                    <ProgressButton loading={saving} error={error} disabled={saving}
                                    className={classes.button}
                                    variant={'contained'}
                                    color={'primary'}
                                    handleClick={this.handleSave}>
                        {get('SAVE')}
                    </ProgressButton>
                </div>
            </ComponentWrapper>
        )
    }
}

const mapStateToProps = ({session}) => ({
    authUser: session.authUser,
    profile: session.profile,
});

const authCondition = profile => !!profile;

ItemComposerComponent.propTypes = {
    authUser: PropTypes.object,
    profile: PropTypes.object,
    match: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired
};

export default compose(
    withStyles(styles, {withTheme: true}),
    withAuthorization(authCondition),
    connect(mapStateToProps),
    withRouter,
)(ItemComposerComponent)