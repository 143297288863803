import React, {useEffect} from 'react';
import Dialog from '@material-ui/core/Dialog';
import {AppLink, SignUp} from "../landing/LandingPanels";
import WalrusBottomComponent from "../landing/WalrusBottomComponent";
import {Grid} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";

let openDialogFn;
export function openLetsRegisterDialog() {
    openDialogFn();
}

const useStyles = makeStyles(theme => ({
    applink: {
        marginTop: theme.spacing(1),
    }
}));
export default function LetsRegisterDialog() {
    const [open, setOpen] = React.useState(false);
    const classes = useStyles();

    useEffect(() => {
        openDialogFn = () => setOpen(true);
    }, []);

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <div>
            <Dialog open={open} fullWidth={true} maxWidth={"md"} onClose={handleClose}>
                <Grid container direction={"row"} justifyContent={"space-evenly"}>
                    <Grid item xs={12} md={6}>
                        <SignUp/>
                    </Grid>
                    <Grid item xs={12} md={6} className={classes.applink}>
                        <AppLink/>
                    </Grid>
                </Grid>
                <WalrusBottomComponent/>
            </Dialog>
        </div>
    );
}
