import React from "react";
import {PropTypes} from "prop-types";

import {Grid, Hidden, makeStyles} from "@material-ui/core";

import MyurWaitingComponent from "./MyurWaitingComponent";
import FloatingMenu from "../navigation/FloatingMenu";
import layoutStyles from "./styles/layoutStyles";

const useStyles = makeStyles(theme => ({
    ...layoutStyles(theme),
}));

const ProfileSectionComponent = ({waiting, children}) => {

    const classes = useStyles();
    return (
        <Grid container className={classes.component} direction={"column"}>
            <MyurWaitingComponent open={waiting} />
            <Grid container direction={"row"} justifyContent={"space-between"} spacing={4} alignItems={"flex-start"}>
                <Grid container item sm={12} md={8} direction={"column"}>
                    {children}
                </Grid>
                <Hidden smDown>
                    <Grid container item md={4}>
                        <FloatingMenu/>
                    </Grid>
                </Hidden>
            </Grid>
        </Grid>
    )
}

ProfileSectionComponent.propTypes = {
    waiting: PropTypes.bool
};

export default ProfileSectionComponent;