import React from 'react';
import PropTypes from 'prop-types';

import moment from 'moment';

import {makeStyles} from "@material-ui/core";
import LoanStateComponent from "./LoanStateComponent";
import {
    ACEPTADO, ACTIVO_OWNER,
    BLUE, DATE_TIME_FORMAT, FINALIZADO, getLoanState,
    MARINE, ORANGE,
    PENDIENTE_RESPUESTA,
    YELLOW
} from "../../utils/constants";
import LoanInteractionComponent from "./LoanInteractionComponent";
import {get} from '../common/i18n/i18n'

function formatDate(date) {
    if (!date) {
        return '';
    }
    return moment.utc(date, DATE_TIME_FORMAT).tz(moment.tz.guess()).format('DD/MM');
}

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexFlow: 'nowrap row',
        justifyContent: 'space-between',
        alignItems: 'baseline',
        minHeight: 120,
    },
    line: {
        position: 'relative',
        top: '53px',
        zIndex: 2,
        width: '95%',
        marginLeft: 'auto',
        marginRight: 'auto',
        height: 5,
        backgroundColor: MARINE,
    }
}));

function getError (date, deliveryDate, receptionDate, state, init) {
    const currentMoment = moment();
    const referenceState = init ? ACEPTADO : ACTIVO_OWNER;
    const dateDate = moment(date, DATE_TIME_FORMAT);
    const error = {receptionError: null, deliveryError: null};
    if (state === referenceState && dateDate.isBefore(currentMoment) && !deliveryDate) {
        error.deliveryError = get('loan_lifecycle_delivery_error');
    }
    if (state === referenceState &&dateDate.isBefore(currentMoment) && !!deliveryDate && ! receptionDate) {
        error.receptionError = get('loan_lifecycle_reception_error');
    }
    return error;
}

export default function LoanLifeCycleComponent({loan, className}) {
    const classes = useStyles();
    const secondState = (loan.loanStatus.id > 3 && loan.loanStatus.id < 7) ? getLoanState(loan.loanStatus.id) : getLoanState(2);
    let secondDate = formatDate(loan.loanConfirmationDate);
    if(secondState.id === 4) { //Caducado
        secondDate = formatDate(loan.requestDueDate);
    }
    const errorInit =
        getError(loan.loanInitDate, loan.loanInitDateOwnerConfirmation, loan.loanInitDateBorrowerConfirmation, true);
    const errorEnd =
        getError(loan.loanInitDate, loan.loanInitDateOwnerConfirmation, loan.loanInitDateBorrowerConfirmation, false);
    return (
        <div className={className}>
            <div className={classes.line}/>
            <div className={classes.root}>
                <LoanStateComponent date={formatDate(loan.loanAskDate)} state={get(PENDIENTE_RESPUESTA)} color={YELLOW}/>
                <LoanStateComponent date={secondDate}
                                    state={get(secondState.key)} color={secondState.color}/>
                <LoanInteractionComponent state={get('loan_lifecycle_init')} date={loan.loanInitDate}
                                          deliveryDate={loan.loanInitDateOwnerConfirmation}
                                          receptionDate={loan.loanInitDateBorrowerConfirmation}
                                          color={BLUE}
                                          deliveryError={errorInit.deliveryError}
                                          receptionError={errorInit.receptionError}
                />
                <LoanInteractionComponent state={get('loan_lifecycle_end')} date={loan.loanEndDate}
                                          deliveryDate={loan.loanEndDateBorrowerConfirmation}
                                          receptionDate={loan.loanEndDateOwnerConfirmation}
                                          color={ORANGE}
                                          deliveryError={errorEnd.deliveryError}
                                          receptionError={errorEnd.receptionError}
                />
                <LoanStateComponent
                    date={!!loan.loanEndDateOwnerConfirmation ? 'OK' : null}
                    error={loan.pendingIssue}
                    state={get(FINALIZADO)} color={'green'}
                />
            </div>
        </div>
    );
}

LoanLifeCycleComponent.propTypes = {
    loan: PropTypes.object.isRequired,
    className: PropTypes.string,
};

LoanLifeCycleComponent.defaultProps = {
    className: '',
};