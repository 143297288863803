import React, {Component} from 'react'
import {PropTypes} from 'prop-types'
import {compose} from "recompose";
import {connect} from "react-redux";
import {withRouter} from 'react-router-dom';

import {Grid, Typography} from "@material-ui/core";

import {buildUrl, doDelete, doGet, doPut} from "../../../utils/http";
import {
    API_ITEM_DELETE, API_ITEM_DISABLE, API_ITEM_ENABLE,
    API_ITEM_MY_ITEMS,
    SEVERITY_ERROR,
    SEVERITY_SUCCESS
} from "../../../utils/constants";
import {showMessage} from "../../common/NotificationSnack";
import ItemCard from "../../item/ItemCard";
import {get} from "../../common/i18n/i18n";
import UserItemProperties from "../../item/UserItemProperties";
import withAuthorization from "../../auth/withAuthorization";
import {ROUTE_ITEM_EDIT} from "../../../utils/routes";
import ProfileSectionComponent from "../../common/ProfileSectionComponent";
import ItemVertSkeleton from "../../item/ItemVertSkeleton";

const INITIAL_STATE = {
    items: [],
    waiting: false,
};

class ItemListComponent extends Component {

    constructor(props) {
        super(props);
        this.state = {...INITIAL_STATE};
    }

    componentDidMount() {
        this.retrieveItems();
    }

    retrieveItems = () => {
        this.setState({waiting: true});
        doGet(buildUrl(API_ITEM_MY_ITEMS, {uid: this.props.user.uid}))
            .then(this.onItemsRetrieved)
            .catch(this.onError);
    };

    onItemsRetrieved = items => this.setState({items, waiting: false});

    onDeleteClicked = (event, item) => {
        this.setState({waiting: true});
        doDelete(buildUrl(API_ITEM_DELETE, {uid: this.props.user.uid, itemId: item.id}))
            .then(() => this.onItemDeleted(item))
            .catch(this.onError);
    };

    onEnableClicked = (event, item) => {
        this.setState({waiting: true});
        const url = item.enabled ? API_ITEM_DISABLE : API_ITEM_ENABLE;
        doPut(buildUrl(url, {itemId: item.id, uid: this.props.user.uid}), "")
            .then(() => this.onItemSaved(item))
            .catch(this.onError);
    };

    onEditClicked = (event, item) => {
        this.props.history.push(buildUrl(ROUTE_ITEM_EDIT, {uid: this.props.user.uid, itemId: item.id}))
    };

    onError = error => {
        this.setState({waiting: false});
        showMessage(error, SEVERITY_ERROR);
    };

    onItemSaved = item => {
        this.retrieveItems();
        const action = !!item.id ? 'edit' : 'create';
        showMessage(get(`item_${action}_item_success`, [item.title]), SEVERITY_SUCCESS);
    };

    onItemDeleted = item => {
        this.retrieveItems();
        showMessage(get('delete_success', [item.title]), SEVERITY_SUCCESS);
    };

    render() {
        const {items, waiting} = this.state;

        if (!!items && items.length > 0) {
            return (
                <ProfileSectionComponent waiting={waiting}>
                    <Grid container spacing={1}>
                        {items.map(item =>
                            <ItemCard key={item.id} gridProps={{xs: 6, sm: 4}} item={item}
                                      itemEdit={true} itemEnable={true} itemDelete={true}
                                      onDeleteClicked={event => this.onDeleteClicked(event, item)}
                                      onEnableClicked={event => this.onEnableClicked(event, item)}
                                      onEditClicked={event => this.onEditClicked(event, item)}
                                      ItemPropertiesComponent={UserItemProperties}/>
                        )}
                    </Grid>
                </ProfileSectionComponent>
            )
        } else if (!waiting) {
            return (
                <ProfileSectionComponent waiting={waiting}>
                    <Typography variant={"h6"}>{get('user_no_items')}</Typography>
                </ProfileSectionComponent>
            )
        }
        return (
            <ProfileSectionComponent waiting={waiting}>
                <Grid container spacing={1}>
                    {[0, 1, 2, 3, 4, 5].map(idx => <ItemVertSkeleton key={idx} gridProps={{xs: 6, sm: 4}}/>)}
                </Grid>
            </ProfileSectionComponent>
        )
    }

    error = {
        "id": "39",
        "errorCode": "illegal_attempted_access",
        "messageES": "Acceso denegado. Por favor, intenta registrate de nuevo",
        "messageEN": "Access denied. Please, register with a valid account"
    };
}

ItemListComponent.propTypes = {
    classes: PropTypes.object.isRequired,
    user: PropTypes.object,
};

const mapStateToProps = ({session}) => ({
    user: session.authUser,
});

const authCondition = profile => !!profile;

export default compose(
    withAuthorization(authCondition),
    connect(mapStateToProps),
    withRouter,
)(ItemListComponent)