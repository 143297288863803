import React, {useEffect, useState} from 'react'
import {compose} from 'recompose'
import {connect} from 'react-redux'

import {makeStyles} from '@material-ui/core/styles'
import {AppBar, Tab, Tabs} from "@material-ui/core";
import SwipeableViews from "react-swipeable-views";

import LoanListComponent from "./LoanListComponent";
import GoBackHeader from "../common/GoBackHeader";

import {IMPROPIO, BLUE, PROPIO, LOAN_MY_LOANS, MARINE} from "../../utils/constants";
import {get} from "../common/i18n/i18n";
import withAuthorization from '../auth/withAuthorization'
import {buildUrl, doRequest} from "../../utils/http";
import {showMessage} from "../common/NotificationSnack";
import layoutStyles from "../common/styles/layoutStyles";
import clsx from "clsx";

const useStyles = makeStyles(theme => ({
    ...layoutStyles(theme),
    root: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper,
        background: MARINE,
    },
    tabBar: {
        backgroundColor: BLUE,
    }

}));

function MyLoanListComponent(props) {

    const [tabIndex, setTabIndex] = useState(0);
    const [propioCurrentLoans, setPropioCurrentLoans] = useState([]);
    const [propioPastLoans, setPropioPastLoans] = useState([]);
    const [impropioCurrentLoans, setImpropioCurrentLoans] = useState([]);
    const [impropioPastLoans, setImpropioPastLoans] = useState([]);

    const classes = useStyles();

    useEffect(() => {
            if (!props.authUser) {
                return;
            }
            const uid = props.authUser.uid;
            doRequest(
                buildUrl(LOAN_MY_LOANS, {uid, inProcessLoans: true}),
                loanList => onReceiveLoanList(true, loanList),
                error => showMessage(error),
                {method: 'GET'});
        // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [props.authUser.uid]
    );

    const handleRequestPastLoans = () => {
        const uid = props.authUser.uid;
        doRequest(
            buildUrl(LOAN_MY_LOANS, {uid, inProcessLoans: false}),
            loanList => onReceiveLoanList(false, loanList),
            error => showMessage(error),
            {method: 'GET'});
    };

    const onReceiveLoanList = (currentLoans, loanList) => {
        const uid = props.authUser.uid;
        const propioLoans = [];
        const impropioLoans = [];
        for (let i = 0; i < loanList.length; i++) {
            const loan = loanList[i];
            if (loan.ownerId === uid) {
                propioLoans.push(loan);
            } else {
                impropioLoans.push(loan);
            }
        }
        if (currentLoans) {
            setPropioCurrentLoans(propioLoans);
            setImpropioCurrentLoans(impropioLoans);
        } else {
            setPropioPastLoans(propioLoans);
            setImpropioPastLoans(impropioLoans);
        }
    };

    const handleTabSelection = (event, value) => {
        setTabIndex(value);
    };

    return (
        <div className={clsx(classes.component, classes.root)}>
            <GoBackHeader title={get('my_loans')}/>
            <AppBar position="static" className={classes.tabBar}>
                <Tabs value={tabIndex} onChange={handleTabSelection} centered={true}>
                    <Tab label="PROPIO"/>
                    <Tab label="IMPROPIO"/>
                </Tabs>
            </AppBar>
            <SwipeableViews index={tabIndex} onChangeIndex={handleTabSelection}>
                {<LoanListComponent mode={PROPIO}
                                    currentLoans={propioCurrentLoans}
                                    pastLoans={propioPastLoans}
                                    hidden={tabIndex !== PROPIO}
                                    handleRequestPastLoans={handleRequestPastLoans}
                />}
                {<LoanListComponent mode={IMPROPIO}
                                    currentLoans={impropioCurrentLoans}
                                    pastLoans={impropioPastLoans}
                                    hidden={tabIndex !== IMPROPIO}
                                    handleRequestPastLoans={handleRequestPastLoans}
                />}
            </SwipeableViews>
        </div>
    );
}

const mapStateToProps = ({session}) => ({
    authUser: session.authUser,
});

const authCondition = profile => !!profile;

export default compose(
    withAuthorization(authCondition),
    connect(mapStateToProps),
)(MyLoanListComponent)