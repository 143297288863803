import React from 'react';
import PropTypes from 'prop-types';
import {ButtonBase, Grid, Typography} from "@material-ui/core";
import {get} from "../../common/i18n/i18n";
import DestinationComponent from "./DestinationComponent";
import {openLetsRegisterDialog} from "../../common/LetsRegisterDialog";
import SendIcon from "../../../images/icon_avion_Blanco.png";
import {BLUE, MARINE, PALE_BLUE} from "../../../utils/constants";
import {makeStyles} from "@material-ui/styles";
import SendIMComponent from "../../chat/SendIMComponent";

const useStyles = makeStyles(theme => ({
    owner: {
        color: MARINE,
        display: 'block',
        marginTop: theme.spacing(2),
    },
    instantMessaging: {
        width: '100%',
        backgroundColor: theme.palette.common.white,
        display: 'flex',
        flexFlow: 'wrap row',
        justifyContent: 'space-between',
        alignItems: "center",
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
    messageButton: {
        display: 'flex',
        flexFlow: 'wrap row',
        justifyContent: "space-between",
        color: theme.palette.common.white,
        backgroundColor: BLUE,
        borderRadius: '1rem',
        padding: theme.spacing(1, 2),
        ...theme.typography.button,
        '&:hover': {
            backgroundColor: PALE_BLUE,
        },
    },
    messageIcon: {
        marginLeft: theme.spacing(1),
        width: '1.3rem',
        height: '1.3rem',
    }
}));

const IMContent = ({uid, item}) => {
    const classes = useStyles();

    const dest = {
        uid: item.ownerId,
        nickname: item.ownerNickname,
        avatar: item.ownerAvatar,
        latency: item.ownerLatency,
        responseRating: item.ownerResponseRating,
        receivedRatings: item.receivedRatings,
        score: item.ownerScore,
    };

    if (!!uid) {
        return (
            <SendIMComponent borrowerId={uid} ownerId={item.ownerId} dest={dest}
                             itemId={item.id} itemTitle={item.title}
                             sendDisabled={uid === dest.uid}/>
        )
    }

    return (
        <div className={classes.instantMessaging}>
            <DestinationComponent dest={dest}/>
            <ButtonBase className={classes.messageButton} onClick={openLetsRegisterDialog}>
                {get('send_message')}
                <img alt={'send'} className={classes.messageIcon} src={SendIcon}/>
            </ButtonBase>
        </div>
    )
}

IMContent.propTypes = {
    uid: PropTypes.string,
    item:PropTypes.object.isRequired
};

function ImComponent({uid, item}) {
    const classes = useStyles();
    return (
        <Grid item xs={12} md={6}>
            <Typography className={classes.owner} variant={'h2'} align={"left"}>
                {get('whoses_this_item')}
            </Typography>
            <IMContent uid={uid} item={item}/>
        </Grid>
    );
}

ImComponent.propTypes = {
    uid: PropTypes.string,
    item:PropTypes.object.isRequired
};

export default ImComponent;