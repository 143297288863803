import React from "react";
import {makeStyles, Typography} from "@material-ui/core";

import wave from '../../images/wave_background.svg';
import walrus from '../../images/walrus_small.png';
import {get} from "../common/i18n/i18n";


const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        display: 'flex',
        flexFlow: 'wrap row',
        justifyContent: 'center',
        alignItems: 'flex-end',
        marginTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
        backgroundImage: `url(${wave})`,
        backgroundPosition: 'center bottom',
        backgroundSize: '720px 150px',
        backgroundRepeat: 'repeat-x',
    },
    title: {
        color: theme.palette.common.white,
    }

}));

export default function WalrusBottomComponent(props) {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <img className={classes.image} align={'right'} alt={'walrus'} src={walrus}/>
            <Typography variant={'h3'} className={classes.title} align={"center"}>
                {get('walrus_lets_make_money')}
            </Typography>
        </div>
    )
}