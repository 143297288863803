import React from 'react';
import {PropTypes} from "prop-types";
import {useHistory} from 'react-router-dom';

import {makeStyles, ButtonBase} from '@material-ui/core';

import {BLUE, PALE_BLUE} from "../../utils/constants";
import {get} from "./../common/i18n/i18n";
import DestinationComponent from "../item/detail/DestinationComponent";
import SendIcon from '../../images/icon_avion_Blanco.png';
import {buildUrl} from "../../utils/http";
import {ROUTE_CHAT} from "../../utils/routes";

const useStyles = makeStyles(theme => ({
    owner: {
        width: '100%',
        backgroundColor: theme.palette.common.white,
        display: 'flex',
        flexFlow: 'wrap row',
        justifyContent: 'space-between',
        alignItems: "center",
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
    messageButton: {
        display: 'flex',
        flexFlow: 'wrap row',
        justifyContent: "space-between",
        color: theme.palette.common.white,
        backgroundColor: BLUE,
        borderRadius: '1rem',
        padding: theme.spacing(1, 2),
        ...theme.typography.button,
        '&:hover': {
            backgroundColor: PALE_BLUE,
        },
    },
    messageIcon: {
        marginLeft: theme.spacing(1),
        width: '1.3rem',
        height: '1.3rem',
    },
}));

export default function SendIMComponent({ownerId, borrowerId, itemId, itemTitle, dest, sendDisabled}) {
    const classes = useStyles();
    const history = useHistory();

    const handleMessageClick = event => {
        const state = {
            otherNickname: dest.nickname,
            itemTitle,
        };
        history.push(buildUrl(ROUTE_CHAT, {chatId: `${borrowerId}!${ownerId}!${itemId}`}), state);
    };

    return (
        <div className={classes.owner}>
            <DestinationComponent dest={dest}/>
            {
                !sendDisabled &&
                <ButtonBase className={classes.messageButton} onClick={handleMessageClick}>
                    {get('send_message')}
                    <img alt={'send'} className={classes.messageIcon} src={SendIcon}/>
                </ButtonBase>
            }
        </div>
    );
}

SendIMComponent.propTypes = {
    dest: PropTypes.shape({
        uid: PropTypes.string,
        nickname: PropTypes.string,
        avatar: PropTypes.string,
        score: PropTypes.number,
        latency: PropTypes.number
    }).isRequired,
    borrowerId: PropTypes.string.isRequired,
    ownerId: PropTypes.string.isRequired,
    itemId: PropTypes.string.isRequired,
    itemTitle: PropTypes.string.isRequired,
    sendDisabled: PropTypes.bool,
};

SendIMComponent.defaultProps = {
    sendDisabled: false,
};