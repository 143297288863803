import React, {useEffect} from 'react';
import LiveSearchNewSubmissionBanner from "./LiveSearchNewSubmissionBanner";
import {buildUrl, doGet} from "../../utils/http";
import {API_LIVE_GET_MY_RESPONSES} from "../../utils/constants";
import {showMessage} from "../common/NotificationSnack";
import {useSelector} from "react-redux";
import LiveSearchMyResponse from "./LiveSearchMyResponse";
import ItemSkeleton from "../user/item/ItemSkeleton";
import {Typography} from "@material-ui/core";
import {get} from "../common/i18n/i18n";

const LiveSearchMyResponses = props => {
    const [responses, setResponses] = React.useState([]);
    const [waiting, setWaiting] = React.useState(false);
    const auth = useSelector(state => state.session.authUser);

    useEffect(() => {
        if (!auth) {
            return;
        }
        setWaiting(true);
        doGet(buildUrl(API_LIVE_GET_MY_RESPONSES, {uid: auth.uid}))
            .then(onResponsesRetrieved)
            .catch(onError);

    }, [auth]);

    const onResponsesRetrieved = responses => {
        const _responses = responses.sort((search1, search2) => search1.publicationDate < search2.publicationDate);
        setResponses(_responses);
        setWaiting(false);
    };

    const onError = error => {
        setWaiting(false);
        showMessage(error);
    };
    const waitingComponent = () =>
        (
            <div>
                <LiveSearchNewSubmissionBanner/>
                {[0, 1, 2].map(index => <ItemSkeleton key={index}/>)}
            </div>
        );

    const noResponses = () =>
        (
            <div>
                <LiveSearchNewSubmissionBanner/>
                <Typography variant={"h6"}>{get('live_search_no_responses')}</Typography>
            </div>
        );

    if (waiting) {
        return waitingComponent();
    }
    if (responses === []) {
        return noResponses();
    }

    return (
        <div>
            <LiveSearchNewSubmissionBanner/>
            {responses.map(response => <LiveSearchMyResponse key={response.id} response={response}/>)}
        </div>
    );
};

export default LiveSearchMyResponses;