import React, {useState} from 'react';
import PropTypes from 'prop-types';
import moment from "moment";

import {makeStyles} from "@material-ui/styles";
import clsx from "clsx";

import {
    Checkbox,
    Divider,
    FormControl,
    FormControlLabel,
    InputAdornment, InputLabel, MenuItem, Select,
    TextField,
    Typography
} from "@material-ui/core";
import {DatePicker} from "@material-ui/pickers";
import {ToggleButton, ToggleButtonGroup} from "@material-ui/lab";

import {CARD_ID_TYPES, MAX_DESCRIPTION_CHAR} from "../../../utils/constants";
import {get} from "../../common/i18n/i18n";
import TipComponent from "../../common/TipComponent";

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
    },
    name: {
        flexGrow: 1,
    },
    surname: {
        flexGrow: 2,
    },
    row: {
        display: 'flex',
        flexFlow: 'wrap row',
        justifyContent: 'space-between',
        alignItems: 'baseline',
    },
    centerRow: {
        marginTop: theme.spacing(1),
        alignItems: 'center',
    },
    leftAlign: {
        marginTop: theme.spacing(1),
        justifyContent: 'flex-start',
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        [theme.breakpoints.down('sm')]: {
            width: '100%',
        },
    },
    genre: {
        [theme.breakpoints.down('sm')]: {
            marginTop: theme.spacing(1),
        }
    },
    divider: {
        height: 2,
        margin: theme.spacing(2, 0)
    },
    cardIdType: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        minWidth: '10em',
        zIndex: 2,
    },
    webSite: {
        flexGrow: 2,
    }
}));

const UnifiedUserProfile = ({profile, onUpdate}) => {
    const classes = useStyles();

    const maxDate = moment().startOf('day').subtract(18, 'year');
    const onChecked = event => onUpdate(event.target.id, null, event.target.checked);

    const [descriptionLength, setDescriptionLength] = useState(0);
    const descriptionError = (descriptionLength > MAX_DESCRIPTION_CHAR) ? get('description_2_long') : '';
    const descriptionUpdate = description => {
        setDescriptionLength(description.length);
        onUpdate('companyProfile', 'description', description);
    };

    if (!profile) {
        return (<Typography>Loading data</Typography>);
    }

    return (
        <div className={classes.root}>
            <div className={classes.row}>
                <TextField
                    id='name'
                    label={get('user_name')}
                    className={clsx(classes.textField, classes.name)}
                    value={profile.name || ''}
                    onChange={event => onUpdate('name', null, event.target.value)}
                    margin="normal"
                />
                <TextField
                    id='surname'
                    label={get('user_surname')}
                    className={clsx(classes.textField, classes.surname)}
                    value={profile.surname || ''}
                    onChange={event => onUpdate('surname', null, event.target.value)}
                    margin="normal"
                />
            </div>
            <div className={classes.row}>
                <TextField
                    id='phone'
                    label={get('user_phone')}
                    className={classes.textField}
                    value={profile.phone || ''}
                    onChange={event => onUpdate('phone', null, event.target.value)}
                    margin="normal"
                    InputProps={{
                        startAdornment: <InputAdornment position="start">(+34)</InputAdornment>,
                    }}/>
            </div>
            <div className={classes.row}>
                <TipComponent tip={get('unified_profile_phone_tip')}/>
            </div>
            <Divider className={classes.divider} variant={"middle"}/>
            <div className={clsx(classes.row, classes.centerRow)}>
                <Typography>{get('select_notifications')}</Typography>
            </div>
            <div className={clsx(classes.row, classes.leftAlign)}>
                <FormControlLabel
                    className={classes.over8teen}
                    control={
                        <Checkbox
                            id={'byFCM'}
                            checked={profile.byFCM}
                            onChange={onChecked}
                        />
                    }
                    label={get('select_notifications_fcm')}
                />
                <FormControlLabel
                    className={classes.over8teen}
                    control={
                        <Checkbox
                            id={'byEmail'}
                            checked={profile.byEmail}
                            onChange={onChecked}
                        />
                    }
                    label={get('select_notifications_email')}
                />

            </div>
            <Divider className={classes.divider} variant={"middle"}/>
            <div className={classes.row}>
                <FormControlLabel
                    control={
                        <Checkbox
                            id={'professional'}
                            checked={profile.professional}
                            onChange={event => onUpdate('professional', null, event.target.checked)}
                            color="primary"
                        />
                    }
                    label={get('business_area_add')}
                    labelPlacement={'end'}
                />
            </div>
            {
                profile.professional &&
                <>
                    <div className={classes.row}>
                        <TextField
                            id='name'
                            label={get('business_name')}
                            className={clsx(classes.textField, classes.name)}
                            value={profile.companyProfile.name}
                            onChange={event => onUpdate('companyProfile', 'name', event.target.value)}
                            disabled={!!profile.companyProfile.companyId}
                            margin="normal"
                        />
                        <TextField
                            id='webSite'
                            label="Web"
                            className={clsx(classes.textField, classes.webSite)}
                            value={profile.companyProfile.webSite}
                            onChange={event => onUpdate('companyProfile', 'webSite', event.target.value)}
                            margin="normal"
                        />
                    </div>
                    <div className={classes.row}>
                        <TextField
                            id='cardId'
                            label={get('vat_number')}
                            className={classes.textField}
                            value={profile.companyProfile.cif || ''}
                            disabled={!!profile.companyProfile.companyId}
                            onChange={event => onUpdate('companyProfile', 'cif', event.target.value)}
                            margin="normal"
                        />
                        <FormControl className={classes.cardIdType}>
                            <InputLabel id="cardIdTypeId-label">{get('vat_number_type')}</InputLabel>
                            <Select
                                labelId="cardIdTypeId-label"
                                id="cardIdTypeId"
                                disabled={!!profile.companyProfile.companyId}
                                value={!!profile.companyProfile.cardIdType && profile.companyProfile.cardIdType}
                                onChange={event => onUpdate('companyProfile', 'cardIdType', event.target.value)}
                            >
                                <MenuItem value={1}>{CARD_ID_TYPES[1]}</MenuItem>
                                <MenuItem value={2}>{CARD_ID_TYPES[2]}</MenuItem>
                                <MenuItem value={4}>{CARD_ID_TYPES[4]}</MenuItem>
                            </Select>
                        </FormControl>
                        <TextField
                            id='withholding'
                            className={classes.textField}
                            label={get('withholding')}
                            helperText={get('withholding_helper')}
                            type={'number'}
                            onChange={event => onUpdate('companyProfile', 'withholding', event.target.value)}
                            value={profile.companyProfile.withholding || ''}
                            margin="normal"
                            InputProps={{
                                endAdornment: <InputAdornment position="end">%</InputAdornment>,
                                inputProps: {
                                    style: {textAlign: "right"},
                                    min: 0,
                                    max: 100,
                                },
                            }}
                        />
                    </div>
                    <div className={classes.row}>
                        <TextField
                            id="info"
                            label={get('description')}
                            error={descriptionError !== ''}
                            fullWidth
                            multiline
                            rows={4}
                            rowsMax={10}
                            value={profile.companyProfile.description || ''}
                            onChange={event => descriptionUpdate(event.target.value)}
                            className={classes.textField}
                            margin="normal"
                            variant="outlined"
                            FormHelperTextProps={{
                                classes: {
                                    root: clsx(classes.helperText, MAX_DESCRIPTION_CHAR - descriptionLength < 20 && classes.helperTextError)
                                }
                            }}
                            helperText={`${descriptionError} ${descriptionLength}/${MAX_DESCRIPTION_CHAR}`}
                        />
                    </div>
                </>
            }
            {
                !profile.professional &&
                <div className={classes.row}>
                    <DatePicker
                        label={get('birth_date')}
                        className={classes.textField}
                        value={profile.userProfile.birthDate}
                        initialFocusedDate={maxDate}
                        maxDate={maxDate}
                        disableFuture
                        openTo="year"
                        format={'DD/MM/YYYY'}
                        views={["year", "month", "date"]}
                        autoOk={true}
                        onChange={date => onUpdate('userProfile', 'birthDate', date)}
                    />
                    <ToggleButtonGroup
                        className={clsx(classes.textField, classes.genre)}
                        size={'small'}
                        value={profile.userProfile.gender}
                        exclusive
                        onChange={(event, gender) => onUpdate('userProfile', 'gender', gender)}>
                        <ToggleButton className={classes.gender} value="0">
                            {get('female')}
                        </ToggleButton>
                        <ToggleButton className={classes.gender} value="1">
                            {get('male')}
                        </ToggleButton>
                    </ToggleButtonGroup>
                    <TextField
                        id='cardId'
                        label={get('user_identification_document')}
                        className={classes.textField}
                        value={profile.userProfile.nif || ''}
                        onChange={event => onUpdate('userProfile', 'nif', event.target.value)}
                        margin="normal"
                    />
                    <FormControl className={classes.cardIdType}>
                        <InputLabel id="cardIdTypeId-label">{get('user_identification_document_type')}</InputLabel>
                        <Select
                            labelId="cardIdTypeId-label"
                            id="cardIdTypeId"
                            disabled={!!profile.companyProfile.companyId}
                            value={!!profile.userProfile.cardIdType && profile.userProfile.cardIdType}
                            onChange={event => onUpdate('userProfile', 'cardIdType', event.target.value)}
                        >
                            <MenuItem value={1}>{CARD_ID_TYPES[1]}</MenuItem>
                            <MenuItem value={2}>{CARD_ID_TYPES[2]}</MenuItem>
                            <MenuItem value={3}>{CARD_ID_TYPES[3]}</MenuItem>
                        </Select>
                    </FormControl>
                </div>
            }
        </div>
    );
};

UnifiedUserProfile.propTypes = {
    profile: PropTypes.object,
    onUpdate: PropTypes.func.isRequired,
};

export default UnifiedUserProfile;