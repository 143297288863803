import {getToken, isSignedId} from "./firebase";

export const REQUEST_AUTH = 'Basic dXNlcjpwYXNz';

export const doGet = url => doPromiseRequest({url, method: 'GET'});
/**
 *
 * @param url {String}
 * @param body {String}
 * @param options {Object}
 * @returns {*|Promise<*>}
 */
export const doPost = (url, body, options = {}) => doPromiseRequest({url, body, method: 'POST', ...options});
/**
 *
 * @param url {String}
 * @param body {String}
 * @param options {Object}
 * @returns {*|Promise<*>}
 */
export const doPut = (url, body, options = {}) => doPromiseRequest({url, body, method: 'PUT', ...options});

/**
 *
 * @param url {String}
 * @param body {String}
 * @param options {Object}
 * @returns {*|Promise<*>}
 */
export const doDelete = (url, body, options = {}) => doPromiseRequest({url, body, method: 'DELETE', ...options});

/**
 * Executes a remote http request. It automatically adds authorization headers to the request if available.
 * @param options object configuring the request with the following properties:
 * url: mandatory, remote uri.
 * method: mandatory, one of "GET", "PUT","POST" or "DELETE".
 * body: Optional, body of the request.
 * signal: Optional, used to cancel the request.
 * auth: Optional, whether use x-authorization-firebase or Authorization: Bearer auth header. Valid values are firebase |
 * bearer.

 * @returns {*|Promise<*>}
 */
export const doPromiseRequest = options => {
    if (isSignedId()) {
        return getToken()
            .then(token => doPromiseFetch({...options, token}))
    }
    return doPromiseFetch(options);
};

export const doPromiseFetch = options => {
    const {url, method, body, signal, auth, token} = options;
    const headers = getHeaders(body, token, auth);

    return fetch(url, {
        mode: 'cors',
        method: method,
        headers: headers,
        body,
        signal,
    })
        .then(response => resolveFetchPromise(response))
        .catch(error => rejectFetchPromise(error));
}

const rejectFetchPromise = error => new Promise((resolve, reject) => {
    console.error("ERROR: ", error);
    reject(error)
});

const resolveFetchPromise = response => new Promise((resolve, reject) => {
    if (response.ok && response.status !== 204) {
        return response.clone().json().catch(() => resolve(response.text()))
            .then(data => resolve(data));
    } else if (response.status === 204) {
        resolve({});
    } else {
        console.error("ERROR: ", response);
        return response.text()
            .then(json => reject(json))
            .catch(error => reject(error));
    }

});

/**
 * Performs a remote http request. If the request finish normally, then onSuccess is called, onError otherwise.
 * Request options are specified in options argument.
 * @param url Remote URL to where request is made.
 * @param onSuccess Callback called when request finishes normally.
 * @param onError Callback called when an error occurs.
 * @param options object configuring the request with the following properties: method: mandatory, one of "GET", "PUT",
 * "POST" or "DELETE". body: Optional, body of the request. signal: Optional, used to cancel the request. auth:
 * Optional, whether use x-authorization-firebase or Authorization: Bearer auth header. Valid values are firebase |
 * bearer.
 * @returns {Promise<any>|Promise<any | never>}
 * @deprecated Please, use doPromiseFetch instead.
 */
export const doRequest = (url, onSuccess, onError, options) => {
    if (isSignedId()) {
        return getToken()
            .then(token =>
                doFetch(url, onSuccess, onError, {...options, token})
            ).catch(error => onError(error));
    }
    return doFetch(url, onSuccess, onError, options);
};

export const doFetch = (url, onSuccess, onError, options) => {
    const {method, body, signal, auth, token} = options;
    const headers = getHeaders(body, token, auth);

    return fetch(url, {
        mode: 'cors',
        method: method,
        headers: headers,
        body,
        signal,
    }).then(response => {
        if (response.ok && response.status !== 204) {
            return response.clone().json().catch(() => response.text())
                .then(data => onSuccess(data));
        } else if (response.status === 204) {
            onSuccess({});
        } else {
            console.error("ERROR: ", response);
            return response.text()
                .then(json => onError(json))
                .catch(error => onError(error));
        }
    });
};

/**
 * Generates http request headers.
 *
 * @param body
 * @param token
 * @param auth
 * @returns {Headers}
 */
const getHeaders = (body, token, auth) => {
    const headers = new Headers({'accept': 'application/json'});
    if(!!token) {
        if (auth === 'bearer') {
            headers.append('Authorization', 'Bearer ' + token);
        } else {
            headers.append('x-authorization-firebase', token);
            headers.append('authorization', REQUEST_AUTH);
        }
    }

    if (!!body && !(body instanceof FormData)) {
        headers.append('Content-Type', 'application/json');
    }
    return headers;
}

export const buildUrl = (url, variables) =>
    !!url ? url.replace(/:(\w+)/g, (match, p1) => p1 in variables ? variables[p1] : match) : undefined;