import React from 'react';
import {render} from 'react-dom';
import {Provider} from 'react-redux'
import './index.css';
import App from './App';
import createStore from "./configureStore";
import rootReducer from "./reducers";
import {Elements} from "@stripe/react-stripe-js";
import {loadStripe} from "@stripe/stripe-js/pure";
import {API_PUBLIC_KEY, APP_VERSION} from "./utils/constants";
import {Helmet} from "react-helmet";

const store = createStore(rootReducer);

const apiPromise = loadStripe(API_PUBLIC_KEY);
global.app_version = APP_VERSION;
render(
    <Elements stripe={apiPromise}>
        <Helmet>
            <title>myur! - La app para alquilarlo todo</title>
            <meta name="description"
                  content="✅ Somos la app para alquilarlo todo! Empieza ya a ganar dinerito con las cosas que no usas y alquila todo lo que necesites con myur!"/>
            <meta property="og:title" content="myur! - La app para alquilarlo todo"/>
            <meta property="og:description"
                  content="✅ Somos la app para alquilarlo todo! Empieza ya a ganar dinerito con las cosas que no usas y alquila todo lo que necesites con myur!"/>
            <meta property="og:type" content="website"/>
            <meta property="og:locale" content="es_ES"/>
            <meta property="og:site_name" content="myur!"/>
            <meta property="og:url" content="https://es.myur.app/"/>
            <meta property="og:image" content="https://www.myur.app/wp-content/uploads/2021/01/favicon_azul.png"/>
        </Helmet>
        <Provider store={store}>
            <App/>
        </Provider>
    </Elements>,
    document.getElementById('root')
);

