import {BLUE, MARINE, SALMON} from "../../utils/constants";
import layoutStyles from "../common/styles/layoutStyles";

export const cardStyles = theme => ({
    ...layoutStyles(theme),
    root: {
        display: 'flex',
        flexFlow: 'nowrap column',
        marginTop: theme.spacing(),
        marginLeft: theme.spacing(),
        marginRight: theme.spacing(),
    },
    data: {
        display: 'flex',
        flexFlow: 'nowrap row',
        height: '100%',
        background: 'white',
        color: MARINE,
    },
    what: {
        display: 'flex',
        flexFlow: 'wrap column',
        flex: '2 1 50%',
        textAlign: 'left',
        marginLeft: theme.spacing(),
    },
    response: {
        display: 'flex',
        flexFlow: 'wrap column',
        flex: '1 1 25%',
        justifyContent: 'center',
        alignItems: 'center',
    },
    when: {
        display: 'flex',
        flexFlow: 'wrap column',
        flex: '1 1 25%',
        alignItems: 'center',
        textAlign: 'center',
    },
    section: {
        color: BLUE,
        fontWeight: 'bold',
    },
    title: {
        color: MARINE,
        fontWeight: 'bold',
        marginBottom: theme.spacing(),
    },
    number: {
        color: MARINE,
        background: SALMON,
        borderRadius: '50%',
        width: theme.spacing(3),
        height: theme.spacing(3),
        fontWeight: 'bold',
        marginBottom: theme.spacing(),
    },
    action: {
        color: MARINE,
        fontWeight: 'bold',
        margin: theme.spacing(),
    },
});