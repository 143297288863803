import React, {useState} from 'react';
import {
    Button, Dialog, DialogActions, DialogContent,
    DialogTitle, Paper, Typography
} from '@material-ui/core';
import {makeStyles} from "@material-ui/core/styles";

import {get} from "../common/i18n/i18n";
import {
    DEFAULT_PHOTO_HEIGHT,
    DEFAULT_PHOTO_WIDTH,
    MARINE,
    PALE_BLUE,
    LIGHT_BLUE,
    BLUE,
    API_LIVE_SEARCHES,
    MYUR_ITEM_PHOTO_MAX_WIDTH,
    MYUR_ITEM_PHOTO_ASPECT_RATIO,
    SEVERITY_ERROR,
    MAX_MINI_DESCRIPTION_CHAR, SEVERITY_SUCCESS, MAX_LIVE_SEARCH_TITLE_CHAR, ANALYTICS_NEW_LIVE_SEARCH
} from "../../utils/constants";
import {cropAndResize, readFile} from "../common/image/imageUtils";
import {showMessage} from "../common/NotificationSnack";
import CameraIcon from '../../images/camera_blue.png';
import TipsIcon from '../../images/tips.png';
import MyurInput from "./MyurInput";
import ImageCard from "../common/image/ImageCard";
import {useSelector} from "react-redux";
import {doRequest} from "../../utils/http";
import buttonStyles from "../common/styles/buttonStyles";
import clsx from "clsx";
import layoutStyles from "../common/styles/layoutStyles";
import MyurWaitingComponent from "../common/MyurWaitingComponent";
import {analytics, logEvent} from "../../utils/firebase";

const VALIDATION_ERRORS = {
    name: '',
    description: '',
    zipCode: '',
    location: '',
};

const useStyles = makeStyles(theme => ({
    dialog: {
        textTransform: 'lowercase',
        background: PALE_BLUE,
        textAlign: 'center',
        color: 'white',
        fontWeight: 'bold'
    },
    content: {
        display: 'flex',
        flexFlow: 'nowrap column',
        background: PALE_BLUE,
    },
    actions: {
        display: 'flex',
        flexFlow: 'nowrap column',
        justifyContent: 'center',
        background: PALE_BLUE,
    },
    imagePaper: {
        width: DEFAULT_PHOTO_WIDTH,
        height: DEFAULT_PHOTO_HEIGHT,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        margin: theme.spacing(),
        background: PALE_BLUE,
        border: `2px solid ${MARINE}`
    },
    image: {
        width: DEFAULT_PHOTO_WIDTH / 2,
        height: DEFAULT_PHOTO_HEIGHT / 2,
    },
    label: {
        marginTop: theme.spacing(2),
        fontWeight: 'bold'
    },
    tip: {
        display: 'flex',
        flexFlow: 'nowrap row',
        justifyContent: 'flex-start',
        alignItems: 'center',
        background: LIGHT_BLUE,
        padding: theme.spacing(2),
        borderRadius: 20,
        marginTop: theme.spacing(2),
    },
    tipIcon: {
        height: 64,
    },
    tipText: {
        marginLeft: theme.spacing(2),
        fontWeight: 'bold',
        color: BLUE,
    },
    ...buttonStyles(theme),
    ...layoutStyles(theme),
}));

export default function NewSubmissionDialog(props) {
    const [open, setOpen] = React.useState(false);
    const [name, setName] = React.useState('');
    const [saving, setSaving] = React.useState(false);
    const [description, setDescription] = React.useState('');
    const [location, setLocation] = React.useState('');
    const [zipCode, setZipCode] = React.useState('');
    const [photo, setPhoto] = React.useState(null);
    const [errors, setErrors] = useState({...VALIDATION_ERRORS});
    const user = useSelector(state => state.session.profile);
    const classes = useStyles();

    function handleClickOpen() {
        setOpen(true);
    }

    function handleClose() {
        setErrors({...VALIDATION_ERRORS});
        setOpen(false);
    }

    const handleAddPhoto = (event, photo) => {
        if (event.target.files && event.target.files.length > 0) {
            const file = event.target.files[0];
            readFile(file)
                .then(image =>
                    cropAndResize(image, MYUR_ITEM_PHOTO_ASPECT_RATIO, MYUR_ITEM_PHOTO_MAX_WIDTH)
                        .then(bin => onPhotoAdded(image, bin, photo))
                )
                .catch(error => showMessage(error));
        }
    };

    /**
     *
     * @param photo {"image":{},"fileName":"banks.jpg","fileType":"image/jpeg","width":600,"height":600}
     * @param url
     * @param bin
     */
    const onPhotoAdded = (photo, bin) => {
        const url = window.URL.createObjectURL(bin);
        setPhoto({...photo, photo: url, bin,});
    };

    const handleDeletePhoto = () => {
        setPhoto(null);
    };

    const hasErrors = errors =>
        errors.name !== '' || errors.description !== '' || errors.zipCode !== '' || errors.location !== '';

    const validate = () => {
        const _errors = {...VALIDATION_ERRORS};
        _errors.name = !name ? get('mandatory_title')
            : name.length > MAX_LIVE_SEARCH_TITLE_CHAR ? get('title_2_long') : '';
        _errors.description = !description ? get('mandatory_description')
            : description.length > MAX_MINI_DESCRIPTION_CHAR ? get('description_2_long') : '';
        _errors.zipCode = !zipCode ? get('mandatory_zipcode') : '';
        _errors.location = !location ? get('mandatory_location') : '';
        setErrors(_errors);
        return _errors;
    };

    const handleSave = event => {
        event.preventDefault();
        if (hasErrors(validate())) {
            return;
        }
        setSaving(true);
        const specificRequest = {
            uid: user.uid,
            nickname: user.nickname,
            title: name,
            description,
            zipCode,
            town: location,
        };
        const body = new FormData();
        if (!!photo) {
            const fileName = photo.fileName;
            specificRequest.photo = fileName;
            body.append('photo', photo.bin, fileName);
        }
        const specificRequestDTO =
            new Blob([JSON.stringify(specificRequest)], {type: "application/json"});
        body.append('specificRequestDTO', specificRequestDTO);
        doRequest(API_LIVE_SEARCHES, onSuccess, onError, {method: 'POST', body});
    };

    const onSuccess = specificRequest => {
        logEvent(analytics, ANALYTICS_NEW_LIVE_SEARCH, {
            search_id: specificRequest.id,
            search_title: specificRequest.title,
            publisher_id: specificRequest.uid,
            publisher_nickname: specificRequest.nickname
        });
        handleClose();
        setSaving(false);
        showMessage(get('saved'), SEVERITY_SUCCESS ) ;
    };
    const onError = error => {
        setSaving(false);
        showMessage(error, SEVERITY_ERROR);
    }

    return (
        <div>
            <Button className={classes.defaultButton} onClick={handleClickOpen} disabled={!user}>
                {get('live_search_new')}
            </Button>
            <Dialog open={open} onClose={handleClose} maxWidth={'sm'} fullWidth={true}>
                <form noValidate autoComplete="off" onSubmit={handleSave}>
                    <DialogTitle className={classes.dialog}>{get('live_search_new_submission_title')}</DialogTitle>
                    <DialogContent className={classes.content}>
                        <MyurInput
                            label={get('live_search_name_label')}
                            placeholder={get('live_search_name_placeholder')}
                            value={name}
                            onChange={event => setName(event.target.value)}
                            classes={{
                                helperText: clsx(classes.helperText, MAX_LIVE_SEARCH_TITLE_CHAR - name.length < 10 && classes.helperTextError)
                            }}
                            error={errors.name !== ''}
                            helperText={`${errors.name} ${name.length}/${MAX_LIVE_SEARCH_TITLE_CHAR}`}

                        />
                        <Typography className={classes.label}>{get('live_search_photo_label')}</Typography>
                        {
                            !!photo ?
                                <ImageCard
                                    photo={photo}
                                    deleteOption={true}
                                    handleDelete={handleDeletePhoto}
                                    changeOption={true}
                                    handleChange={handleAddPhoto}
                                />
                                :
                                <label htmlFor={'file_image_add'}>
                                    <Paper className={classes.imagePaper}>
                                        <input
                                            hidden={true}
                                            type={'file'}
                                            name={'image'}
                                            id={'file_image_add'}
                                            onChange={handleAddPhoto}
                                            accept={'image/png, image/jpeg'}
                                        />
                                        <img className={classes.image} src={CameraIcon} alt={get('select_image')}/>
                                    </Paper>
                                </label>
                        }
                        <MyurInput
                            label={get('live_search_description_label')}
                            placeholder={get('live_search_description_placeholder')}
                            value={description}
                            onChange={event => setDescription(event.target.value)}
                            classes={{
                                helperText: clsx(classes.helperText, MAX_MINI_DESCRIPTION_CHAR - description.length < 10 && classes.helperTextError)
                            }}
                            error={errors.description !== ''}
                            helperText={`${errors.description} ${description.length}/${MAX_MINI_DESCRIPTION_CHAR}`}
                        />
                        <MyurInput
                            label={get('live_search_location_label')}
                            placeholder={get('live_search_location_placeholder')}
                            value={location}
                            onChange={event => setLocation(event.target.value)}
                            error={errors.location !== ''}
                            helperText={errors.location}
                        />
                        <MyurInput
                            placeholder={get('live_search_zipcode_placeholder')}
                            value={zipCode}
                            onChange={event => setZipCode(event.target.value)}
                            error={errors.zipCode !== ''}
                            helperText={errors.zipCode}
                        />
                        <Paper className={classes.tip}>
                            <img className={classes.tipIcon} src={TipsIcon} alt={get('select_image')}/>
                            <Typography className={classes.tipText}
                                        variant={"caption"}>{get('live_search_tip')}</Typography>
                        </Paper>
                    </DialogContent>
                    <DialogActions className={classes.actions}>
                        <Button className={classes.blueButton} type={'submit'} disabled={saving}>
                            {get('live_search_new')}
                        </Button>
                    </DialogActions>
                </form>
                <MyurWaitingComponent open={saving}/>
            </Dialog>
        </div>
    );
}