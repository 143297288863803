import React from 'react'
import {connect} from 'react-redux'
import {compose} from 'recompose'
import {withStyles} from '@material-ui/core/styles'
import {Button, isWidthDown, withWidth} from '@material-ui/core'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'

import withAuthorization from '../../auth/withAuthorization'
import {
    API_USER_CREATE_PAYMENT_METHOD,
    API_USER_GET_STRIPE_CLIENT, MARINE,
    RED, SEVERITY_ERROR
} from '../../../utils/constants'
import PropTypes from 'prop-types'
import {get} from "../../common/i18n/i18n";
import buttonStyles from "../../common/styles/buttonStyles";
import {buildUrl, doRequest} from "../../../utils/http";
import {CardElement} from "@stripe/react-stripe-js";
import {showMessage} from "../../common/NotificationSnack";
import ProgressButton from "../../common/ProgressButton";

let openDialogFn;

const styles = theme => ({
    ...buttonStyles(theme),
    iframe: {
        minHeight: 380,
        display: 'grid',
    },
    error: {
        color: RED,
        width: "100%",
        textAlign: "center",
    },
    card: {
        border: `1px solid ${MARINE}`,
        borderRadius: theme.spacing(1),
        margin: theme.spacing(2, 0),
        padding: theme.spacing(2),
    }
});

const CARD_ELEMENT_OPTIONS = {
    style: {
        base: {
            color: "#32325d",
            fontFamily: 'Raleway, "Helvetica Neue", Helvetica, sans-serif',
            fontSmoothing: "antialiased",
            "::placeholder": {
                color: "#aab7c4",
            },
        },
        invalid: {
            color: "#fa755a",
            iconColor: "#fa755a",
        },
    },
};

const INITIAL_STATE = {
    defaultMethod: true,
    open: false,
    secret: '',
    error: false,
    success: false,
    loading: false,
};

class PaymentMethodDialog extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            ...INITIAL_STATE,
        };
        this.orderCode = null;
        this.uid = null;
    }

    componentDidMount() {
        openDialogFn = this.openDialog;
    }

    componentWillUnmount() {
        this.setState({...INITIAL_STATE});
    }

    render() {
        const {open, error, success, loading, secret} = this.state;
        const {classes, width, stripe, elements} = this.props;
        const fullScreen = isWidthDown('sm', width);
        const disabled = !stripe || !elements || !secret || loading;
        return (
            <Dialog
                open={open}
                fullScreen={fullScreen}
                disableBackdropClick={true}
                disableEscapeKeyDown={true}
                onClose={this.handleClose}
                onEntered={this.onEnter}
                aria-labelledby={get('user_add_credit_card')}
            >
                <DialogTitle id="user_add_credit_card-dialog-title">{get('user_add_credit_card')}.</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {get('user_add_credit_card_help')}
                    </DialogContentText>
                    <CardElement className={classes.card} options={CARD_ELEMENT_OPTIONS} />
                </DialogContent>
                <DialogActions>
                    <Button onClick={this.handleClose} color="secondary" variant={"outlined"} disabled={loading}>
                        {get('close')}
                    </Button>
                    <ProgressButton handleClick={this.handleSubmit}
                                    color={'primary'} variant={"contained"}
                                    loading={loading} success={success} error={!!error}
                                    disabled={disabled}>
                        {get('user_add_credit_card')}
                    </ProgressButton>
                </DialogActions>
            </Dialog>
        );
    }

    requestClientSecret = () =>
        doRequest(buildUrl(API_USER_GET_STRIPE_CLIENT, {uid: this.props.authUser.uid}),
            response => this.setState({secret: response}),
            error => showMessage(error, SEVERITY_ERROR),
            {method: 'GET'}
        );

    handleSubmit = async event => {
        event.preventDefault();
        this.setState({error: '', success: false, loading: true});
        const {stripe, elements} = this.props
        if (!stripe || !elements) {
            this.onError(get('internal_error'));
            return;
        }

        const result = await stripe.confirmCardSetup(this.state.secret, {
            payment_method: {
                card: elements.getElement(CardElement),
                billing_details: {
                    name: this.props.authUser.displayName,
                },
            }
        });

        if (result.error) {
            this.onError(result.error);
        } else {
            const body = JSON.stringify({
                token: result.setupIntent.payment_method,
                uid: this.props.authUser.uid,
                defaultMethod: true
            });
            await doRequest(API_USER_CREATE_PAYMENT_METHOD,
                this.onSuccess,
                error => this.onError(error),
                {method: "POST", body}
            )
        }
    };

    onError = error => {
        showMessage(error, SEVERITY_ERROR);
        this.setState({error, success: false, loading: false});
        this.requestClientSecret();
    }

    onSuccess = () => {
        this.props.onSuccess();
        this.setState({error: '', success: true, loading: false});
        this.handleClose();
    }

    openDialog = uid => {
        this.uid = uid;
        this.setState({open: true});
        this.requestClientSecret();
    }

    handleClose = () => {
        this.setState({...INITIAL_STATE});
    };
}

export function openCreatePaymentMethodDialog(uid) {
    openDialogFn(uid);
}

const mapStateToProps = ({session}) => ({
    authUser: session.authUser,
});

const authCondition = authUser => !!authUser;

PaymentMethodDialog.propTypes = {
    classes: PropTypes.object.isRequired,
    authUser: PropTypes.object,
    onSuccess: PropTypes.func.isRequired,
    onError: PropTypes.func.isRequired,
};

export default compose(
    withAuthorization(authCondition),
    withStyles(styles),
    withWidth(),
    connect(mapStateToProps)
)(PaymentMethodDialog)
