import React from 'react';
import clsx from "clsx";
import {PropTypes} from 'prop-types';

import {Checkbox, FormControlLabel, Input, makeStyles, withStyles} from "@material-ui/core";
import {Rating} from "@material-ui/lab";
import StarBorderIcon from '@material-ui/icons/StarBorder';
import {BLUE, MARINE, SALMON} from "../../utils/constants";
import {get} from "../common/i18n/i18n";

const MyurRating = withStyles({
    iconFilled: {
        color: SALMON,
    },
    iconEmpty: {
        color: MARINE,
    }
})(Rating);

const ConfirmCheckbox = withStyles({
    root: {
        color: BLUE,
        '&$checked': {
            color: BLUE,
        },
    },
    checked: {},
})(props => <Checkbox color="default" {...props} />);

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.common.white,
        borderRadius: '0.5em',
        width: '50%',
        display: 'flex',
        flexFlow: 'wrap column',
        alignItems: 'center',
        padding: theme.spacing(2),
    },
}));

export default function RatingForm(props) {
    const classes = useStyles();
    const {className, rating, setRating, comment, setComment, anonymous, setAnonymous} = props;
    return (
        <div className={clsx(classes.root, className)}>
            <FormControlLabel
                control={
                    <ConfirmCheckbox
                        checked={anonymous}
                        onChange={event => setAnonymous(event.target.checked)}
                        value={'anonymous'}
                    />
                }
                label={get('loan_detail_rating_form_anonymous')}
            />

            <MyurRating
                name="loan-rating"
                value={rating}
                onChange={(event, rating) => setRating(rating)}
                //precision={0.5}
                emptyIcon={<StarBorderIcon fontSize="inherit"/>}
            />
            <Input
                fullWidth={true}
                value={comment}
                onChange={event => setComment(event.target.value)}
                placeholder={get('loan_detail_rating_form_input_placeholder')}
            />
        </div>
    );
}

RatingForm.propTypes = {
    className: PropTypes.string,
    rating: PropTypes.number,
    setRating: PropTypes.func.isRequired,
    comment: PropTypes.string,
    setComment: PropTypes.func.isRequired,
    anonymous: PropTypes.bool,
    setAnonymous: PropTypes.func.isRequired,
};