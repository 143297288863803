import React from 'react';

import {makeStyles} from "@material-ui/core";

import NotificationIcon from "../../../images/icon_prestamos.svg";
import {BLUE, SAND} from "../../../utils/constants";
import Skeleton from "@material-ui/lab/Skeleton";
import {get} from "../../common/i18n/i18n";

const useStyles = makeStyles(theme => ({
    paper: {
        display: 'flex',
        flexFlow: 'no-wrap row',
        justifyContent: 'flex-start',
        backgroundColor: theme.palette.common.white,
        marginBottom: theme.spacing(),
        padding: theme.spacing(),
        borderTop: `2px solid ${SAND}`
    },
    photo: {
        height: '2rem',
        borderRadius: '4px',
        margin: theme.spacing(),
    },
    info: {
        width: '100%',
        textAlign: 'left',
    },
    header: {
        display: 'flex',
        flexFlow: 'wrap row',
        justifyContent: 'space-between',
    },
    title: {
        marginTop: theme.spacing(),
        color: BLUE,
    },
}));

const NotificationSkeleton = props => {
    const classes = useStyles();
    return (
        <div className={classes.paper}>
            <img className={classes.photo} src={NotificationIcon} alt={get('notification')}/>
            <div className={classes.info}>
                <div>
                    <div className={classes.header}>
                        <Skeleton className={classes.title} height={32} width={'30%'}/>
                        <Skeleton className={classes.title} height={16} width={'20%'}/>
                    </div>
                    <Skeleton className={classes.title} height={32} width={'80%'}/>
                </div>
            </div>
        </div>
    );
};

export default NotificationSkeleton;