import React, {useEffect, useState} from 'react';
import {PropTypes} from 'prop-types';
import {makeStyles, Typography} from "@material-ui/core";
import moment from "moment";
import {DATE_TIME_FORMAT, MARINE} from "../../utils/constants";

const useStyles = makeStyles(theme => ({
    countdown: {
        display: 'flex',
        flexFlow: 'nowrap row',
        justifyContent: 'center',
    },
    question: {
        marginLeft: theme.spacing(2),
        fontWeight: 'bold',
        color: MARINE,
    },
    digit: {
        display: 'flex',
        flexFlow: 'nowrap column',
        justifyContent: 'center',
        margin: theme.spacing(),
        width: 96,
    },
    number: {
        padding: theme.spacing(2),
        verticalAlign: 'center',
        fontWeight: 'bold',
        border: '1px solid ' + MARINE,
    },
    caption: {
        fontWeight: theme.typography.fontWeightBold,
    }
}));

export default function LoanCountdownComponent({dueDate, helperText, className}) {

    const classes = useStyles();
    const [now, setNow] = useState(moment());

    useEffect(() => {
        const timer = setInterval(() => setNow(moment()), 1000);
        return () => clearInterval(timer);
    }, []);

    const diff = moment.duration(moment.utc(dueDate, DATE_TIME_FORMAT).diff(now));
    const to = {days: 0, hours: 0, minutes: 0, seconds: 0};

    if (diff.asDays() > 0) {
        to.days = diff.asDays();
        to.hours = (to.days % 1) * 24;
        to.minutes = (to.hours % 1) * 60;
        to.seconds = (to.minutes % 1) * 60;
    }

    return (
        <div className={className}>
            <Typography variant={'h6'} className={classes.question}>
                {helperText}
            </Typography>
            <div className={classes.countdown}>
                <div className={classes.digit}>
                    <Typography variant={'h3'} className={classes.number}>
                        {(Math.floor(to.days) + '').padStart(2, '0')}
                    </Typography>
                    <Typography variant={'caption'} className={classes.caption}>días</Typography>
                </div>
                <div className={classes.digit}>
                    <Typography variant={'h3'} className={classes.number}>
                        {(Math.floor(to.hours) + '').padStart(2, '0')}
                    </Typography>
                    <Typography variant={'caption'} className={classes.caption}>horas</Typography>
                </div>
                <div className={classes.digit}>
                    <Typography variant={'h3'} className={classes.number}>
                        {(Math.floor(to.minutes) + '').padStart(2, '0')}
                    </Typography>
                    <Typography variant={'caption'} className={classes.caption}>minutos</Typography>
                </div>
                <div className={classes.digit}>
                    <Typography variant={'h3'} className={classes.number}>
                        {(Math.floor(to.seconds) + '').padStart(2, '0')}
                    </Typography>
                    <Typography variant={'caption'} className={classes.caption}>segundos</Typography>
                </div>
            </div>
        </div>
    )
}

LoanCountdownComponent.propTypes = {
    className: PropTypes.string,
    dueDate: PropTypes.string.isRequired,
    helperText: PropTypes.string,
};

LoanCountdownComponent.defaulProps = {
    className: '',
};