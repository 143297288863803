import React from 'react';
import PropTypes from 'prop-types';
import moment from "moment";
import {get} from "../common/i18n/i18n";
import {Typography} from "@material-ui/core";
import makeStyles from "@material-ui/core/styles/makeStyles";
import {cardStyles} from "./live_search_styles";
import {Link} from "react-router-dom";
import clsx from "clsx";
import {getPublicUrl} from "../item/item_utils";

const useStyles = makeStyles(cardStyles);

const LiveSearchMyResponse = ({response}) => {
    const date = moment(response.responseDate, 'YYYYMMDDhhmmss').format(get('date_ui'));
    const time = moment(response.responseDate, 'YYYYMMDDhhmmss').format('hh:mm:ss');
    const url = getPublicUrl(response.item);
    const classes = useStyles();
    return (
        <div className={classes.root}>
            <div className={classes.data}>
                <div className={classes.when}>
                    <Typography variant={'caption'} className={classes.section}>{date}</Typography>
                    <Typography variant={'caption'} className={classes.section}>{time}</Typography>
                </div>
                <div className={classes.what}>
                    <Typography variant={'caption'} className={classes.section}>{get('live_search_response_to')}</Typography>
                    <Typography variant={'body1'} className={classes.title}>{response.title}</Typography>
                </div>
                <div className={classes.response}>
                    <Typography variant={'caption'} className={classes.section}>{get('live_search_response_with')}</Typography>
                    <Typography variant={'body1'} className={clsx(classes.title, classes.link)} component={Link} to={url}>
                        {response.item.title}
                    </Typography>
                </div>
            </div>
        </div>
    );
};

LiveSearchMyResponse.propTypes = {
    response: PropTypes.object.isRequired
};

export default LiveSearchMyResponse;