import React from 'react';

import {PropTypes} from "prop-types";

import {
    useMediaQuery, useTheme, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField
} from '@material-ui/core';
import {get} from "../../common/i18n/i18n";
import {makeStyles} from "@material-ui/core/styles";
import buttonStyles from "../../common/styles/buttonStyles";

const useStyles = makeStyles(theme => ({
    ...buttonStyles(theme),
}));

export default function ReportItemDialog({open, handleClose, handleReport}) {
    const [reason, setReason] = React.useState('');
    const classes = useStyles();
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('xs'));

    const onSendClicked = () => {
        handleReport(reason.trim());
    };

    return (
        <Dialog open={open} onClose={handleClose} fullScreen={fullScreen}>
            <DialogTitle>{get('report_item')}</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    {get('report_item_helper')}
                </DialogContentText>
                <TextField
                    autoFocus
                    value={reason}
                    label={get('reason')}
                    type={'text'}
                    onChange={event => setReason(event.target.value)}
                    fullWidth
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} className={classes.defaultButton}>
                    {get('CANCEL')}
                </Button>
                <Button onClick={onSendClicked} className={classes.blueButton}>
                    {get('send')}
                </Button>
            </DialogActions>
        </Dialog>
    );
}

ReportItemDialog.propTypes = {
    open: PropTypes.bool.isRequired,
    handleReport: PropTypes.func.isRequired,
    handleClose: PropTypes.func.isRequired,
};
