import React from 'react'

import {makeStyles} from '@material-ui/core/styles'
import {get} from "../common/i18n/i18n";
import LiveSearchBoard from "./LiveSearchBoard";
import LiveSearchMySubmissions from "./LiveSearchMySubmissions";
import LiveSearchTabs from "./LiveSearchTabs";
import layoutStyles from "../common/styles/layoutStyles";
import LiveSearchMyResponses from "./LiveSearchMyResponses";

const useStyles = makeStyles(theme => ({
    ...layoutStyles(theme)
}));

export default function LiveSearchComponent(props) {
    const classes = useStyles();

    const TABS = [
        {title: get('live_search_board'), component: <LiveSearchBoard/>, disabled: () => false},
        {title: get("live_search_my_submissions"), component: <LiveSearchMySubmissions/>, disabled: user => !user},
        {title: get("live_search_my_responses"), component: <LiveSearchMyResponses/>, disabled: user => !user},
    ];

    return (
        <div className={classes.component}>
            <LiveSearchTabs className={classes.root} tabs={TABS}/>
        </div>
    );
}

