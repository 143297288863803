import React from 'react';
import {useHistory} from 'react-router-dom';
import {PropTypes} from "prop-types";

import {Button} from "@material-ui/core";

import {doSignInWithGoogle} from "../../utils/firebase";
import {get} from "../common/i18n/i18n";
import {API_USER_EXISTS, SEVERITY_ERROR} from "../../utils/constants";
import GoogleIcon from '../../images/Google_icon.png'
import {showMessage} from "../common/NotificationSnack";
import {buildUrl, doFetch} from "../../utils/http";
import {LANDING} from "../../utils/routes";

export default function GoogleButton(props) {
    const history = useHistory();
    const useGoogle = () =>
        doSignInWithGoogle()
            .then(result => {
                const {uid, email, emailVerified} = result.user;
                return doFetch(
                    buildUrl(API_USER_EXISTS, {uid}),
                    user => {
                        if (user.uid === uid) {
                            history.push(LANDING)
                        } else {
                            props.onCredentials(uid, email, emailVerified);
                        }
                    },
                    () => props.onCredentials(uid, email, emailVerified),
                    {method: 'GET'}
                );
            })
            .catch(error => showMessage(error, SEVERITY_ERROR));

    return (
        <Button onClick={useGoogle} className={props.className}>
            <img alt={'select_email_password'} src={GoogleIcon} width={24} style={{marginRight: 16}}/>
            {get('select_google_account')}
        </Button>
    );
}

GoogleButton.propTypes = {
    onCredentials: PropTypes.func.isRequired,
    className: PropTypes.string,
};