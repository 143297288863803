import React, {useState} from 'react';
import {Button, Typography} from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import {get} from "../common/i18n/i18n";
import {makeStyles} from "@material-ui/core/styles";
import buttonStyles from "../common/styles/buttonStyles";
import {useMediaQuery, useTheme} from "@material-ui/core";
import {PropTypes} from "prop-types";
import IssueTypeRadioGroup from "./IssueTypeRadioGroup";
import MyurInput from "../live/MyurInput";
import {MARINE, MAX_DESCRIPTION_CHAR, PALE_SAND} from "../../utils/constants";
import InsuranceIssueComponent from "./InsuranceIssueComponent";
import clsx from "clsx";
import layoutStyles from "../common/styles/layoutStyles";

const useStyles = makeStyles(theme => ({
    dialogContent: {
        //width: '100%',
        //backgroundColor: PALE_SAND,
    },
    dialogTitle: {
        color: MARINE,
        backgroundColor: PALE_SAND,
    },
    description: {
        width: '100%',
        color: MARINE,
    },
    issueType: {
        backgroundColor: theme.palette.common.white,
        maxHeight: '14rem',
    },
    descriptionLabel: {
        color: MARINE,
        ...theme.typography.h4,
    },
    ...buttonStyles(theme),
    ...layoutStyles(theme),
}));

const VALIDATION_ERRORS = {
    issueType: '',
    issueDescription: '',
    insuranceIssueWhere: '',
    insuranceIssueWhen: '',
    insuranceIssueImage: '',
};

const insuranceIssueTemplate = {broken: false,  where: '', when: null, image: null};

export default function NewIssueDialog({loan, open, handleClose, handleIssue}) {

    const classes = useStyles();
    const [issueType, setIssueType] = useState(null);
    const [description, setDescription] = React.useState('');
    const [insuranceIssue, setInsuranceIssue] = useState({...insuranceIssueTemplate});
    const [errors, setErrors] = useState({...VALIDATION_ERRORS});
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('xs'));

    const onSendClicked = () => {
        const errors = validate();
        if (hasErrors(errors)) {
            return;
        }
        handleIssue({issueType, issueText: description, insuranceIssue});
    };

    const onChange = value => setIssueType(value);

    const onClose = () => {
        setIssueType(null);
        setDescription('');
        setInsuranceIssue({...insuranceIssueTemplate});
        setErrors({...VALIDATION_ERRORS});
        handleClose();
    };

    const isInsuranceIssue = issueType =>
        !!issueType && issueType.issueTypeId >= 6 && issueType.issueTypeId <= 8;

    const validate = () => {
        const errors = {...VALIDATION_ERRORS};
        const isII = isInsuranceIssue(issueType);
        errors.issueType = !!issueType ? '' : get('issue_error_type');
        errors.issueDescription = !description ? get('issue_error_description_mandatory')
            : description.length > MAX_DESCRIPTION_CHAR ? get('issue_error_description_2_long') : '';
        errors.insuranceIssueWhere = (isII && !insuranceIssue.where) ? get('issue_error_where') : '';
        errors.insuranceIssueWhen = (isII && !insuranceIssue.when) ? get('issue_error_when') : '';
        errors.insuranceIssueImage =
            !!isII && (issueType.issueTypeId === 6 || issueType.issueTypeId === 8) && !insuranceIssue.image
                ? get('issue_error_image') : '';
        setErrors(errors);
        return errors;
    };

    const hasErrors = errors => {
        return (errors.issueType !== '' || errors.issueDescription !== '' || errors.insuranceIssueWhere !== ''
            || errors.insuranceIssueWhen !== '' || errors.insuranceIssueImage);
    };

    return (
        <Dialog open={open} onClose={onClose} fullScreen={fullScreen}>
            <DialogTitle className={classes.dialogTitle} disableTypography={true}>
                <Typography variant={'h2'}>{loan.itemTitle}</Typography>
            </DialogTitle>
            <DialogContent className={classes.dialogContent}>
                <IssueTypeRadioGroup className={classes.issueType} value={issueType} error={errors.issueType} onChange={onChange}/>
                {
                    isInsuranceIssue(issueType) &&
                        <InsuranceIssueComponent onChange={setInsuranceIssue} value={insuranceIssue}
                                                 issueType={issueType} errors={errors}/>
                }
                <MyurInput
                    className={classes.description}
                    label={get('issue_description_label')}
                    placeholder={get('issue_description_placeholder')}
                    value={description}
                    onChange={event => setDescription(event.target.value)}
                    multiline={true}
                    rows={4}
                    classes={{
                        label: classes.descriptionLabel,
                    }}
                    fullWidth
                    error={errors.issueDescription !== ''}
                    FormHelperTextProps={{
                        classes: {
                            root: clsx(classes.helperText, MAX_DESCRIPTION_CHAR - description.length < 20 && classes.helperTextError)
                        }
                    }}
                    helperText={`${errors.issueDescription} ${description.length}/${MAX_DESCRIPTION_CHAR}`}
                />
                <Typography variant={'h6'}>{get('issue_send_helper')}</Typography>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} className={classes.defaultButton}>
                    {get('CANCEL')}
                </Button>
                <Button onClick={onSendClicked} className={classes.blueButton}>
                    {get('send')}
                </Button>
            </DialogActions>
        </Dialog>
    );
}

NewIssueDialog.propTypes = {
    loan: PropTypes.object.isRequired,
    open: PropTypes.bool.isRequired,
    handleIssue: PropTypes.func.isRequired,
    handleClose: PropTypes.func.isRequired,
};