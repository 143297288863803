import React from 'react'
import {PropTypes} from 'prop-types'

import {Typography, Button, makeStyles} from '@material-ui/core'

import {
    BLUE, PALE_SAND, DEFAULT_MAX_WIDTH,
} from "../../utils/constants";
import OwnerToBorrower from "./OwnerToBorrower";
import buttonStyles from "../common/styles/buttonStyles";
import clsx from "clsx";
import RatingForm from "./RatingForm";
import {get} from "../common/i18n/i18n";

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        maxWidth: DEFAULT_MAX_WIDTH,
        backgroundColor: PALE_SAND,
        display: 'flex',
        flexFlow: 'wrap column',
        alignItems: 'center',
    },
    ownerToBorrower: {
        justifyContent: 'center',
    },
    title: {
        marginTop: theme.spacing(),
        color: BLUE,
        fontWeight: 'bold',
    },
    button: {
        margin: theme.spacing(2),
        fontWeight: 'bold',
        color: BLUE,
    },
    rating: {
        margin: theme.spacing(2, 0),
    },
    ...buttonStyles(theme),
}));

export default function ConfirmEndLoan(props) {
    const classes = useStyles();
    const {className, loan, onEndLoanConfirmation, rating, comment, anonymous, handleRatingUpdate} = props;

    return (
        <div className={clsx(classes.root, className)}>
            <OwnerToBorrower
                className={classes.ownerToBorrower}
                owner={{avatar: loan.ownerAvatar, nickname: loan.ownerNickname}}
                borrower={{avatar: loan.borrowerAvatar, nickname: loan.borrowerNickname}}
            />
            <div>
                <Typography variant={'body1'} className={classes.title}>
                    {get('loan_detail_confirm_finished_l1')}
                </Typography>
            </div>

            <RatingForm className={classes.rating}
                        rating={rating} setRating={handleRatingUpdate('rating')}
                        comment={comment} setComment={handleRatingUpdate('comment')}
                        anonymous={anonymous} setAnonymous={handleRatingUpdate('anonymous')}
            />

            <div>
                <Typography variant={"body1"} className={classes.title}>
                    {get('loan_detail_confirm_finished_l2')}
                </Typography>
            </div>
            <div>
                <Button variant={'contained'} className={classes.blueButton} onClick={onEndLoanConfirmation}>
                    {get('loan_detail_confirm_finished_b1')}
                </Button>
            </div>
        </div>
    )
}

ConfirmEndLoan.propTypes = {
    className: PropTypes.string,
    loan: PropTypes.object.isRequired,
    onEndLoanConfirmation: PropTypes.func.isRequired,
    rating: PropTypes.number,
    comment: PropTypes.string,
    anonymous: PropTypes.bool,
    handleRatingUpdate: PropTypes.func.isRequired,
};