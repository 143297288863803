import React, {useEffect, useRef, useState} from 'react';
import {Link} from "react-router-dom";
import clsx from "clsx";
import {useDispatch, useSelector} from "react-redux";

import {makeStyles, Avatar, Badge, Typography, Hidden, Button, IconButton} from "@material-ui/core";
import AddIconOutline from '@material-ui/icons/AddCircleOutline';
import AddIcon from '@material-ui/icons/AddCircle';

import myur from "../../images/myur_logo_DSB.png";
import UserPhoto from '../../images/UserPhoto5.png';
import Messages from '../../images/icon_mensajes.png';
import Notifications from '../../images/icon_notification.png';
import * as routes from "../../utils/routes";
import {
    BLUE,
    DEFAULT_AVATAR,
    DEFAULT_MAX_WIDTH,
    DEFAULT_USER_AVATAR_URL,
    MARINE, STORE_APP_STORE, STORE_GOOGLE_PLAY,
    TOP_MAX_WIDTH
} from "../../utils/constants";
import {get} from "../common/i18n/i18n";
import '../../index.css';
import {getProfile} from "../../utils/localStorage";
import {buildUrl} from "../../utils/http";
import MyurDropdown from "./MyurDropdown";
import withMessaging from "../../utils/firebase/withMessaging";
import MC_miperfil from "../../images/MC_miperfil.png";
import {ROUTE_NOTIFICATIONS, ROUTE_PROFILE} from "../../utils/routes";
import MC_misprestamos from "../../images/MC_misprestamos.png";
import {ROUTE_LOANS} from "../../utils/routes";
import MC_misarticulos from "../../images/MC_misarticulos.png";
import {ROUTE_MY_ITEMS} from "../../utils/routes";
import MC_mensajes from "../../images/MC_mensajes.png";
import {ROUTE_CHATS} from "../../utils/routes";
import {ROUTE_LIVE_SEARCH} from "../../utils/routes";
import MC_favoritos from "../../images/MC_favoritos.png";
import {ROUTE_FAVS} from "../../utils/routes";
import MC_misganancias from "../../images/MC_misganancias.png";
import {ROUTE_EARNS} from "../../utils/routes";
import MC_opiniones from "../../images/MC_opiniones.png";
import {ROUTE_REVIEWS} from "../../utils/routes";
import {
    addMessagesPending,
    addNotificationsPending,
    reloadMessagesPending,
    reloadNotificationsPending
} from "../../actions/notificationActions";
import googlePlay from "../../images/google-play-badge.png";
import appStore from "../../images/Download_on_the_App_Store_Badge.svg";
import buttonStyles from "../common/styles/buttonStyles";

const useStyles = makeStyles(theme => ({
    ...buttonStyles(theme),
    root: {
        display: 'flex',
        flexFlow: 'nowrap row',
        justifyContent: 'space-around',
        alignItems: 'center',
        margin: '0 auto',
        position: 'relative',
        width: '100%',
        maxWidth: TOP_MAX_WIDTH,
        backgroundColor: theme.palette.common.white,
        height: '4em',
    },
    logo: {
        [theme.breakpoints.down('md')]: {
            marginLeft: theme.spacing(1)
        }
    },
    inner: {
        display: 'flex',
        flexFlow: 'nowrap row',
        justifyContent: 'space-between',
        width: '100%',
        maxWidth: DEFAULT_MAX_WIDTH,
        margin: '0 auto',
        position: 'relative',
        [theme.breakpoints.down('sm')]: {
            display: 'flex',
            flexFlow: 'nowrap row',
            justifyContent: "flex-end",
        }
    },
    user: {
        display: 'flex',
        flexFlow: 'nowrap row',
        justifyContent: 'flex-start',
        alignItems: 'center',
        [theme.breakpoints.down('md')]: {
            marginRight: theme.spacing(1)
        }
    },
    options: {
        display: 'flex',
        flexFlow: 'nowrap row',
        justifyContent: 'center',
        alignItems: 'center'
    },
    notifications: {
        display: 'flex',
        flexFlow: 'nowrap row',
        justifyContent: 'flex-end',
        alignItems: 'center',
        [theme.breakpoints.down('xs')]: {
            order: 1,
            marginRight: theme.spacing(1),
        },
    },
    text: {
        marginLeft: theme.spacing(2),
        color: MARINE,
    },
    link: {
        color: MARINE,
        textTransform: 'lowercase',
        textDecoration: 'none',
        '&:hover': {
            textDecoration: 'underline',
        },
    },
    marginLeft: {
        marginLeft: theme.spacing(2),
    },
    cap: {
        textTransform: "uppercase",
    },
    marginRight: {
        marginRight: theme.spacing(2),
    },
    stores: {
       display: "block",
        verticalAlign: "center"
    },
    store: {
        width: 'auto',
        height: '20px',
        marginLeft: theme.spacing(1)
    },
    newItemIcon: {
        color: BLUE,
        fontSize: "larger"
    }
}));

/*
const MORE_MYUR_OPTIONS = [
    {text: 'how_it_works', icon: MC_ayuda, url: ROUTE_HELP},
    {text: 'who_are_we', icon: MC_ayuda, url: ROUTE_HELP},
    {text: 'faq', icon: MC_ayuda, url: ROUTE_HELP},
    {text: 'help', icon: MC_ayuda, url: ROUTE_HELP},
];
*/
const PROFILE_OPTIONS = [
    {text: 'edit_profile', icon: MC_miperfil, url: ROUTE_PROFILE},
    {text: 'my_loans', icon: MC_misprestamos, url: ROUTE_LOANS},
    {text: 'my_items', icon: MC_misarticulos, url: ROUTE_MY_ITEMS},
    {text: 'chats', icon: MC_mensajes, url: ROUTE_CHATS},
    {text: 'notifications', icon: MC_mensajes, url: ROUTE_NOTIFICATIONS},
    {text: 'requests', icon: MC_miperfil, url: ROUTE_LIVE_SEARCH},
    {text: 'favourites', icon: MC_favoritos, url: ROUTE_FAVS},
    {text: 'my_earns', icon: MC_misganancias, url: ROUTE_EARNS},
    {text: 'reviews', icon: MC_opiniones, url: ROUTE_REVIEWS},
    // {text: 'share_with_friends', icon: MC_formasdepago, url: ROUTE_SHARE},
];

function TopComponent(props) {
    const {registerNotificationHandler, unRegisterNotificationHandler} = props;
    const classes = useStyles();
    const [profile, setProfile] = useState(null);
    const user = useSelector(state => state.session.profile);
    const dispatch = useDispatch();
    const messagesPending = useSelector(state => state.notifications.messagesPending);
    const notificationsPending = useSelector(state => state.notifications.notificationsPending);
    const userRef = useRef(user);
    userRef.current = user;

    const onMessage = message => {
        if (message.android_channel_id === 'chatchannel') {
            dispatch(addMessagesPending(1));
        } else {
            dispatch(addNotificationsPending(1));
        }
    }

    useEffect(() => {
        registerNotificationHandler(onMessage);
        return () => unRegisterNotificationHandler();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (!!user) {
            setProfile(user);
        } else {
            setProfile(getProfile());
        }
    }, [user]);

    useEffect(() => {
        if (!user) {
            return;
        }
        dispatch(reloadMessagesPending(user.uid));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user]);

    useEffect(() => {
        if (!user) {
            return;
        }
        dispatch(reloadNotificationsPending(user.uid));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user]);

    return (
        <div className={classes.root}>
            <div className={classes.logo}>
                <Link to={routes.LANDING}>
                    <img height={40} alt="myur!" src={myur}/>
                </Link>
            </div>
            <div container className={classes.inner}>
                <Hidden smDown>
                    <div className={classes.options}>
                        <a href={STORE_GOOGLE_PLAY} className={classes.store}>
                            <img alt='google play' className={classes.store} src={googlePlay}/>
                        </a>
                        <a href={STORE_APP_STORE} className={classes.store}>
                            <img className={classes.store} alt={'app store'} src={appStore}/>
                        </a>
                    </div>
                </Hidden>
                <div className={classes.options}>
                    <Hidden xsDown>
                        <Button className={clsx(classes.blueButton, classes.marginRight)}
                                component={Link} to={routes.ROUTE_ITEM_NEW}
                                startIcon={<AddIconOutline />}
                        >
                            {get('new_item')}
                        </Button>
                    </Hidden>
                    <Hidden smUp>
                        <IconButton className={classes.marginRight} style={{padding: 0}} component={Link} to={routes.ROUTE_ITEM_NEW}>
                            <AddIcon className={classes.newItemIcon}/>
                        </IconButton>
                    </Hidden>
                    <Hidden xsDown>
                        <Typography component={Link} variant={'h4'}
                                    className={clsx(classes.link, classes.marginRight)}
                                    to={routes.ROUTE_LIVE_SEARCH}>
                            {get('live_search_request')}
                        </Typography>
                    </Hidden>
                    {!!profile ?
                        <div className={classes.notifications}>
                            <Badge badgeContent={messagesPending} color="primary" className={classes.marginRight}>
                                <Link to={routes.ROUTE_CHATS}>
                                    <img height={24} alt={get('messages')} src={Messages}/>
                                </Link>
                            </Badge>
                            <Badge badgeContent={notificationsPending} color="primary" className={classes.marginRight}>
                                <Link to={routes.ROUTE_NOTIFICATIONS}>
                                    <img height={24} alt={get('notifications')} src={Notifications}/>
                                </Link>
                            </Badge>
                        </div>
                        :
                        <div className={classes.notifications}/>
                    }
                </div>
            </div>
            {!!profile ?
                <div className={classes.user}>
                    <Hidden smDown>
                        <Avatar alt={profile.nickname}
                                src={buildUrl(DEFAULT_USER_AVATAR_URL, {uid: profile.uid})}
                                className={classes.avatar}
                                imgProps={{
                                    onError: e => e.target.src = DEFAULT_AVATAR
                                }}
                        />
                    </Hidden>
                    <MyurDropdown label={profile.nickname} variant={'h5'} options={PROFILE_OPTIONS}/>
                </div>
                :
                <div className={classes.user}>
                    <img className={classes.avatar} height={40} alt="myur!" src={UserPhoto}/>
                    <Typography component={Link} variant={'h4'}
                                className={clsx(classes.link, classes.marginLeft, classes.cap)}
                                to={routes.ROUTE_SIGN_IN}>
                        {get('access')}
                    </Typography>
                </div>
            }
        </div>
    );
}

export default withMessaging()(TopComponent);