import React, {useState, useEffect} from 'react'
import {PropTypes} from 'prop-types'
import {withRouter} from 'react-router-dom'
import {compose} from "recompose";
import {connect} from "react-redux";

import {makeStyles} from "@material-ui/core/styles";
import GoBackHeader from "../../common/GoBackHeader";
import {buildUrl, doGet} from "../../../utils/http";
import {showMessage} from "../../common/NotificationSnack";
import {API_LOAN_GET_PROFIT, BLUE, MARINE, YELLOW} from "../../../utils/constants";
import withAuthorization from "../../auth/withAuthorization";
import {Typography} from "@material-ui/core";
import {get} from "../../common/i18n/i18n";
import {getUid} from "../../../utils/utils";
import {Bar, BarChart, LabelList, ResponsiveContainer, Tooltip, XAxis} from "recharts";
import moment from "moment";
import clsx from "clsx";
import layoutStyles from "../../common/styles/layoutStyles";
import ProfileSectionComponent from "../../common/ProfileSectionComponent";
import EarnsDetailComponent from "./EarnsDetailComponent";

const useStyles = makeStyles(theme => ({
    ...layoutStyles(theme),
    root: {
        display: "flex",
        flexFlow: "nowrap column",
        alignContent: "center",
    },
    ball: {
        display: "flex",
        flexFlow: "nowrap column",
        alignItems: "center",
        justifyContent: "center",
        width: "24%",
        height: 0,
        textAlign: "center",
        verticalAlign: "middle",
        lineHeight: 0,
        padding: "12% 0",
        borderRadius: "50%",
        color: theme.palette.common.white,
        backgroundColor: BLUE,
    },
    bigger: {
        width: "30%",
        padding: "15% 0",
    },
    summary: {
        display: "flex",
        flexFlow: "wrap row",
        width: "100%",
        justifyContent: "space-around",
        alignItems: "center",
    },
    chart: {
        marginTop: theme.spacing(2),
    },
    profit: {
        fontSize: 12,
        color: MARINE,
    },
}));

function fillData(profit) {
    const returnValue = [];
    for (var i = 11; i >= 0; i--) {
        const value = moment().startOf("month").subtract(i, "months");
        const detailDate = value.format("YYYYMM");
        const actualValue = profit.detailProfit.find(value => value.detailDate === detailDate);
        returnValue.push({
                detailDate,
                year: value.get("year"),
                month: value.get("month") + 1,
                parcialProfit: !!actualValue ? actualValue.parcialProfit : 0,
            });
    }
    return {...profit, detailProfit: returnValue};
}
function UserDetailComponent(props) {

    const [profit, setProfit] = useState({globalProfit: 0, detailProfit: []});
    const [waiting, setWaiting] = useState(false);
    const classes = useStyles();
    const uid = getUid();

    useEffect(() => {
        setWaiting(true);
        doGet(buildUrl(API_LOAN_GET_PROFIT, {uid}))
            .then(profit => {
                setProfit(fillData(profit));
                setWaiting(false);
            })
            .catch(
                error => {
                    setWaiting(false);
                    showMessage(error);
                });
    }, [uid]);

    return (
        <ProfileSectionComponent waiting={waiting}>
            <GoBackHeader title={get('my_earns')}/>
            <div className={classes.summary}>
                <div className={clsx(classes.bigger, classes.ball)}>
                    <Typography variant="h1">total</Typography>
                    <Typography variant="h1">{get('currency_formatter').format(profit.globalProfit)}</Typography>
                </div>
                {
                    profit.detailProfit.length > 0 &&
                    <div className={classes.ball}>
                        <Typography variant="h2">
                            {moment(profit.detailProfit[profit.detailProfit.length - 1].detailDate, "YYYYMM").format("MMM YYYY")}
                        </Typography>
                        <Typography variant="h2">
                            {get('currency_formatter').format(profit.detailProfit[profit.detailProfit.length - 1].parcialProfit)}
                        </Typography>
                    </div>
                }
            </div>
            <ResponsiveContainer className={classes.chart} width={"100%"} aspect={1.78}>
                <BarChart margin={{top: 16, bottom: 16, left: 64, right: 16}}
                          data={profit.detailProfit}>
                    <XAxis axisLine={false} interval={1} tick={{fontSize: 10}}
                           dataKey={data => moment(data.detailDate, "YYYYMM").format("MMM")}/>
                    <Tooltip separator="" formatter={value => [get('currency_formatter').format(value), ""]}/>
                    <Bar dataKey="parcialProfit" fill={YELLOW}>
                        <LabelList className={classes.profit} dataKey="parcialProfit" position="top"
                                   formatter={data => get('currency_formatter').format(data)}/>
                    </Bar>
                </BarChart>
            </ResponsiveContainer>
            <EarnsDetailComponent/>
        </ProfileSectionComponent>
    );
}

UserDetailComponent.propTypes = {
    authUser: PropTypes.object,
};

const mapStateToProps = ({session}) => ({
    authUser: session.authUser,
});

const authCondition = profile => !!profile;

export default compose(
    connect(mapStateToProps),
    withAuthorization(authCondition),
    withRouter,
)(UserDetailComponent);