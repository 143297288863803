import React from 'react';
import {Grid, Typography} from "@material-ui/core";
import {get} from "../common/i18n/i18n";
import {Link, useLocation} from "react-router-dom";
import * as routes from "../../utils/routes";
import {makeStyles} from "@material-ui/styles";
import layoutStyles from "../common/styles/layoutStyles";
import {MARINE, SAND} from "../../utils/constants";
import clsx from "clsx";

const useStyles = makeStyles(theme => ({
    ...layoutStyles(theme),
    menu: {
        border: `2px solid ${MARINE}`,
        width: "100%",
        borderRadius: 8
    },
    item: {
        width: "90%",
        boxSizing: "border-box",
        margin: theme.spacing(1, 0),
        "&:hover": {
            backgroundColor: SAND,
            borderRadius: theme.spacing(1)
        }
    },
    selected: {
        backgroundColor: SAND,
        borderRadius: theme.spacing(1)
    }
}));

const FloatingMenu = props => {
    const classes = useStyles();
    const location = useLocation();
    return (
        <Grid container direction={"column"} alignItems={"center"} className={classes.menu}>
            <Typography variant={"h1"}>{get('my_account')}</Typography>
            <Typography variant={"h2"}
                        className={clsx(classes.link, classes.item, location.pathname === routes.ROUTE_PROFILE && classes.selected)}
                        component={Link} to={routes.ROUTE_PROFILE}>
                {get('edit_profile')}
            </Typography>
            <Typography variant={"h2"}
                        className={clsx(classes.link, classes.item, location.pathname === routes.ROUTE_LOANS && classes.selected)}
                        component={Link} to={routes.ROUTE_LOANS}>
                {get('my_loans')}
            </Typography>
            <Typography variant={"h2"}
                        className={clsx(classes.link, classes.item, location.pathname === routes.ROUTE_MY_ITEMS && classes.selected)}
                        component={Link} to={routes.ROUTE_MY_ITEMS}>
                {get('my_items')}
            </Typography>
            <Typography variant={"h2"}
                        className={clsx(classes.link, classes.item, location.pathname === routes.ROUTE_CHATS && classes.selected)}
                        component={Link} to={routes.ROUTE_CHATS}>
                {get('chats')}
            </Typography>
            <Typography variant={"h2"}
                        className={clsx(classes.link, classes.item, location.pathname === routes.ROUTE_NOTIFICATIONS && classes.selected)}
                        component={Link} to={routes.ROUTE_NOTIFICATIONS}>
                {get('notifications')}
            </Typography>
            <Typography variant={"h2"}
                        className={clsx(classes.link, classes.item, location.pathname === routes.ROUTE_LIVE_SEARCH && classes.selected)}
                        component={Link} to={routes.ROUTE_LIVE_SEARCH}>
                {get('requests')}
            </Typography>
            <Typography variant={"h2"}
                        className={clsx(classes.link, classes.item, location.pathname === routes.ROUTE_FAVS && classes.selected)}
                        component={Link} to={routes.ROUTE_FAVS}>
                {get('favourites')}
            </Typography>
            <Typography variant={"h2"}
                        className={clsx(classes.link, classes.item, location.pathname === routes.ROUTE_EARNS && classes.selected)}
                        component={Link} to={routes.ROUTE_EARNS}>
                {get('my_earns')}
            </Typography>
            <Typography variant={"h2"}
                        className={clsx(classes.link, classes.item, location.pathname === routes.ROUTE_REVIEWS && classes.selected)}
                        component={Link} to={routes.ROUTE_REVIEWS}>
                {get('reviews')}
            </Typography>
        </Grid>
    );
};

export default FloatingMenu;