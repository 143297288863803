import React from 'react'

import {Paper} from "@material-ui/core";
import {makeStyles} from "@material-ui/styles";
import Skeleton from "@material-ui/lab/Skeleton";

import {DEFAULT_MAX_WIDTH, PALE_SAND} from "../../../utils/constants";

const useStyles = makeStyles(theme => ({
    photo: {
        height: '160px',
        borderRadius: '4px',
        marginRight: theme.spacing(2),
    },
    paper: {
        display: 'flex',
        flexFlow: 'no-wrap row',
        justifyContent: 'flex-start',
        backgroundColor: PALE_SAND,
        marginBottom: theme.spacing(),
        width: '100%',
        maxWidth: DEFAULT_MAX_WIDTH,
    },
    info: {
        textAlign: 'left',
        width: '95%',
    },
}));

export default function ItemSkeleton() {
    const classes = useStyles();
    return (
        <Paper className={classes.paper}>
            <Skeleton variant={'rect'} height={160} width={192} className={classes.photo}/>
            <div className={classes.info}>
                <Skeleton height={'1em'} width={'80%'}/>
                <Skeleton height={'1em'} width={'50%'}/>
                <Skeleton height={'1em'} width={'60%'}/>
                <Skeleton height={'1em'} width={'40%'}/>
                <Skeleton height={'1em'} width={'50%'}/>
            </div>
        </Paper>
    )
}