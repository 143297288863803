import { auth, googleProvider } from './firebase';
import {
    createUserWithEmailAndPassword, fetchSignInMethodsForEmail, sendEmailVerification as _sendEmailVerification,
    sendPasswordResetEmail, signInWithEmailAndPassword, signInWithPopup, signOut, updatePassword,
    linkWithCredential, unlink, onAuthStateChanged, signInWithPhoneNumber, RecaptchaVerifier, AuthErrorCodes
} from "firebase/auth";

// Sign Up
export const doCreateUserWithEmailAndPassword = (email, password) =>
    createUserWithEmailAndPassword(auth, email, password); // Promise return ignored.

// Sign In
export const doSignInWithEmailAndPassword = (email, password) =>
    signInWithEmailAndPassword(auth, email, password);

// User exists
export const userExists = email =>
    fetchSignInMethodsForEmail(auth, email);

export const doSignInWithGoogle = () =>
    signInWithPopup(auth, googleProvider);

export const sendEmailVerification = (onSuccess, onError) =>
    !!auth.currentUser
        ? _sendEmailVerification(auth.currentUser).then(onSuccess).catch(onError)
        : new Promise((resolve, reject) => reject("No user"));

export const userReload = () =>
    !!auth.currentUser
        ? auth.currentUser.reload() : new Promise((resolve, reject) => reject("No user"));

// Password Reset
export const doPasswordReset = email =>
    sendPasswordResetEmail(auth, email);

// Password Change
export const doPasswordUpdate = password =>
    updatePassword(auth.currentUser, password)
        .then(() => console.debug("password updated"))
        .catch(error => console.error("error updating password: ", error));

// Sign out
export const doSignOut = () =>
    signOut(auth);

export const isSignedId = () =>
    (!!auth && !!auth.currentUser);

// Returns a promise to get the current id token.
export const getToken = () =>
    auth.currentUser.getIdToken(false);

export {
    linkWithCredential, onAuthStateChanged, signInWithPhoneNumber, RecaptchaVerifier, AuthErrorCodes, unlink
};