import React, {useState} from 'react';
import {IconButton, Menu, MenuItem} from '@material-ui/core';
import {PropTypes} from "prop-types";

import MoreVertIcon from '@material-ui/icons/MoreVert';
import {makeStyles} from "@material-ui/core/styles";
import {BLUE} from "../../../utils/constants";
import {get} from "../../common/i18n/i18n";
import ReportItemDialog from "./ReportItemDialog";

const useStyles = makeStyles(theme => ({
    button: {
        padding: 0,
        color: BLUE,
    },
}));

export default function ContextualItemMenu({uid, item, handleEdit, handleDelete, handleReport}) {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [open, setOpen] = useState(false);
    const classes = useStyles();

    const handleClick = event => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const onDeleteClicked = () => {
        handleDelete(uid, item);
        handleClose();
    };

    const onEditClicked = () => {
        handleEdit(uid, item);
        handleClose();
    };

    const onReportClicked = reason => {
        handleReport(uid, item, reason);
        setOpen(false);
        handleClose();
    };

    const handleDialogOpen = () => {
        setOpen(true);
        handleClose();
    };

    const handleDialogClose = () => {
        setOpen(false);
        handleClose();
    };

    return (
        <div>
            <IconButton onClick={handleClick} className={classes.button}>
                <MoreVertIcon />
            </IconButton>
            <Menu
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                {item.ownerId === uid ?
                    [
                        <MenuItem key={'edit'} onClick={onEditClicked}>{get('edit')}</MenuItem>,
                        <MenuItem key={'delete'} onClick={onDeleteClicked}>{get('delete')}</MenuItem>
                    ]
                    :
                    <MenuItem onClick={handleDialogOpen}>{get('report')}</MenuItem>
                }
            </Menu>
            <ReportItemDialog handleReport={onReportClicked} open={open} handleClose={handleDialogClose}/>
        </div>
    );
}

ContextualItemMenu.propTypes = {
    uid: PropTypes.string,
    item: PropTypes.object,
    handleEdit: PropTypes.func.isRequired,
    handleDelete: PropTypes.func.isRequired,
    handleReport: PropTypes.func.isRequired,
};