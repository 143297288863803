import React from 'react'
import {connect} from 'react-redux'
import {compose} from 'recompose'
import PropTypes from 'prop-types'

import {withStyles} from '@material-ui/core/styles'
import {
    Button, Input, InputLabel, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle,
    Checkbox, FormControl, FormControlLabel, withWidth, isWidthDown, FormHelperText
} from '@material-ui/core'
import MaskedInput from 'react-text-mask'

import withAuthorization from '../../auth/withAuthorization'
import {doRequest} from "../../../utils/http";
import {API_USER_CREATE_BANK_ACCOUNT} from '../../../utils/constants'
import {get} from "../../common/i18n/i18n";

let openDialogFn;

const styles = theme => ({

    group: {
        display: 'flex',
        flexDirection: 'column',
        flexWrap: 'wrap',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 256,
    },
});

const INITIAL_STATE = {
    uid: '',
    bankAccountNumber: '',
    defaultMethod: true,
    open: false,
};

function BankAccountInputMask(props) {
    const {inputRef, ...other} = props;

    return (
        <MaskedInput
            {...other}
            ref={ref => {
                inputRef(ref ? ref.inputElement : null);
            }}
            guide={true}
            mask={[/[a-zA-Z]/, /[a-zA-Z]/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/,
                '-', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
            placeholderChar={'\u2000'}
            placeholder={'ES11-1111-1111-1111-1111-1111'}
            showMask={false}
        />
    );
}

BankAccountInputMask.propTypes = {
    inputRef: PropTypes.func.isRequired,
};

class BankAccountDialog extends React.Component {

    constructor(props) {
        super(props);
        this.state = {...INITIAL_STATE};
    }

    componentDidMount() {
        openDialogFn = this.openDialog;
    }

    componentWillUnmount() {
        this.setState({...INITIAL_STATE});
    }

    onUpdate = event => this.setState({
        [event.target.id]: event.target.value
    });

    onChecked = event => this.setState({[event.target.id]: event.target.checked});

    render() {
        const {open, bankAccountNumber, defaultMethod} = this.state;
        const {classes, width} = this.props;
        const fullScreen = isWidthDown('sm', width);
        const bankAccountNumberSan = !!bankAccountNumber ? bankAccountNumber.replace(/-/g, '').trim() : '';
        const enabled = bankAccountNumberSan.length === 24;

        return (
            <Dialog
                open={open}
                fullScreen={fullScreen}
                onClose={this.handleClose}
                aria-labelledby={get('user_add_bank_account')}
            >
                <DialogTitle id="create-new-bank-account-dialog-title">{get('user_add_bank_account')}.</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {get('user_add_bank_account_help')}
                    </DialogContentText>
                    <div className={classes.group}>
                        <FormControl className={classes.formControl}>
                            <InputLabel htmlFor="bankAccountNumber" shrink={true}>
                                {get('bank_account_number')}
                            </InputLabel>
                            <Input
                                autoFocus={true}
                                value={bankAccountNumber}
                                onChange={this.onUpdate}
                                id="bankAccountNumber"
                                inputComponent={BankAccountInputMask}
                            />
                            <FormHelperText>{get('spanish_bank_accounts_only')}</FormHelperText>
                        </FormControl>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    id={'defaultMethod'}
                                    checked={defaultMethod}
                                    onChange={this.onChecked}
                                />
                            }
                            label={get('default_bank_account')}
                        />
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button onClick={this.handleClose} color="secondary" variant={"outlined"}>
                        {get('CANCEL')}
                    </Button>
                    <Button onClick={this.save} color="primary" variant={"contained"} disabled={!enabled}>
                        {get('SAVE')}
                    </Button>
                </DialogActions>
            </Dialog>
    );
    }

    openDialog = uid => this.setState({open: true, uid});

    handleClose = () => {
        this.setState({...INITIAL_STATE});
    };

    save = () => {
        const {uid, bankAccountNumber, defaultMethod} = this.state;
        const {onSuccess, onError} = this.props;
        const body = JSON.stringify({
        uid,
        iban: bankAccountNumber.replace(/-/g, '').trim(),
        defaultAccount: defaultMethod,
    });
        doRequest(API_USER_CREATE_BANK_ACCOUNT, onSuccess, onError, {method: 'POST', body});
        this.handleClose();
    };

    }

    export function openBankAccountDialog(uid) {
        openDialogFn(uid);
    }

    const mapStateToProps = ({session}) => ({
        authUser: session.authUser,
    });

const authCondition = profile => !!profile;

    BankAccountDialog.propTypes = {
        classes: PropTypes.object.isRequired,
        authUser: PropTypes.object,
        onSuccess: PropTypes.func.isRequired,
        onError: PropTypes.func.isRequired,
    };

    export default compose(
    withAuthorization(authCondition),
    withWidth(),
    withStyles(styles),
    connect(mapStateToProps)
    )(BankAccountDialog)
