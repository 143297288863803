export const APP_VERSION = process.env.REACT_APP_VERSION;
export const MYUR_BASE_ROUTE = process.env.REACT_APP_BASE_ROUTE;
export const MYUR_API_BASE_URL = process.env.REACT_APP_MYUR_API_BASE_URL;
export const MYUR_CDN_BASE_URL = process.env.REACT_APP_MYUR_CDN_BASE_URL;
export const MYUR_CHAT_BASE_URL = process.env.REACT_APP_MYUR_CHAT_BASE_URL;

export const MYUR_TERMS_URL = process.env.REACT_APP_TERMS_URL;
export const MYUR_PRIVACY_URL = process.env.REACT_APP_PRIVACY_URL;
export const MYUR_COOKIES_URL = process.env.REACT_APP_COOKIES_URL;
export const MYUR_FAQ_URL = process.env.REACT_APP_FAQ_URL;

export const MYUR_FUC = process.env.REACT_APP_FUC;
export const MYUR_TERM = process.env.REACT_APP_TERM;

// FIREBASE CONFIGURATION
export const FB_API_KEY = process.env.REACT_APP_FB_API_KEY;
export const FB_AUTH_DOMAIN = process.env.REACT_APP_FB_AUTH_DOMAIN;
export const FB_DATABASE_URL = process.env.REACT_APP_FB_DATABASE_URL;
export const FB_PROJECT_ID = process.env.REACT_APP_FB_PROJECT_ID;
export const FB_STORAGE_BUCKET = process.env.REACT_APP_FB_STORAGE_BUCKET;
export const FB_MESSAGING_SENDER_ID = process.env.REACT_APP_FB_MESSAGING_SENDER_ID;
export const FB_APP_ID = process.env.REACT_APP_FB_APP_ID;
export const FB_PUBLIC_VAPID_KEY = process.env.REACT_APP_FB_PUBLIC_VAPID_KEY;
export const FB_MEASUREMENT_ID = process.env.REACT_APP_FB_MEASUREMENT_ID;
export const API_PUBLIC_KEY = process.env.REACT_APP_API_PUBLIC_KEY;

// APP STORES
export const STORE_GOOGLE_PLAY = "https://play.google.com/store/apps/details?id=com.rodamen.myur.production&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1";
export const STORE_APP_STORE = "https://apps.apple.com/us/app/myur/id1536724107";
export const API_ITEM_CREATE_ITEM = MYUR_API_BASE_URL + 'item/item';
export const API_ITEM_LANDING = MYUR_API_BASE_URL + 'item/search';
export const API_ITEM_SEARCH = MYUR_API_BASE_URL + 'item/search';
export const API_ITEM_PUBLIC_DETAIL = MYUR_API_BASE_URL + 'item/publicDetail/:id';
export const API_ITEM_DETAIL = MYUR_API_BASE_URL + 'item/detail/:id';
export const API_ITEM_DETAIL_UID = MYUR_API_BASE_URL + 'item/detail/:id?uid=:uid';
export const API_ITEM_MY_ITEM_DETAIL = MYUR_API_BASE_URL + 'item/myItemDetail/:itemId?uid=:uid';
export const API_ITEM_UPDATE = MYUR_API_BASE_URL + 'item/updateItem';
export const API_ITEM_UPDATE_MULTIPART = MYUR_API_BASE_URL + 'item/updateItemMultipart';
export const API_ITEM_MY_ITEMS = MYUR_API_BASE_URL + 'item/myItems/:uid';
export const API_ITEM_DELETE = MYUR_API_BASE_URL + 'item/delete/:uid/:itemId';
export const API_ITEM_FAVORITE = MYUR_API_BASE_URL + 'item/favoriteItem/:uid/:itemId';
export const API_ITEM_UNFAVORITE = MYUR_API_BASE_URL + 'item/unfavoriteItem/:uid/:itemId';
export const API_ITEM_REPORT = MYUR_API_BASE_URL + 'item/report/:itemId';
export const API_ITEM_ENABLE = MYUR_API_BASE_URL + 'item/enable/:uid/:itemId';
export const API_ITEM_DISABLE = MYUR_API_BASE_URL + 'item/disable/:uid/:itemId';
export const API_ITEM_RELATED_ITEMS = MYUR_API_BASE_URL + 'item/similarItems?itemId=:itemId&numMaxResults=:count'
export const API_LIVE_GET_LIVE_SEARCHES = MYUR_API_BASE_URL + 'item/specificRequest?start=:start&count=:count';
export const API_LIVE_SEARCHES = MYUR_API_BASE_URL + 'item/specificRequest';
export const API_LIVE_GET_SEARCHES_RESPONSES = MYUR_API_BASE_URL + 'item/specificRequest/:uid';
export const API_LIVE_GET_MY_RESPONSES = MYUR_API_BASE_URL + 'item/specificResponse/:uid';
export const API_LIVE_GET_SEARCH = MYUR_API_BASE_URL + 'item/specificRequest/:uid/:id';
export const API_LIVE_RESPONSE = MYUR_API_BASE_URL + 'item/specificResponse';

export const API_MASTER_GET_CATEGORIES = MYUR_API_BASE_URL + 'master/allCategories';
export const API_MASTER_GET_SUBCATEGORIES = MYUR_API_BASE_URL + 'master/subcategoriesByCategoryId/:categoryId';
export const API_MASTER_GET_CATEGORY = MYUR_API_BASE_URL + 'master/categoryById/:categoryId';
export const API_MASTER_GET_SUBCATEGORY = MYUR_API_BASE_URL + 'master/subcategoryById/:subcategoryId';
export const API_MASTER_CARD_ID_TYPES = MYUR_API_BASE_URL + 'master/cardIdType';
export const API_MASTER_GET_CARD_TYPES = MYUR_API_BASE_URL + 'master/cardType';
export const API_MASTER_SEARCH_PROVINCES = MYUR_API_BASE_URL + 'master/province';
export const API_MASTER_SEARCH_LOCALITIES = MYUR_API_BASE_URL + 'master/locality';
export const API_MASTER_EXCESS = MYUR_API_BASE_URL + 'master/excess/:subCategoryId?purchasePrice=:purchasePrice';
export const API_MASTER_INSURANCE_TYPE = MYUR_API_BASE_URL + 'master/insuranceType/:subCategoryId';
export const API_MASTER_ISSUE_TYPES = MYUR_API_BASE_URL + 'master/allIssueType';
export const API_MASTER_LICENSE_TYPES = MYUR_API_BASE_URL + 'master/allLicenceType';
export const API_MASTER_VEHICLE_BOAT_TYPES_BY_CATEGORY = MYUR_API_BASE_URL + 'master/allVehicleBoatsByCategoryId/:subCategoryId';
export const API_MASTER_VEHICLE_BOAT_SUBTYPES_BY_TYPE = MYUR_API_BASE_URL + 'master/vehicleBoat/:parentTypeName';
export const API_MASTER_VEHICLE_BOAT_TYPE = MYUR_API_BASE_URL + 'master/vehicleBoat?typeName=:typeName';
export const API_MASTER_VEHICLE_BOAT_TYPE_HAS_CHILDREN = MYUR_API_BASE_URL + 'master/vehicleBoat?typeName=:typeName';
export const API_MASTER_ZIPCODE_COORDINATES = MYUR_API_BASE_URL + 'master/coordsByZipcode/:zipcode';
export const API_NOTIFICATION_LAST = MYUR_API_BASE_URL + 'notification/last/:uid';
export const API_NOTIFICATION_PENDING = MYUR_API_BASE_URL + 'notification/pending/:uid';

export const API_ISSUE_CREATE = MYUR_API_BASE_URL + 'issue/issue';
export const API_ISSUE_DETAIL = MYUR_API_BASE_URL + 'issue/issueDetail/:senderId/:loanIssueId';
export const ADD_USER = MYUR_API_BASE_URL + 'user/addUser';
export const ADD_USER_AVATAR = MYUR_API_BASE_URL + 'user/addUserAvatar';
export const API_USER_GET_USER_DATA = MYUR_API_BASE_URL + 'user/getAllUserData/:uid';
export const API_USER_EXISTS = MYUR_API_BASE_URL + 'user/existsUser/:uid';
export const API_USER_EXISTS_NICKNAME = MYUR_API_BASE_URL + 'user/existsNickname/:nickname';
export const API_USER_UPDATE = MYUR_API_BASE_URL + 'user/updateUser/:uid';
export const API_USER_UPDATE_AVATAR = MYUR_API_BASE_URL + 'user/updateUserAvatar/:uid';
export const ADD_PERSONAL_DATA = MYUR_API_BASE_URL + 'user/addPersonalData';
export const API_USER_SAVE_PROFILE = MYUR_API_BASE_URL + 'user/addNewUserProfileData';
export const API_USER_CREATE_PAYMENT_METHOD = MYUR_API_BASE_URL + 'user/addPaymentMethod';
export const API_USER_DELETE_PAYMENT_METHOD = MYUR_API_BASE_URL + 'user/deletePaymentMethod/:uid/:cardId';
export const API_USER_CREATE_BANK_ACCOUNT = MYUR_API_BASE_URL + 'user/addBankAccount';
export const API_USER_DELETE_BANK_ACCOUNT = MYUR_API_BASE_URL + 'user/deleteBankAccount/:uid/:bankAccountId';
export const API_USER_CREATE_COMPANY = MYUR_API_BASE_URL + 'user/addProfessionalData/:uid';
export const API_USER_UPDATE_COMPANY = MYUR_API_BASE_URL + 'user/updateCompany/:uid/:companyId';
export const API_USER_GET_COMPANY = MYUR_API_BASE_URL + 'user/company/:companyId';
export const API_USER_CREATE_ADDRESS = MYUR_API_BASE_URL + 'user/addUserAddress';
export const API_USER_DELETE_ADDRESS = MYUR_API_BASE_URL + 'user/deleteAddress/:uid/:addressId';
export const API_USER_DELETE_LICENSE = MYUR_API_BASE_URL + 'user/licence/:uid/:licenseId';
export const API_USER_UPDATE_LICENSE = MYUR_API_BASE_URL + 'user/licence';
export const API_USER_GET_FAVOURITES = MYUR_API_BASE_URL + 'item/myFavoriteItems/:uid';
export const API_USER_GET_PUBLIC_DATA = MYUR_API_BASE_URL + 'user/getPublicUserData/:uid';
export const API_USER_GET_PUBLIC_ITEMS = MYUR_API_BASE_URL + 'item/userItems/:userId?start=:start&count=:count';
export const API_USER_GET_STRIPE_CLIENT = MYUR_API_BASE_URL + 'user/getStripeClientSecret/:uid';

export const LOAN_CHECK_AVAILABILITY = MYUR_API_BASE_URL + 'loan/checkAvailability';
export const LOAN_ADD_LOAN = MYUR_API_BASE_URL + 'loan/addLoan';
export const LOAN_MY_LOANS = MYUR_API_BASE_URL + 'loan/loan?uid=:uid&inProcessLoans=:inProcessLoans';
export const LOAN_DETAILS = MYUR_API_BASE_URL + 'loan/detail/:uid/:loanId';
export const LOAN_ACCEPT_LOAN = MYUR_API_BASE_URL + 'loan/accept/:uid/:loanId';
export const LOAN_CANCEL_LOAN = MYUR_API_BASE_URL + 'loan/cancel/:uid/:loanId';
export const LOAN_DENY_LOAN = MYUR_API_BASE_URL + 'loan/denyLoan/:uid/:loanId';
export const LOAN_CONFIRM_DELIVERY_OWNER_LOAN = MYUR_API_BASE_URL + 'loan/confirmDeliveryByOwner';
export const LOAN_CONFIRM_DELIVERY_BORROWER_LOAN = MYUR_API_BASE_URL + 'loan/confirmDeliveryByBorrower';
export const LOAN_CONFIRM_RETURN_BORROWER_LOAN = MYUR_API_BASE_URL + 'loan/confirmReturnByBorrower';
export const LOAN_END_LOAN = MYUR_API_BASE_URL + 'loan/endLoan/:loanId?ownerId=:ownerId';
export const API_LOAN_GET_PROFIT = MYUR_API_BASE_URL + 'loan/myProfit/:uid';
export const API_LOAN_GET_PROFIT_DETAIL = MYUR_API_BASE_URL + 'loan/myProfitDetail/:uid?year=:year&month=:month';
export const API_LOAN_CAN_MAKE_OPERATION = MYUR_API_BASE_URL + 'loan/canMakeOp/:operation?uid=:uid';
export const API_DEVICE_DEVICE = MYUR_API_BASE_URL + 'device/device';

export const API_CHAT_CHAT_LIST = MYUR_CHAT_BASE_URL + 'chat?since=:since&max=:max';
export const API_CHAT_CHAT = MYUR_CHAT_BASE_URL + 'chat/:borrowerId/:ownerId/:itemId?since=:since&max=:max';
export const API_CHAT_SEND_MESSAGE = MYUR_CHAT_BASE_URL + 'message';
export const API_CHAT_MESSAGE = MYUR_CHAT_BASE_URL + 'message/:messageId';
export const API_CHAT_PENDING = MYUR_CHAT_BASE_URL + 'user/:uid/messages/sent';
export const PROPIO = 0;
export const IMPROPIO = 1;

// MASTER FILES
/**
 * UI
 */
export const MARINE = '#041e42';
export const BLUE = '#0c6592';
export const PALE_BLUE = '#8edded';
export const LIGHT_BLUE = '#C6EEF5';
export const BROWN = '#a27148';
export const SAND = '#dbd8d1';
export const PALE_SAND = '#e8e7e2';
export const RED = '#9e1030';
export const SALMON = '#fd8678';
export const ORANGE = '#d45500';
export const YELLOW = '#f7bd02';
export const TOP_MAX_WIDTH = 1280;
export const HTML_FONT_SIZE = 16;
export const DEFAULT_MAX_WIDTH = 960;
export const DEFAULT_WIDTH = 960;
export const DEFAULT_PHOTO_WIDTH = 192;
export const DEFAULT_PHOTO_HEIGHT = 160;
export const MYUR_ITEM_PHOTO_MAX_WIDTH = 1000;
export const MYUR_ITEM_PHOTO_ASPECT_RATIO = 1.2;
export const MYUR_AVATAR_MAX_WIDTH = 400;
export const MYUR_AVATAR_ASPECT_RATIO = 1;
export const MYUR_AVATAR_FILE_NAME = 'avatar.jpg';

export const DEFAULT_PHOTO = MYUR_CDN_BASE_URL + 'myur/no_image.png';
export const DEFAULT_AVATAR = MYUR_CDN_BASE_URL + 'myur/UserPhoto2.png';
export const DEFAULT_USER_AVATAR_URL = MYUR_CDN_BASE_URL + ':uid/avatar.jpg';
export const INSURANCE_CONDITIONS_URL = MYUR_CDN_BASE_URL + 'insurance/specialConditions/CLAUSULAS_ESPECIALES_Cobertura_:code.pdf';


export const DATE_TIME_FORMAT = 'YYYYMMDDHHmmss';
export const DATE_FORMAT = 'YYYYMMDD';

export const SEARCH_COUNT_ITEMS = 20;

export const MAX_ITEM_PHOTOS = 4;
export const MAX_LOAN_PHOTOS = 5;
export const MAX_LIVE_SEARCH_TITLE_CHAR = 40;
export const MAX_MINI_DESCRIPTION_CHAR = 80;
export const MAX_USER_DESCRIPTION_CHAR = 400;
export const MAX_DESCRIPTION_CHAR = 500;

/**
 * NOTIFICATION SEVERITY
 */
export const SEVERITY_SUCCESS = 'success';
export const SEVERITY_INFO = 'info';
export const SEVERITY_WARNING = 'warning';
export const SEVERITY_ERROR = 'error';

// Update notifications pending every five minutes (in millis).
export const NOTIFICATIONS_PENDING_UPDATE = 1000 * 60 * 5;
/**
 * LOAN STATES
 */
export const PENDIENTE_RESPUESTA = 'PENDIENTE_RESPUESTA'; // solicitado por el borrower
export const CANCELADO = 'CANCELADO'; // el borrower cancela el alquiler antes de que el dueño conteste
export const DENEGADO = 'DENEGADO'; // el dueño no quiere alquilar
export const CADUCADO = 'CADUCADO'; // el dueño no ha respondido
export const ACEPTADO = 'ACEPTADO'; // el dueño acepta el alquiler
export const ACTIVO_OWNER = 'ACTIVO_OWNER'; // el dueño ha finalizado el proceso de entrega del artículo
export const ACTIVO_BORROWER = 'ACTIVO_BORROWER'; // el borrower ha confirmado que el dueño le ha entregado el artículo
export const FINALIZADO = 'FINALIZADO'; // el préstamo ha finalizado correctamente, el dueño ha confirmado la recuperación del artículo
export const INCIDENCIA = 'INCIDENCIA'; // El préstamo tiene una incidencia abierta.
export const DEVUELTO = 'DEVUELTO'; // El borrower ha confirmado la devolución del artículo al dueño.

export const LOAN_STATUS = [
    {
        id: 1,
        key: PENDIENTE_RESPUESTA,
        color: SALMON,
    },
    {
        id: 2,
        key: ACEPTADO,
        color: PALE_BLUE,
    },
    {
        id: 3,
        key: ACTIVO_OWNER,
        color: BLUE,
    },
    {
        id: 4,
        key: CADUCADO,
        color: RED,
    },
    {
        id: 5,
        key: CANCELADO,
        color: RED,
    },
    {
        id: 6,
        key: DENEGADO,
        color: RED,
    },
    {
        id: 7,
        key: FINALIZADO,
        color: RED,
    },
    {
        id: 8,
        key: INCIDENCIA,
        color: RED,
    },
    {
        id: 9,
        key: ACTIVO_BORROWER,
        color: RED,
    },
    {
        id: 10,
        key: DEVUELTO,
        color: RED,
    },
];

export const LOAN_ISSUE_STATUS = [
    '', 'RECIBIDA', 'TRAMITACION_SOPORTE', 'TRAMITACION_ASEGURADORA', 'RESUELTA'
];

export const LOAN_OPERATION_BORROW = 1;
export const LOAN_OPERATION_LEND = 2;

export const getLoanState =
    loanStateId => (loanStateId > 0 && loanStateId < LOAN_STATUS.length) ? LOAN_STATUS[loanStateId - 1] : null;

export const INSURANCE_TYPES = [
    '', 'BASIC', 'PLUS', 'EXTRA', 'NIBW', 'NIBW_FC'
];

export const INSURANCE_CONDITIONS = {
    NIBW: process.env.REACT_APP_INSURANCE_CONDITIONS_URL_NIBW,
    NIBW_FC: process.env.REACT_APP_INSURANCE_CONDITIONS_URL_NIBW_FC
};

export const DEFAULT_INSURANCE = {
    type: 4, code: 'NIBW'
};

export const MIN_DEPOSIT_PERCENT = 10;

export const EXTRA_DATA_BOAT = "boatsForm";
export const EXTRA_DATA_VEHICLE = "vehiclesForm";

export const BOAT_REGISTRATION_LISTS = [6, 7];

export const BOAT_MATERIALS = ['fiber', 'wood'];

export const SPAIN = {code: 11, iso: 'ESP', iso2: 'ES', name: 'ESPAÑA', display: 'España'};

export const CARD_ID_TYPES = ['', 'NIF', 'NIE', 'PASSPORT', 'CIF'];

export const SERVICE_CATEGORY_ID = 78;
export const SPACE_CATEGORY_ID = 11;

export const CHAT_MESSAGE_TYPE_TEXT = "chat_text";
export const CHAT_MESSAGE_TYPE_IMAGE = "chat_image";
export const CHAT_MESSAGE_TYPE_VIDEO = "chat_video";
export const CHAT_MESSAGE_TYPE_GEO = "chat_geo";
export const CHAT_MESSAGE_TYPE_ACTION = "chat_action";

export const MAPS_DEFAULT_CENTER = {lat: 40.4166400, lng: -3.7032700};
export const MAPS_DEFAULT_ZOOM = 7;
export const MAPS_API_PUBLIC_KEY = process.env.REACT_APP_MAPS_API_PUBLIC_KEY;
export const MAPS_MARKER_DISPERSION = 400; //240m max. aprox.

// ITEM
export const ITEM_MIN_PRICE = 3;

/*
 * USER MANDATORY DATA
 */
export const USER_NO_ID_CARD = "user_not_id_card";
export const USER_NO_PHONE = "user_not_phone";
export const USER_NO_ADDRESS = "user_not_address";
export const USER_NO_PAYMENT = "user_no_paym";

/*
 * Permissions
 */
export const PERMISSION_GRANTED = 'granted';
export const PERMISSION_PROMPT = 'prompt';
export const PERMISSION_DENIED = 'denied';
export const GEOLOCATION_PERMISSION = 'geolocation';
export const GEOLOCATION_PERMISSION_STATUS = 'geolocation_permission_status';

/*
 * Geolocalization
 */
export const GEOLOCATION_ERROR_CODE_USER_DENIED = 1;
export const SEARCHES_BEFORE_ASK_FOR_GEOLOCALIZATION_CONSENT = 10;
export const LANDINGS_BEFORE_ASK_FOR_GEOLOCALIZATION_CONSENT = 20;

export const ANALYTICS_DELETE_ITEM = "delete_item";
export const ANALYTICS_NEW_ITEM = "new_item";
export const ANALYTICS_UPDATE_ITEM = "update_item";
export const ANALYTICS_VIEW_ITEM = "view_item";
export const ANALYTICS_SIGN_UP = "sign_up";
export const ANALYTICS_NEW_LIVE_SEARCH = "new_live_search";
export const ANALYTICS_RESPONSE_TO_LIVE_SEARCH = "response_to_live_search";
export const ANALYTICS_ACCEPT_LOAN = "purchase";
export const ANALYTICS_DENY_LOAN = "remove_from_cart";
export const ANALYTICS_REQUEST_LOAN = "add_to_cart";