import React from 'react';
import PropTypes from 'prop-types';
import {generate} from 'shortid';

import {FormControl, FormHelperText, InputBase, InputLabel, withStyles} from "@material-ui/core";
import makeStyles from "@material-ui/core/styles/makeStyles";

import {BLUE, MARINE} from "../../utils/constants";
import clsx from "clsx";
import {DateTimePicker} from "@material-ui/pickers";

const MyurCustomInput = withStyles(theme => ({
    root: {
        'label + &': {
            marginTop: theme.spacing(3),
        },
    },
    input: {
        borderRadius: '1.25rem',
        position: 'relative',
        backgroundColor: theme.palette.common.white,
        border: `1px solid ${BLUE}`,
        color: MARINE,
        padding: '10px 12px',
    },
}))(InputBase);

const useStyles = makeStyles(theme => ({
    margin: {
        marginTop: theme.spacing(2),
    },
    error: {
        color: 'red !important',
    },
    helperText: {
        marginLeft: '1em',
    },
}));

function MyurInput (props) {
    const {className, classes, label, helperText, error, InputProps, shrink, FormHelperTextProps, ...otherProps} = props;
    const _classes = useStyles();
    const nodeId = 'myur-date_time_input-' + generate();
    const labelClass = clsx(!!classes && classes.label);
    const focusClass = clsx(!!classes && classes.focused);
    return (
        <FormControl className={clsx(className, _classes.margin)} error={error}>
            {!!label &&
            <InputLabel shrink={shrink} className={labelClass} classes={{focused: focusClass}} htmlFor={nodeId}>
                {label}
            </InputLabel>
            }
            <MyurCustomInput id={nodeId} {...otherProps} {...InputProps}/>
            <FormHelperText className={clsx(_classes.helperText, !!classes && classes.helperText)} {...FormHelperTextProps}>
                {helperText}
            </FormHelperText>
        </FormControl>
    );
}
export default function MyurDateTimePicker(props) {
    const {onChange, ...otherProps} = props;
    return (<DateTimePicker TextFieldComponent={MyurInput} onChange={onChange} {...otherProps}/>);
}

MyurDateTimePicker.propTypes = {
    label: PropTypes.string,
    placeholder: PropTypes.string,
    defaultValue: PropTypes.string,
    value: PropTypes.object,
    onChange: PropTypes.func.isRequired,
    classes: PropTypes.object,
    className: PropTypes.string,
    error: PropTypes.bool,
};