import React from 'react'
import {connect} from 'react-redux'
import {compose} from 'recompose'
import {withStyles} from '@material-ui/core/styles'
import {Button, isWidthDown, TextField, withWidth} from '@material-ui/core'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'

import withAuthorization from '../../auth/withAuthorization'
import {Checkbox, FormControlLabel, FormGroup} from "@material-ui/core";
import {doRequest} from "../../../utils/http";
import {
    API_USER_CREATE_ADDRESS,
    API_MASTER_SEARCH_LOCALITIES, API_MASTER_SEARCH_PROVINCES,
    SPAIN
} from "../../../utils/constants";
import PropTypes from "prop-types";
import SearchSelect from "../../common/SearchSelect";
import {get} from "../../common/i18n/i18n";

let openDialogFn;

const styles = theme => ({

    group: {
        display: 'flex',
        flexDirection: 'column',
        flexWrap: 'wrap',
        justifyContent: 'space-around',
    },

    textField: {
        margin: theme.spacing(1),
    },

    searchSelect: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },

    input: {
        fontSize: 'x-small',
        lineHeight: '1.5em',
        fontFamily: 'monospace'
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
});

const INITIAL_STATE = {
    uid: undefined,
    addressId: undefined,
    description: '',
    address1: '',
    address2: '',
    zipCode: '',
    provinceId: null,
    province: '',
    townId: null,
    town: '',
    countryId: SPAIN.code,
    country: SPAIN.display,
    billingAddr: true,
    defaultAddr: false,
    open: false,
};

class AddressDialog extends React.Component {

    constructor(props) {
        super(props);
        this.state = {...INITIAL_STATE};
    }

    componentDidMount() {
        openDialogFn = this.openDialog;
    }

    onUpdate = event => this.setState({[event.target.id]: event.target.value});

    onChecked = event => this.setState({[event.target.id]: event.target.checked});

    onUpdateProvince = province => this.setState({
        provinceId: province.id,
        province: province.name,
        townId: null,
        town: '',
    });

    onUpdateTown = town => this.setState({
        town: town.localityName,
        townId: town.id,
    });

    render() {
        const {open, addressId, address1, address2, zipCode, provinceId, country, province, town,
            billingAddr, defaultAddr, description} = this.state;
        const {classes, width} = this.props;
        const fullScreen = isWidthDown('sm', width);
        const disabled = provinceId === null;
        const title = !!addressId ? get('update_addr') : get('new_addr');
        const helpText = !!addressId ? get('update_addr_help') : get('new_addr_help');
        return (
            <Dialog
                open={open}
                fullScreen={fullScreen}
                onClose={this.handleClose}
                aria-labelledby="address create or update"
            >
                <DialogTitle id="form-dialog-addreds">{title}</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {helpText}
                    </DialogContentText>
                    <div className={classes.group}>
                        <TextField id={'description'}
                                   type={'text'}
                                   label={get('description')}
                                   value={description}
                                   onChange={this.onUpdate}
                        />
                        <TextField id={'address1'}
                                   type={'text'}
                                   label={get('address')}
                                   value={address1}
                                   onChange={this.onUpdate}
                        />
                        <TextField id={'address2'}
                                   type={'text'}
                                   value={address2}
                                   onChange={this.onUpdate}
                        />
                        <TextField id={'country'}
                                   type={'text'}
                                   label={get('country')}
                                   value={country}
                                   disabled
                        />
                        <SearchSelect
                            id={'province-select'}
                            className={classes.searchSelect}
                            retrieveUrl={API_MASTER_SEARCH_PROVINCES+'?search=countryCode:' + SPAIN.code + ',name:*:search*'}
                            selected={{name: province}}
                            optionLabel={option => option.name}
                            optionValue={option => option}
                            onSelect={this.onUpdateProvince}
                            title={get('select_province')}
                            helperText={get('select_province_helper')}
                        />
                        <SearchSelect
                            id={'town-select'}
                            className={classes.searchSelect}
                            retrieveUrl={API_MASTER_SEARCH_LOCALITIES+'?search=provinceCode:' + provinceId+ ',localityName:*:search*'}
                            selected={{townName: town}}
                            optionLabel={option => option.townName + (!!option.localityName ?  ' -- ' + option.localityName : '')}
                            optionValue={option => option.localityName}
                            onSelect={this.onUpdateTown}
                            title={get('select_location')}
                            helperText={get('select_location_helper')}
                            disabled={disabled}
                        />
                        <TextField id={'zipCode'}
                                   type={'text'}
                                   label={get('zipcode')}
                                   value={zipCode}
                                   onChange={this.onUpdate}
                        />
                        <FormGroup row>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        id={'billingAddr'}
                                        checked={billingAddr}
                                        onChange={this.onChecked}
                                    />
                                }
                                label={get('billing_address')}
                            />
                        </FormGroup>
                        <FormGroup row>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        id={'defaultAddr'}
                                        checked={defaultAddr}
                                        onChange={this.onChecked}
                                    />
                                }
                                label={get('default_address')}
                            />
                        </FormGroup>
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button onClick={this.handleClose} color="secondary" variant={"outlined"}>
                        {get('CANCEL')}
                    </Button>
                    <Button onClick={this.save} color="primary" variant={"contained"}>
                        {get('SAVE')}
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }

    openDialog = (uid, address) => {
        if (!!address) {
            this.setState({
                open: true,
                uid,
                addressId: address.addressId,
                description: address.description,
                address1: address.address1,
                address2: address.address2,
                zipCode: address.zipCode,
                provinceId: address.provinceId,
                province: address.province,
                townId: address.townId,
                town: address.town,
                countryId: address.countryId,
                country: address.country,
                defaultAddr: address.defaultAddr,
                billingAddr: address.billingAddr,
            });
        } else {
            this.setState({open: true, uid});
        }
    };

    handleClose = () => {
        this.setState({...INITIAL_STATE});
    };

    save = () => {
        const {uid, addressId, description, address1, address2, zipCode, provinceId, province, townId, town,
            countryId, country, defaultAddr, billingAddr} = this.state;
        const {onSuccess, onError} = this.props;
        const body = {
            uid,
            description,
            address1,
            address2,
            zipCode,
            provinceId,
            province,
            townId,
            town,
            countryId,
            country,
            defaultAddr,
            billingAddr,
        };
        if (!!addressId) {
            body['addressId'] = addressId;
        }
        doRequest(API_USER_CREATE_ADDRESS, onSuccess, onError, {method: 'POST', body: JSON.stringify(body)});
        this.handleClose();
    };
}

export function openAddressDialog(uid, address) {
    openDialogFn(uid, address);
}

const mapStateToProps = ({session}) => ({
    authUser: session.authUser,
});

const authCondition = profile => !!profile;

AddressDialog.propTypes = {
    classes: PropTypes.object.isRequired,
    onSuccess: PropTypes.func.isRequired,
    onError: PropTypes.func.isRequired,
};

export default compose(
    withAuthorization(authCondition),
    withStyles(styles),
    withWidth(),
    connect(mapStateToProps)
)(AddressDialog)
