import React from 'react'
import {connect} from 'react-redux'
import {compose} from 'recompose'
import {withStyles} from '@material-ui/core/styles'
import {Button, isWidthDown, withWidth} from '@material-ui/core'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'

import withAuthorization from '../../auth/withAuthorization'
import {doRequest} from "../../../utils/http";
import {API_MASTER_LICENSE_TYPES, DATE_FORMAT, API_USER_UPDATE_LICENSE} from "../../../utils/constants";
import PropTypes from "prop-types";
import {get} from "../../common/i18n/i18n";
import {DatePicker} from "@material-ui/pickers";
import moment from "moment";
import AsyncSelect from "../../common/AsyncSelect";

let openDialogFn;

const styles = theme => ({
    group: {
        display: 'flex',
        flexDirection: 'column',
        flexWrap: 'wrap',
        justifyContent: 'space-around',
    },
    textField: {
        margin: theme.spacing(1),
    },
    select: {
    },

});

const LICENSE_TEMPLATE = {
    uid: null,
    licenceType: '',
    licenceCode: '',
    licenceDate: '',
    licenceExpirationDate: '',
};

const INITIAL_STATE = {
    uid: undefined,
    open: false,
    license: {...LICENSE_TEMPLATE},
    licenseType: undefined,
    licenceDate: undefined,
    licenceExpirationDate: undefined,
    dialogNode: null,
};

class LicenseDialog extends React.Component {

    constructor(props) {
        super(props);
        this.dialog = null;
        this.setDialogRef = node => {
            this.dialog = node;
        }
        this.state = {...INITIAL_STATE};
    }

    componentDidMount() {
        openDialogFn = this.openDialog;
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        // This is quite uncommon. It is used to properly show different licences in select without overflow control.
        if (!prevState.dialogNode && !!this.dialog) {
            this.setState({
                dialogNode: this.dialog
            });
        }
    }

    handleDate = (field, date) =>
        this.setState(ps => ({
            [field]: date,
            license: {...ps.license, [field]: date.format(DATE_FORMAT)}
        }));

    handleLicenseType = licenseType => this.setState(ps => ({
        licenseType,
        license: {...ps.license, licenceType: licenseType.id, licenceCode: licenseType.licenceTypeCode },
    }));

    render() {
        const {open, license, licenceDate, licenceExpirationDate, licenseType, dialogNode} = this.state;
        const {classes, width} = this.props;
        const fullScreen = isWidthDown('sm', width);
        const today = moment().startOf('day');
        const title = !!license.licenceId ? get('license_update_license') : get('license_create_license');
        const helpText = !!license.licenceId ? get('license_update_license_helper') : get('license_create_license_helper');
        return (
            <Dialog
                open={open}
                fullScreen={fullScreen}
                onClose={this.handleClose}
                disablePortal
                ref={this.setDialogRef}
            >
                <DialogTitle>{title}</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {helpText}
                    </DialogContentText>
                    <div className={classes.group}>
                        <AsyncSelect
                            className={classes.half}
                            selected={licenseType}
                            optionLabel={option => get(option.licenceTypeCode)}
                            optionValue={option => option}

                            retrieveUrl={API_MASTER_LICENSE_TYPES}
                            title={get('license_select_license_type')}
                            value={licenseType}
                            onSelect={this.handleLicenseType}
                            disabled={!!license.licenceId}
                            menuPortalTarget={dialogNode}
                        />

                        <DatePicker
                            label={get('license_valid_from')}
                            className={classes.textField}
                            value={licenceDate}
                            initialFocusedDate={licenceDate}
                            maxDate={today}
                            disableFuture
                            openTo="year"
                            format={'DD/MM/YYYY'}
                            views={["year", "month", "date"]}
                            autoOk={true}
                            onChange={date => this.handleDate('licenceDate', date)}
                        />
                        <DatePicker
                            label={get('license_valid_to')}
                            className={classes.textField}
                            value={licenceExpirationDate}
                            initialFocusedDate={licenceExpirationDate}
                            minDate={today}
                            openTo="year"
                            format={'DD/MM/YYYY'}
                            views={["year", "month", "date"]}
                            autoOk={true}
                            onChange={date => this.handleDate('licenceExpirationDate', date)}
                        />

                    </div>
                </DialogContent>
                <DialogActions>
                    <Button onClick={this.handleClose} color="secondary" variant={"outlined"}>
                        {get('CANCEL')}
                    </Button>
                    <Button onClick={this.save} color="primary" variant={"contained"}>
                        {get('SAVE')}
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }

    openDialog = (uid, license) => {
        if (!!license) {
            this.setState({
                open: true,
                uid,
                license,
                licenseType: {id: license.licenceType, licenceTypeCode: license.licenceCode},
                licenceDate: moment(license.licenceDate, DATE_FORMAT),
                licenceExpirationDate: moment(license.licenceExpirationDate, DATE_FORMAT),
            });
        } else {
            const today = moment().startOf('day');
            this.setState({
                open: true,
                uid,
                license: {
                    ...LICENSE_TEMPLATE,
                    uid,
                    licenceDate: today.format(DATE_FORMAT),
                    licenceExpirationDate: today.format(DATE_FORMAT)
                },
                licenceDate: moment(today, DATE_FORMAT),
                licenceExpirationDate: moment(today, DATE_FORMAT),
            });
        }
    };

    handleClose = () => {
        this.setState({...INITIAL_STATE});
    };

    save = () => {
        const license = this.state.license;
        const method = !!license.licenceId ? 'PUT' : 'POST';
        const {onSuccess, onError} = this.props;
        doRequest(API_USER_UPDATE_LICENSE, onSuccess, onError, {method, body: JSON.stringify(license)});
        this.handleClose();
    };
}

export function openLicenseDialog(uid, license) {
    openDialogFn(uid, license);
}

const mapStateToProps = ({session}) => ({
    authUser: session.authUser,
});

const authCondition = profile => !!profile;

LicenseDialog.propTypes = {
    classes: PropTypes.object.isRequired,
    onSuccess: PropTypes.func.isRequired,
    onError: PropTypes.func.isRequired,
};

export default compose(
    withAuthorization(authCondition),
    withStyles(styles),
    withWidth(),
    connect(mapStateToProps)
)(LicenseDialog)
