import React from 'react'
import {makeStyles} from '@material-ui/core/styles'
import {PropTypes} from 'prop-types'

import {Typography} from '@material-ui/core'
import {BLUE, MARINE} from '../../../utils/constants'
import ScoreComponent from "../../common/ScoreComponent";
import Link from "react-router-dom/Link";
import {buildUrl} from "../../../utils/http";
import {ROUTE_USER_DETAIL} from "../../../utils/routes";
import LatencyComponent from "../LatencyComponent";
import clsx from "clsx";
import layoutStyles from "../../common/styles/layoutStyles";
import {get} from "../../common/i18n/i18n";

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexFlow: 'wrap column',
        justifyContent: 'space-between',
        alignItems: 'flex-start',
    },
    name: {
        color: MARINE,
    },
    score: {
        display: 'flex',
        flexFlow: 'wrap row',
        justifyContent: 'flex-start',
        alignItems: 'center',
    },

    line: {
        marginLeft: theme.spacing(2),
        color: BLUE,
    },
    ...layoutStyles(theme),
}));

export default function DestinationComponent({dest}) {
    const classes = useStyles();
    return (
        <div className={classes.root}>
            <Typography variant={'h5'} className={clsx(classes.name, classes.link)} component={Link}
                        to={buildUrl(ROUTE_USER_DETAIL, {uid: dest.uid})}>
                {dest.nickname}
            </Typography>
            <div className={classes.score}>
                <ScoreComponent score={dest.score} size={'1rem'}/>
                <Typography className={classes.line} variant={'caption'}>
                    {
                        !dest.receivedRatings ? null : get('number_of_reviews', [dest.receivedRatings])
                    }
                </Typography>
            </div>
            <LatencyComponent latency={dest.latency}/>
        </div>
    );
}

DestinationComponent.propTypes = {
    dest: PropTypes.shape({
        uid: PropTypes.string,
        nickname: PropTypes.string,
        avatar: PropTypes.string,
        score: PropTypes.number,
        latency: PropTypes.number,
        receivedRatings: PropTypes.number,
    }).isRequired,
};