import React from 'react'

import {PropTypes} from "prop-types";

import {withStyles} from '@material-ui/core/styles'
import {Typography} from "@material-ui/core";
import {get} from "../common/i18n/i18n";


const styles = theme => ({
    root: {
        marginTop: theme.spacing(),
    },
});


function LatencyComponent(props) {
    const {classes, latency} = props;

    if (latency === undefined || latency === null) {
        return (
            <Typography variant={'caption'} className={classes.root}>
                {get('user_unknown_delay')}.
            </Typography>
        );
    } else if (latency < 1) {
        return (
            <Typography variant={'caption'} className={classes.root}>
                {get('user_zero_delay')}
            </Typography>
        );
    }
    return (
        <Typography variant={'caption'} className={classes.root}>
            {get('user_delay', [latency, (latency !== 1 ? 's' : '')])}
        </Typography>
    );
}

LatencyComponent.propTypes= {
    classes: PropTypes.object.isRequired,
    latency: PropTypes.number,
};

export default withStyles(styles)(LatencyComponent);