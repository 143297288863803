import React, {useCallback, useEffect, useRef, useState} from 'react';
import clsx from "clsx";
import {Link} from "react-router-dom";
import PropTypes from "prop-types";

import {Avatar, IconButton, Snackbar} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import {Alert} from "@material-ui/lab";
import CloseIcon from '@material-ui/icons/Close';



import live from '../../images/Icono_busquedas_Myur.svg'
import {API_LIVE_GET_LIVE_SEARCHES, BLUE, SALMON, SEVERITY_ERROR} from "../../utils/constants";
import {ROUTE_LIVE_SEARCH} from "../../utils/routes";
import layoutStyles from "../common/styles/layoutStyles";
import {buildUrl, doGet} from "../../utils/http";
import {showMessage} from "../common/NotificationSnack";
import {sleep} from "../../utils/utils";
import {get} from "../common/i18n/i18n";

const LONG_WAIT = 3 * 60 * 1000;
const SHORT_WAIT = 15 * 1000;

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        '& > * + *': {
            marginTop: theme.spacing(2),
        },
        boxSizing: "border-box",
        zIndex: 100
    },
    snackRoot: {
        width: '50%',
        borderRadius: "4px",
        padding: "0px",
        backgroundColor: SALMON,
        boxSizing: "border-box",
        [theme.breakpoints.down('xs')]: {
            width: "100%",
            borderRadius: 0,
            bottom: 0,
            left: 0,
            right: 0
        },
    }
}));

const useAlertStyles = makeStyles((theme) => ({
    ...layoutStyles(theme),
    standardInfo: {
        color: theme.palette.common.white,
        backgroundColor: SALMON,
        '& $icon': {
            color: BLUE
        }
    },
    alertRoot: {
        width: '100%',
        backgroundColor: SALMON,
    },
    message: {
        width: "100%",
        display: "flex",
        flexFlow: "nowrap row",
        justifyContent: "space-between"
    },
    aText: {
        color: theme.palette.common.white,
        textAlign: "left"
    }
}));

const LiveSearchSnack = props => {

    /*
    Necesitamos abrir el snack cada cierto tiempo.
    Aparece sólo en la página de búsqueda.
    Muestra la entradilla.
    Luego muestra cada búsqueda.
    La entradilla y cada búsqueda se muestran durante 15 segundos.
    Si no hay búsquedas, la entradilla se muestra 3 minutos.
    El snack se queda cerrado 3 minutos y vuelve a abrirse.
     */
    const classes = useStyles();
    const [open, setOpen] = React.useState(true);
    const [searches, setSearches] = useState(null);

    useEffect(() => {
        doGet(buildUrl(API_LIVE_GET_LIVE_SEARCHES, {start: 0, count: 1000}))
            .then(searches => setSearches(searches))
            .catch(error => showMessage(error, SEVERITY_ERROR));
    }, []);

    const onFinish = useCallback(() => {
        //console.log(new Date().getTime()/10e4, ": finish");
        if (!open) { //evitamos poner un segunto timeout cuando ya está otro en marcha.
            return;
        }
        setOpen(false);
        setTimeout(() => {
            //console.log(new Date().getTime()/10e4, ": endTimeout")
            setOpen(true);
        }, LONG_WAIT);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className={classes.root}>
            <Snackbar open={open} classes={{root: classes.snackRoot}} anchorOrigin={{vertical: "bottom", horizontal: "center"}}>
                <LiveSearchAlert classes={classes} searches={searches} open={open} onFinish={onFinish}/>
            </Snackbar>
        </div>
    );
};

const LiveSearchAlert = ({searches, onFinish, open}) => {
    const [currentSearch, setCurrentSearch] = useState(null);
    const classes = useAlertStyles();
    // Tenemos que crear una referencia para poder actualizar el valor y que la actualización se vea en useEffect.
    const openRef = useRef(open);
    // No podemos usar useState 1) porque no es para renderizar, 2) porque provocará un render adicional incorrecto con el snack ya cerrado cuando el usuario lo cierre manualmente.
    const looping = useRef(false);

    // console.log(t(), ": LiveSearchAlert, open: ", open, ", looping: ", looping.current);
    useEffect(() => {
        openRef.current = open;
    }, [open]);

    useEffect(() => {
        // console.log(t(), ": useEffect open: ", open, ", looping: ", looping.current, ", searches: ", searches?.specificRequests);
        if (!open || looping.current) {
            // console.log(t(), ": (!open) open: ", open, ", looping: ", looping.current, ", searches: ", searches?.specificRequests);
            return undefined;
        }
        const loop = async function() {
            // console.log(t(), ": enter loop");
            looping.current = true;
            if((searches?.specificRequests?.length ?? 0) === 0) {
                await  sleep(LONG_WAIT);
                return;
            }
            await sleep(SHORT_WAIT);
            for (let i = 0; i < searches.specificRequests.length; i++) {
                // console.log(t(), ": loop: ", i, ", search: ", searches.specificRequests[i], ", openRef: ", openRef.current);
                setCurrentSearch(searches.specificRequests[i]);
                await sleep(SHORT_WAIT);
                if (!openRef.current) {
                    // console.log(t(), ": loop: ", i, ", break!!");
                    break;
                }
            }
        }
        // console.log(t(), ": before starting loop");
        loop().then(() => {
            // console.log(t(), ": loop finish");
            looping.current = false;
            onFinish();
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[searches, open, onFinish]);

        return (
            <Alert icon={<CurrentSearchIcon currentSearch={currentSearch}/>}
                   severity="info"
                   classes={{root: classes.alertRoot, standardInfo: classes.standardInfo, message: classes.message}}>
                <CurrentSearchLink currentSearch={currentSearch}/>
                <IconButton size="small" aria-label="close" color="inherit" onClick={onFinish}>
                    <CloseIcon fontSize="small" />
                </IconButton>
            </Alert>
        );
}
LiveSearchAlert.propTypes = {
    searches: PropTypes.shape({
        specificRequests: PropTypes.arrayOf(PropTypes.shape({
            sea: PropTypes.string,
            nickname: PropTypes.string
        }))
    }),
    onFinish: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired
};

const CurrentSearchIcon  = ({currentSearch}) => {
    if (!currentSearch) {
        return (<img width={32} src={live} alt="live"/>);
    }
    return (<Avatar width={32} height={32} src={currentSearch.avatar} alt={currentSearch.nickname}/>);
}

CurrentSearchIcon.propTypes = {
    currentSearch: PropTypes.shape({
        avatar: PropTypes.string,
        nickname: PropTypes.string
    })
};

const CurrentSearchLink = ({currentSearch}) => {
    const classes = useAlertStyles();
    if (!currentSearch) {
        return (
                <Link to={ROUTE_LIVE_SEARCH} className={clsx(classes.link, classes.aText)}>
                    {get('live_search_banner')}
                </Link>
        );
    }
    return (
            <Link to={ROUTE_LIVE_SEARCH} className={clsx(classes.link, classes.aText)}>
                {get('live_search_link', [currentSearch.nickname, currentSearch.title])}
            </Link>
    );
}

CurrentSearchLink.propTypes = {
    currentSearch: PropTypes.shape({
        title: PropTypes.string,
        nickname: PropTypes.string
    })
};

export default LiveSearchSnack;