import React from 'react';
import {makeStyles, Grid, Typography} from "@material-ui/core";
import layoutStyles from "../common/styles/layoutStyles";
import {MARINE, PALE_BLUE} from "../../utils/constants";
import clsx from "clsx";
import RRSSComponent from "./RRSSComponent";
import {get} from "../common/i18n/i18n";

const useStyles = makeStyles(theme => ({
    root: {
        color: MARINE,
    },
    title: {
        padding: theme.spacing(2),
    },
    contact: {
        backgroundColor: PALE_BLUE,
        padding: theme.spacing(2),
        marginBottom: theme.spacing(4),
    },
    contactLine: {
        marginBottom: theme.spacing(3),
    },
    logos: {
        alignItems: "center",
    },
    marginRight: {
        marginRight: theme.spacing(2),
    },
    ...layoutStyles(theme),
}));

function Title() {

    const classes = useStyles();

    return(
        <div className={clsx(classes.title, classes.contactLine)}>
            <Typography align={"center"} variant={"h1"}>{get('contact_title')}</Typography>
            <Typography align={"left"} variant={"body1"}>
                {get('contact_subtitle')}
            </Typography>
        </div>
    );
}

function ContactLine({title, subtitle}) {

    const classes = useStyles();

    return(
        <div className={classes.contactLine}>
            <Typography align={"left"} variant={"h2"}>{title}</Typography>
            <Typography align={"left"} variant={"body1"}>{subtitle}</Typography>
        </div>
    );
}

function RRSS() {

    const classes = useStyles();

    return(
        <div className={classes.contactLine}>
            <Typography align={"left"} variant={"h2"}>{get('contact_social')}</Typography>
            <div className={clsx(classes.defaultRow, classes.logos)}>
                <Typography className={classes.marginRight} align={"left"} variant={"body1"}>
                    {get('contact_follow_us')}
                </Typography>
                <RRSSComponent/>
            </div>
        </div>
    );
}

export default function ContactComponent(props) {

    const classes = useStyles();

    return(
        <Grid container className={clsx(classes.maxWidth, classes.root)}>
            <Grid item xs={2} md={4}/>
            <Grid item xs={8} md={4}>
                <Title/>
                <div className={classes.contact}>
                    <ContactLine title={get('contact_technical_support')} subtitle={"soporte@myur.app"}/>
                    <ContactLine title={get('contact_platform')} subtitle={"social@myur.app"}/>
                    <ContactLine title={get('contact_other')} subtitle={"myur@myur.app"}/>
                    <RRSS/>
                </div>
            </Grid>
            <Grid item xs={2} md={4}/>
        </Grid>
    )
}