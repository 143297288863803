import React from 'react';
import {useHistory} from "react-router-dom";
import {PropTypes} from 'prop-types';

import {Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Typography} from '@material-ui/core';
import {makeStyles} from "@material-ui/styles";

import {ROUTE_PROFILE} from "../../utils/routes";
import {get} from "../common/i18n/i18n";
import buttonStyles from "../common/styles/buttonStyles";

const useStyles = makeStyles(theme => ({
    ...buttonStyles(theme),
    problemList: {
        margin: theme.spacing(1, 0)
    }
}));

export default function CanMakeOpDialog({problems, open, handleClose}) {
    const history = useHistory();
    const classes = useStyles();

    const handleOpenProfile = () => {
        handleClose();
        history.push(ROUTE_PROFILE);
    }

    return (
        <Dialog open={open} onClose={handleClose}>
            <DialogTitle>{get('user_data_pending_title')}</DialogTitle>
            <DialogContent>
                <DialogContentText component={"div"}>
                    {get('user_data_pending_helper')}
                    <ul className={classes.problemList}>
                        {
                            problems.map(problem => <li key={problem}><Typography>{get(problem)}</Typography></li>)
                        }
                    </ul>
                    {get('user_data_pending_redirect')}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button className={classes.defaultButton} onClick={handleClose}>
                    {get('CANCEL')}
                </Button>
                <Button className={classes.blueButton} onClick={handleOpenProfile} autoFocus>
                    {get('OK')}
                </Button>
            </DialogActions>
        </Dialog>
    );
}

CanMakeOpDialog.propTypes = {
    problems: PropTypes.array,
    open: PropTypes.bool,
    handleClose: PropTypes.func.isRequired
}
