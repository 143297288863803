import React from 'react';
import clsx from "clsx";
import {withRouter} from 'react-router-dom';
import moment from "moment";
import {connect} from "react-redux";
import {PropTypes} from "prop-types";
import shortid from 'shortid';
import {compose} from "recompose";

import {Button, Divider, Typography, withStyles} from "@material-ui/core";
import {
    API_CHAT_CHAT, API_CHAT_MESSAGE, API_CHAT_SEND_MESSAGE, API_ITEM_DETAIL, API_ITEM_DETAIL_UID,
    API_USER_GET_PUBLIC_DATA, BLUE, BROWN, CHAT_MESSAGE_TYPE_TEXT, DEFAULT_PHOTO_WIDTH, DEFAULT_USER_AVATAR_URL,
    DEFAULT_WIDTH, HTML_FONT_SIZE, MARINE, SEVERITY_ERROR,
} from "../../utils/constants";
import {get} from "../common/i18n/i18n";
import {getToken, isSignedId} from "../../utils/firebase";
import {buildUrl, doGet, doPut} from "../../utils/http";
import {showMessage} from "../common/NotificationSnack";
import ItemSkeleton from "../user/item/ItemSkeleton";
import MessageGroupHeader from "./MessageGroupHeader";
import DateSeparator from "./DateSeparator";
import withAuthorization from "../auth/withAuthorization";
import buttonStyles from "../common/styles/buttonStyles";
import MyurInput from "../live/MyurInput";
import ChatWrapper from "./ChatWrapper";
import ItemCard from "../item/ItemCard";
import ItemVertSkeleton from "../item/ItemVertSkeleton";
import DestinationComponent from "../item/detail/DestinationComponent";
import ChatMessageComponent from "./ChatMessageComponent";
import {addMessagesPending, reloadMessagesPending} from "../../actions/notificationActions";
import withMessaging from "../../utils/firebase/withMessaging";
import {getUid} from "../../utils/utils";

const styles = theme => ({
    root: {
        width: '100%',
        maxWidth: DEFAULT_WIDTH,
        display: 'flex',
        flexFlow: 'no-wrap row',
        justifyContent: 'space-around',
    },
    messageList: {
        display: 'flex',
        flexFlow: 'nowrap column',
    },
    message: {
        color: MARINE,
        margin: theme.spacing(0, 1),
        alignSelf: 'flex-start',
    },
    image: {
        width: DEFAULT_PHOTO_WIDTH,
        height: 'auto',
    },
    messageOwn: {
        color: BROWN,
        alignSelf: 'flex-end',
    },
    sender: {
        width: '100%',
        display: 'flex',
        flexFlow: 'wrap row',
        justifyContent: 'space-between',
        alignItems: 'baseline',
        background: BLUE,
        height: '5em',
    },
    textField: {
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(1),
        flexGrow: 2,
    },
    button: {
        marginRight: theme.spacing(2),
        marginLeft: theme.spacing(1),
        maxHeight: theme.spacing(6),
        flexGrow: 0,
    },
    chat: {
        width: '75%',
    },
    sidePanel: {
        width: '20%',
    },
    divider: {
        height: '2px',
        margin: theme.spacing(2),
    },
    ...buttonStyles(theme),
});

const INITIAL_STATE = {
    waiting: false,
    chat: null,
    items: [],
    text: '',
    item: null,
    receiver: null,
    width: window.innerWidth,
    height: window.innerHeight,
};

const DATE_SEPARATOR = 'DATE_SEPARATOR';
const GROUP_HEADER = 'GROUP_HEADER';
const MESSAGE = 'MESSAGE';

class ChatComponent extends React.Component {

    constructor(props) {
        super(props);
        this.lastReceivedMessage = null;
        this.lastDay = moment(0);
        this.lastSender = null;
        this.state = {...INITIAL_STATE};
        this.messageListRef = React.createRef();
    }

    componentDidMount() {
        const chatId = this.props.match.params.chatId;
        const state = this.props.history.location.state;
        const {itemTitle, otherNickname} = !!state ? state : {itemTitle: '', otherNickname: ''};
        const [borrowerId, ownerId, itemId] = !!chatId ? chatId.split('!') : [null, null, null];
        const uid = getUid();
        const receiverUid = uid === borrowerId ? ownerId : borrowerId;
        this.borrowerId = borrowerId;
        this.otherNickname = otherNickname;
        this.ownerId = ownerId;
        this.itemId = itemId;
        this.itemTitle = itemTitle;
        window.addEventListener('resize', this.updateDimensions);
        this.retrieveChat();
        this.requestItem(itemId, uid);
        this.requestReceiver(receiverUid);
        this.props.registerNotificationHandler(this.onMessage);
    }

    componentWillUnmount() {
        this.props.unRegisterNotificationHandler();
        window.removeEventListener('resize', this.updateDimensions);
        this.setMessagesRead(false);
    }

    getSnapshotBeforeUpdate(prevProps, prevState) {
        // Are we adding new items to the list?
        // Capture the scroll position so we can adjust scroll later.
        const list = this.messageListRef.current;
        //console.log("getSnapshotBeforeUpdate");
        if (!!list && prevState.items.length < this.state.items.length) {
            return list.scrollHeight - list.scrollTop;
        }
        return null;
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        // If we have a snapshot value, we've just added new items.
        // Adjust scroll so these new items don't push the old ones out of view.
        // (snapshot here is the value returned from getSnapshotBeforeUpdate)
        const list = this.messageListRef.current;
        if (!!snapshot) {
            list.scrollTop = list.scrollHeight - snapshot;
        } else if (!!list) {
            //console.log("top: ", list.scrollTop, "height: ", list.scrollHeight,
            //    "topMax: ", list.scrollTopMax, "clientHeight: ", list.clientHeight);
            list.scrollTop = list.scrollHeight - list.clientHeight;        }
    }

    updateDimensions = () => {
        this.setState({width: window.innerWidth, height: window.innerHeight});
    };

    requestItem = (id, uid) => {
        this.setState({loading: true});
        if (!!uid) {
            doGet(buildUrl(API_ITEM_DETAIL_UID, {id, uid}))
                .then(this.requestItemSuccess)
                .catch(this.requestError);
        } else {
            doGet(buildUrl(API_ITEM_DETAIL, {id}))
                .then(this.requestItemSuccess)
                .catch(this.requestError);
        }
    };

    requestReceiver = uid => {
        doGet(buildUrl(API_USER_GET_PUBLIC_DATA, {uid}))
            .then(this.requestReceiverSuccess)
            .catch(this.requestError);
    };

    requestItemSuccess = item => {
        this.setState({item});
    }

    requestError = error => {
        showMessage(error, SEVERITY_ERROR);
    }

    requestReceiverSuccess = receiver => {
        this.setState({receiver});
    }

    onMessage = message => {
        const {borrowerId, id, itemId, ownerId, payloadType, body: payload, timestamp} = message;
        const senderId = this.props.user.uid === borrowerId ? ownerId : borrowerId;
        this.receivedMessage({senderId, borrowerId, id, itemId, ownerId, payloadType, payload, timestamp});
    };

    receivedMessage = message => {
        this.setState(prevState => {
            const prevItems = prevState.items;
            const {items, lastDay, lastSender, lastReceivedMessage} =
                this.createMessageElement(message, prevState.chat, this.lastDay, this.lastSender, this.lastReceivedMessage);
            this.lastDay = lastDay;
            this.lastSender = lastSender;
            this.lastReceivedMessage = lastReceivedMessage;

            return {
                items: [...prevItems, ...items]
            }
        });
    };

    doGet = url => {
        if (isSignedId()) {
            return getToken()
                .then(token => {
                    const headers = new Headers({'Accept': 'application/json'});
                    headers.append('Authorization', 'Bearer ' + token);
                    fetch(url, {headers})
                        .then(response => {
                            if (response.ok) {
                                return response.json().then(json => this.onChatRetrieved(json));
                            } else if (response.status === 404) {
                                return this.onChatRetrievedError(response);
                            } else {
                                return response.text()
                                    .then(text => this.onError(text))
                                    .catch(error => this.onError(error));
                            }
                        });
                })
                .catch(error => this.onError(error));
        }
    };

    doPost = (url, uid, message) => {
        if (isSignedId()) {
            return getToken()
                .then(token => {
                    const method = 'POST';
                    const headers = new Headers({'Accept': 'application/json'});
                    headers.append('Content-Type', 'application/json');
                    headers.append('Authorization', 'Bearer ' + token);
                    fetch(url, {
                        headers,
                        method,
                        body: JSON.stringify(message)
                    })
                        .then(response => {
                            if (response.ok) {
                                return response.json().then(json => this.onMessageSent({
                                    ...message,
                                    senderId: uid,
                                    system: false,
                                    status: 'sent'
                                }));
                            } else {
                                return response.text()
                                    .then(text => this.onError(text))
                                    .catch(error => this.onError(error));
                            }
                        });
                })
                .catch(error => this.onError(error));
        }
    };

    onMessageSent = message => {
        this.setState({text: ''});
        this.receivedMessage(message);
    };

    updateChatText = event => {
        this.setState({text: event.target.value});
    };

    onKeyDown = event => {
        if (event.key === 'Enter') {
            this.sendMessage();
            event.preventDefault();
        }
    };

    onFocus = value => {
        this.focus = value;
        this.setMessagesRead(true);
    };

    /**
     *
     * @param updateMessageList boolean. If true, updates de ui to set messages as read. If false does nothing.
     */
    setMessagesRead = updateMessageList => {
        if (this.lastReceivedMessage === null || this.lastReceivedMessage.status === 'read') {
            return;
        }
        const uid = getUid();
        const body = {
            id: this.lastReceivedMessage.id,
            timestamp: this.lastReceivedMessage.timestamp,
            payloadType: 'status',
            payload: 'read'
        }
        doPut(buildUrl(API_CHAT_MESSAGE, {messageId: this.lastReceivedMessage.id}), JSON.stringify(body), {auth: 'bearer'})
            .then(() => this.onSetMessagesRead(updateMessageList, uid))
            .catch(this.onError);
    };

    onSetMessagesRead = (updateMessageList, uid) => {
        if (!!uid) {
            this.props.reloadMessagesPending(uid);
        }
        if (updateMessageList) {
            this.onSetMessagesRead()
        }
    }

    onMessagesRead = () => {
        const uid = this.props.user.uid;
        let count = 0;
        this.setState(prevState => {
            const _items = prevState.items;
            let found = false;
            const lastMessageId = this.lastReceivedMessage.id;
            for (let _item of _items) {
                if (_item.type !== MESSAGE) {
                    continue;
                }
                const notOwn = uid !== _item.data.senderId;
                if (!found && notOwn && _item.data.status !== 'read') {
                    _item.data.status = 'read';
                    count++;
                } else if (found && !notOwn) {
                    this.lastReceivedMessage = _item.data;
                }
                found = _item.data.id === this.lastReceivedMessage.id;
            }
            if (lastMessageId === this.lastReceivedMessage.id) {
                this.lastReceivedMessage = null;
            }
            return ({items: _items});
        });
        this.props.addMessagesPending(-count);
        console.groupEnd();
    }

    retrieveChat = () => {
        this.setState({waiting: true});
        const options = {
            borrowerId: this.borrowerId,
            ownerId: this.ownerId,
            itemId: this.itemId,
            since: moment().format(),
            max: 50
        };
        this.doGet(buildUrl(API_CHAT_CHAT, options));
    };

    onChatRetrieved = response => {
        this.lastDay = moment(0);
        this.lastSender = null;
        this.lastReceivedMessage = null;
        let items = [];
        for (let i = 0; i < response.messages.length; i++) {
            const {items: _items, lastDay, lastSender, lastReceivedMessage} =
                this.createMessageElement(response.messages[i], response, this.lastDay, this.lastSender, this.lastReceivedMessage);
            items = [...items, ..._items];
            this.lastDay = lastDay;
            this.lastSender = lastSender;
            this.lastReceivedMessage = lastReceivedMessage;
        }
        this.setState({
            chat: response,
            items,
            waiting: false,
        });
        this.setMessagesRead(false);
    };

    createMessageElement = (message, chat, lastDay, lastSender, lastReceivedMessage) => {
        const items = [];
        const own = this.props.user.uid === message.senderId;
        const _message = {...message, own,};
        const ts = moment(message.timestamp);
        let _lastDay = lastDay,
            _lastSender = lastSender,
            _lastReceivedMessage = lastReceivedMessage;

        if (!ts.isSame(_lastDay, 'day')) {
            _lastDay = ts;
            items.push({type: DATE_SEPARATOR, data: ts, key: shortid.generate()});
        }
        let {nickname, avatar} = message.senderId === message.ownerId ?
            {nickname: chat.ownerNickname, avatar: chat.ownerAvatar} :
            {nickname: chat.borrowerNickname, avatar: chat.borrowerAvatar};
        if (!own) {
            _lastReceivedMessage = message;
        }
        if (message.senderId !== _lastSender) {
            _lastSender = message.senderId;
            items.push({type: GROUP_HEADER, data: {nickname, avatar, timestamp: message.timestamp, own}, key: shortid.generate()});
        }
        items.push({type: MESSAGE, data: _message, key: _message.id});
        return {
            items,
            lastDay: _lastDay,
            lastSender: _lastSender,
            lastReceivedMessage:  _lastReceivedMessage,
        }
    };

    onChatRetrievedError = error => {
        const chat = {
            borrowerId: this.borrowerId,
            borrowerNickname: this.otherNickname,
            borrowerAvatar: buildUrl(DEFAULT_USER_AVATAR_URL, {uid: this.borrowerId}),
            ownerId: this.ownerId,
            ownerNickname: this.otherNickname,
            ownerAvatar: buildUrl(DEFAULT_USER_AVATAR_URL, {uid: this.ownerId}),
            borrowerState: "inactive",
            firstMsgTS: moment().toISOString(),
            itemId: this.itemId,
            itemTitle: this.itemTitle,
            key: `${this.ownerId}!${this.borrowerId}!${this.itemId}`,
            messages: [],
        };
        this.setState({chat, waiting: false});
    };

    sendMessage = () => {
        const text = this.state.text;
        const {borrowerId, ownerId, itemId} = this.state.chat;
        if (text.trim() !== '') {
            const message = {
                borrowerId,
                ownerId,
                itemId,
                timestamp: moment().toISOString(),
                payloadType: CHAT_MESSAGE_TYPE_TEXT,
                payload: text,
            };
            this.doPost(API_CHAT_SEND_MESSAGE, this.props.user.uid, message);
        }
    };
    onError = error => {
        this.setState({
            waiting: false,
        });
        showMessage(error);
    };

    render() {
        const {waiting, chat, items, text, item, receiver, height} = this.state;
        const {classes, user} = this.props;
        const messageHeight = height - (4 + 4 + 5) * HTML_FONT_SIZE; //substract TopComponent, ChatWrapper and SendComponent height
        if (waiting) {
            return (
                <ChatWrapper>
                    <div className={classes.messageList} style={{height: messageHeight, overflowY: 'scroll'}}>
                        {[0, 1, 2].map(index => <ItemSkeleton key={index}/>)}
                    </div>
                </ChatWrapper>
            );
        }

        if (chat === null) {
            return (
                <ChatWrapper>
                    <Typography variant={"h6"}>{get('user_no_messages')}</Typography>
                </ChatWrapper>
            );
        }

        return (
            <ChatWrapper chat={chat} uid={user.uid}>
                <div className={classes.root}>
                    <div className={classes.chat}>
                        <div ref={this.messageListRef} className={classes.messageList}
                             style={{height: messageHeight, overflowY: 'scroll'}}>
                            {items.map(item => {
                                switch (item.type) {
                                    case DATE_SEPARATOR:
                                        return <DateSeparator key={item.key} timestamp={item.data}/>;
                                    case GROUP_HEADER:
                                        return <MessageGroupHeader key={item.key} header={item.data}/>;
                                    default:
                                        return <ChatMessageComponent key={item.key} message={item.data}/>
                                }
                            })}
                        </div>
                        <span className={classes.sender}>
                            <MyurInput
                                className={classes.textField}
                                placeholder={get('write_here')}
                                value={text}
                                onChange={this.updateChatText}
                                inputProps={{
                                    onKeyDown: this.onKeyDown,
                                    onFocus: () => this.onFocus(true),
                                    onBlur: () => this.onFocus(false),
                                }}
                            />
                            <Button className={clsx(classes.defaultButton, classes.button)} onClick={this.sendMessage}>
                                {get('send')}
                            </Button>
                        </span>
                    </div>
                    <div className={classes.sidePanel}>
                        {!!receiver ? <DestinationComponent dest={receiver}/> : <ItemVertSkeleton/>}
                        <Divider className={classes.divider}/>
                        {!!item ? <ItemCard item={item}/> : <ItemVertSkeleton/>}
                    </div>
                </div>
            </ChatWrapper>
        );
    }
}

const authCondition = profile => !!profile;

ChatComponent.propTypes = {
    classes: PropTypes.object.isRequired,
    user: PropTypes.object,
};

const mapStateToProps = ({session}) => ({
    user: session.profile,
});

const mapDispatchToProps = dispatch => ({
    addMessagesPending: count => dispatch(addMessagesPending(count)),
    reloadMessagesPending: uid => dispatch(reloadMessagesPending(uid)),
});

export default compose(
    withStyles(styles),
    withRouter,
    withAuthorization(authCondition),
    withMessaging('chatchannel'),
    connect(mapStateToProps, mapDispatchToProps),
)(ChatComponent);

export const sendMessage = message => {
    if (isSignedId()) {
        return getToken()
            .then(token => {
                const method = 'POST';
                const headers = new Headers({'Accept': 'application/json'});
                headers.append('Content-Type', 'application/json');
                headers.append('Authorization', 'Bearer ' + token);
                return fetch(API_CHAT_SEND_MESSAGE, {
                    headers,
                    method,
                    body: JSON.stringify(message)
                })

            })
    }
}