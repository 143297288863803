import {MIN_DEPOSIT_PERCENT} from "../../utils/constants";

export const ITEM_TEMPLATE = {
    userUid: '',
    userNickname: '',
    title: '', // Optional
    description: '', // Optional
    location: '', // Optional
    zipcode: '', // Optional
    hourlyPrice: null, // Mandatory
    dailyPrice: null, // Mandatory
    weeklyPrice: null, // Mandatory
    monthlyPrice: null, // Mandatory
    deposit: 0.0, // Optional. Defaults to zero.
    purchasePrice: 0, // Optional
    purchaseDate: undefined, // Optional
    categoryId: undefined,
    category: {  // Optional
        categoryId: undefined,
        categoryCode: '',
        parentCategory: undefined,
        parentCategoryCode: '',
        extraDataRequired: null,
        depositPercent: MIN_DEPOSIT_PERCENT,
        requiredDeposit: true,
        insurable: false,
        rentalWith: null,
    },
    borrowed: false, // Not used
    expired: false, // Not used
    currencyId: 1, // Not used
    languageId: 1, // Not used
    banned: false, // Not used
    enabled: true,
    numAcceptedLoans: 0, // Not used
    numFavorites: 0, // Not used
    professionalOwner: false, // Not used
    score: 0.0, // Not used
    visits: 0, // Not used
    photos: [],
    insuranceCode: '', //Optional
    insuranceRequired: false, // Mandatory
    insuranceType: 0, //Optional
};

/*
{
  "banned": false,
  "enabled:": true,
  "category": {
    "categoryId": 0,
    "categoryCode": "string",
    "parentCategory": 0,
    "parentCategoryCode": "string"
    "insurable": true,
    "depositPercent": 0,
  },
  "categoryId": "string",
  "currencyId": 0,
  "dailyPrice": 0,
  "deposit": 0,
  "description": "string",
  "enabled": true,
  "id": "string",
  "insuranceCode": "string",
  "insuranceRequired": true,
  "insuranceType": 0,
  "languageId": 0,
  "location": "string",
  "numAcceptedLoans": 0,
  "numFavorites": 0,
  "photos": [
    {
      "associatedPartName": "string",
      "id": "string",
      "itemId": "string",
      "photo": "string",
      "photoOrder": 0
    }
  ],
  "priceFrom": 0,
  "professionalOwner": true,
  "purchaseDate": "string",
  "purchasePrice": 0,
  "score": 0,
  "title": "string",
  "userNickname": "string",
  "userUid": "string",
  "visits": 0,
  "zipcode": "string"
}
 */

export const VEHICLE_TEMPLATE = {
    itemId: null,
    type: null,
    subtype: null,
    brand: '',
    model: '',
    licencePlate: '',
    countryLicencePlate: "ES",
};

export const BOAT_TEMPLATE = {
    itemId: null,
    type: null,
    subtype: null,
    boatType: '',
    material: '',
    length: 0,
    engines: 0,
    constructionYear: 2020,
    power: 0,
    zipcodePort: '',
    boatName: '',
    brand: '',
    model: '',
    licencePlate: 7,
    countryLicencePlate: "ES",
};