import React, {useEffect} from 'react';
import {useDispatch} from "react-redux";
import {useHistory, useLocation} from 'react-router-dom';

import buttonStyles from "../common/styles/buttonStyles";

import {makeStyles} from '@material-ui/core/styles';
import {Hidden, MobileStepper, Stepper, Step, StepLabel, Button} from '@material-ui/core';

import {
    BLUE,
    DEFAULT_WIDTH,
    ADD_USER,
    SEVERITY_SUCCESS,
    ANALYTICS_SIGN_UP
} from "../../utils/constants";
import {get} from "../common/i18n/i18n";
import CheckAgeComponent from "./CheckAgeComponent";
import SelectCredentialsComponent from "./SelectCredentialsComponent";
import NicknameComponent from "./NicknameComponent";
import {doFetch} from "../../utils/http";
import {showMessage} from "../common/NotificationSnack";
import ProgressButton from "../common/ProgressButton";
import {retrieveUserPublicProfile} from "../../actions/sessionActions";
import {LANDING} from "../../utils/routes";
import {SignInLink} from "./SignInComponent";
import VerifyEmailComponent from "./VerifyEmailComponent";
import {validate_username, validate_zipcode} from "../user/user_validation";
import {analytics, logEvent} from "../../utils/firebase";

const STEP_AGE = 0;
const STEP_LOGIN = 1;
const STEP_VERIFY = 2;
const STEP_PROFILE = 3;

const useStyles = makeStyles(theme => ({
    ...buttonStyles(theme),
    root: {
        width: DEFAULT_WIDTH,
        backgroundColor: BLUE,
    },
    instructions: {
        marginTop: theme.spacing(),
        marginBottom: theme.spacing(),
    },
    mobileStepper: {
        width: '100%',
        maxWidth: DEFAULT_WIDTH,
        flexGrow: 1,
        border: '1px solid red',
    },
}));

function getSteps() {
    return [get('age'), get('login'), get('verify_email'), get('user_data')];
}

const canContinue = (step, over8teen, uid, email, verifiedEmail, username, zipcode) => {
    if (step === STEP_AGE) {
        return over8teen;
    }
    if (step === STEP_VERIFY) {
        return verifiedEmail;
    }
    return over8teen && !!uid && !!email && !!verifiedEmail
        && validate_username(username) === ''
        && validate_zipcode(zipcode) === '';
};

export default function SignUpStepper(props) {
    const classes = useStyles();
    const [activeStep, setActiveStep] = React.useState(0);
    const [over8teen, setOver8teen] = React.useState(false);
    const [username, setUsername] = React.useState('');
    const [email, setEmail] = React.useState('');
    const [verifiedEmail, setVerifiedEmail] = React.useState(false);
    const [uid, setUid] = React.useState('');
    const [zipcode, setZipcode] = React.useState('');
    const [waiting, setWaiting] = React.useState(false);
    const history = useHistory();
    let location = useLocation();
    const dispatch = useDispatch();
    const steps = getSteps();

    /**
     * Scroll to top of page
     */
    useEffect(() => {
        window.scrollTo(0, 0)
    }, []);

    useEffect(() => {
        if (!!location.state) {
            setUid(location.state.uid);
            setEmail(location.state.email);
            setVerifiedEmail(location.state.emailVerified);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getStepContent = step => {
        switch (step) {
            case STEP_LOGIN:
                return <SelectCredentialsComponent onCredentials={onCredentials}/>;
            case STEP_VERIFY:
                return (
                    <VerifyEmailComponent
                        email={email}
                        verifiedEmail={verifiedEmail}
                        onChange={verifiedEmail => setVerifiedEmail(verifiedEmail)}
                        onError={onError}
                    />);
            case STEP_PROFILE:
                return (
                    <NicknameComponent
                        onUsernameChange={username => setUsername(username)}
                        onZipcodeChange={zipcode => setZipcode(zipcode)}
                    />
                );
            default:
                return (
                    <CheckAgeComponent onChange={over8teen => {
                        setOver8teen(over8teen)
                    }}/>
                );
        }
    };

    const onCredentials = (uid, email, verifiedEmail) => {
        setUid(uid);
        setEmail(email);
        setVerifiedEmail(verifiedEmail);
        // forced to send it as a parameter because update queue in react my be not so updated.
        handleNext(verifiedEmail);
    };

    const handleNext = _verifiedEmail => {
        const isEmailVerified = _verifiedEmail || verifiedEmail;
        if (activeStep === steps.length - 1) {
            setWaiting(true);
            const body = {
                adult: over8teen,
                email: email.trim(),
                nickname: username.trim(),
                uid: uid,
                zipCode: zipcode.trim()
            };
            doFetch(ADD_USER,
                onUserCreated,
                onError,
                {method: 'POST', body: JSON.stringify(body)}
            );
        } else if (activeStep === STEP_LOGIN && isEmailVerified) {
            setActiveStep(STEP_PROFILE);
        } else if (activeStep === STEP_AGE && !!uid && !!email && isEmailVerified) {
            setActiveStep(STEP_PROFILE);
        } else {
            setActiveStep(prevActiveStep => prevActiveStep + 1);
        }
    };

    const onError = error => {
        setWaiting(false);
        showMessage(error);
    };

    const onUserCreated = user => {
        setWaiting(false);
        logEvent(analytics, ANALYTICS_SIGN_UP, {
            usr_id: user.uid,
            user_nickname: user.nickname,
            user_zipcode: user.zipCode
        });
        _retrieveUserPublicProfile(user.uid);
        showMessage(get('landing_disclaimer_welcome'), SEVERITY_SUCCESS);
        history.push(LANDING);
    };

    const _retrieveUserPublicProfile = uid =>
        dispatch(retrieveUserPublicProfile(uid));

    return (
        <div className={classes.root}>
            <Hidden xsDown>
                <Stepper activeStep={activeStep}>
                    {steps.map((label, index) => {
                        const stepProps = {};
                        const labelProps = {};
                        return (
                            <Step key={label} {...stepProps}>
                                <StepLabel {...labelProps}>{label}</StepLabel>
                            </Step>
                        );
                    })}
                </Stepper>
            </Hidden>
            <Hidden smUp>
                <MobileStepper
                    steps={getSteps().length}
                    position="static"
                    activeStep={activeStep}
                    backButton={<Button className={classes.defaultButton} disabled>Atrás</Button>}
                    nextButton={
                        <ProgressButton
                            loading={waiting}
                            disabled={!canContinue(activeStep, over8teen, uid, email, verifiedEmail, username, zipcode)}
                            handleClick={handleNext}
                            className={classes.defaultButton}>
                            {activeStep === steps.length - 1 ? get('finish') : get('next_step')}
                        </ProgressButton>
                    }
                />
            </Hidden>
            <div>
                {getStepContent(activeStep)}
                <Hidden xsDown>
                    {activeStep !== STEP_LOGIN && (
                        <ProgressButton
                            loading={waiting}
                            disabled={!canContinue(activeStep, over8teen, uid, email, verifiedEmail, username, zipcode)}
                            handleClick={handleNext}
                            className={classes.defaultButton}>
                            {activeStep === steps.length - 1 ? get('finish') : get('next_step')}
                        </ProgressButton>
                    )}
                </Hidden>
            </div>
            <div>
                <SignInLink/>
            </div>
        </div>
    );
}