import React from 'react'

import {PropTypes} from "prop-types";

import {makeStyles} from '@material-ui/core/styles'
import {Typography} from "@material-ui/core";
import {get} from "../../common/i18n/i18n";


const useStyles = makeStyles (theme => ({
    root: {
        marginLeft: theme.spacing(),
        marginTop: theme.spacing(),
        textAlign: 'left',
    },
}));


export default function UserDescriptionComponent(props) {
    const {nickname, description} = props;
    const classes = useStyles();

    if (!description) {
        return (
            <Typography variant={'body2'} className={classes.root}>
                {get('no_self_description', [nickname])}
            </Typography>
        );
    }
    return (
        <Typography variant={'body2'} className={classes.root}>
            {description}
        </Typography>
    );
}

UserDescriptionComponent.propTypes= {
    description: PropTypes.string,
    nickname: PropTypes.string.isRequired,
};