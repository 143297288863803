import React from 'react'

import {PropTypes} from 'prop-types'

import {makeStyles} from "@material-ui/core/styles";
import {Typography} from "@material-ui/core";

import {BLUE, RED} from "../../utils/constants";
import {get} from "../common/i18n/i18n";
import LabelValue from "../common/LabelValue";

const useStyles = makeStyles(theme => ({
    bannedSection: {
        marginTop: theme.spacing(1),
    },
    banned: {
        border: `2px solid ${RED}`,
        color: BLUE,
        padding: theme.spacing(1),
        marginRight: theme.spacing(1),
    },
    price: {
        marginLeft: theme.spacing(2),
        color: BLUE,
    }
}));
export default function UserItemProperties({item}) {
    const classes = useStyles();
    const formatter = get('currency_formatter');
    return (
            <div>
                <LabelValue label={get('description')} value={item.description}/>
                <LabelValue label={get('price')}>
                    <Typography className={classes.price}>
                    {
                        ['hourlyPrice', 'dailyPrice', 'weeklyPrice', 'monthlyPrice']
                            .map(price =>
                                !!item[price] ? formatter.format(item[price]) + get(`price_${price}`) : null
                            ).filter(price => !!price).join(' | ')
                    }
                    </Typography>
                </LabelValue>
                <div className={classes.bannedSection}>
                    {item.banned &&
                        <Typography variant={"h5"} component={"span"} className={classes.banned}>
                            {get('banned')}
                        </Typography>}
                    {!item.enabled &&
                        <Typography variant={"h5"} component={"span"} className={classes.banned}>
                            {get('not_enabled')}
                        </Typography>}
                </div>
            </div>
    )
}

UserItemProperties.TypeProps = {
    item: PropTypes.object.isRequired,
};