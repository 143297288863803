import React from "react";
import {Hidden, makeStyles, Typography} from "@material-ui/core";
import clsx from "clsx";

import {Link} from "react-router-dom";

import {
    MARINE,
    ORANGE,
    PALE_BLUE,
    SALMON,
    SAND,
    RED,
    MYUR_TERMS_URL,
    MYUR_PRIVACY_URL,
    MYUR_COOKIES_URL, MYUR_FAQ_URL, STORE_GOOGLE_PLAY, STORE_APP_STORE
} from "../../utils/constants";
import myur_logo from '../../images/logo_vertical.png';
import googlePlay from '../../images/google-play-badge.png';
import {get} from "../common/i18n/i18n";
import {ROUTE_CONTACT} from "../../utils/routes";
import RRSSComponent from "../landing/RRSSComponent";
import appStore from "../../images/Download_on_the_App_Store_Badge.svg";

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        display: 'flex',
        flexFlow: 'wrap row',
        justifyContent: 'center',
        maxHeight: 192,
    },
    section: {
        paddingTop: theme.spacing(3),
        [theme.breakpoints.down('sm')]: {
            width: '33%',
        },
    },
    sectionText: {
        boxSizing: 'border-box',
        paddingLeft: theme.spacing(3),
        textAlign: 'left',
        [theme.breakpoints.down('sm')]: {
            width: '33%',
            paddingLeft: theme.spacing(1),
        },
    },
    image: {
        boxSizing: 'border-box',
        padding: theme.spacing(2),
        maxHeight: 155,
    },
    myur: {
        backgroundColor: PALE_BLUE,
        flexGrow: 3,
    },
    platform: {
        backgroundColor: ORANGE,
        maxWidth: '16.75rem',
        color: SAND,
        flexGrow: 1,
    },
    other: {
        display: 'flex',
        flexFlow: 'nowrap column',
        backgroundColor: SALMON,
        maxWidth: '16.75rem',
        color: MARINE,
        flexGrow: 1,
    },
    legal: {
        display: 'flex',
        flexFlow: 'nowrap column',
        backgroundColor: SAND,
        color: RED,
        flexGrow: 3,
        [theme.breakpoints.down('xs')]: {
            flexGrow: 1,
        },
    },
    link: {
        textDecoration: 'none',
        '&:hover': {
            textDecoration: 'underline',
        }
    },
    red: {
        color: RED,
    },
    marine: {
        color: MARINE,
    },
    store: {
        width: '92px',
        height: 'auto',
    },
}));

export default function FootComponents(props) {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <Hidden smDown>
                <div className={clsx(classes.section, classes.myur)}>
                    <img className={classes.image} align={'right'} alt={'myur logo'} src={myur_logo}/>
                </div>
            </Hidden>
            <div className={clsx(classes.section, classes.sectionText, classes.platform)}>
                <Typography variant={'h2'}>la plataforma</Typography>
                {/*
                <Typography variant={'h6'}>¿cómo funciona?</Typography>
                <Typography variant={'h6'}>¿quiénes somos?</Typography>
                <Typography variant={'h6'}>ayuda</Typography>
                */}
                <div className={classes.store}>
                    <a href={STORE_GOOGLE_PLAY}>
                        <img className={classes.store} alt='google play' src={googlePlay}/>
                    </a>
                </div>
                <div>
                    <a href={STORE_APP_STORE}>
                        <img className={classes.store} alt={'app store'} src={appStore}/>
                    </a>
                </div>
            </div>
            <div className={clsx(classes.section, classes.sectionText, classes.other)}>
                <Typography variant={'h2'}>otros</Typography>
                <Typography variant={'h6'} className={clsx(classes.marine, classes.link)} component={'a'} target="_blank" rel="noopener noreferrer" href={MYUR_FAQ_URL}>
                    {get('faq')}
                </Typography>
                <Typography variant={'h6'} className={clsx(classes.marine, classes.link)} component={Link} to={ROUTE_CONTACT}>
                    contacto
                </Typography>
                <RRSSComponent/>
            </div>
            <div className={clsx(classes.section, classes.sectionText, classes.legal)}>
                <Typography variant={'h2'}>legal</Typography>
                <Typography variant={'h6'} className={clsx(classes.red, classes.link)} component={'a'} target="_blank" rel="noopener noreferrer" href={MYUR_TERMS_URL}>
                    {get('terms')}
                </Typography>
                <Typography variant={'h6'} className={clsx(classes.red, classes.link)} component={'a'} target="_blank" rel="noopener noreferrer" href={MYUR_PRIVACY_URL}>
                    {get('privacy')}
                </Typography>
                <Typography variant={'h6'} className={clsx(classes.red, classes.link)} component={'a'} target="_blank" rel="noopener noreferrer" href={MYUR_COOKIES_URL}>
                    {get('cookie_policy')}
                </Typography>
            </div>
        </div>
    )
}