import React from 'react'
import {makeStyles} from '@material-ui/core/styles'

import {Avatar} from '@material-ui/core'
import {BLUE, DEFAULT_AVATAR} from '../../../utils/constants'
import ScoreComponent from "../../common/ScoreComponent";
import Skeleton from "@material-ui/lab/Skeleton";

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexFlow: 'wrap row',
        justifyContent: 'flex-start',
        alignItems: 'center',
        background: 'white',
        color: BLUE,
    },
    detail: {
        display: 'flex',
        flexFlow: 'wrap column',
        justifyContent: 'space-between',
        alignItems: 'flex-start',
    },
    avatar: {
        margin: theme.spacing(),
        marginRight: theme.spacing(2),
        width: 60,
        height: 60,
    },
    line: {
        marginLeft: theme.spacing(),
    },
    left: {
        display: 'flex',
        flexFlow: 'nowrap column',
    },
    core: {
        display: 'flex',
        flexFlow: 'wrap row',
    },
}));

export default function UserDetailMiniCardSkeleton() {
    const classes = useStyles();
    return (
        <div className={classes.root}>
            <div className={classes.left}>
                <div className={classes.core}>
                    <Avatar alt={"avatar"} src={DEFAULT_AVATAR} className={classes.avatar}/>
                    <div className={classes.detail}>
                        <Skeleton className={classes.line} height={10} width={'30%'}/>
                        <div className={classes.root}>
                            <ScoreComponent score={undefined}/>
                        </div>
                        <Skeleton className={classes.line} height={5} width={'30%'}/>
                    </div>
                </div>
                <Skeleton height={10} width={'80%'}/>
                <Skeleton height={10} width={'60%'}/>
                <Skeleton height={10} width={'70%'}/>
            </div>
            <Skeleton height={10} width={'20%'}/>
        </div>
    );
}