import React from 'react';
import PropTypes from 'prop-types';

import {Grid} from "@material-ui/core";
import ItemCard from "../../item/ItemCard";
import ItemVertSkeleton from "../../item/ItemVertSkeleton";

export default function UserItemsComponent({items, waiting}) {
    return (
        <Grid container spacing={1}>
            {
                waiting
                    ? [0, 1, 2, 3].map(idx => <ItemVertSkeleton key={idx} gridProps={{xs: 4, sm: 3, md: 2}}/>)
                    : items.map(item => <ItemCard key={item.id} item={item} gridProps={{xs: 4, sm: 3, md: 2}}/>)
            }
        </Grid>
    );
}

UserItemsComponent.props = {
    items: PropTypes.array.isRequired,
    waiting: PropTypes.bool.isRequired,
};