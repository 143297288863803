import React from 'react'
import {PropTypes} from 'prop-types';
import {Paper, Typography, Divider, IconButton} from "@material-ui/core";
import DeleteIcon from '@material-ui/icons/Delete'
import EditIcon from '@material-ui/icons/Edit'
import {makeStyles} from "@material-ui/core/styles";
import {openLicenseDialog} from "./LicenseDialog";
import LabelValue from "../../common/LabelValue";
import {get} from "../../common/i18n/i18n";
import moment from "moment";
import {DATE_FORMAT} from "../../../utils/constants";

const useStyles = makeStyles(theme => ({
    root: {
        margin: theme.spacing(),
        padding: theme.spacing(),
        minWidth: 180,
        textAlign: 'left',
    },
    bottomMargin: {
        bottomMargin: theme.spacing(2),
    },
    topMargin: {
        topMargin: theme.spacing(4),
    },
    first_line: {
        display: 'flex',
        flexFlow: 'no-wrap row',
        justifyContent: 'flex-end',
        alignItems: 'center',
    },
    description: {
        flexGrow: 1,
        textAlign: 'left',
    },
    divider: {
        marginBottom: theme.spacing(),
    },
    delete: {
        margin: theme.spacing(0),
    },
}));

export default function LicenseCard(props) {
    const {license, handleDelete} = props;
    const classes = useStyles();
    return (
        <Paper className={classes.root}>
            <div className={classes.first_line}>
                <Typography variant={"subtitle2"} className={classes.description}>
                    {get('license_title', [license.licenceCode])}
                </Typography>
                <IconButton aria-label="Edit" className={classes.delete} size="small"
                            onClick={() => openLicenseDialog(license.uid, license)}>
                    <EditIcon fontSize="inherit" />
                </IconButton>
                <IconButton aria-label="Delete" className={classes.delete} size="small"
                            onClick={() => handleDelete(license.licenceId)}>
                    <DeleteIcon fontSize="inherit" />
                </IconButton>
            </div>
            <Divider className={classes.divider}/>
            <LabelValue label={get('license_valid_from')}
                        value={moment(license.licenceDate, DATE_FORMAT).format(get('date_ui'))}/>
            <LabelValue label={get('license_valid_to')}
                        value={moment(license.licenceExpirationDate, DATE_FORMAT).format(get('date_ui'))}/>
            <LabelValue label={get('license_type')} value={license.licenceCode}/>
        </Paper>
    );}

LicenseCard.propTypes = {
    license: PropTypes.object.isRequired,
    company: PropTypes.object,
    handleDelete: PropTypes.func.isRequired,
};