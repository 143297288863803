import React from 'react'

import {PropTypes} from 'prop-types'
import {makeStyles} from '@material-ui/core/styles'
import {withRouter} from 'react-router-dom'

import moment from 'moment'

import {Button, Paper, Typography} from "@material-ui/core";
import {PALE_SAND, BLUE, DEFAULT_PHOTO_HEIGHT} from "../../utils/constants";
import {ROUTE_LOANS} from "../../utils/routes";
import {get} from "../common/i18n/i18n";
import Arrow from "../../images/datesarrow_blue.png";

const useStyles = makeStyles(theme => ({
    photo: {
        flex: '0 1 auto',
        height: DEFAULT_PHOTO_HEIGHT,
        borderRadius: '4px',
        marginRight: theme.spacing(2),
        [theme.breakpoints.down('xs')]: {
            height: DEFAULT_PHOTO_HEIGHT / 2,
            marginRight: theme.spacing(1),
        },
    },
    paper: {
        display: 'flex',
        flexFlow: 'nowrap row',
        flex: '0 1 auto',
        justifyContent: 'flex-start',
        backgroundColor: PALE_SAND,
        marginBottom: theme.spacing(),
    },
    info: {
        display: 'flex',
        flex: '1 1 auto',
        flexFlow: 'nowrap column',
        alignItems: 'center',
        [theme.breakpoints.down('xs')]: {
            marginLeft: -40,
        },
    },
    title: {
        marginTop: theme.spacing(1),
        color: BLUE,
        fontWeight: 'bold',
        overflow: "hidden",
        textOverflow: "ellipsis",
        [theme.breakpoints.down('xs')]: {
            marginTop: theme.spacing(0.5),
            fontSize: 16,
        },
    },
    state: {
        transform: 'rotate(-90deg)',
        transformOrigin: '0% 0%',
        color: 'white',
        fontWeight: 'bold',
        backgroundColor: BLUE,
        borderRadius: '12px',
        alignSelf: 'flex-start',
        position: 'relative',
        top: DEFAULT_PHOTO_HEIGHT - 8,
        left: theme.spacing(),
        width: DEFAULT_PHOTO_HEIGHT - 16,
        height: 24,
        textTransform: 'upperCase',
        [theme.breakpoints.down('xs')]: {
            top: DEFAULT_PHOTO_HEIGHT / 2 - 4,
            width: DEFAULT_PHOTO_HEIGHT / 2 - 8,
            minWidth: DEFAULT_PHOTO_HEIGHT / 2 - 8,
            height: 15,
            fontSize: 8,
        },
    },
    dateContainer: {
        display: 'flex',
        flexFlow: 'nowrap row',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    date: {
        color: BLUE,
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        [theme.breakpoints.down('xs')]: {
            padding: theme.spacing(0, 1),
            fontSize: 12,
        }
    },
    arrow: {
        width: theme.spacing(4),
        [theme.breakpoints.down('xs')]: {
            width: theme.spacing(2),
        }
    },
    button: {
        background: theme.palette.common.white,
        color: BLUE,
        fontWeight: 'bold',
        borderRadius: 24,
        height: 48,
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(),
        [theme.breakpoints.down('xs')]: {
            marginTop: theme.spacing(1),
            fontSize: 8,
            height: 12,
            borderRadius: 6,
        }
    },
}));

function LoanListItem({loan, history}) {
    const classes = useStyles();
    const startDate = moment(loan.loanInitDate, 'YYYYMMDDHHmmss').format('DD/MM/YYYY');
    const endDate = moment(loan.loanEndDate, 'YYYYMMDDHHmmss').format('DD/MM/YYYY');
    const handleClick = () => history.push(ROUTE_LOANS + '/' + loan.loanId);
    return (
        <Paper className={classes.paper}>
            <img className={classes.photo} src={loan.itemPhoto} alt={loan.itemTitle}/>
            <Typography
                className={classes.state}
                style={{backgroundColor: loan.loanStatus.colorCode}}
                variant={"caption"}>
                {get(loan.loanStatus.key)}
            </Typography>
            <div className={classes.info}>
                <Typography variant={"subtitle1"} className={classes.title}>{loan.itemTitle}</Typography>
                <div className={classes.dateContainer}>
                    <Typography variant={"body1"} className={classes.date}>{startDate}</Typography>
                    <img className={classes.arrow} alt={'arrow'} src={Arrow}/>
                    <Typography variant={"body1"} className={classes.date}>{endDate}</Typography>
                </div>
                <Button className={classes.button} variant={"contained"} onClick={handleClick}>
                    {get('goto_item_detail')}
                </Button>
            </div>
        </Paper>
    )
}

LoanListItem.TypeProps = {
    loan: PropTypes.object.isRequired,
    history: PropTypes.object,
};

export default withRouter(LoanListItem);