import React, {useState} from 'react'
import PropTypes from 'prop-types'
import {makeStyles, useTheme} from '@material-ui/core/styles'
import SwipeableViews from 'react-swipeable-views'
import AppBar from '@material-ui/core/AppBar'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import Typography from '@material-ui/core/Typography'
import {PALE_BLUE} from "../../utils/constants";
import {useSelector} from "react-redux";

function TabContainer({ children, dir }) {
    return (
        <Typography component="div" dir={dir} style={{ marginTop: 0, padding: 0 }}>
            {children}
        </Typography>
    );
}

TabContainer.propTypes = {
    children: PropTypes.node.isRequired,
    dir: PropTypes.string.isRequired,
};

const useStyles = makeStyles({
    root: {
        backgroundColor: PALE_BLUE,
        width: '100%',
    },
});

export default function LiveSearchTabs({tabs}) {
    const [value, setValue] = useState(0);
    const user = useSelector(state => state.session.profile);
    const theme = useTheme();
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <AppBar position="static" color="default">
                <Tabs
                    value={value}
                    onChange={(event, value) => setValue(value)}
                    indicatorColor="primary"
                    textColor="primary"
                    variant="fullWidth"
                >
                    {tabs.map(tab => <Tab key={tab.title} label={tab.title} disabled={tab.disabled(user)}/>)}
                </Tabs>
            </AppBar>
            <SwipeableViews
                index={value}
                onChangeIndex={index => setValue(index)}
            >
                {tabs.map(tab =>
                    <TabContainer key={tab.title} dir={theme.direction}>
                        {tab.component}
                    </TabContainer>)}
            </SwipeableViews>
        </div>
    );
}

LiveSearchTabs.propTypes = {
    tabs: PropTypes.array.isRequired,
};
