import React from 'react';
import {PropTypes} from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';

import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Typography from '@material-ui/core/Typography';

import {DATE_TIME_FORMAT, LOAN_ISSUE_STATUS, MARINE} from "../../utils/constants";
import {get} from '../common/i18n/i18n';
import moment from "moment";

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
    },
    stepper: {
        padding: '0.5rem 2rem 1rem 2rem',
    },
    title: {
        color: MARINE,
        padding: '1rem 2rem',
    },

}));

function StateDate({date}) {
    if (!date) {
        return <React.Fragment/>
    }
    return (
        <Typography variant={'caption'}>
            {moment(date, DATE_TIME_FORMAT).format(get('compact_date_time_ui'))}
        </Typography>
    )
}

StateDate.propTypes = {
    date: PropTypes.string,
};

export default function IssueStatusStepper({issue}) {
    const classes = useStyles();
    const activeStep = issue.loanIssueStatus >= 4 ? 4 : issue.loanIssueStatus - 1;
    return (
        <div className={classes.root}>
            <Typography className={classes.title} variant={'h2'}>
                {get('issue_status')}
            </Typography>
            <Stepper className={classes.stepper} activeStep={activeStep} orientation="vertical">
                <Step>
                    <StepLabel>
                        <Typography variant={'h5'}>
                            {get('issue_status_' +  LOAN_ISSUE_STATUS[1])}
                        </Typography>
                        <StateDate date={issue.statusReceivedDate}/>
                    </StepLabel>
                </Step>
                <Step completed={issue.loanIssueStatus > 2 && !!issue.statusSupportProcDate}>
                    <StepLabel>
                        <Typography variant={'h5'}>
                            {get('issue_status_' +  LOAN_ISSUE_STATUS[2])}
                        </Typography>
                        <StateDate date={issue.statusSupportProcDate}/>
                    </StepLabel>
                </Step>
                <Step completed={issue.loanIssueStatus !== 3 && !!issue.statusInsuranceProcDate}>
                    <StepLabel>
                        <Typography variant={'h5'}>
                            {get('issue_status_' +  LOAN_ISSUE_STATUS[3])}
                        </Typography>
                        <StateDate date={issue.statusInsuranceProcDate}/>
                    </StepLabel>
                </Step>
                <Step>
                    <StepLabel>
                        <Typography variant={'h5'}>
                            {get('issue_status_' +  LOAN_ISSUE_STATUS[4])}
                        </Typography>
                        <StateDate date={issue.statusSolvedDate}/>
                    </StepLabel>
                </Step>
            </Stepper>
        </div>
    );
}
