import React, {Component} from 'react'
import {compose} from 'recompose'
import {withRouter} from 'react-router-dom'
import {PropTypes} from 'prop-types'
import {connect} from 'react-redux'

import {cropAndResize, readFile} from "../common/image/imageUtils";
import {showMessage} from "../common/NotificationSnack";
import {
    ACEPTADO,
    LOAN_CONFIRM_DELIVERY_BORROWER_LOAN,
    LOAN_CONFIRM_DELIVERY_OWNER_LOAN,
    LOAN_CONFIRM_RETURN_BORROWER_LOAN,
    LOAN_END_LOAN,
    ACTIVO_BORROWER,
    ACTIVO_OWNER,
    SEVERITY_ERROR,
    DEVUELTO,
    SEVERITY_SUCCESS,
    MYUR_ITEM_PHOTO_ASPECT_RATIO,
    MYUR_ITEM_PHOTO_MAX_WIDTH,
} from "../../utils/constants";
import withAuthorization from "../auth/withAuthorization";
import {buildUrl, doRequest} from "../../utils/http";
import loadImage from "blueimp-load-image";
import {get} from "../common/i18n/i18n";
import shortid from "shortid";
import LoanStartEndComponent from "./LoanStartEndComponent";
import ConfirmEndLoan from "./ConfirmEndLoan";
import {sendMessage} from "../chat/ChatComponent";
import moment from "moment";

const START_END = 'START_END';
const ACTION = 'ACTION';

const INITIAL_STATE = {
    images: [],
    loading: false,
    view: START_END,
    loan: undefined,
    data: undefined,
    rating: 0.0,
    comment: '',
    anonymous: true,
};

class LoanActionWrapper extends Component {

    constructor(props) {
        super(props);
        const messages = {
            start: {
                owner: {
                    what: get('loan_detail_start_owner_what'),
                    done: get('loan_detail_start_owner_done'),
                    now: get('loan_detail_start_owner_now'),
                    and: get('loan_detail_start_owner_and'),
                    other: get('loan_detail_start_owner_other'),
                    url: LOAN_CONFIRM_DELIVERY_OWNER_LOAN,
                    action: this.beginLoan,
                },
                borrower: {
                    what: get('loan_detail_start_borrower_what'),
                    done: get('loan_detail_start_borrower_done'),
                    now: get('loan_detail_start_borrower_now'),
                    and: get('loan_detail_start_borrower_and'),
                    other: get('loan_detail_start_borrower_other'),
                    url: LOAN_CONFIRM_DELIVERY_BORROWER_LOAN,
                    action: this.beginLoan,
                }
            },
            end: {
                owner: {
                    what: get('loan_detail_end_owner_what'),
                    done: get('loan_detail_end_owner_done'),
                    now: get('loan_detail_end_owner_now'),
                    and: get('loan_detail_end_owner_and'),
                    url: LOAN_END_LOAN,
                    method: 'PUT',
                    action: this.showConfirmationScreen,
                },
                borrower: {
                    what: get('loan_detail_end_borrower_what'),
                    done: get('loan_detail_end_borrower_done'),
                    now: get('loan_detail_end_borrower_now'),
                    and: get('loan_detail_end_borrower_and'),
                    url: LOAN_CONFIRM_RETURN_BORROWER_LOAN,
                    method: 'POST',
                    action: this.showConfirmationScreen,
                }
            }
        };
        this.action = this.getAction(props.loan);
        this.role = this.getRole(props.loan);
        this.data = messages[this.action][this.role];
        this.state = {...INITIAL_STATE};
    }

    getAction = loan => {
        const state = loan.loanStatus.key;
        switch (state) {
            case ACEPTADO:
            case ACTIVO_OWNER:
                return 'start';
            case ACTIVO_BORROWER:
            case DEVUELTO:
                return 'end';
            default:
                throw new Error(get('loan_detail_invalid_state', [state]));
        }
    };

    getRole = loan => (this.props.authUser.uid === loan.ownerId) ? 'owner' : 'borrower';

    onSelectFile = event => {
        if (event.target.files && event.target.files.length > 0) {
            const file = event.target.files[0];
            readFile(file)
                .then(image => {
                    cropAndResize(image, MYUR_ITEM_PHOTO_ASPECT_RATIO, MYUR_ITEM_PHOTO_MAX_WIDTH).then((bin) => {
                        const img = window.URL.createObjectURL(bin);
                        const newImageList = [...this.state.images, {...image, src: img, bin}];
                        this.setState({images: newImageList});
                    });
                })
                .catch(error => showMessage(error));
        }
    };

    handleAddPhoto = event => {
        if (event.target.files && event.target.files.length > 0) {
            const file = event.target.files[0];
            loadImage(file,
                img => {
                    if (img.type === "error") {
                        showMessage(get('image_error', [file.name]), SEVERITY_ERROR);
                    } else {
                        const ext = file.name.substr(file.name.lastIndexOf('.'));
                        const fileName = shortid() + ext;
                        const image = {
                            image: img,
                            fileName: fileName,
                            fileType: file.type,
                            width: img.width,
                            height: img.height,
                        };
                        cropAndResize(image, MYUR_ITEM_PHOTO_ASPECT_RATIO, MYUR_ITEM_PHOTO_MAX_WIDTH)
                            .then(bin => {
                                const img = window.URL.createObjectURL(bin);
                                this.setState(prevState => ({
                                    images: [...prevState.images,
                                        {...image, photo: img, bin, photoOrder: prevState.images.length}],
                                }));
                            });
                    }
                }, {orientation: true});
        }
    };

    handleDeletePhoto = photoToDelete => {
        this.setState(prevState => {
            const photos = prevState.images
                .filter(photo => photo.photoOrder !== photoToDelete.photoOrder)
                .map((photo, index) => ({...photo, photoOrder: index}));
            return {
                images: photos,
            }
        });
    };

    showConfirmationScreen = () => {
        this.setState({view: ACTION});
    };

    handleRatingUpdate = field => value => {
        this.setState({[field]: value});
    };

    getItemDelivery = () => {
        const uid = this.props.authUser.uid;
        const loanId = this.props.loan.loanId;

        const photos = this.state.images.map(
            image => ({
                associatedPartName: image.fileName,
                photo: image.fileName,
                photoOrder: image.photoOrder,
                loanId,
                uploaderId: uid,
            })
        );
        return {
            loanId,
            userId: uid,
            photos,
        };
    };

    beginLoan = () => {
        this.setState({loading: true});
        const {images} = this.state;
        const loan = this.props.loan;
        const loanId = loan.loanId;
        const formData = new FormData();
        const itemDelivery = this.getItemDelivery();
        const itemDeliveryDTO = new Blob([JSON.stringify(itemDelivery)], {type: "application/json"});
        formData.append('itemDeliveryDTO', itemDeliveryDTO);
        for (let i = 0; i < images.length; i++) {
            formData.append('files', images[i].bin, images[i].fileName);
        }
        doRequest(buildUrl(this.data.url, {loanId, ownerId: loan.ownerId}),
            response => this.sendPhotosToChat(get('loan_send_photos_chat_begin'), loan, response.photos),
            this.onError,
            {method: 'POST', body: formData}
        );
        this.onSuccess();
    };

    sendPhotosToChat = (msg, loan, photos) => {
        const {ownerId, borrowerId, itemId} = loan;
        const message = {
            borrowerId,
            ownerId,
            itemId,
            timestamp: moment().utc().format("YYYY-MM-DDTHH:mm:ss.SSS[Z]"),
            payloadType: "chat_text",
            payload: msg,
        }
        sendMessage(message)
            .then(response => {
                if (response.ok) {
                    photos.forEach(photo => {
                            const photoMessage = {
                                borrowerId,
                                ownerId,
                                itemId,
                                timestamp: moment().utc().format("YYYY-MM-DDTHH:mm:ss.SSS[Z]"),
                                payloadType: "chat_image",
                                payload: photo.photo,
                            }
                            sendMessage(photoMessage);
                        }
                    )
                } else {
                    throw new Error(`Error HTTP ${response.status} - ${response.statusText}`);
                }
            })
            .catch(this.onError);
    };

    endLoan = () => {
        this.setState({loading: true});
        const {images, rating, comment, anonymous} = this.state;
        const uid = this.props.authUser.uid;
        const loan = this.props.loan;

        const loanId = loan.loanId;
        const formData = new FormData();
        const itemDelivery = this.getItemDelivery();
        const endLoan = {
            itemDelivery,
            userRating: {
                loanId,
                reviewerId: uid,
                uidRating: rating,
                itemRating: rating,
                title: null,
                description: comment,
                anonymous: anonymous,
            }
        };
        const endLoanDTO = new Blob([JSON.stringify(endLoan)], {type: "application/json"});
        formData.append('endLoanDTO', endLoanDTO);
        for (let i = 0; i < images.length; i++) {
            formData.append('files', images[i].bin, images[i].fileName);
        }
        doRequest(buildUrl(this.data.url, {loanId, ownerId: loan.ownerId}),
            response => this.sendPhotosToChat(get('loan_send_photos_chat_end'), loan, response.photos),
            this.onError,
            {method: this.data.method, body: formData}
        );
        this.onSuccess();
    };

    onSuccess = response => {
        this.setState({loading: false});
        showMessage(get('saved'), SEVERITY_SUCCESS);
        this.props.history.goBack();
    };

    onError = error => {
        this.setState({loading: false});
        showMessage(error, SEVERITY_ERROR);
    };

    render() {
        const {loan} = this.props;
        const {images, loading, view, rating, comment, anonymous} = this.state;
        if (view === START_END) {
            return <LoanStartEndComponent loan={loan} loading={loading} images={images} data={this.data}
                                          handleAddPhoto={this.handleAddPhoto}
                                          handleDeletePhoto={this.handleDeletePhoto}
            />
        }
        return <ConfirmEndLoan loan={loan}
                               onEndLoanConfirmation={this.endLoan}
                               handleRatingUpdate={this.handleRatingUpdate}
                               rating={rating} comment={comment} anonymous={anonymous}/>
    }

}


LoanActionWrapper.propTypes = {
    loan: PropTypes.object.isRequired,
};

const mapStateToProps = ({session}) => ({
    authUser: session.authUser,
});

const authCondition = profile => !!profile;

export default compose(
    withRouter,
    withAuthorization(authCondition),
    connect(mapStateToProps),
)(LoanActionWrapper);