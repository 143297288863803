import React from 'react';
import {PropTypes} from "prop-types";

import {makeStyles} from "@material-ui/core/styles";
import {Button, Divider, Typography} from "@material-ui/core";

import {get} from "../../common/i18n/i18n";
import {MARINE, PALE_BLUE, RED} from "../../../utils/constants";
import CustomDateTimeComponent from '../../common/CustomDateTimeComponent';
import buttonStyles from "../../common/styles/buttonStyles";

const useStyles = makeStyles(theme => ({
    ...buttonStyles(theme),
    root: {
        background: PALE_BLUE,
        marginTop: theme.spacing(),
        padding: theme.spacing(2),
    },
    container: {
        display: 'flex',
        flexFlow: 'wrap row',
        justifyContent: 'space-between',
        alignItems: 'baseline',
        backgroundColor: theme.palette.common.white,
        borderRadius: '2rem',
        maxWidth: '26rem',
        marginLeft: 'auto',
        marginRight: 'auto',
    },
    divider: {
        margin: theme.spacing(0, 2),
        backgroundColor: MARINE,
        background: MARINE,
        color: MARINE,
        width: '2px',
        height: '1.3rem',
        flexGrow: 0,
    },
    error: {
        fontWeight: 'bold',
        color: RED,
        textAlign: 'left',
        marginTop: theme.spacing(1),
    }
}));

const NotAvailable = ({notAvailable}) => {
    if (!notAvailable) {
        return null;
    }
    return (
        <Typography align={"left"}>{get("loan_response_not_available")}</Typography>
    );
}

NotAvailable.propTypes = {
    notAvailable: PropTypes.bool.isRequired
}

export default function CheckAvailabilityComponent(props) {
    const {startDate, endDate, notAvailable, error, handleUpdateDate, checkAvailability} = props;
    const classes = useStyles();
    const dateTimeFormat = get('min_date_time_ui');
    return (
        <div className={classes.root}>
            <Typography variant={'h2'} style={{color: MARINE, marginBottom: '1em'}}>
                {get('when_needed')}
            </Typography>
            <div className={classes.container}>
                <CustomDateTimeComponent
                    ampm={false}
                    label={get('pick-up_date')}
                    style={{flexGrow: 2}}
                    value={startDate}
                    initialFocusedDate={startDate}
                    InputProps={{
                        disableUnderline: true
                    }}
                    disablePast
                    format={dateTimeFormat}
                    onChange={date => handleUpdateDate('startDate', date)}
                />
                <Divider className={classes.divider} orientation={'vertical'} variant={"middle"}/>
                <CustomDateTimeComponent
                    ampm={false}
                    label={get('return_date')}
                    style={{flexGrow: 2}}
                    value={endDate}
                    initialFocusedDate={endDate}
                    InputProps={{
                        disableUnderline: true
                    }}
                    disablePast
                    format={dateTimeFormat}
                    onChange={date => handleUpdateDate('endDate', date)}
                />
            </div>
            <Typography variant={'caption'} component="div" className={classes.error}>{get(error)}</Typography>
            <NotAvailable notAvailable={notAvailable}/>
            <Button className={classes.blueButton} onClick={checkAvailability}>
                {get('check_availability')}
            </Button>
        </div>
    );
}

CheckAvailabilityComponent.propTypes = {
    startDate: PropTypes.object.isRequired,
    endDate: PropTypes.object.isRequired,
    notAvailable: PropTypes.bool.isRequired,
    error: PropTypes.string,
    handleUpdateDate: PropTypes.func.isRequired,
    checkAvailability: PropTypes.func.isRequired,
};