import React from 'react';
import {PropTypes} from "prop-types";

import {makeStyles, Typography} from '@material-ui/core';

import {BLUE, DEFAULT_WIDTH, MARINE, PALE_SAND, SAND} from "../../utils/constants";
import {get} from "../common/i18n/i18n";
import SendIMComponent from "../chat/SendIMComponent";

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        maxWidth: DEFAULT_WIDTH,
        display: 'flex',
        flexFlow: 'nowrap column',
        alignItems: 'flex-start',
    },
    question: {
        marginLeft: theme.spacing(2),
        fontWeight: 'bold',
        color: BLUE,
    },
    owner: {
        width: '100%',
        border: `2px solid ${BLUE}`,
        backgroundColor: theme.palette.common.white,
        borderRadius: '0px 12px 12px 0px',
        display: 'flex',
        flexFlow: 'wrap row',
        justifyContent: 'space-between',
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
    messageButton: {
        display: 'flex',
        flexFlow: 'nowrap column',
        alignItems: 'center',
        fontWeight: 'bold',
        color: MARINE,
        backgroundColor: PALE_SAND,
        borderRadius: 12,
        width: 96,
        textTransform: 'upperCase',
        '&:hover': {
            backgroundColor: SAND,
        },
    },
    messageIcon: {
        width: 32,
        height: 32,
        paddingTop: theme.spacing(2),
    },
}));
export default function UserLoanIMComponent({loan, uid}) {
    const classes = useStyles();
    const label = uid === loan.ownerId ? 'borrower' : 'owner';
    const question = uid === loan.ownerId ? get('whos_asking') : get('whoses_this_item');
    const dest = {
        uid: loan[label + 'Id'],
        nickname: loan[label + 'Nickname'],
        avatar: loan[label + 'Avatar'],
        score: loan[label + 'Score'],
        latency: loan[label + 'Latency'],
    };

    return (
        <div className={classes.root}>
            <Typography variant={'h3'} className={classes.question}>{question}</Typography>
            <SendIMComponent borrowerId={loan.borrowerId} ownerId={loan.ownerId} dest={dest}
                             itemId={loan.itemId} itemTitle={loan.itemTitle} sendDisabled={false}/>
        </div>
    );
}

UserLoanIMComponent.propTypes = {
    loan: PropTypes.object.isRequired,
    uid: PropTypes.string.isRequired,
};