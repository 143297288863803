import React from 'react'

import PropTypes from 'prop-types'
import moment from 'moment'

import makeStyles from "@material-ui/core/styles/makeStyles";
import {Typography, IconButton} from "@material-ui/core";
import NextIcon from '@material-ui/icons/ArrowForwardIosRounded';

import {Link} from "react-router-dom";
import {ROUTE_LIVE_SEARCH_DETAIL} from "../../utils/routes";
import {buildUrl} from "../../utils/http";
import {get} from "../common/i18n/i18n";
import {cardStyles} from "./live_search_styles";

const useStyles = makeStyles(cardStyles);

const ButtonLink = React.forwardRef((props, ref) => <Link innerRef={ref} {...props} />);

export default function LiveSearchMyCard(props) {
    const {search} = props;
    const classes = useStyles();
    const date = moment(search.publicationDate, 'YYYYMMDDhhmmss').format(get('date_ui'));
    const time = moment(search.publicationDate, 'YYYYMMDDhhmmss').format('hh:mm:ss');
    const numberClass = search.newResponses ? classes.number : classes.title;
    return (
        <div className={classes.root}>
            <div className={classes.data}>
                <div className={classes.when}>
                    <Typography variant={'caption'} className={classes.section}>{date}</Typography>
                    <Typography variant={'caption'} className={classes.section}>{time}</Typography>
                </div>
                <div className={classes.what}>
                    <Typography variant={'caption'} className={classes.section}>{get('live_search_i_need')}</Typography>
                    <Typography variant={'body1'} className={classes.title}>{search.title}</Typography>
                </div>
                <div className={classes.response}>
                    <Typography variant={'caption'} className={classes.section}>{get('live_search_responses')}</Typography>
                    <Typography variant={'body1'} className={numberClass}>{search.responsesNum}</Typography>
                </div>
                <div className={classes.action}>
                    <IconButton component={ButtonLink}
                                to={buildUrl(ROUTE_LIVE_SEARCH_DETAIL, {specificRequestId: search.id})}>
                        <NextIcon/>
                    </IconButton>
                </div>
            </div>
        </div>
    );
}

LiveSearchMyCard.propTypes = {
    search: PropTypes.object.isRequired,
};