import React from 'react';
import {PropTypes} from 'prop-types';
import clsx from "clsx";

import Skeleton from "@material-ui/lab/Skeleton";
import {Avatar, makeStyles, Paper} from "@material-ui/core";

import {DEFAULT_AVATAR} from "../../../utils/constants";
import ScoreComponent from "../../common/ScoreComponent";
import {reviewCardStyles} from "./ReviewCardStyles";

const useStyles = makeStyles(theme => reviewCardStyles(theme));

export default function UserReviewCardSkeleton({className}) {
    const classes = useStyles();

    return (
        <Paper className={clsx(classes.root, className)} variant={"outlined"}>
            <div className={classes.user}>
                <Avatar alt={"avatar"} src={DEFAULT_AVATAR} className={classes.avatar}/>

                <div className={classes.detail}>
                    <Skeleton height={5} width={'80%'}/>
                    <Skeleton height={5} width={'80%'}/>
                </div>
                <div className={classes.rating}>
                    <ScoreComponent score={undefined} size={'16px'}/>
                </div>
            </div>
            <Skeleton height={10} width={'80%'}/>
            <Skeleton height={10} width={'60%'}/>
        </Paper>
    );
}

UserReviewCardSkeleton.propTypes = {
    className: PropTypes.string
}