import React from 'react';
import {PropTypes} from 'prop-types';
import {useHistory} from "react-router-dom";

import {Avatar, IconButton, makeStyles, Typography} from "@material-ui/core";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";

import {BLUE, DEFAULT_AVATAR, DEFAULT_USER_AVATAR_URL, DEFAULT_WIDTH} from "../../utils/constants";
import {buildUrl} from "../../utils/http";

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        maxWidth: DEFAULT_WIDTH,
        height: '4em',
    },
    header: {
        display: 'flex',
        flexFlow: 'wrap row',
        justifyContent: 'flex-start',
        alignItems: 'center',
        background: 'white',
    },
    title: {
        marginTop: theme.spacing(1),
        color: BLUE,
        fontWeight: 'bold',
    },
    backButton: {
        margin: theme.spacing(1),
    },
    chatHeader: {
        display: 'flex',
        flexFlow: 'wrap row',
        justifyContent: 'flex-start',
        alignItems: 'center',
        flexGrow: 2,
    },
    nickname: {
        fontWeight: 'bold',
        color: BLUE,
    }
}));

export default function ChatWrapper({children, uid, chat}) {
    const history = useHistory();
    const classes = useStyles();
    let {nickname, other} = !chat ?
        {nickname: '', other: ''} :
        uid === chat.ownerId ?
            {nickname: chat.borrowerNickname, other: chat.borrowerId} :
            {nickname: chat.ownerNickname, other: chat.ownerId};


    return (
        <div className={classes.root}>
            <div className={classes.header}>
                <IconButton className={classes.backButton} onClick={() => history.goBack()}>
                    <ArrowBackIcon/>
                </IconButton>
                {
                    !!chat &&
                    <React.Fragment>
                        <div className={classes.chatHeader}>
                            <Avatar alt={nickname} src={buildUrl(DEFAULT_USER_AVATAR_URL, {uid: other})}
                                    className={classes.backButton}
                                    imgProps={{
                                        onError: e => e.target.src = DEFAULT_AVATAR
                                    }}
                            />
                            <Typography className={classes.nickname}>{nickname}</Typography>
                        </div>
                        <div>
                            <Typography className={classes.nickname}>{chat.itemTitle}</Typography>
                        </div>
                    </React.Fragment>
                }
            </div>
            {children}
        </div>
    );
}

ChatWrapper.propTypes = {
    uid: PropTypes.string,
    chat: PropTypes.object,
};