import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import clsx from "clsx";

import {makeStyles, Checkbox, FormControl, FormControlLabel, InputAdornment, Paper, TextField, Typography} from '@material-ui/core'
import {DatePicker} from '@material-ui/pickers'


import {
    API_MASTER_GET_CATEGORIES,
    API_MASTER_GET_SUBCATEGORIES, API_MASTER_INSURANCE_TYPE, INSURANCE_TYPES, MAX_DESCRIPTION_CHAR,
    MAX_ITEM_PHOTOS, INSURANCE_CONDITIONS,
    PALE_SAND, EXTRA_DATA_VEHICLE, EXTRA_DATA_BOAT,
} from '../../utils/constants'
import AsyncSelect from '../common/AsyncSelect'
import {buildUrl} from "../../utils/http";
import ImageCard from "../common/image/ImageCard";
import {get} from "../common/i18n/i18n";
import {categories} from "../../utils/categories";
import layoutStyles from "../common/styles/layoutStyles";
import VehicleForm from "./VehicleForm";
import BoatForm from "./BoatForm";
import TipComponent from "../common/TipComponent";
import {depositRequired} from "./item_validation";
import {isInsurable, isServiceOrSpace} from "./item_utils";

const useStyles = makeStyles(theme => ({
    ...layoutStyles(theme),
    root: {
        background: PALE_SAND,
        width: '100%',
        marginTop: theme.spacing(2),
    },
    title: {
        textAlign: 'left',
        marginLeft: theme.spacing(1),
        marginTop: theme.spacing(1),
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        [theme.breakpoints.down('md')]: {
            width: '100%',
        },
    },
    select: {
        '&:last-of-type': {
            marginRight: theme.spacing(1),
        },
        [theme.breakpoints.down('md')]: {
            marginTop: theme.spacing(1),
            marginRight: theme.spacing(1),
        },
    },
    limitedWidth: {
        maxWidth: '9em',
        [theme.breakpoints.down('md')]: {
            maxWidth: '100%',
        },
    },
    price: {
        width: '23%',
        [theme.breakpoints.down('md')]: {
            width: '100%',
        },
    },
    grow: {
        flexGrow: 1,
    },
    row: {
        display: 'flex',
        flexFlow: 'row wrap',
        justifyContent: 'space-between',
        alignItems: 'baseline',
        width: '100%',
    },
    form: {
        display: 'flex',
        flexFlow: 'wrap column',
    },

    imageSection: {
        marginTop: theme.spacing(1),
        background: PALE_SAND,
    },
    imageContainer: {
        background: PALE_SAND,
        display: 'flex',
        flexFlow: 'row wrap',
        marginTop: theme.spacing(1),
        justifyContent: 'flex-start',
        padding: theme.spacing(1),
    },
    imagePaper: {
        width: 210,
        height: 175,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        margin: theme.spacing(1),
    },
    imageError: {
        color: 'red',
        background: PALE_SAND,
        paddingLeft: theme.spacing(2),
        paddingBottom: theme.spacing(1),
        textAlign: 'left',
    },
    button: {
        margin: theme.spacing(1),
    },
    link: {
        textDecoration: 'none',
        marginLeft: theme.spacing(1),
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(2),
        color: theme.palette.common.black,
        '&:hover': {
            textDecoration: 'underline',
        }
    },
    tip: {
        margin: theme.spacing(1),
    },
}));

export default function ItemForm(props) {
    const {
        item, user, validationErrors,
        onUpdate, onUpdateNumber,
        onSelectCategory, onSelectSubCategory,
        handleUpdatePurchaseDate,
        onSelectInsurance,
        onItemEnable,
        handleVehicleUpdate,
        handleBoatUpdate,
        handleAddPhoto, handleDeletePhoto
    } = props;
    const classes = useStyles();
    const maxDate = moment().startOf('day').add(1, 'day');

    const parentCategory = {
        parentCategory: null,
        categoryId: item.category.parentCategory || '',
        categoryCode: item.category.parentCategoryCode || '',
    };

    const purchaseDate = !!item.purchaseDate ? moment(item.purchaseDate, 'YYYY') : moment();
    const descriptionLength = !!item.description ? item.description.length : 0;
    const insurance = !!item.insurance ? item.insurance : {
        insuranceType: item.insuranceType,
        insuranceCode: INSURANCE_TYPES[item.insuranceType]
    };

    const getDepositHelper = () => {
        if (!!validationErrors.deposit) {
            return validationErrors.deposit;
        }
        // Si es profesional, no se establece fianza obligatoria
        if (!!user && !!user.companyId && !!item.category && !!item.category.depositPercent) {
            return get('item_desired_deposit', [item.category.depositPercent]);
        }
        if (!!item.category && !!item.category.depositPercent && item.category.requiredDeposit) {
            return get('item_min_deposit', [item.category.depositPercent]);
        }
        if (!!item.category && !!item.category.depositPercent && !item.category.requiredDeposit) {
            return get('item_desired_deposit', [item.category.depositPercent]);
        }
        return "";
    }

    return (
        <div>
            <Paper className={classes.root}>
                <div className={classes.form}>
                    <div className={classes.row}>
                        <TextField
                            id='title'
                            autoFocus
                            error={validationErrors.title !== ''}
                            required={true}
                            label={get('name_it')}
                            className={clsx(classes.textField, classes.half)}
                            onChange={onUpdate}
                            value={item.title || ''}
                            margin="normal"
                            helperText={validationErrors.title}
                        />
                    </div>
                    <div className={classes.row}>
                        <TextField
                            id="description"
                            label={get('description')}
                            error={validationErrors.description !== ''}
                            fullWidth
                            multiline
                            rows={4}
                            rowsMax={10}
                            value={item.description || ''}
                            onChange={onUpdate}
                            className={classes.textField}
                            margin="normal"
                            variant="outlined"
                            FormHelperTextProps={{
                                classes: {
                                    root: clsx(classes.helperText, MAX_DESCRIPTION_CHAR - descriptionLength < 20 && classes.helperTextError)
                                }
                            }}
                            helperText={`${validationErrors.description} ${descriptionLength}/${MAX_DESCRIPTION_CHAR}`}
                        />
                    </div>
                    <div className={classes.row}>
                        <AsyncSelect
                            className={clsx(classes.half, classes.select, classes.zFour)}
                            error={validationErrors.category !== ''}
                            helperText={validationErrors.category}
                            isClearable={true}
                            selected={parentCategory}
                            optionLabel={option =>
                                !!option.categoryCode ? get(option.categoryCode) : get(categories[option.categoryId])}
                            optionValue={option => option}

                            retrieveUrl={API_MASTER_GET_CATEGORIES}
                            title={get('select_category')}
                            onSelect={onSelectCategory}
                        />
                        <AsyncSelect
                            className={clsx(classes.half, classes.select, classes.zThree)}
                            error={validationErrors.subCategory !== ''}
                            helperText={validationErrors.subCategory}
                            isClearable={true}
                            selected={item.category}
                            optionLabel={option => get(option.categoryCode)}
                            optionValue={option => option}

                            retrieveUrl={buildUrl(API_MASTER_GET_SUBCATEGORIES, {categoryId: item.category.parentCategory})}
                            title={get('select_subcategory')}
                            onSelect={onSelectSubCategory}
                            disabled={!item.category.parentCategory}
                        />
                    </div>
                    {
                        !!item.category && item.category.extraDataRequired === EXTRA_DATA_VEHICLE &&
                            <VehicleForm
                                vehicle={item.vehicleDetailDTO} onUpdate={handleVehicleUpdate}
                                category={item.category}
                                validationErrors={validationErrors}
                            />
                    }
                    {
                        !!item.category && item.category.extraDataRequired === EXTRA_DATA_BOAT &&
                            <BoatForm
                                boat={item.boatDetailDTO} onUpdate={handleBoatUpdate}
                                category={item.category}
                                validationErrors={validationErrors}
                            />

                    }
                    <div className={classes.row}>

                        { // No mostramos ni fecha de compra ni precio de compra o valor actual para los espacios ni para los servicios
                            !isServiceOrSpace(item) &&
                            <>
                                <DatePicker
                                    id="purchaseDate"
                                    className={clsx(classes.textField, classes.limitedWidth)}
                                    label={get('purchase_date')}
                                    value={purchaseDate}
                                    initialFocusedDate={purchaseDate}
                                    maxDate={maxDate}
                                    disableFuture
                                    format={'YYYY'}
                                    autoOk={true}
                                    onChange={handleUpdatePurchaseDate}
                                    style={{marginLeft: '8px'}}
                                    openTo={'year'}
                                    views={['year']}
                                />
                                <TextField
                                    id='purchasePrice'
                                    className={clsx(classes.textField, classes.limitedWidth)}
                                    label={get('current_price')}
                                    error={validationErrors.purchasePrice !== ''}
                                    helperText={validationErrors.purchasePrice}
                                    type={'number'}
                                    required={item.category.insurable}
                                    onChange={onUpdateNumber('purchasePrice')}
                                    value={item.purchasePrice || ''}
                                    margin="normal"
                                    InputProps={{
                                        endAdornment: <InputAdornment position="end">€</InputAdornment>,
                                        inputProps: {
                                            style: {textAlign: "right"},
                                            min: 0,
                                        },
                                    }}
                                />
                            </>
                        }
                        <TextField
                            id='location'
                            label={get('location')}
                            className={clsx(classes.textField, classes.grow)}
                            onChange={onUpdate}
                            value={item.location || ''}
                            margin="normal"
                        />
                        <TextField
                            id='zipcode'
                            className={clsx(classes.textField, classes.limitedWidth)}
                            label={get('zipcode')}
                            onChange={onUpdate}
                            value={item.zipcode || ''}
                            margin="normal"
                        />
                    </div>
                    <div className={classes.row}>
                        {isInsurable(item) &&
                            <AsyncSelect
                                className={clsx(classes.quarter, classes.select, classes.zTwo)}
                                error={validationErrors.insurance !== ''}
                                helperText={
                                    `${validationErrors.insurance}. 
                                    ${(item.category.insurable && !item.purchasePrice ? get('mandatory_item_purchase_price') : '')}`}
                                selected={insurance}
                                optionLabel={option => !!option && option.insuranceCode ? get(`insurance_type_${option.insuranceCode}`) : ''}
                                optionValue={option => option}
                                isClearable={true}
                                retrieveUrl={buildUrl(API_MASTER_INSURANCE_TYPE, {subCategoryId: item.category.categoryId})}
                                title={get('select_insurance')}
                                onSelect={onSelectInsurance}
                                disabled={!item.category.categoryId || item.category.insurable === false || !item.purchasePrice}
                            />
                        }
                        <TextField
                            id='deposit'
                            className={clsx(classes.limitedWidth, classes.textField)}
                            error={validationErrors.deposit !== ''}
                            helperText={getDepositHelper()}
                            label={get('deposit')}
                            type={'number'}
                            onChange={onUpdateNumber('deposit')}
                            value={item.deposit || ''}
                            required={depositRequired(item)}
                            margin="normal"
                            InputProps={{
                                endAdornment: <InputAdornment position="end">€</InputAdornment>,
                                inputProps: {
                                    style: {textAlign: "right"},
                                    min: 0,
                                },
                            }}
                        />
                    </div>
                    <div className={classes.row}>
                        {
                            isInsurable(item) && !!item.insuranceType &&
                            <a className={classes.link}
                               href={INSURANCE_CONDITIONS[insurance.insuranceCode]}
                               target="_blank" rel="noopener noreferrer">
                                {get('insurance_conditions', [get(`insurance_type_${insurance.insuranceCode}`)])}
                            </a>
                        }
                    </div>
                    {!!item.id &&
                        <div className={classes.row}>
                            <FormControl className={classes.textField}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={item.enabled}
                                            onChange={onItemEnable}
                                            value={item.enabled}
                                            color={'primary'}
                                            label={'nonlnslns'}
                                        />
                                    }
                                    label={get('item_enable_option')}
                                />
                            </FormControl>
                        </div>
                    }
                    <div className={classes.row}>
                        <TextField
                            id='hourlyPrice'
                            className={clsx(classes.price, classes.textField)}
                            required={false}
                            error={validationErrors.hourlyPrice !== ''}
                            helperText={validationErrors.hourlyPrice}
                            label={get('hourly_price')}
                            type={'number'}
                            onChange={onUpdateNumber('hourlyPrice')}
                            value={item.hourlyPrice || ''}
                            margin="normal"
                            InputProps={{
                                endAdornment: <InputAdornment position="end">€</InputAdornment>,
                                inputProps: {
                                    style: {textAlign: "right"},
                                    min: 1,
                                },
                            }}
                        />
                        <TextField
                            id='dailyPrice'
                            className={clsx(classes.price, classes.textField)}
                            required={true}
                            error={validationErrors.dailyPrice !== ''}
                            helperText={validationErrors.dailyPrice}
                            label={get('daily_price')}
                            type={'number'}
                            onChange={onUpdateNumber('dailyPrice')}
                            value={item.dailyPrice || ''}
                            margin="normal"
                            InputProps={{
                                endAdornment: <InputAdornment position="end">€</InputAdornment>,
                                inputProps: {
                                    style: {textAlign: "right"},
                                    min: 1,
                                },
                            }}
                        />
                        <TextField
                            id='weeklyPrice'
                            className={clsx(classes.price, classes.textField)}
                            required={false}
                            error={validationErrors.weeklyPrice !== ''}
                            helperText={validationErrors.weeklyPrice}
                            label={get('weekly_price')}
                            type={'number'}
                            onChange={onUpdateNumber('weeklyPrice')}
                            value={item.weeklyPrice || ''}
                            margin="normal"
                            InputProps={{
                                endAdornment: <InputAdornment position="end">€</InputAdornment>,
                                inputProps: {
                                    style: {textAlign: "right"},
                                    min: 1,
                                },
                            }}
                        />
                        <TextField
                            id='monthlyPrice'
                            className={clsx(classes.price, classes.textField)}
                            required={false}
                            error={validationErrors.monthlyPrice !== ''}
                            helperText={validationErrors.monthlyPrice}
                            label={get('monthly_price')}
                            type={'number'}
                            onChange={onUpdateNumber('monthlyPrice')}
                            value={item.monthlyPrice || ''}
                            margin="normal"
                            InputProps={{
                                endAdornment: <InputAdornment position="end">€</InputAdornment>,
                                inputProps: {
                                    style: {textAlign: "right"},
                                    min: 1,
                                },
                            }}
                        />
                    </div>
                    <div className={classes.row}>
                        <TipComponent className={classes.tip}>
                            <Typography>{get('item_multiprice_help_1')}</Typography>
                            <Typography>{get('item_multiprice_help_2')}</Typography>
                        </TipComponent>
                    </div>
                </div>
            </Paper>
            <Paper className={clsx(classes.defaultColumn, classes.imageSection)}>
                <div className={classes.imageContainer}>
                    {
                        item.photos.map(
                            photo =>
                                <ImageCard
                                    key={photo.photoOrder}
                                    photo={photo}
                                    deleteOption={true}
                                    handleDelete={handleDeletePhoto}
                                    changeOption={true}
                                    handleChange={handleAddPhoto}
                                />
                        )
                    }
                    {
                        item.photos.length < MAX_ITEM_PHOTOS && (
                            <label htmlFor={'file_image_add'}>
                                <Paper className={classes.imagePaper}>
                                    <input
                                        hidden={true}
                                        type={'file'}
                                        name={'image'}
                                        id={'file_image_add'}
                                        onChange={handleAddPhoto}
                                        accept={'image/png, image/jpeg'}
                                    />
                                    <Typography variant={'body2'}>{get('item_select_images')}</Typography>
                                </Paper>
                            </label>
                        )
                    }
                </div>
                <Typography variant={'caption'} className={classes.imageError}>{validationErrors.photos}</Typography>
            </Paper>
        </div>
    );
}

ItemForm.propTypes = {
    item: PropTypes.object,
    user: PropTypes.object,
    validationErrors: PropTypes.object.isRequired,
    onSelectCategory: PropTypes.func.isRequired,
    onSelectSubCategory: PropTypes.func.isRequired,
    onSelectInsurance: PropTypes.func.isRequired,
    handleVehicleUpdate: PropTypes.func.isRequired,
    handleAddPhoto: PropTypes.func.isRequired,
    handleDeletePhoto: PropTypes.func.isRequired,
    onUpdate: PropTypes.func.isRequired,
    onItemEnable: PropTypes.func.isRequired,
    onUpdateNumber: PropTypes.func.isRequired,
    //onInsuranceConditionsAccepted: PropTypes.func.isRequired,
};