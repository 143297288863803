import React from 'react';
import PropTypes from 'prop-types';

import {makeStyles, Typography} from "@material-ui/core";
import {MARINE, RED, SAND} from "../../utils/constants";
import WarningIcon from '@material-ui/icons/Warning';

const useStyles = makeStyles({
    root: {
        display: 'flex',
        flexFlow: 'nowrap column',
        alignItems: 'center',
        zIndex: 10,
        minWidth: 64,
    },
    state: props => ({
        fontWeight: 'bold',
        color:  props.error ? RED : !!props.date ? props.color : SAND,
        textTransform: 'lowerCase',
    }),
    date: props => ({
        display: 'flex',
        flexFlow: 'nowrap column',
        justifyContent: 'center',
        alignItems: 'center',
        alignContent: 'center',
        borderRadius: '50%',
        height: '4rem',
        width: '4rem',
        backgroundColor: !!props.date ? props.color : SAND,
        fontWeight: 'bold',
        color: MARINE,
        textAlign: 'center',
    }),
    error: {
        display: 'flex',
        flexFlow: 'nowrap column',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: '50%',
        height: '4rem',
        width: '4rem',
        backgroundColor: 'white',
        fontWeight: 'bold',
        fontSize: 64,
        color: RED,
    }
});

export default function LoanStateComponent(props) {
    const {state, date, hour, error} = props;
    const classes = useStyles(props);
    const dateVariant = !!hour ? 'caption' : 'body2';
    if (!!error) {
        return errorComponent(state, classes);
    }
    return (
        <div className={classes.root}>
            <Typography className={classes.state} variant={'body2'}>{state}</Typography>
            <Typography className={classes.date} variant={dateVariant}>{date} {hour}</Typography>
        </div>
    );
}

function errorComponent(state, classes) {
    return (
        <div className={classes.root}>
            <Typography className={classes.state} variant={'body2'}>{state}</Typography>
            <div className={classes.error}>
                <WarningIcon fontSize={'large'}/>
            </div>
        </div>
    );
}

LoanStateComponent.propTypes = {
    state: PropTypes.string.isRequired,
    date: PropTypes.string,
    hour: PropTypes.string,
    color: PropTypes.string,
    error: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
};