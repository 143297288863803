import React, {useState, useEffect} from 'react'
import {PropTypes} from 'prop-types'
import {withRouter} from 'react-router-dom'
import {compose} from "recompose";
import {connect} from "react-redux";

import {makeStyles} from "@material-ui/core/styles";
import {buildUrl, doRequest} from "../../utils/http";
import {showMessage} from "../common/NotificationSnack";
import {PALE_SAND, BLUE} from "../../utils/constants";
import withAuthorization from "../auth/withAuthorization";
import {get} from "../common/i18n/i18n";
import {API_LIVE_GET_SEARCH} from "../../utils/constants";
import LiveSearchResponseHeader from "./LiveSearchResponseHeader";
import {Grid, Typography} from "@material-ui/core";
import ItemCard from "../item/ItemCard";
import MyurWaitingComponent from "../common/MyurWaitingComponent";
import layoutStyles from "../common/styles/layoutStyles";

const useStyles = makeStyles (theme => ({
    ...layoutStyles(theme),
    items: {
        width: "100%",
        padding: theme.spacing(1),
        display: 'flex',
        flexFlow: 'wrap row',
        justifyContent: 'flex-start',
    },
    content: {
        backgroundColor: PALE_SAND,
    },
    title: {
        color: BLUE,
        fontWeight: 'bold',
        margin: theme.spacing(),
        textTransform: 'capitalize',
        textAlign: 'center',
    },
}));

function LiveSearchResponseComponent(props) {

    const [liveSearch, setLiveSearch] = useState(null);
    const [waiting, setWaiting] = useState(false);
    const classes = useStyles();
    const searchId = props.match.params.specificRequestId;
    const authUser = props.authUser;

    useEffect(() => {
        if (!!authUser){
            setWaiting(true);
            doRequest(
                buildUrl(API_LIVE_GET_SEARCH, {uid: authUser.uid, id: searchId}),
                liveSearch => {setLiveSearch(liveSearch); setWaiting(false);},
                error => {setWaiting(false); showMessage(error);},
                {method: 'GET'}
            );
        }
    }, [searchId, authUser]);

    if (liveSearch === null) {
        return <Typography className={classes.title}>{get('user_no_live_search')}</Typography>
    }
    const responses = liveSearch.specificResponseDTO;
    return (
        <div className={classes.component}>
            <MyurWaitingComponent open={waiting}/>
            <div className={classes.content}>
                <LiveSearchResponseHeader liveSearch={liveSearch}/>
                <div>
                    <Typography variant={'h6'} align={'left'} className={classes.title}>
                        {get('responses')}
                    </Typography>
                    <Grid container spacing={1} className={classes.items}>
                        {!!responses && responses.map(response =>
                            <ItemCard key={response.item.id} item={response.item} gridProps={{xs: 6, sm: 4, md: 3}}/>)}
                    </Grid>
                </div>
            </div>
        </div>
    );
}

LiveSearchResponseComponent.propTypes = {
    authUser: PropTypes.object,
};

const mapStateToProps = ({session}) => ({
    authUser: session.authUser,
});

const authCondition = profile => !!profile;

export default compose (
    connect(mapStateToProps),
    withAuthorization(authCondition),
    withRouter,
)(LiveSearchResponseComponent);