import {withStyles} from "@material-ui/core/styles";
import {BLUE, DEFAULT_MAX_WIDTH, STORE_APP_STORE, STORE_GOOGLE_PLAY} from "../../utils/constants";
import buttonStyles from "../common/styles/buttonStyles";
import {useHistory} from "react-router-dom";
import {ROUTE_SIGN_UP} from "../../utils/routes";
import {Button, Grid, Hidden, Typography} from "@material-ui/core";
import {get} from "../common/i18n/i18n";
import React from "react";
import googlePlay from "../../images/google-play-badge.png";
import appStore from "../../images/Download_on_the_App_Store_Badge.svg";

export const SignUp = withStyles(theme => ({
    root: {
        boxSizing: "border-box",
        width: "100%",
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(2),
    },
    blue: {
        color: BLUE,
    },
    ...buttonStyles(theme),
}))(({classes}) => {
    const history = useHistory();
    const handleSignUp =  () => history.push(ROUTE_SIGN_UP);
    return (
        <Grid container direction={"column"} spacing={1} className={classes.root} alignItems={"center"}>
            <Hidden smDown>
                <Grid item>
                    <Typography variant="h1" align={"center"} className={classes.blue}>
                        {get('landing_sign_up_want_more')}
                    </Typography>
                </Grid>
                <Grid item>
                    <Typography variant="h1" align={"center"} className={classes.blue}>
                        {get('landing_sign_up_register_for_free')}
                    </Typography>
                </Grid>
            </Hidden>
            <Grid item>
                <Button className={classes.blueButton} onClick={handleSignUp}>
                    {get('landing_sign_up_register')}
                </Button>
            </Grid>
        </Grid>
    );
});

export const AppLink = withStyles(theme => ({
    app: {
        display: "flex",
        flexFlow: "nowrap column",
        alignItems: "center",
    },
    download: {
        textTransform: "uppercase",
    },
}))(({classes}) => {
    return (
        <Grid container direction={"row"}>
            <Grid item xs={1}/>
            <Grid container item xs={10} direction={"column"} alignItems={"center"} spacing={1}>
                <Hidden mdUp>
                    <Grid item>
                        <Typography variant={"h6"} align={"center"}>
                            {get('landing_app_link_keep_browsing')}
                        </Typography>
                    </Grid>
                </Hidden>
                <Grid item>
                    <Typography variant="h3" className={classes.download} align={"center"}>
                        {get('landing_app_link_download')}
                    </Typography>
                </Grid>
                <Grid item>
                    <a href={STORE_GOOGLE_PLAY}>
                        <img width={180} alt='google play' src={googlePlay}/>
                    </a>
                </Grid>
                <Grid item>
                    <a href={STORE_APP_STORE}>
                        <img width={180} alt={'app store'} src={appStore}/>
                    </a>
                </Grid>
            </Grid>
            <Grid item xs={1}/>
        </Grid>
    );
});

export const Disclaimer = withStyles(theme => ({
    root: {
        boxSizing: "border-box",
        padding: theme.spacing(3, 0),
        width: "100%",
        maxWidth: DEFAULT_MAX_WIDTH,
    },
    disclaimer: {
        boxSizing: "border-box",
        padding: theme.spacing(2),
        display: "flex",
        flexFlow: "nowrap column",
        alignItems: "center",
    },
    wrapper: {
        padding: theme.spacing(0, 2),
    },
}))(({classes}) => {
    return (
        <Grid container direction={"row"} justifyContent={"center"} spacing={2} className={classes.root}>
            <Grid item xs={12} md={6} className={classes.disclaimer}>
                <div className={classes.wrapper}>
                    <Typography variant="h1" align={"left"}>
                        {get('landing_disclaimer_welcome')}
                    </Typography>
                    <Typography variant={"h6"} align={"left"}>
                        {get('landing_disclaimer_dont_buy')}
                    </Typography>
                    <Typography variant={"h6"} align={"left"}>
                        {get('landing_disclaimer_profit')}
                    </Typography>
                    <Typography variant={"h6"} align={"left"}>
                        {get('landing_disclaimer_no_worries')}
                    </Typography>
                    <Typography variant={"h6"} align={"left"}>
                        {get('landing_disclaimer_be_paid')}
                    </Typography>
                </div>
            </Grid>
            <Hidden smDown>
                <Grid item md={6}>
                    <AppLink/>
                </Grid>
            </Hidden>
        </Grid>
    );
})
