import React from 'react';
import {makeStyles} from "@material-ui/core/styles";

import {get} from '../common/i18n/i18n';
import walrus from '../../images/walrus_small.png';

const useStyles = makeStyles(theme => ({
    main: {
        color: '#888',
        margin: 0,
        display: 'table',
        width: '100%',
        height: '100vh',
        textAlign: 'center',
    },

    fof: {
        display: 'table-cell',
        verticalAlign: 'middle',
    },
}));

export default function NotFound({props}) {
    const classes = useStyles();
    return (
        <div className={classes.main}>
            <div className={classes.fof}>
                <img alt={'Myur!'} src={walrus}/>
                <h1>{get('page_not_found')}</h1>
            </div>
        </div>
    );
}