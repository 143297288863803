import React from 'react'
import {PropTypes} from 'prop-types'
import {Link} from "react-router-dom";

import {Typography} from "@material-ui/core";
import {makeStyles} from '@material-ui/styles'

import {BLUE, SAND, DATE_TIME_FORMAT} from "../../../utils/constants";
import {ROUTE_ITEM, ROUTE_LOAN, ROUTE_LIVE_SEARCH_DETAIL, ROUTE_PROFILE} from "../../../utils/routes";
import NotificationIcon from '../../../images/icon_prestamos.svg';
import {buildUrl} from "../../../utils/http";
import {get} from "../../common/i18n/i18n";
import clsx from "clsx";
import moment from "moment";
import layoutStyles from "../../common/styles/layoutStyles";

const useStyles = makeStyles(theme => ({
    ...layoutStyles(theme),
    paper: {
        display: 'flex',
        flexFlow: 'no-wrap row',
        justifyContent: 'flex-start',
        backgroundColor: theme.palette.common.white,
        marginBottom: theme.spacing(),
        padding: theme.spacing(),
        borderTop: `2px solid ${SAND}`
    },
    photo: {
        height: '2rem',
        borderRadius: '4px',
        fill: BLUE,
        margin: theme.spacing(),
    },
    info: {
        width: '100%',
        textAlign: 'left',
    },
    link: {
        textDecoration: 'none'
    },
    header: {
        display: 'flex',
        flexFlow: 'wrap row',
        justifyContent: 'space-between',
    },
    title: {
        marginTop: theme.spacing(),
        color: BLUE,
    },
    unread: {
        backgroundColor: theme.palette.common.white,
        color: BLUE,
        '& h4': {
            fontWeight: theme.typography.h4.fontWeight + 200,
        },
        '& p': {
            fontWeight: theme.typography.body1.fontWeight + 200,
        },
    }
}));

const NOTIFICATION_TYPES= {
    loan_ask: ROUTE_LOAN,
    loan_cancel: ROUTE_LOAN,
    loan_deny: ROUTE_LOAN,
    loan_authorized: ROUTE_LOAN,
    loan_accept: ROUTE_LOAN,
    loan_started_by_owner: ROUTE_LOAN,
    loan_started_by_user: ROUTE_LOAN,
    less_than_30_hours: ROUTE_LOAN,
    less_than_30_hours_owner: ROUTE_LOAN,
    less_than_30_hours_borrower: ROUTE_LOAN,
    expired_loan: ROUTE_LOAN,
    loan_ended_by_user: ROUTE_LOAN,
    loan_ended_by_owner: ROUTE_LOAN,
    error_on_accept: ROUTE_LOAN,
    extension_ask: ROUTE_LOAN,
    extension_deny: ROUTE_LOAN,
    extension_accept: ROUTE_LOAN,
    unused_loan: ROUTE_LOAN,
    unconfirmed_delivery: ROUTE_LOAN,
    unconfirmed_return: ROUTE_LOAN,
    cancelled_by_support: ROUTE_LOAN,
    finalized_by_support: ROUTE_LOAN,
    overdue_loan_owner: ROUTE_LOAN,
    overdue_loan_borrower: ROUTE_LOAN,
    loan_must_start_owner: ROUTE_LOAN,
    loan_must_start_borrower: ROUTE_LOAN,
    less_than_2_hours_owner: ROUTE_LOAN,
    less_than_2_hours_borrower: ROUTE_LOAN,
    loan_must_start_1h_owner: ROUTE_LOAN,
    loan_must_start_1h_borrower: ROUTE_LOAN,
//    payment_card_error: '',
//    expired_payment_card: '',
//    model_347: '',
    response_sr: ROUTE_LIVE_SEARCH_DETAIL,
    expired_sr: ROUTE_LIVE_SEARCH_DETAIL,
    no_longer_insurable: ROUTE_ITEM,
    bank_account_error: ROUTE_PROFILE,
    pro_add_phone: ROUTE_PROFILE
};

const onNotification = notification => {
    const title = get(notification.title);
    const regex = /%(.*?)%/g;
    const i18nKey = notification.text.match(regex)[0];
    const parameters = notification.text.replace(regex, '%%').split('%%').filter(x => x).map(x => x.trim());
    return {
        title,
        text: get(i18nKey, parameters),
    };
};

export default function NotificationCard({notification}) {
    const classes = useStyles();

    const _notification = onNotification(notification);
    const date = moment(notification.notificationDate, DATE_TIME_FORMAT).calendar(null, {
        sameDay: 'hh:mm a',
        lastDay: `[${get('yesterday')}]`,
        lastWeek: 'DD/MM/YYYY',
        sameElse: 'DD/MM/YYYY'
    });

    return (
        <div className={clsx(classes.paper, classes.border, !notification.watched && classes.unread)}>
            <img className={classes.photo} src={NotificationIcon} alt={notification.title}/>
            <div className={classes.info}>
                <Link
                    to={buildUrl(NOTIFICATION_TYPES[notification.type], {[notification.referenceName]: notification.referenceId})}
                    className={classes.link}>
                    <div className={classes.header}>
                        <Typography variant={'h4'} className={clsx(classes.title, !notification.watched && classes.unread)}>
                            {_notification.title}
                        </Typography>
                        <Typography variant={'body2'} className={clsx(classes.title, !notification.watched && classes.unread)}>
                            {date}
                        </Typography>
                    </div>
                    <Typography variant={'body1'} className={clsx(classes.title, !notification.watched && classes.unread)}>
                        {_notification.text}
                    </Typography>
                </Link>

            </div>
        </div>
    )
}

NotificationCard.TypeProps = {
    notification: PropTypes.object.isRequired,
};
