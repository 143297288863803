import {FIREBASE_AUTHENTICATED, GOT_USER_PUBLIC_PROFILE, REQUEST_USER_PUBLIC_PROFILE} from "./actionTypes";
import {showMessage} from "../components/common/NotificationSnack";
import {buildUrl, doFetch, doGet} from "../utils/http";
import {API_MASTER_ZIPCODE_COORDINATES, API_USER_EXISTS, SEVERITY_ERROR} from "../utils/constants";
import {saveProfile} from "../utils/localStorage";
import {getOrigin} from "../reducers";
import {updateGeoPosition} from "./searchActions";

export const firebaseAuthenticatedActionCreator = authUser => ({
    type: FIREBASE_AUTHENTICATED,
    authUser,
});

export const retrieveUserPublicProfileActionCreator = uid => ({
    type: REQUEST_USER_PUBLIC_PROFILE,
    uid,
});

const gotUserPublicProfileActionCreator = profile => ({
    type: GOT_USER_PUBLIC_PROFILE,
    profile,
});

export const firebaseAuthenticated =  authUser => dispatch => dispatch(firebaseAuthenticatedActionCreator(authUser));

export const retrieveUserPublicProfile = uid => (dispatch, getState) => {
    dispatch(retrieveUserPublicProfileActionCreator(uid));
    return doFetch(
        buildUrl(API_USER_EXISTS, {uid}),
        gotUserPublicProfile(dispatch, getState),
        onRetrieveUserPublicProfileError,
        {method: 'GET'}
    );
};

const onRetrieveUserPublicProfileError = error => {
    saveProfile(null);
    showMessage(error, SEVERITY_ERROR);
}

const gotUserPublicProfile = (dispatch, getState) => profile => {
    if (!!profile && !!profile.uid) {
        saveProfile(profile);
        dispatch(gotUserPublicProfileActionCreator(profile));
        const origin = getOrigin(getState());
        if (!origin.lat || !origin.lng) {
            doGet(buildUrl(API_MASTER_ZIPCODE_COORDINATES, {zipcode: profile.zipCode}))
                .then(result => dispatch(updateGeoPosition(result.latitude, result.longitude)))
                .catch(error => console.error(error));
        }
    } else {
        saveProfile(null);
    }
};

export const userAuthenticated = authUser => dispatch => {
    if (!!authUser) {
        dispatch(firebaseAuthenticated(authUser));
        return dispatch(retrieveUserPublicProfile(authUser.uid));
    } else {
        saveProfile(null);
    }
    return dispatch(firebaseAuthenticated(authUser));
};