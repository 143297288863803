import React, {useState} from 'react';

import {Button, Dialog, DialogTitle, DialogActions, DialogContent, DialogContentText} from '@material-ui/core';

import PropTypes from "prop-types";
import {get} from "./i18n/i18n";

export default function ConfirmationDialog(props) {

    const {onConfirmation, title, content, button, className, size, ...other} = props;
    const [open, setOpen] = useState(false);

    const handleClickOpen = () => setOpen(true);

    const handleClose = () => setOpen(false);

    const handleOk = () => {
        onConfirmation();
        handleClose();
    };

    return (
        <div>
            {
                !!button ?
                    React.createElement(
                        button,
                        {className: !!className ? className : '', onClick: handleClickOpen},
                        null)
                    :
                    <Button className={!!className ? className : ''}
                            size={size}
                            variant="contained" color="primary" onClick={handleClickOpen}>
                        {get(open)}
                    </Button>
            }
            <Dialog fullWidth={true}
                    maxWidth={'sm'}
                    disableBackdropClick
                    disableEscapeKeyDown
                    open={open}
                    {...other}
            >
                <DialogTitle>{title}</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {content}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="secondary" variant={"outlined"}>
                        {get('CANCEL')}
                    </Button>
                    <Button onClick={handleOk} color="primary" variant={"contained"}>
                        {get('ACCEPT')}
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

ConfirmationDialog.propTypes = {
    title: PropTypes.string.isRequired,
    content: PropTypes.string.isRequired,
    onConfirmation: PropTypes.func.isRequired,
    className: PropTypes.string,
    button: PropTypes.any,
    size: PropTypes.oneOf(['small', 'medium', 'large']),
};

ConfirmationDialog.defaultProps = {
    size: 'medium',
};