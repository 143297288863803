import React from 'react';
import PropTypes from 'prop-types';
import {Button, makeStyles, Typography} from "@material-ui/core";
import {auth, sendEmailVerification, userReload} from "../../utils/firebase";
import {showMessage} from "../common/NotificationSnack";
import {SEVERITY_INFO} from "../../utils/constants";
import {get} from "../common/i18n/i18n";

VerifyEmailComponent.propTypes = {
    email: PropTypes.string,
    verifiedEmail: PropTypes.bool,
    onChange: PropTypes.func.isRequired,
    onError: PropTypes.func.isRequired,
};

const useStyles = makeStyles(theme => ({
    text: {
        marginTop: theme.spacing(1),
        color: theme.palette.common.white,
    },
    button: {
        margin: theme.spacing(1, 2),
        color: theme.palette.common.white,
    }
}));

function VerifyEmailComponent({email, verifiedEmail, onChange, onError}) {

    const classes = useStyles();

    const reSendVerificationEmail = () =>
        sendEmailVerification(
            () => showMessage(get('verify_email_sent'), SEVERITY_INFO),
            onError)

    const onDone = () =>
        userReload()
            .then(() => onChange(!!auth.currentUser && auth.currentUser.emailVerified))
            .catch(onError);

    return (
        <div>
            <Typography className={classes.text} variant={"h5"}>
                {get('verify_email_verification', [email])}
            </Typography>
            <Button className={classes.button} onClick={reSendVerificationEmail} disabled={verifiedEmail} variant={"outlined"}>
                {get('verify_email_resend')}
            </Button>
            <Button className={classes.button} onClick={onDone} disabled={verifiedEmail} variant={"outlined"}>
                {get('verify_email_done')}
            </Button>
        </div>
    );
}

export default VerifyEmailComponent;