import React from 'react'
import PropTypes from "prop-types";

import {withStyles} from '@material-ui/core/styles'
import {API_LIVE_GET_LIVE_SEARCHES, DEFAULT_MAX_WIDTH, PALE_BLUE} from "../../utils/constants";
import {Typography} from "@material-ui/core";
import LiveSearchNewSubmissionBanner from "./LiveSearchNewSubmissionBanner";
import {get} from "../common/i18n/i18n";
import ItemSkeleton from "../user/item/ItemSkeleton";
import {showMessage} from "../common/NotificationSnack";
import {buildUrl, doFetch, doRequest} from "../../utils/http";
import LiveSearchCard from "./LiveSearchCard";
import {getUid} from "../../utils/utils";

const styles = theme => ({
    root: {
        width: '100%',
        maxWidth: DEFAULT_MAX_WIDTH,
        background: PALE_BLUE,
    },
});

const INITIAL_STATE = {
    waiting: false,
    found: 0,
    start: 0,
    liveSearches: [],
};

class LiveSearchBoard extends React.Component {

    constructor(props) {
        super(props);
        this.state = {...INITIAL_STATE};
    }

    componentDidMount() {
        this.retrieveLiveSearches();
    }

    retrieveLiveSearches = () => {
        this.setState({waiting: true});
        const uid = getUid();
        const request = !!uid ? doRequest : doFetch;
        request(
            buildUrl(API_LIVE_GET_LIVE_SEARCHES, {start: 0, count: 1000}),
            this.onLiveSearchesRetrieved,
            this.onError,
            {method: 'GET'}
        );
    };

    onLiveSearchesRetrieved = response => {
        this.setState({
            found: response.numFound,
            start: response.start,
            liveSearches: response.specificRequests,
            waiting: false
        });
    };

    onError = error => {
        this.setState({waiting: false});
        showMessage(error);
    };

    render() {
        const {classes} = this.props;
        const {waiting, liveSearches} = this.state;
        const uid = getUid();

        if (waiting) {
            return this.waiting();
        }
        if (liveSearches === []) {
            return this.noItems();
        }
        return (
            <div className={classes.root}>
                <LiveSearchNewSubmissionBanner/>
                {liveSearches.map(search => <LiveSearchCard key={search.id} search={search} uid={uid}/>)}
            </div>
        );
    }

    noItems() {
        return (
            <div className={this.props.classes.root}>
                <LiveSearchNewSubmissionBanner/>
                <Typography variant={"h6"}>{get('user_no_items')}</Typography>
            </div>
        );
    }

    waiting() {
        return (
            <div className={this.props.classes.root}>
                <LiveSearchNewSubmissionBanner/>
                {[0, 1, 2].map(index => <ItemSkeleton key={index}/>)}
            </div>
        );
    }
}

LiveSearchBoard.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(LiveSearchBoard);
