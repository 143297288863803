import {DATE_TIME_FORMAT, DEFAULT_AVATAR} from "./constants";
import * as moment from 'moment';
import {LS_UID} from "./localStorage";

export const boolean2human = value => value === true ? 'yes' : 'no';

export const getAvatar = url => url !== null ? url : DEFAULT_AVATAR;

export const getUid = () => localStorage.getItem(LS_UID);

export const no_op = () => undefined;

export const isFunction = obj => !!(obj && obj.constructor && obj.call && obj.apply);

export const isString = string => typeof(string) === 'string' || string instanceof String;

export const isTodayOrAfter = date => moment().isSameOrAfter(moment(date, DATE_TIME_FORMAT),'day');

export const sleep =  ms => new Promise(resolve => setTimeout(resolve, ms));

/**
 * Prints current time in format mm:ss.SSS. Just for debugging timers.
 * @returns {string} current time in format mm:ss.SSS
 */
export const t = () =>  moment().format("mm:ss.SSS");

// https://developer.mozilla.org/es/docs/Web/JavaScript/Reference/Global_Objects/Array/from#generador_de_secuencia_(rango)
export const range = (start, stop, step) => Array.from({ length: (stop - start) / step + 1}, (_, i) => start + (i * step));

export function getBrowserGeoPosition() {
    const defaultValue = {lat: null, lng: null};
    if (navigator.geolocation) {
        return new Promise((resolve, reject) => navigator.geolocation.getCurrentPosition(resolve, reject))
    } else {
        return new Promise(resolve => resolve(defaultValue))
    }
}

export const generateOrderId = () => {
    const characters = '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ';
    let orderId = Math.floor(Math.random() * 1000).toString().padStart(4, '0');

    for (let i = 0; i < 8; i++ ) {
        orderId += characters.charAt(Math.floor(Math.random() * characters.length));
    }
    return orderId;
}

export const generateComponentId = (suffix, length) => {
    const characters = '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ';
    let componentId = suffix;

    for (let i = 0; i < length; i++ ) {
        componentId += characters.charAt(Math.floor(Math.random() * characters.length));
    }
    return componentId;
}

/**
 * Waits at most ms milliseconds for a promise to resolve. After ms milliseconds, the promise is rejected.
 * @param ms Max milliseconds to wait.
 * @param promise to resolve within ms milliseconds.
 * @returns {Promise<*|Promise<unknown> extends PromiseLike<infer U> ? U : (*|Promise<unknown>)>}
 */
export const promiseTimeout = function(ms, promise){
    // Create a promise that rejects in <ms> milliseconds
    let timeout = new Promise((resolve, reject) => {
        let id = setTimeout(() => {
            clearTimeout(id);
            reject('Timed out in '+ ms + 'ms.')
        }, ms)
    });

    // Returns a race between our timeout and the passed in promise
    return Promise.race([
        promise,
        timeout
    ]);
}

/**
 *
 * @param input String
 * @returns {string|string}
 */
export const sanitize = input =>
    !!input ? input.toLowerCase().trim().normalize("NFD").replace(/[\u0300-\u036f]/g,"").normalize("NFC").replace(/[^a-z0-9]/gim, "-")
        : "";