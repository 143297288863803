import {DEFAULT_MAX_WIDTH, MARINE} from "../../../utils/constants";

const layoutStyles = theme => ({
    defaultRow: {
        display: 'flex',
        flexFlow: 'wrap row',
    },
    defaultRowNoWrap: {
        display: 'flex',
        flexFlow: 'nowrap row',
    },
    spaceBetweenRow: {
        display: 'flex',
        flexFlow: 'wrap row',
        justifyContent: 'space-between',
    },
    defaultColumn: {
        display: 'flex',
        flexFlow: 'wrap column',
    },
    marginOne: {
        margin: theme.spacing(1),
    },
    marginTopOne: {
        marginTop: theme.spacing(1),
    },
    marginBottomOne: {
        marginBottom: theme.spacing(1),
    },
    marginVertOne: {
        margin: theme.spacing(1, 0),
    },
    maxWidth: {
        width: '100%',
        maxWidth: DEFAULT_MAX_WIDTH,
    },
    component: {
        width: '100%',
        maxWidth: DEFAULT_MAX_WIDTH,
        padding: theme.spacing(2),
        boxSizing: "border-box",
    },
    fullWidth: {
        width: '100%',
    },
    helperText: {
        textAlign: 'right',
    },
    helperTextError: {
        color: 'red',
    },
    link: {
        textDecoration: 'none',
        color: MARINE,
        '&:hover': {
            textDecoration: 'underline',
        }
    },
    half: {
        width: '48%',
        marginLeft: theme.spacing(1),
        [theme.breakpoints.down('md')]: {
            width: '100%',
        },
    },
    quarter: {
        width: '24%',
        marginLeft: theme.spacing(1),
        [theme.breakpoints.down('md')]: {
            width: '100%',
        },
    },
    zTwo: {
        zIndex: 2,
    },
    zThree: {
        zIndex: 3,
    },
    zFour: {
        zIndex: 4,
    },
});

export default layoutStyles;
